import { Dialog, Transition } from '@headlessui/react';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { Fragment, useCallback, useEffect, useState } from 'react';
import Toggle from 'src/components/Toggle';
import { usePricingFlowContext } from '../../PricingFlow';
import {
  PenguinPricingFlow,
  PenguinPricingFlowWithProductVolumes,
  PenguinPricingSheet,
  PenguinProduct,
  TieredPrice,
} from '../../types';

const Settings = ({ children }: { children: React.ReactNode }) => (
  <div className="flex flex-col gap-6 px-4 sm:px-6">{children}</div>
);

const Setting = ({
  onToggle,
  title,
  enabled,
}: {
  onToggle: (enabled: boolean) => void;
  title: string;
  enabled: boolean;
}) => (
  <div className="flex items-center justify-between gap-3">
    <div className="font-medium">{title}</div>
    <Toggle enabled={enabled} onToggle={onToggle} />
  </div>
);

const SectionHeader = ({ title }: { title: string }) => (
  <div className="my-6 bg-gray-100 px-6 py-2 text-sm uppercase text-gray-500">
    {title}
  </div>
);

const Callout = ({
  title,
  content,
  href,
}: {
  title: string;
  content: string;
  href?: string;
}) => {
  return (
    <div className="flex gap-2 rounded-lg bg-gray-100 px-3 py-3">
      <div className="shrink-0">
        <InformationCircleIcon className="h-6 w-6 text-gray-500" />
      </div>
      <div>
        <div className="mb-1 font-medium">{title}</div>
        <div className="mb-4 text-gray-500">{content}</div>
        {href && (
          <a href={href} className="font-medium" target="_blank">
            Learn more
          </a>
        )}
      </div>
    </div>
  );
};

interface FormState {
  hasMonthlyMinimumRamp: boolean;
  tiering: {
    tierableProducts: { [productId: string]: boolean };
  };
}

function generateInitialFormState(pricingFlow: PenguinPricingFlow): FormState {
  // Only show products the user has selected and that supports tiering, and check if tiering is enabled
  console.log(pricingFlow.products);
  const tierableProducts = pricingFlow.products
    .filter((p: PenguinProduct) => p.tiered)
    .reduce(
      (acc, p) => {
        acc[p.id] =
          pricingFlow.manualQuote?.products?.[p.id]?.type === 'tiered';
        return acc;
      },
      {} as { [productName: string]: boolean },
    );

  return {
    hasMonthlyMinimumRamp:
      pricingFlow.additionalData?.minimumRampUp !== undefined,
    tiering: {
      tierableProducts,
    },
  };
}

function generateTieredProductQuote(
  flow: PenguinPricingFlowWithProductVolumes,
  productId: string,
): TieredPrice {
  const product = flow.products.find((p) => p.id === productId);
  if (product == null) {
    throw new Error(`Could not find product ${productId}`);
  }

  const expectedVolume = product.volume;
  if (expectedVolume == null) {
    throw new Error(`Tiered product missing volume ${productId}`);
  }

  const price = flow.recommendedQuote.products[productId].price;

  const productInfo = (flow.pricingSheetData as PenguinPricingSheet)
    .countryPricingSheets['us'].productInfo;
  const stepSize = productInfo[productId].pricingTierStepSize ?? 1000;

  const tiers = [
    { minimum: 1, price },
    { minimum: stepSize, price },
    { minimum: stepSize * 2, price },
  ];
  return { type: 'tiered', tiers };
}

function generateQuoteProductsWithTiers(
  pricingFlow: PenguinPricingFlowWithProductVolumes,
  tierableProducts: FormState['tiering']['tierableProducts'],
): PenguinPricingFlowWithProductVolumes['manualQuote']['products'] {
  const { manualQuote } = pricingFlow;

  const oldQuote = manualQuote?.products ?? {};
  const newQuote: PenguinPricingFlow['manualQuote']['products'] = {};

  for (const productId of Object.keys(tierableProducts)) {
    const isEnabled = tierableProducts[productId];
    if (isEnabled) {
      const oldPrice = oldQuote?.[productId];
      if (oldPrice?.type === 'tiered') {
        // Don't clear tiers if they already exist
        newQuote[productId] = oldPrice;
      } else {
        newQuote[productId] = generateTieredProductQuote(
          pricingFlow,
          productId,
        );
      }
    }
  }
  return newQuote;
}

export default function AdvancedSettings(props: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { open, setOpen } = props;
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<PenguinPricingFlowWithProductVolumes>();

  const [formState, setRawFormState] = useState(() =>
    generateInitialFormState(pricingFlow),
  );
  useEffect(() => {
    setRawFormState(generateInitialFormState(pricingFlow));
  }, [pricingFlow]);

  // Deep merge when updating form state to allow merging partial state
  const setFormState = useCallback(
    // Technically this should be a deep partial, but it doesn't matter here
    // because the values are already partial
    (newState: Partial<typeof formState>) => {
      setRawFormState(_.merge({}, formState, newState));
    },
    [formState, setRawFormState],
  );

  const saveChanges = useCallback(() => {
    const { manualQuote, additionalData } = pricingFlow;

    updateFlow(
      {
        ...pricingFlow,
        manualQuote: {
          ...manualQuote,
          products: generateQuoteProductsWithTiers(
            pricingFlow,
            formState.tiering.tierableProducts,
          ),
        },
      },
      false,
    );
  }, [pricingFlow, updateFlow, formState]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="flex text-xl font-medium leading-6 text-gray-900">
                        <div>Advanced Settings</div>
                        <div className="flex grow flex-row-reverse">
                          <button
                            type="button"
                            className="relative rounded-md text-gray-700 hover:text-black focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </Dialog.Title>
                    </div>
                    <SectionHeader title="Tiered Options" />
                    <Settings>
                      {Object.keys(formState.tiering.tierableProducts).map(
                        (productId) => {
                          const product = pricingFlow.products.find(
                            (p) => p.id === productId,
                          );
                          if (product == null) {
                            throw new Error(
                              `Could not find product ${productId}`,
                            );
                          }
                          const isEnabled =
                            formState.tiering.tierableProducts[productId];
                          return (
                            <Setting
                              key={productId}
                              title={product.name}
                              enabled={isEnabled}
                              onToggle={() => {
                                setFormState({
                                  tiering: {
                                    tierableProducts: {
                                      [productId]: !isEnabled,
                                    },
                                  },
                                });
                              }}
                            />
                          );
                        },
                      )}
                      <Callout
                        title="How to edit tiers"
                        content="Enabling the tiered option grants you the ability to make direct edits to tiers from the pricing table."
                      />
                      <div className="flex flex-row items-center gap-3">
                        <button
                          type="button"
                          className="col-span-full flex-1 justify-center rounded-lg border border-gray-200 bg-white px-5 py-2 font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
                          onClick={() => {
                            setOpen(false);
                            // Reset form
                            setFormState(generateInitialFormState(pricingFlow));
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="col-span-full flex-1 justify-center rounded-lg border border-fuchsia-900 bg-fuchsia-900 px-5 py-2 font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
                          onClick={() => {
                            saveChanges();
                            setOpen(false);
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </Settings>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
