import Tooltip from 'src/components/Tooltip';
import { formatCurrency } from 'src/dashboard/utils';
import {
  PenguinManualQuote,
  PenguinPricingFlowWithProductVolumes,
  PenguinProductPrices,
  PenguinProductWithVolume,
  PenguinRecommendedQuote,
  PricingFlowType,
} from '../../types';
import {
  calculateCostForEachMonth,
  monthlyRevenueForQuote,
} from './PenguinQuoteTable';

function calculateSupportCostFixedMonthly(params: {
  products: PenguinProductWithVolume[];
  productPrices: PenguinProductPrices;
  recommendedQuote: PenguinRecommendedQuote;
  manualQuote: PenguinManualQuote;
}): number {
  const { products, productPrices, recommendedQuote, manualQuote } = params;
  return products.reduce((acc, product: PenguinProductWithVolume) => {
    if (productPrices[product.id].skuGroup === 'Support') {
      acc += monthlyRevenueForQuote(
        manualQuote?.products?.[product.id] ??
          recommendedQuote.products?.[product.id],
        product.volume,
        product.transactionSize,
        productPrices[product.id],
      );
    }
    return acc;
  }, 0);
}

/**
 * Calculate contractual revenue cost per month
 * @param params
 * @returns an array of the monthly costs for the contractual revenue from month 1 to month subscriptionTerms
 */
function calculateMinimumAndSupportCostDynamicPerMonth(params: {
  productPrices: PenguinProductPrices;
  pricingFlow: PenguinPricingFlowWithProductVolumes;
}): number[] {
  const { productPrices, pricingFlow } = params;
  const { products, recommendedQuote, manualQuote } = pricingFlow;

  // Calculate the monthly support products cost
  const supportCostMonthly = calculateSupportCostFixedMonthly({
    products,
    productPrices,
    recommendedQuote,
    manualQuote,
  });

  // Create an array of the monthly minimums for each month of the subscriptionTerms
  let contractualCostPerMonth: number[] = [];
  for (let i = 0; i < pricingFlow.additionalData.subscriptionTerms; i++) {
    const defaultMonthlyMinimum: number =
      manualQuote?.monthlyMinimum ?? recommendedQuote.monthlyMinimum;

    if (pricingFlow.additionalData?.minimumRampUp == null) {
      // There's no minimum ramp up, so use the fixed monthly minimum
      contractualCostPerMonth.push(defaultMonthlyMinimum + supportCostMonthly);
    } else {
      // There's a minimum ramp up
      const minimum: number =
        typeof pricingFlow.additionalData.minimumRampUp[i] === 'number'
          ? (pricingFlow.additionalData.minimumRampUp[i] as number) // frustrating that I need to typecast this
          : defaultMonthlyMinimum;
      contractualCostPerMonth.push(minimum + supportCostMonthly);
    }
  }

  return contractualCostPerMonth;
}

export default function AnnualCosts(props: {
  pricingFlow: PenguinPricingFlowWithProductVolumes;
  productPrices: PenguinProductPrices;
}) {
  const { pricingFlow, productPrices } = props;

  const numberOfYears = Math.ceil(
    pricingFlow.additionalData.subscriptionTerms / 12,
  );

  /**
   * CONTRACTUAL REVENUE
   */
  // Sum up the minimum costs for each year of the subscriptionTerms
  const contractualCostPerMonth: number[] =
    calculateMinimumAndSupportCostDynamicPerMonth({
      productPrices,
      pricingFlow,
    });

  let minCostPerYear: number[] = [];
  for (let i = 0; i < numberOfYears; i++) {
    let costForYear = 0;
    for (let j = 0; j < 12; j++) {
      const month = i * 12 + j;
      if (month < contractualCostPerMonth.length) {
        costForYear += contractualCostPerMonth[month];
      } else {
        break; // no more months left in subscription terms
      }
    }
    minCostPerYear.push(costForYear);
  }

  /**
   * USAGE BASED REVENUE
   */

  // Calculate the cost based on usage products for each month of the subscriptionTerms
  const productCostPerMonth: number[] = calculateCostForEachMonth({
    products: pricingFlow.products,
    recommendedQuote: pricingFlow.recommendedQuote,
    manualQuote: pricingFlow.manualQuote,
    productPrices: (
      pricingFlow.pricingSheetData.countryPricingSheets as {
        [key: string]: { productInfo: PenguinProductPrices };
      }
    )['us'].productInfo,
    subscriptionTerms: pricingFlow.additionalData.subscriptionTerms,
  });

  // Sum up the cost for each year of the subscriptionTerms
  let productCostPerYear: number[] = [];
  for (let i = 0; i < numberOfYears; i++) {
    let costForYear = 0;
    for (let j = 0; j < 12; j++) {
      const month = i * 12 + j;
      if (month < productCostPerMonth.length) {
        costForYear += productCostPerMonth[month];
      } else {
        break; // no more months left in subscription terms
      }
    }
    productCostPerYear.push(costForYear);
  }

  /**
   * COMBINE
   */

  // Combine minCostPerYear and productCostPerYear into a single array for display ease
  // costPerYear = {minCost: number, productCost: number}
  let costPerYear: { minCost: number; productCost: number }[] = [];
  for (let i = 0; i < numberOfYears; i++) {
    costPerYear.push({
      minCost: minCostPerYear[i],
      productCost: productCostPerYear[i],
    });
  }

  // @ts-ignore
  const IS_COMPLEX_DEMO = pricingFlow.type === PricingFlowType.COMPLEX_DEMO;
  // Find the recommended price of the Support products and add them together
  const supportBasicPriceRecommended = pricingFlow.recommendedQuote.products[
    'Developer Support (Basic)'
  ]
    ? pricingFlow.recommendedQuote.products['Developer Support (Basic)'].price
    : undefined;
  console.log('supportBasicPriceRecommended ', supportBasicPriceRecommended);
  const supportBasicPriceManual =
    pricingFlow.manualQuote?.products &&
    pricingFlow.manualQuote?.products['Developer Support (Basic)'] &&
    pricingFlow.manualQuote?.products['Developer Support (Basic)'].type ===
      'fixed'
      ? pricingFlow.manualQuote.products['Developer Support (Basic)'].price
      : undefined;
  console.log('supportBasicPriceManual ', supportBasicPriceManual);
  const supportBasicPrice =
    supportBasicPriceManual ?? supportBasicPriceRecommended ?? undefined;
  console.log('supportBasicPrice ', supportBasicPrice);
  const supportPremiumRecommended = pricingFlow.recommendedQuote.products[
    'Developer Support (Premium)'
  ]
    ? pricingFlow.recommendedQuote.products['Developer Support (Premium)'].price
    : undefined;
  console.log('supportPremiumRecommended ', supportPremiumRecommended);
  const supportPremiumManual =
    pricingFlow.manualQuote?.products &&
    pricingFlow.manualQuote?.products['Developer Support (Premium)'] &&
    pricingFlow.manualQuote?.products['Developer Support (Premium)'].type ===
      'fixed'
      ? pricingFlow.manualQuote.products['Developer Support (Premium)'].price
      : undefined;
  console.log('supportPremiumManual ', supportPremiumManual);
  const supportPremiumPrice =
    supportPremiumManual ?? supportPremiumRecommended ?? undefined;
  console.log('supportPremiumPrice ', supportPremiumPrice);
  // Add all the support prices together
  const hasSupport =
    supportBasicPrice !== undefined || supportPremiumPrice !== undefined;
  const supportPricePercentage = hasSupport
    ? (supportBasicPrice ?? 0) + (supportPremiumPrice ?? 0)
    : undefined;
  console.log('supportPricePercentage ', supportPricePercentage);

  return (
    <div className="mt-4 flex w-full rounded-xl border border-gray-200 bg-white">
      <table className="min-w-full border-separate border-spacing-0">
        <thead>
          <tr>
            <th
              scope="col"
              className="rounded-tl-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
            >
              Total
            </th>
            <th
              scope="col"
              className="has-tooltip rounded-tl-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
            >
              <Tooltip
                as="th"
                scope="col"
                className="has-tooltip text-left text-xs font-medium text-gray-700 backdrop-filter"
                location="TOP"
                text="Annual revenue from monthly minimums and support fees"
              >
                <>Contractually committed revenue</>
              </Tooltip>
            </th>
            <th
              scope="col"
              className="rounded-tl-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
            >
              <Tooltip
                as="th"
                scope="col"
                className="has-tooltip text-left text-xs font-medium text-gray-700 backdrop-filter"
                location="TOP"
                text="Annual revenue based on usage"
              >
                <>Usage-based revenue</>
              </Tooltip>
            </th>
            {IS_COMPLEX_DEMO && (
              <th
                scope="col"
                className="rounded-tl-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
              >
                <Tooltip
                  as="th"
                  scope="col"
                  className="has-tooltip text-left text-xs font-medium text-gray-700 backdrop-filter"
                  location="TOP"
                  text="Revenue from developer support"
                >
                  <>Support revenue</>
                </Tooltip>
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          {costPerYear.map((costs, idx) => {
            const { minCost, productCost } = costs;
            // For each year, display the number of billable months
            // which may be less than 12 in the last year
            const numberOfBillableMonths =
              idx < numberOfYears - 1
                ? 12
                : pricingFlow.additionalData.subscriptionTerms % 12 === 0
                  ? 12
                  : pricingFlow.additionalData.subscriptionTerms % 12;
            return (
              <tr key={idx}>
                <td className="text-md px-6 py-3 text-gray-500">
                  Year {idx + 1} ({numberOfBillableMonths} month
                  {numberOfBillableMonths === 1 ? '' : 's'})
                </td>
                <td className="text-md relative px-6 py-3 font-semibold text-gray-500">
                  {formatCurrency({
                    amount: minCost,
                    currency: 'USD',
                    rounding: true,
                  })}
                </td>
                <td className="text-md px-6 py-3 font-semibold text-gray-500">
                  {formatCurrency({
                    amount: productCost,
                    currency: 'USD',
                    rounding: true,
                  })}
                </td>
                {IS_COMPLEX_DEMO && (
                  <td className="text-md px-6 py-3 font-semibold text-gray-500">
                    {typeof supportPricePercentage === 'number'
                      ? formatCurrency({
                          amount: (productCost * supportPricePercentage) / 100,
                          currency: 'USD',
                          rounding: true,
                        })
                      : 'N/A'}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
