import {
  CubeTransparentIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import _ from 'lodash';
import { useState } from 'react';
import { ProductSelectField } from 'src/components/Fields';
import { User } from '../../../../types';
import StepDetailsTopbar from '../Components/StepDetailsTopbar';

import { datadogRum } from '@datadog/browser-rum';
import { useToast } from 'src/components/Toast';
import { classNames } from 'src/dashboard/App';
import { usePricingFlowContext } from '../../PricingFlow';
import {
  PenguinAdditionalData,
  PenguinCategories,
  PenguinPricingFlow,
  PenguinPricingSheet,
  PenguinProduct,
  PenguinProductPrices,
  PricingFlowStage,
} from '../../types';
import BottomBar from '../Components/BottomBar';
import OpportunityOverviewBar from '../Components/OpportunityOverviewBar';
import { PenguinFlowSteps, VOLUME_INPUTS } from '../PenguinPricingFlowPage';
const ALL_CATEGORIES_LABEL = 'All';

// START TRANSFER RISK ENGINE ADD-ON CONSTANTS

/**
 * This is a list of the parentIds for the vanilla originator and high risk transfer products
 * These are used to power the add-ons display logic. It's a bit of a hack, but it works.
 * More details here: https://www.notion.so/dealops/Transfer-Risk-Engine-as-add-on-2437c66b867a4419a6dca56b44141eb6?pvs=4#6d03e0f6911045b5a300c61511a7001b
 */

const vanillaOriginatorParentIds = [
  '38e2ecbf-ae8d-4894-a69c-b5b7f8492eee', // (Staging Env: Plaid Staging)
  '90ea0f7c-b1a9-4323-9efb-43d8cfe422fd',
  '34c828b7-8672-4879-be96-8bdda9bc0a47',
  '76579835-799f-4bd2-9a1a-db2be44538c8',
  '244339d2-f660-4754-9997-17961e78262e', // (Prod Env: Plaid)
  'bf6398a5-07e3-43c7-9902-664c93a8018a',
  'f7bcfd19-f7be-4bd3-af4e-ae31290c9a35',
  'acccab5c-9f6c-4de5-aea1-38ca289c1b76',
  '3d3b1d4f-b5dd-40dc-9128-1db77c0c0dec', // (Prod Env: Plaid Staging)
  '13173a8f-371a-46d3-b91d-c888f511909f',
  'b8f05ac6-fd5d-4c50-8164-85eeb15f41d3',
  'a341c31d-48a1-41e4-93d4-ddcc60afd322',
];

const highRiskParentIds = [
  '52cab07f-12f6-4f4b-ad9b-231cf861ca4b', // (Staging Env: Plaid Staging)
  'bedeefbc-6b9c-45e2-b206-52164ccfbe20',
  '064d55ac-049e-4909-8b04-daeaad9c46ee',
  'c62f61ee-325b-4f43-9a90-dd2bb2bc30be',
  '12251c00-095e-48ac-939c-49449734af9d', // (Prod Env: Plaid)
  '221ca55f-e188-45fa-8ebf-adfaa6b57890',
  'fd2c53e6-fbe5-4a23-bea4-f739c2eba3ab',
  'b1ee8886-797f-40ac-8cfb-940811640d91',
  '4d5149d1-0c7f-42d7-b983-86042f371a96', // (Prod Env: Plaid Staging)
  'effaea09-079c-4e17-a73f-48357593a34d',
  '74c753d0-bbb4-4c5a-9c12-ee3fd4ff6b75',
  'be979e89-dfe1-40d4-aa17-01f051597972',
];

const TransferRiskEngineVanillaAddOnIds = [
  'ec1c1a0a-c650-4955-a8a0-696a13e7ac51', // (Staging Env: Plaid Staging)
  '64b0a1c2-a72b-480a-b34f-1881f8c25923', // (Prod Env: Plaid)
  '58ae4fd0-1438-4f3f-be23-2103cf3a92b0', // (Prod Env: Plaid Staging)
];
const TransferRiskEngineHighRiskAddOnIds = [
  '1a3ab50b-4c2b-422b-a8a0-bc08e6a26794', // (Staging Env: Plaid Staging)
  '30283649-bcad-4ea6-aaa5-19a3966b69ad', // (Prod Env: Plaid)
  '876d34d8-3d41-49ab-bbed-0722832f5317', // (Prod Env: Plaid Staging)
];

// END TRANSFER RISK ENGINE ADD-ON CONSTANTS

export const updatePricingFlowWithProducts = (params: {
  products: PenguinProduct[];
  pricingFlow: PenguinPricingFlow;
  updateFlow: (pricingFlow: PenguinPricingFlow, showLoading?: boolean) => void;
}) => {
  const { products, pricingFlow, updateFlow } = params;
  // Check if products includes a product with skuGroup = 'Support'
  const pricingSheetData = pricingFlow.pricingSheetData as PenguinPricingSheet;
  const productInfo = pricingSheetData.countryPricingSheets['us']
    .productInfo as PenguinProductPrices;
  const hasSupportPackage = products.some((product) => {
    return productInfo[product.id].skuGroup === 'Support';
  });

  const supportPackageTimezone = hasSupportPackage
    ? pricingFlow.additionalData?.supportPackageTimezone ?? 'UTC'
    : null;

  updateFlow(
    {
      ...pricingFlow,
      products: products,
      additionalData: {
        ...(pricingFlow.additionalData as PenguinAdditionalData),
        supportPackageTimezone,
      },
    },
    false,
  );
};
export default function Step2ProductSelection(props: {
  validateStep: (pricingFlow: PenguinPricingFlow) => {
    value: boolean;
    error: string | null;
  };
  user: User;
  previousStage: () => void;
}) {
  const { previousStage, validateStep } = props;
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<PenguinPricingFlow>();
  const { showToast } = useToast();

  const products = (pricingFlow?.products as PenguinProduct[]) ?? [];

  const setProducts = (products: PenguinProduct[]) => {
    updatePricingFlowWithProducts({ products, updateFlow, pricingFlow });
  };

  const setSupportPackageTimezone = (timezone: string | null) => {
    updateFlow(
      {
        ...pricingFlow,
        additionalData: {
          ...(pricingFlow.additionalData as PenguinAdditionalData),
          supportPackageTimezone: timezone,
        },
      },
      false,
    );
  };
  const pricingSheetData = pricingFlow.pricingSheetData as PenguinPricingSheet;

  const submit = async () => {
    const { value, error } = validateStep({
      ...pricingFlow,
      products: products,
    });
    if (!value) {
      if (error) {
        showToast({
          title: error,
          subtitle: '',
          type: 'error',
        });
      }
      return;
    }

    updateFlow({
      ...pricingFlow,
      products: products,
      stage: PricingFlowStage.CALCULATE_PRICE,
      additionalData: {
        ...(pricingFlow.additionalData as PenguinAdditionalData),
        customStage: PenguinFlowSteps[PenguinFlowSteps.indexOf(VOLUME_INPUTS)],
      },
    });
  };

  return (
    <div className="flex w-full grow flex-col overflow-hidden">
      <div className="flex h-full w-full grow flex-row">
        <OpportunityOverviewBar opportunityData={pricingFlow.opportunityData} />
        <ProductSelectionForm
          products={products}
          productPrices={
            pricingSheetData.countryPricingSheets['us']
              .productInfo as PenguinProductPrices
          }
          setProducts={setProducts}
          setSupportPackageTimezone={setSupportPackageTimezone}
          supportPackageTimezone={
            pricingFlow.additionalData?.supportPackageTimezone
          }
        />
      </div>
      <BottomBar
        primaryButtonProps={{ label: 'Next', onClick: submit }}
        secondaryButtonProps={{
          label: 'Back',
          onClick: async () => previousStage(),
        }}
        helpText={
          products.length +
          ' product' +
          (products.length === 1 ? '' : 's') +
          ' selected'
        }
      />
    </div>
  );
}

const Tab = ({
  name,
  selected,
  onClick,
  count,
}: {
  name: string;
  selected: boolean;
  onClick: (tab: string) => void;
  count?: number;
}) => {
  return (
    <button
      name={name}
      onClick={() => {
        onClick(name);
      }}
    >
      <div
        className={classNames(
          'flex w-fit rounded-md px-3 py-2 text-sm font-semibold',
          selected
            ? 'bg-fuchsia-100 text-fuchsia-950'
            : 'text-fuchsia-800 hover:bg-fuchsia-50 hover:text-fuchsia-950',
        )}
      >
        {name}
        {!!count && name !== ALL_CATEGORIES_LABEL && (
          <span
            className={classNames(
              selected ? 'bg-fuchsia-100' : 'bg-fuchsia-50',
              'ml-2 inline-flex h-4 items-center justify-center self-center rounded-full px-2 py-2.5 text-xs font-semibold text-fuchsia-950',
            )}
          >
            {count}
          </span>
        )}
      </div>
    </button>
  );
};

function getSkuGroups(skus: PenguinProductPrices): string[] {
  const hardcodedFirstSkuGroups = [
    // expected skuGroups for penguin
    'Core & Assets',
    'IDV',
    'Income',
    'Transfers',
    'Signal',
    'Enrich',
    'CRA Products',
    'Hosted Links',
    // expected skuGroups for complex_demo
    'Authentication and Security',
    'Payment Processing',
    'AI and Data Services',
    'Developer Tools and Support',
    'Analytics and Reporting',
  ];
  const hardcodedLastSkuGroups = ['Support', 'Implementation Services'];

  // We look to see if there are any Sku groups that were not captured in the hardcoded lists
  const skuGroups = new Set();
  if (skus == null) {
    return [];
  }
  Object.values(skus).forEach((sku) => {
    if (
      !hardcodedFirstSkuGroups.includes(sku.skuGroup) &&
      !hardcodedLastSkuGroups.includes(sku.skuGroup)
    ) {
      skuGroups.add(sku.skuGroup);
    }
  });
  const unknownSkuGroups = Array.from(skuGroups) as string[];
  if (skuGroups.size !== 0) {
    // This is unexpected – send an error to Datadog but don't throw an error to the user
    console.error('found unknown sku groups: ', unknownSkuGroups);
    datadogRum.addError(new Error('found unknown sku groups'), {
      skuGroups: JSON.stringify(unknownSkuGroups),
    });
  }

  return [
    ...hardcodedFirstSkuGroups,
    ...unknownSkuGroups,
    ...hardcodedLastSkuGroups,
  ];
}

const ProductSelectionForm = (props: {
  products: PenguinProduct[];
  productPrices: PenguinProductPrices;
  setProducts: (products: PenguinProduct[]) => void;
  setSupportPackageTimezone: (timezone: string | null) => void;
  supportPackageTimezone: string | null;
}) => {
  const {
    products,
    productPrices,
    setProducts,
    setSupportPackageTimezone,
    supportPackageTimezone,
  } = props;
  const { pricingFlow } = usePricingFlowContext<PenguinPricingFlow>();

  const additionalData = pricingFlow?.additionalData as PenguinAdditionalData;

  const pricingSheetData = pricingFlow.pricingSheetData as PenguinPricingSheet;
  const allCategories = pricingSheetData.countryPricingSheets['us']
    .categories as PenguinCategories;

  let chosenCategories: { name: string; id: string }[] = [
    ...(additionalData?.productCategories?.map((id) => ({
      name: allCategories[id].name,
      id: id,
    })) ?? []),
    { name: ALL_CATEGORIES_LABEL, id: ALL_CATEGORIES_LABEL },
  ];

  const [currentTab, setCurrentTab] = useState<string>(
    chosenCategories[0].name,
  );
  const [searchQuery, setSearchQuery] = useState('');

  // Map each category to the # of selected products in that category.
  let productCountPerCategory: { [category: string]: number } = {};
  chosenCategories.forEach((chosenCategory) => {
    let count = 0;
    if (chosenCategory.name === ALL_CATEGORIES_LABEL) {
      count = Object.keys(products).length;
    } else {
      const category = allCategories[chosenCategory.id];
      if (category !== undefined) {
        count = Object.values(category.subcategories)
          .map((subGroup) => {
            const parentProductCount = products.filter((product) => {
              return subGroup.products.includes(product.id);
            }).length;

            const addOnsCount = Object.values(productPrices).filter(
              (productPrice) => {
                if (productPrice.parentProduct !== undefined) {
                  return (
                    products.find(
                      (product) => product.id === productPrice.id,
                    ) !== undefined &&
                    subGroup.products.includes(
                      productPrice.parentProductId as string,
                    )
                  );
                }
              },
            ).length;

            return parentProductCount + addOnsCount;
          })
          .reduce((acc, val) => acc + val, 0);
      }
    }
    productCountPerCategory[chosenCategory.id] = count;
  });

  return (
    <div className="w-full transform overflow-auto rounded-2xl bg-white px-6 pb-28 text-left align-middle transition-all">
      <StepDetailsTopbar
        stepName="Add Products"
        stepDescription="Select the products you want to include in your quote."
        stepIcon={
          <CubeTransparentIcon className="h-6 w-6" aria-hidden="true" />
        }
      />
      {/* Nav Bar */}
      <div className="my-4 flex flex-col items-center justify-between sm:flex-row">
        <div className="flex gap-2 sm:mb-0">
          {chosenCategories.map((category) => (
            <Tab
              key={category.id}
              name={category.name}
              selected={category.name == currentTab}
              onClick={setCurrentTab}
              count={productCountPerCategory[category.id]}
            />
          ))}
        </div>
        <SearchInput onChange={setSearchQuery} />
      </div>

      <hr className="mt-2 px-6"></hr>
      <ProductGrid
        productGroups={allCategories}
        currentTab={currentTab}
        searchQuery={searchQuery}
        productPrices={productPrices}
        products={products}
        setProducts={setProducts}
        setSupportPackageTimezone={setSupportPackageTimezone}
        supportPackageTimezone={supportPackageTimezone}
        // @ts-ignore
        isComplexDemo={pricingFlow.type === 'COMPLEX_DEMO'}
      />
    </div>
  );
};

const ProductGrid = ({
  productGroups,
  currentTab,
  searchQuery,
  productPrices,
  products,
  setProducts,
  setSupportPackageTimezone,
  supportPackageTimezone,
  isComplexDemo = false,
}: {
  productGroups: PenguinCategories;
  currentTab: string;
  searchQuery: string;
  productPrices: PenguinProductPrices;
  products: PenguinProduct[];
  setProducts: (products: PenguinProduct[]) => void;
  setSupportPackageTimezone: (timezone: string | null) => void;
  supportPackageTimezone: string | null;
  isComplexDemo?: boolean;
}) => {
  const filteredProductPrices: PenguinProductPrices = Object.values(
    productPrices,
  )
    .filter((productPrice: any) => {
      return (
        !searchQuery ||
        productPrice.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    })
    .reduce((acc: any, productPrice: any) => {
      acc[productPrice.id] = productPrice;
      return acc;
    }, {});

  // Switch it to all
  if (currentTab === ALL_CATEGORIES_LABEL) {
    return (
      <div className="mt-4 flex flex-col gap-5">
        {getSkuGroups(productPrices).map((skuGroup) => {
          return (
            <ProductSubGroupSection
              key={skuGroup}
              title={skuGroup}
              productIds={Object.keys(filteredProductPrices)
                .filter((productPrice) => {
                  return (
                    productPrices[productPrice].skuGroup === skuGroup &&
                    productPrices[productPrice].parentProduct == null
                  );
                })
                .sort(
                  (a, b) =>
                    productPrices[a].displayOrder -
                    productPrices[b].displayOrder,
                )}
              products={products}
              productPrices={filteredProductPrices}
              setProducts={setProducts}
              setSupportPackageTimezone={setSupportPackageTimezone}
              supportPackageTimezone={supportPackageTimezone}
              isComplexDemo={isComplexDemo}
            />
          );
        })}
      </div>
    );
  } else {
    // Each subgroup render a subsection
    const currentTabId = Object.keys(productGroups).find(
      (key) => productGroups[key].name === currentTab,
    );
    if (!currentTabId) {
      datadogRum.addError(new Error('ProductGroup ID is not found for a tab'), {
        skuGroups: JSON.stringify({ currentTabName: currentTab }),
      });
      return <></>;
    }
    let sections = Object.keys(productGroups[currentTabId].subcategories).map(
      (subctegoryId) => {
        const filteredProductIds = productGroups[currentTabId].subcategories[
          subctegoryId
        ].products.filter((productId) => {
          return filteredProductPrices[productId] !== undefined;
        });

        const subGroupName =
          productGroups[currentTabId].subcategories[subctegoryId].name;

        return (
          <ProductSubGroupSection
            key={subctegoryId}
            title={subGroupName}
            productIds={filteredProductIds}
            products={products}
            productPrices={filteredProductPrices}
            setProducts={setProducts}
            setSupportPackageTimezone={setSupportPackageTimezone}
            supportPackageTimezone={supportPackageTimezone}
            isComplexDemo={isComplexDemo}
          />
        );
      },
    );
    sections.push(
      <ProductSubGroupSection
        key={'no-group'}
        title={''}
        productIds={productGroups[currentTabId]?.products}
        products={products}
        productPrices={productPrices}
        setProducts={setProducts}
        setSupportPackageTimezone={setSupportPackageTimezone}
        supportPackageTimezone={supportPackageTimezone}
        isComplexDemo={isComplexDemo}
      />,
    );

    return <div className="mt-4 flex flex-col gap-5">{sections}</div>;
  }
};

const ProductSubGroupSection = ({
  title,
  productIds,
  products,
  productPrices,
  setProducts,
  setSupportPackageTimezone,
  supportPackageTimezone,
  isComplexDemo = false,
}: {
  title: string;
  productIds: string[];
  products: PenguinProduct[];
  productPrices: PenguinProductPrices;
  setProducts: (products: PenguinProduct[]) => void;
  setSupportPackageTimezone: (timezone: string | null) => void;
  supportPackageTimezone: string | null;
  isComplexDemo?: boolean;
}) => {
  const { showToast } = useToast();

  if (productIds.length === 0) {
    return null;
  }

  const isProductSelected = (id: string) => {
    return products.find((product) => product.id === id) !== undefined;
  };

  const shouldShowProduct = (
    id: string,
    productPrices: PenguinProductPrices,
  ) => {
    return productPrices[id]?.isActive !== false;
  };

  const getAddOnsForProduct = (
    id: string,
    productPrices: PenguinProductPrices,
  ) => {
    let addOns = Object.keys(productPrices)
      // filter out products that should not be shown
      .filter((id) => shouldShowProduct(id, productPrices))
      .filter((productPriceId) => {
        // Transfer Risk Engine Custom Logic
        if (
          vanillaOriginatorParentIds.includes(id) &&
          TransferRiskEngineVanillaAddOnIds.includes(productPriceId)
        ) {
          return true;
        }

        if (
          highRiskParentIds.includes(id) &&
          TransferRiskEngineHighRiskAddOnIds.includes(productPriceId)
        ) {
          return true;
        }
        // End Transfer Risk Engine Custom Logic

        return productPrices[productPriceId].parentProductId === id;
      })
      .map((productPrice) => {
        return {
          id: productPrices[productPrice].id,
          name: productPrices[productPrice].name,
          checked:
            products.find(
              (product) => product.id === productPrices[productPrice].id,
            ) !== undefined,
        };
      });

    return addOns;
  };

  const transferRiskEngineAddonUnselectCheck = (
    parentId: string,
    addOn: PenguinProduct,
    transferParentIds: string[],
    transferAddOnIds: string[],
  ) => {
    if (
      transferParentIds.includes(parentId) &&
      transferAddOnIds.includes(addOn.id)
    ) {
      // check if another parent is in the set of products
      let remainingTransferParentIds = transferParentIds.filter(
        (pId) => parentId !== pId,
      );
      if (
        products.find((product) =>
          remainingTransferParentIds.includes(product.id),
        )
      ) {
        // if so, keep the addon
        return true;
      }
    }
    return false;
  };

  const unselectProduct = ({
    id,
    products,
    productPrices,
  }: {
    id: string;
    products: PenguinProduct[];
    productPrices: PenguinProductPrices;
  }): PenguinProduct[] => {
    const addOns = getAddOnsForProduct(id, productPrices);

    // if product has add-ons, remove the add-ons as well
    return products.filter((product) => {
      // Transfer Risk Engine Add-ons Custom Logic
      if (
        transferRiskEngineAddonUnselectCheck(
          id,
          product,
          vanillaOriginatorParentIds,
          TransferRiskEngineVanillaAddOnIds,
        )
      ) {
        return true;
      }
      if (
        transferRiskEngineAddonUnselectCheck(
          id,
          product,
          highRiskParentIds,
          TransferRiskEngineHighRiskAddOnIds,
        )
      ) {
        return true;
      }
      return (
        product.id !== id && !addOns.find((addOn) => addOn.id === product.id)
      );
    });
  };

  const selectProduct = ({
    id,
    products: selectedProducts,
    productPrices,
  }: {
    id: string;
    products: PenguinProduct[];
    productPrices: PenguinProductPrices;
  }): PenguinProduct[] => {
    const productPrice = productPrices[id];
    const name = productPrice.name;
    const exclusiveGroup = productPrice.exclusiveGroup;

    if (exclusiveGroup !== undefined) {
      // if a product is in an exclusive group we need to remove all other members
      const exclusiveGroupMembers = Object.values(productPrices)
        .filter((p) => p.exclusiveGroup === exclusiveGroup)
        .map((p) => p.id);

      // find all of the currently selected products with the same exclusiveGroup
      const selectedProductsMember = selectedProducts.map((p) => p.id);
      const selectedExclusiveGroupMembers = _.intersection(
        exclusiveGroupMembers,
        selectedProductsMember,
      );

      if (selectedExclusiveGroupMembers.length !== 0) {
        // if there are any selected products in the exclusiveGroup unselect and notify the user
        showToast({
          title: `You can only select one product from the "${exclusiveGroup}" group.`,
          subtitle: '',
          type: 'info',
        });

        // unselect all products in the exclusive group
        const productsWithoutGroupMembers =
          selectedExclusiveGroupMembers.reduce(
            (currentProducts, groupMemeber) =>
              unselectProduct({
                products: currentProducts,
                id: groupMemeber,
                productPrices: productPrices,
              }),
            selectedProducts,
          );

        // @TODO(Fay) hackkkk
        // remove all products in the exclusiveGroup "Custom pricing" and "Data Insights Dashboard"
        const productsWithoutMoreGroupMembers =
          productsWithoutGroupMembers.filter((product) => {
            return (
              productPrices[product.id].exclusiveGroup !== 'Custom pricing' &&
              productPrices[product.id].exclusiveGroup !==
                'Data Insights Dashboard'
            );
          });

        return [
          ...(name ===
          'Customized AI Models + Data Insights Dashboard (Basic Bundle)'
            ? productsWithoutMoreGroupMembers
            : productsWithoutGroupMembers),
          {
            name: name,
            volume: productPrice.fixedVolume,
            id,
            rampType: 'fixed',
            ProductCode: productPrice.ProductCode,
            tiered: productPrice.tiered,
          } satisfies PenguinProduct,
        ];
      }
    }

    return [
      ...selectedProducts,
      {
        name,
        volume: productPrice.fixedVolume,
        id,
        rampType: 'fixed',
        ProductCode: productPrice.ProductCode,
        tiered: productPrice.tiered,
      } satisfies PenguinProduct,
    ];
  };

  const toggleSelected = (id: string) => {
    if (isProductSelected(id)) {
      setProducts(unselectProduct({ id, products, productPrices }));
    } else {
      setProducts(selectProduct({ id, products, productPrices }));
    }
  };

  return (
    <div className="flex flex-col items-stretch gap-5">
      {title && (
        <div className="rounded-md bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500">
          {title.toUpperCase()}
        </div>
      )}
      <div className="grid grid-cols-2  gap-2 sm:grid-cols-4">
        {productIds.map((productId) => {
          const productPrice = productPrices[productId];
          if (productPrice == null) {
            return null;
          }

          // feature flags
          if (!shouldShowProduct(productId, productPrices)) {
            return null;
          }

          const selected =
            products.find((product) => product.id === productPrice.id) !==
            undefined;
          const product = products.find(
            (product) => product.id === productPrice.id,
          );

          if (productPrice == null) {
            return null;
          }

          const addOns = getAddOnsForProduct(productId, productPrices);

          console.log('is complex demo: ', isComplexDemo);
          const displayTransactionSize =
            (productPrice.priceType === 'percent' ||
              (productPrice.priceType === 'numerical' &&
                productPrice.priceComputationType === 'dynamic')) &&
            !isComplexDemo;

          const displaySupportPackageTimezone =
            productPrice.skuGroup === 'Support';

          return (
            <ProductSelectField
              key={title + productPrice.name}
              name={productPrice.name}
              id={productPrice.id}
              addOns={addOns}
              description={productPrice.unitDefinition}
              onChange={toggleSelected}
              onTransactionSizeChange={(
                id: string,
                transactionSize: number,
              ) => {
                setProducts(
                  products.map((product) => {
                    if (product.id === id) {
                      return {
                        ...product,
                        transactionSize: transactionSize,
                      };
                    } else {
                      return product;
                    }
                  }),
                );
              }}
              checked={selected}
              transactionSize={
                displayTransactionSize
                  ? product?.transactionSize ?? 0
                  : undefined
              }
              displayTransactionSize={displayTransactionSize}
              setSupportPackageTimezone={setSupportPackageTimezone}
              displaySupportPackageTimezone={displaySupportPackageTimezone}
              supportPackageTimezone={supportPackageTimezone}
            />
          );
        })}
      </div>
    </div>
  );
};
const SearchInput = ({ onChange }: { onChange: (val: string) => void }) => {
  return (
    <div className="flex h-10 w-72 flex-row items-center rounded-lg border border-gray-300 p-2 shadow-sm focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900">
      <MagnifyingGlassIcon
        className="mr-2 h-4 w-4 text-gray-500"
        aria-hidden="true"
      />
      <input
        className="text-md -ml-3 border-none bg-transparent text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
        placeholder={'Search for a product'}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};
