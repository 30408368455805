import { useState } from 'react';
import { Organization, User } from '../../../types';
import {
  PenguinAdditionalData,
  PenguinPricingFlow,
  PricingFlowStage,
} from '../types';
import FlowProgressBar from './Components/FlowProgressBar';
import Step2ProductSelection from './Steps/Step2ProductSelection';
import Step3VolumeSelection from './Steps/Step3VolumeSelection';

import { useToast } from 'src/components/Toast';
import { usePricingFlowContext } from '../PricingFlow';
import PenguinPricingFlowNotStartedPage, {
  validateNotStartedFields,
} from './Components/PricingFlowNotStartedPage';
import Step1ModelSelection from './Steps/Step1ModelSelection';
import Step4PricingCalculator from './Steps/Step4PricingCalculator';
import { VolumeSuggestionsContextProvider } from './Steps/VolumeSuggestionsContext';

export const OPP_OVERVIEW = 'Opp Overview';
export const MODEL_SELECTION = 'Model Selection';
export const PRODUCT_SELECTION = 'Product Selection';
export const VOLUME_INPUTS = 'Volume Inputs';
export const PRICING_ADJUSTMENTS = 'Pricing Adjustments';

export const PenguinFlowSteps = [
  OPP_OVERVIEW,
  MODEL_SELECTION,
  PRODUCT_SELECTION,
  VOLUME_INPUTS,
  PRICING_ADJUSTMENTS,
];

interface StepValidations {
  [key: string]: (pricingFlow: PenguinPricingFlow) => {
    value: boolean;
    error: string | null;
  };
}

const StepValidations: StepValidations = {
  [OPP_OVERVIEW]: (pricingFlow: PenguinPricingFlow) => {
    return validateNotStartedFields({
      startDate: pricingFlow.additionalData?.startDate,
      subscriptionTerms: pricingFlow.additionalData?.subscriptionTerms,
    });
  },
  [MODEL_SELECTION]: (pricingFlow: PenguinPricingFlow) => {
    return { value: true, error: null };
  },
  [PRODUCT_SELECTION]: (pricingFlow: PenguinPricingFlow) => {
    const result = pricingFlow.products && pricingFlow.products.length > 0;
    return {
      value: result,
      error: result ? null : 'Please select at least one product to continue',
    };
  },
  [VOLUME_INPUTS]: (pricingFlow: PenguinPricingFlow) => {
    switch (pricingFlow.type) {
      // @ts-ignore I'm not sure how we stuffed COMPLEX_DEMO into here
      case 'COMPLEX_DEMO':
        const complexDemoValue =
          pricingFlow.products &&
          pricingFlow.products.every((product) => {
            return (
              product.volume != null &&
              product.volume !== undefined &&
              product.volume > 0
            );
          });
        return {
          value: complexDemoValue,
          error: complexDemoValue
            ? null
            : 'Please select volume greater than 0 for all products to continue',
        };
      case 'PENGUIN':
      default:
        const penguinValue =
          pricingFlow.products &&
          pricingFlow.products.every((product) => {
            return (
              product.volume != null &&
              product.volume !== undefined &&
              product.volume >= 0
            );
          });
        return {
          value: penguinValue,
          error: penguinValue
            ? null
            : 'Please select volume for all products to continue',
        };
    }
  },
};

export interface PricingFlowProps {
  user: User;
  organization: Organization;
}

export default function PenguinPricingFlowPage(props: PricingFlowProps) {
  const organization = props.organization;
  const { pricingFlow, updateFlow, loading, restartInteractionTracking } =
    usePricingFlowContext<PenguinPricingFlow>();

  const additionalData = pricingFlow?.additionalData as PenguinAdditionalData;
  const [selectedProductCategories, setSelectedProductCategories] = useState<
    string[]
  >(
    (pricingFlow.additionalData as PenguinAdditionalData)?.productCategories ??
      [],
  );
  const [currentStep, setCurrentStep] = useState<string>(
    additionalData?.customStage ?? OPP_OVERVIEW,
  );

  const { showToast } = useToast();

  const setStage = (stage: string) => {
    const targetStepIndex = PenguinFlowSteps.indexOf(stage);

    for (let i = 0; i < targetStepIndex; i++) {
      const validationStep = PenguinFlowSteps[i];
      const validationResult = StepValidations[validationStep](pricingFlow);
      if (!validationResult.value) {
        showToast({
          title: validationResult.error ?? 'Please complete all previous steps',
          subtitle: '',
          type: 'error',
        });
        stage = validationStep;
        break;
      }
    }

    if (stage === currentStep) {
      return;
    }

    updateFlow({
      ...pricingFlow,
      additionalData: {
        ...(pricingFlow.additionalData as PenguinAdditionalData),
        customStage: stage,
      },
    });
  };

  const nextStage = () => {
    const currentStageIndex = PenguinFlowSteps.indexOf(currentStep);
    const nextStage =
      PenguinFlowSteps[
        Math.min(PenguinFlowSteps.length - 1, currentStageIndex + 1)
      ];
    setCurrentStep(nextStage);

    updateFlow({
      ...pricingFlow,
      additionalData: {
        ...(pricingFlow.additionalData as PenguinAdditionalData),
        customStage: nextStage,
      },
    });
  };

  const previousStage = () => {
    const currentStageIndex = PenguinFlowSteps.indexOf(currentStep);
    const previousStage = PenguinFlowSteps[Math.max(0, currentStageIndex - 1)];
    setCurrentStep(previousStage);

    updateFlow({
      ...pricingFlow,
      additionalData: {
        ...(pricingFlow.additionalData as PenguinAdditionalData),
        customStage: previousStage,
      },
      ...(currentStep === MODEL_SELECTION
        ? { stage: PricingFlowStage.NOT_STARTED }
        : {}),
    });
  };

  if (currentStep === OPP_OVERVIEW) {
    // We don't show the FlowProgressBar for NOT_STARTED stage + OPP_OVERVIEW custom stage
    return (
      <PenguinPricingFlowNotStartedPage
        validateStep={StepValidations[OPP_OVERVIEW]}
      />
    );
  }

  let currentStepSection = null;
  switch (currentStep) {
    case MODEL_SELECTION:
      currentStepSection = (
        <Step1ModelSelection
          selectedProductCategories={selectedProductCategories}
          setSelectedProductCategories={setSelectedProductCategories}
          nextStage={nextStage}
          previousStage={previousStage}
          user={props.user}
          organization={organization}
        />
      );
      break;
    case PRODUCT_SELECTION:
      currentStepSection = (
        <Step2ProductSelection
          user={props.user}
          previousStage={previousStage}
          validateStep={StepValidations[PRODUCT_SELECTION]}
        />
      );
      break;
    case VOLUME_INPUTS:
      currentStepSection = (
        <VolumeSuggestionsContextProvider>
          <Step3VolumeSelection
            user={props.user}
            previousStage={previousStage}
            validateStep={StepValidations[VOLUME_INPUTS]}
          />
        </VolumeSuggestionsContextProvider>
      );
      break;
    case PRICING_ADJUSTMENTS:
      currentStepSection = (
        <Step4PricingCalculator previousStage={previousStage} />
      );
      break;
    default:
      currentStepSection = null;
  }

  return (
    <div className="flex h-full flex-col">
      <FlowProgressBar
        stage={currentStep}
        setStage={setStage}
        customSteps={PenguinFlowSteps}
      />
      {currentStepSection}
    </div>
  );
}
