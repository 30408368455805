import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import api from 'src/api';
import Badge, { BadgeColor } from 'src/components/Badge';
import { NumberField } from 'src/components/Fields';
import { formatCurrency } from 'src/dashboard/utils';
import {
  DealopsApprovalLevels,
  DealopsManualQuote,
  DealopsPricingFlow,
  DealopsProduct,
  DealopsProductPrices,
  DealopsRecommendedQuote,
} from '../types';

export default function DealopsQuoteTable(props: {
  pricingFlow: DealopsPricingFlow;
  updateFlow: (flow: DealopsPricingFlow) => void;
  setStage: (stage: DealopsPricingFlow['stage']) => void;
}) {
  const { pricingFlow, updateFlow } = props;
  const { recommendedQuote, manualQuote, products, approvalLevels } =
    pricingFlow;

  const [productPrices, setProductPrices] = useState<
    DealopsProductPrices | undefined
  >(undefined);
  const country = 'us';

  useEffect(() => {
    api
      .get('calculators/' + pricingFlow.pricingSheet)
      .then((response) => {
        setProductPrices(
          response.data.countryPricingSheets[country].productInfo,
        );
        console.log('DEBUG: load product prices: ');
        console.log(response.data.countryPricingSheets[country].productInfo);
      })
      .catch((error) => {
        datadogRum.addError(error);
        console.log(error);
      });
  }, [pricingFlow.pricingSheet]);

  if (!recommendedQuote || !productPrices) {
    datadogRum.addError('No recommended quote');
    // @TODO(fay) better error message about how we couldn't generate a recommendation and log the error
    return <>Couldn't generate recommended pricing</>;
  }

  function calculateMonthlyCost(
    products: DealopsProduct[],
    recommendedQuote: DealopsRecommendedQuote,
    manualQuote: DealopsManualQuote,
  ) {
    let monthlyCost = 0;
    products.forEach((product) => {
      const manualPrice = manualQuote?.products
        ? manualQuote?.products[product.name]
        : null;
      const quotePrice = manualPrice ?? recommendedQuote.products[product.name];
      monthlyCost += product.volume * quotePrice;
    });
    return monthlyCost;
  }

  const monthlyCost = calculateMonthlyCost(
    products,
    recommendedQuote,
    manualQuote,
  );

  return (
    <div>
      <div className="mt-2 w-full">
        <div className="rounded-xl border border-gray-200 bg-white">
          <table className="min-w-full border-separate border-spacing-0">
            <QuoteTableHeader />
            <tbody>
              {products.length > 0
                ? products.map((product: DealopsProduct, idx) => (
                    <CurrentQuoteTableRow
                      key={idx}
                      product={product}
                      productPrices={productPrices}
                      recommendedQuote={recommendedQuote}
                      manualQuote={manualQuote}
                      approvalLevels={approvalLevels}
                      updateFlow={updateFlow}
                      penguinOpportunityPricingFlow={pricingFlow}
                    />
                  ))
                : null}
            </tbody>
            <QuoteTableFooter monthlyCost={monthlyCost} />
          </table>
        </div>
      </div>

      <AnnualCosts
        recommendedQuote={recommendedQuote}
        manualQuote={manualQuote}
        monthlyCost={monthlyCost}
      />
    </div>
  );
}

function QuoteTableFooter(props: { monthlyCost: number }) {
  const { monthlyCost } = props;
  return (
    <tfoot>
      <tr>
        <td
          colSpan={5}
          className="whitespace-nowrap bg-slate-50 px-6  py-4"
        ></td>
        <td
          colSpan={1}
          className="hidden whitespace-nowrap bg-slate-50 px-6 py-4 2xl:table-cell"
        ></td>
        <th
          scope="col"
          colSpan={2}
          className="hidden bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Total est. monthly revenue
        </th>
        <th
          scope="col"
          className="hidden rounded-br-xl bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          {formatCurrency({
            amount: monthlyCost,
            currency: 'USD',
            rounding: true,
          })}
        </th>
      </tr>
    </tfoot>
  );
}

function QuoteTableHeader() {
  return (
    <thead>
      <tr>
        <th
          scope="col"
          className="sticky top-0 z-10 w-full rounded-tl-xl border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
        >
          Product
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter xl:whitespace-nowrap 2xl:table-cell"
        >
          Unit
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Monthly est. volume
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Sticker
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Floor
        </th>
        <th
          scope="col"
          className="has-tooltip sticky top-0 z-20 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Suggested price
          <span className="tooltip z-20 -ml-60 -mt-20 whitespace-nowrap rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white shadow-sm dark:bg-gray-700">
            We take your products and estimated monthly volumes and we come up{' '}
            <br />
            with an overall monthly minimum and price per product that minimizes{' '}
            <br />
            the approval level needed per product.
          </span>
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Quote price
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Approval level
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden rounded-tr-xl  border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Est. monthly revenue
        </th>
      </tr>
    </thead>
  );
}

function AnnualCosts(props: {
  monthlyCost: number;
  manualQuote: DealopsManualQuote;
  recommendedQuote: DealopsRecommendedQuote;
}) {
  const { monthlyCost, manualQuote, recommendedQuote } = props;

  let monthlyMinimumRow = (
    <tr>
      <td className="pr-24 text-sm font-semibold text-gray-700">
        Annual revenue based on minimum
      </td>
      <td>
        {formatCurrency({
          amount:
            (manualQuote?.monthlyMinimum ?? recommendedQuote.monthlyMinimum) *
            12,
          currency: 'USD',
          rounding: false,
        })}
      </td>
    </tr>
  );

  return (
    <div className="mt-2 flex w-fit rounded-xl border border-gray-200 bg-white px-6 py-3.5">
      <table className="">
        <tbody>
          {monthlyMinimumRow}
          <tr>
            <td className="text-sm font-semibold text-gray-700">
              Annual revenue based on products
            </td>
            <td>
              {formatCurrency({
                amount: monthlyCost * 12,
                currency: 'USD',
                rounding: true,
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function CurrentQuoteTableRow(props: {
  product: DealopsProduct;
  productPrices: DealopsProductPrices;
  recommendedQuote: DealopsRecommendedQuote;
  manualQuote?: DealopsManualQuote;
  approvalLevels: DealopsApprovalLevels;
  updateFlow: (flow: DealopsPricingFlow) => void;
  penguinOpportunityPricingFlow: DealopsPricingFlow;
}) {
  const {
    product,
    productPrices,
    recommendedQuote,
    manualQuote,
    updateFlow,
    penguinOpportunityPricingFlow,
  } = props;

  const stickerPrice: number = productPrices[product.name]['selfServe'];
  const floorPrice: number = productPrices[product.name]['financeApproval'];
  const recommendedPrice: number = recommendedQuote.products[product.name];
  const pricingLogs: [string] = recommendedQuote.productPricingLogs
    ? recommendedQuote.productPricingLogs[product.name]
    : [''];

  const manualPrice: number | null = manualQuote?.products
    ? manualQuote?.products[product.name]
    : null;

  const approvalLevel =
    penguinOpportunityPricingFlow.approvalLevels?.[product.name];

  const approvalLevelsHumanReadable = {
    0: 'No approval needed',
    1: '1. Manager',
    2: '2. Head of Function',
    3: '3. Deal Desk',
    4: '4. Finance',
  } as { [key: number]: string };

  const approvalBadgeColors = {
    0: 'green',
    1: 'yellow',
    2: 'orange',
    3: 'orange',
    4: 'red',
  } as { [key: number]: BadgeColor };

  const approvalBadgeColor = approvalBadgeColors[approvalLevel];

  useEffect(() => {
    setQuotePrice(manualPrice ?? recommendedPrice);
  }, [manualPrice, recommendedPrice]);

  const [quotePrice, setQuotePrice] = useState<number>(
    manualPrice ?? recommendedPrice,
  );
  const [monthlyCost, setMonthlyCost] = useState<number>(
    product.volume * quotePrice,
  );

  function updateManualPrice(manualPrice: number | null | 0) {
    console.log('CALL updateManualPrice');
    let newManualQuote = manualQuote;
    if (manualQuote?.products?.[product.name] === manualPrice) {
      // No change
      return;
    }

    if (manualPrice === null) {
      //Remove the product from the manual quote
      const { [product.name]: _, ...rest } = manualQuote?.products ?? {};
      newManualQuote = {
        ...manualQuote,
        products: rest,
      };
    } else {
      newManualQuote = {
        ...manualQuote,
        products: {
          ...manualQuote?.products,
          [product.name]: manualPrice,
        },
      };
    }

    updateFlow({
      ...penguinOpportunityPricingFlow,
      manualQuote: newManualQuote,
    });

    // Update all dependent numbers
    setMonthlyCost(product.volume * quotePrice);
  }

  return (
    <tr>
      {/* Product */}
      <td className="min-w-[172px] px-6 py-4">
        <div className="flex flex-col gap-1">
          <span className="text-sm font-medium text-gray-900">
            {product.name}
          </span>
          {/* @TODO(fay) add product category */}
          <span className="text-xs text-gray-600">Product category</span>
          <span className="text-xs text-gray-600 2xl:hidden">
            Unit: {productPrices[product.name]['unitDefinition'] ?? 'N/A'}
          </span>
        </div>
      </td>
      {/* Unit */}
      <td className="hidden min-w-[172px] px-6 py-4 text-sm text-gray-500 2xl:table-cell">
        {productPrices[product.name]['unitDefinition'] ?? 'N/A'}
      </td>
      {/* Monthly est. volume */}
      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
        {product.volume.toString()}
      </td>
      {/* Sticker */}
      <td className="whitespace-nowrap px-6 py-4 text-xs font-medium text-purple-700">
        <button
          className=""
          title="Set quote price to sticker price"
          onClick={() => {
            updateManualPrice(stickerPrice);
          }}
        >
          <Badge color="purple">
            {formatCurrency({
              amount: stickerPrice,
              currency: 'USD',
              rounding: false,
            })}
          </Badge>
        </button>
      </td>
      {/* Floor */}
      <td className="whitespace-nowrap px-6 py-4 text-xs font-medium text-orange-700">
        <button
          className=""
          title="Set quote price to floor price"
          onClick={() => {
            updateManualPrice(floorPrice);
          }}
        >
          <Badge color="orange">
            {formatCurrency({
              amount: floorPrice,
              currency: 'USD',
              rounding: false,
            })}
          </Badge>
        </button>
      </td>
      {/* Suggested price */}
      <td className="has-tooltip whitespace-nowrap px-6 py-4 text-xs font-medium">
        <button
          className=""
          title="Set quote price to suggested price"
          onClick={() => {
            updateManualPrice(null);
          }}
        >
          <Badge color="green">
            {formatCurrency({
              amount: recommendedPrice,
              currency: 'USD',
              rounding: false,
            })}
          </Badge>
          <span className="tooltip z-20 -ml-60 mt-10 whitespace-nowrap rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white shadow-sm dark:bg-gray-700">
            {pricingLogs.map((pricingLog) => {
              return <div className="text-xs text-gray-100">{pricingLog}</div>;
            })}
          </span>
        </button>
      </td>
      {/* Quote price */}
      <td className="items-center whitespace-nowrap pl-6 text-sm font-medium text-gray-500 hover:bg-gray-100">
        {/* @TODO(fay) should be dynamic currency */}
        $
        <NumberField
          type="number"
          value={quotePrice}
          required={true}
          className="-ml-3 max-w-[120px] border-none bg-transparent py-4 pr-6 text-sm font-medium text-gray-500 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
          onChange={(e) =>
            setQuotePrice(e.target.value ? parseFloat(e.target.value) : 0)
          }
          onBlur={() => updateManualPrice(quotePrice)}
        />
      </td>
      {/* Approval level */}
      <td className="whitespace-nowrap px-6 py-4 text-xs font-medium text-green-700">
        <Badge color={approvalBadgeColor}>
          {approvalLevelsHumanReadable[approvalLevel]}
        </Badge>
      </td>
      {/* Monthly cost */}
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500">
        {formatCurrency({ amount: monthlyCost, currency: 'USD' })}
      </td>
    </tr>
  );
}
