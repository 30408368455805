import { useState } from 'react';
import { FormattedNumberField } from 'src/components/Fields';

export default function DragBarWithPercentInput({
  defaultValue,
  label,
  updateEntityHandler,
}: {
  defaultValue: number;
  label: string | React.ReactNode;
  updateEntityHandler: (value: number) => void;
}) {
  const [localValue, setLocalValue] = useState(defaultValue);

  return (
    <>
      <label className={'block mb-2 text-sm font-medium text-gray-900'}>
        {label}
      </label>
      <div className="flex">
        <div className="relative w-4/5 pr-4 pt-1 h-2">
          <input
            type="range"
            min="0"
            max="100"
            value={localValue}
            className="h-2 w-full cursor-pointer accent-fuchsia-800"
            onChange={(e) => setLocalValue(Number(e.target.value))}
            onMouseUp={() => updateEntityHandler(localValue)}
          />
        </div>
        <FormattedNumberField
          type="text"
          value={localValue}
          numberDecimals={0}
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block p-2.5 w-1/5"
          updateValue={(value: number) => {
            setLocalValue(value);
            updateEntityHandler(value);
          }}
          suffix="%"
          isAllowed={({ floatValue }: { floatValue: number }) => {
            if (floatValue === undefined || floatValue === null) return true;
            return Number(floatValue) >= 0 && Number(floatValue) <= 100;
          }}
        />
      </div>
    </>
  );
}
