import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import {
  CalendarIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import api from 'src/api';
import AppPageHeader from 'src/components/AppPageHeader';
import GraphContainer from 'src/components/graphs/GraphContainer';
import { classNames } from '../App';
import CustomCheckbox from './Components/CustomCheckbox';
import { CPQvsDealopsPerProductPlot, CPQvsDealopsPlot } from './ScatterPlot';

type TabKey = 'Product View' | 'Rep View';

const Tabs = ({
  current,
  setCurrent,
}: {
  current: string;
  setCurrent: React.Dispatch<React.SetStateAction<TabKey>>;
}) => {
  const tabs: { name: TabKey; current: boolean }[] = [
    {
      name: 'Product View',
      current: current === 'Product View',
    },
    {
      name: 'Rep View',
      current: current === 'Rep View',
    },
  ];

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrent(event.target.value as TabKey);
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-pink-800 focus:outline-none focus:ring-pink-800 sm:text-sm"
          defaultValue={current}
          onChange={handleSelectChange}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                onClick={() => setCurrent(tab.name)}
                className={classNames(
                  tab.current
                    ? 'border-pink-700 text-pink-800'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'cursor-pointer whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium',
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

interface User {
  name: string;
  id: string;
  isChecked: boolean;
  // teamId: string;
}

interface UsersFilterProps {
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  // teams: Team[];
}

const UsersFilter = (props: UsersFilterProps) => {
  const { users, setUsers } = props;
  const [searchTerm, setSearchTerm] = useState('');

  const handleUserSelection = (sfdcUserId: string) => {
    setUsers(
      users.map((user) =>
        user.id === sfdcUserId ? { ...user, isChecked: !user.isChecked } : user,
      ),
    );
  };

  const searchTermMatches = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const selectedUsers = users.filter((user) => user.isChecked);

  return (
    <div className="py-4">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <PlusCircleIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Users
            {selectedUsers.length > 0 && (
              <>
                <div className="border-l h-5 mx-2 border-gray-200"></div>
                <span className="inline-flex items-center px-2.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800">
                  {selectedUsers.length}
                </span>
              </>
            )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[300px]">
            <div>
              <div className="flex items-center">
                <div className="relative w-full">
                  <MagnifyingGlassIcon
                    className="absolute left-2 top-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <input
                    type="text"
                    placeholder="Search users..."
                    className="block border-none w-full py-2 pl-8 pr-3 text-sm focus:border-pink-800 focus:outline-none focus:ring-pink-800"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <hr className="border-gray-200" />
              {searchTermMatches.map(({ name, isChecked, id }, idx) => (
                <Menu.Item key={id}>
                  {({ active }) => {
                    // let teamName = teams.find(
                    //   (team) => team.id === teamId,
                    // )?.name;
                    return (
                      <div
                        className={classNames(
                          isChecked
                            ? 'font-medium text-gray-900'
                            : 'text-gray-500',
                          active ? 'bg-gray-100' : '', // on hover, add background color
                          idx === searchTermMatches.length - 1 ? 'pb-3' : '', // add bottom padding to last user
                          'flex items-center px-4 py-2 text-sm w-full',
                        )}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUserSelection(id);
                        }}
                      >
                        <CustomCheckbox isChecked={isChecked} />
                        <span className="ml-2 flex-1">
                          {name} {/*teamName ? `(${teamName})` : ''*/}
                        </span>
                      </div>
                    );
                  }}
                </Menu.Item>
              ))}

              {searchTermMatches.length === 0 && (
                <span className="block px-4 py-2 text-sm text-gray-500">
                  No users found.
                </span>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

interface Stage {
  name: string;
  isChecked: boolean;
}

interface StagesFilterProps {
  stages: Stage[];
  setStages: React.Dispatch<React.SetStateAction<Stage[]>>;
}

const StagesFilter = (props: StagesFilterProps) => {
  const { stages, setStages } = props;
  const [searchTerm, setSearchTerm] = useState('');

  const handleStageSelection = (stageName: string) => {
    setStages(
      stages.map((stage) =>
        stage.name === stageName
          ? { ...stage, isChecked: !stage.isChecked }
          : stage,
      ),
    );
  };

  const searchTermMatches = stages.filter((stage) =>
    stage.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const selectedStages = stages.filter((stage) => stage.isChecked);

  return (
    <div className="py-4">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <PlusCircleIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Stages
            {selectedStages.length > 0 && (
              <>
                <div className="border-l h-5 mx-2 border-gray-200"></div>
                <span className="inline-flex items-center px-2.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800">
                  {selectedStages.length}
                </span>
              </>
            )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[250px]">
            <div>
              <div className="flex items-center">
                <div className="relative w-full">
                  <MagnifyingGlassIcon
                    className="absolute left-2 top-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <input
                    type="text"
                    placeholder="Search stages..."
                    className="block border-none w-full py-2 pl-8 pr-3 text-sm focus:border-pink-800 focus:outline-none focus:ring-pink-800"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <hr className="border-gray-200" />
              {searchTermMatches.map(({ name, isChecked }, idx) => (
                <Menu.Item key={name}>
                  {({ active }) => (
                    <div
                      className={classNames(
                        isChecked
                          ? 'font-medium text-gray-900'
                          : 'text-gray-500',
                        active ? 'bg-gray-100' : '', // on hover, add background color
                        idx === searchTermMatches.length - 1 ? 'pb-3' : '', // add bottom padding to last user
                        'flex items-center px-4 py-2 text-sm w-full',
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        handleStageSelection(name);
                      }}
                    >
                      <CustomCheckbox isChecked={isChecked} />
                      <span className="ml-2 flex-1">{name}</span>
                    </div>
                  )}
                </Menu.Item>
              ))}
              {searchTermMatches.length === 0 && (
                <span className="block px-4 py-2 text-sm text-gray-500">
                  No stages found.
                </span>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

const DateFilter = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  startDate: string;
  endDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <div className="py-4">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <CalendarIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Date
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-0 z-10 mt-2 w-[500px] origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none p-4">
              <Menu.Item>
                {({ close }) => (
                  <DatePicker
                    startDate={startDate}
                    endDate={endDate}
                    handleSubmit={(startDate, endDate) => {
                      setStartDate(startDate);
                      setEndDate(endDate);
                      close();
                    }}
                  />
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </div>
      </Menu>
    </div>
  );
};

export const DatePicker = ({
  startDate: initStartDate,
  endDate: initEndDate,
  handleSubmit,
}: {
  startDate: string;
  endDate: string;
  handleSubmit: (startDate: string, endDate: string) => void;
}) => {
  const [startDate, setStartDate] = useState<string>(initStartDate);

  const [endDate, setEndDate] = useState<string>(initEndDate);

  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setIsValid(validateDates());
  }, [startDate, endDate]);

  const validateDates = () => {
    let isValid = true;

    if (new Date(startDate) > new Date(endDate)) {
      isValid = false;
    }

    try {
      new Date(startDate);
    } catch (error) {
      datadogRum.addError(error);
      isValid = false;
    }

    try {
      new Date(endDate);
    } catch (error) {
      datadogRum.addError(error);
      isValid = false;
    }

    return isValid;
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && isValid) {
      handleSubmit(
        new Date(startDate).toISOString().slice(0, 10),
        new Date(endDate).toISOString().slice(0, 10),
      );
    }
  };

  return (
    <div className="flex flex-row gap-4">
      <div className="flex flex-col gap-2">
        Start date
        <input
          type="date"
          className="text-md w-44 rounded-lg border border-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-pink-600 focus:border-none focus:ring-0 focus:ring-transparent"
          id="startDate"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="flex flex-col gap-2">
        End date
        <input
          type="date"
          className="text-md w-44 rounded-lg border border-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-pink-600 focus:border-none focus:ring-0 focus:ring-transparent"
          id="endDate"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="flex flex-col justify-end">
        <button
          disabled={!isValid}
          onClick={() =>
            handleSubmit(
              new Date(startDate).toISOString().slice(0, 10),
              new Date(endDate).toISOString().slice(0, 10),
            )
          }
          className={classNames(
            isValid
              ? `bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded max-h-10`
              : `bg-gray-300 text-gray-500 font-bold py-2 px-4 rounded max-h-10 cursor-not-allowed`,
          )}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export enum PricingDataOrigin {
  DEALOPS = 'Dealops',
  CPQ = 'CPQ',
}

export interface PricingGuidanceData {
  metadata: Pick<PricingData['metadata'], 'productName'>;
  point: PricingData['point'];
}

export interface PricingData {
  metadata: {
    quoteId: string;
    monthlyMinimum: number;
    opportunityId: string;
    opportunityName: string;
    createdAt: string;
    productName: string;
    // productCategory: string; // called sku group in the db. will bring in v2.
    price: number;
    ownerName: string;
    createdByName: string;
    quoteLineId: string;
    closedDate: any;
    stageName: string;
    // userTeam: string; // no teams in db yet. will bring in on v2
    source: PricingDataOrigin;
  };
  point: {
    x: number;
    y: number;
  };
}

const fetchPricingData = async () => {
  const { data } = await api.post('analytics/asps', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};

const THIRTY_DAYS_AGO = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

const PriceDifferentialAnalysis = () => {
  let [allPricingData, setAllPricingData] = useState<undefined | PricingData[]>(
    undefined,
  );
  let [pricingGuidanceDataByProduct, setPricingGuidanceDataByProduct] =
    useState<Record<string, PricingGuidanceData[]>>({});

  const [startDate, setStartDate] = useState<string>(
    new Date(THIRTY_DAYS_AGO).toISOString().slice(0, 10),
  );
  const [endDate, setEndDate] = useState<string>(
    new Date().toISOString().slice(0, 10),
  );

  const [users, setUsers] = useState<User[]>([]);

  const [stages, setStages] = useState<Stage[]>([]);

  let pricingDataByProduct: Record<string, PricingData[]> = {}; // this is what the child components will use
  // aggregate pricingDifferentialData by product and apply filters
  // todo(seb): move to server?
  if (allPricingData !== undefined) {
    // Filter based on date range
    let filteredPricingData = allPricingData.filter(
      (data) =>
        data.metadata.createdAt >= startDate &&
        data.metadata.createdAt <= endDate,
    );

    // filter users if any are selected
    let selectedUsersNames = users
      .filter((user) => user.isChecked)
      .map((u) => u.name);

    if (selectedUsersNames.length > 0) {
      filteredPricingData = filteredPricingData.filter((d) =>
        selectedUsersNames.includes(d.metadata.ownerName),
      );
    }

    // filter stages if any are selected
    let selectedStagesNames = stages
      .filter((stage) => stage.isChecked)
      .map((stage) => stage.name);

    if (selectedStagesNames.length > 0) {
      filteredPricingData = filteredPricingData.filter((d) =>
        selectedStagesNames.includes(d.metadata.stageName),
      );
    }

    // sort by product name
    filteredPricingData.sort((a, b) =>
      a.metadata.productName.localeCompare(b.metadata.productName),
    );

    // aggregate into map
    for (let data of filteredPricingData) {
      let productName = data.metadata.productName;
      if (pricingDataByProduct[productName] === undefined) {
        pricingDataByProduct[productName] = [];
      }
      pricingDataByProduct[productName].push(data);
    }
  }

  let [tab, setTab] = useState<TabKey>('Product View');

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchPricingData();
      setAllPricingData(data.pricingData);
      setPricingGuidanceDataByProduct(data.pricingGuidanceData);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (allPricingData) {
      // set users
      let userNames: Set<string> = new Set();
      allPricingData.forEach(({ metadata }) => {
        userNames.add(metadata.ownerName);
      });
      setUsers(
        Array.from(userNames)
          .sort()
          .map((name, idx) => ({
            name,
            id: idx.toString(),
            isChecked: false,
          })),
      );

      // set stages
      let stageNames: Set<string> = new Set();
      allPricingData.forEach(({ metadata }) => {
        stageNames.add(metadata.stageName);
      });

      setStages(
        Array.from(stageNames).map((name) => ({ name, isChecked: false })),
      );
    }
  }, [allPricingData]);

  const tabToComponent: Record<TabKey, JSX.Element> = {
    'Product View': (
      <DealopsVsCPQProductCharts
        pricingDataByProduct={pricingDataByProduct}
        pricingGuidanceDataByProduct={pricingGuidanceDataByProduct}
        users={users}
        setUsers={setUsers}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        stages={stages}
        setStages={setStages}
      />
    ),
    'Rep View': (
      <DealopsVsCPQPerRepCharts
        pricingDataByProduct={pricingDataByProduct}
        pricingGuidanceDataByProduct={pricingGuidanceDataByProduct}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        users={users}
        setUsers={setUsers}
        stages={stages}
        setStages={setStages}
      />
    ),
  };

  return (
    <>
      <AppPageHeader title={'Product ASPs'} backPageLabel="Analytics" />
      <div className="p-6 pt-4">
        <Tabs current={tab} setCurrent={setTab} />
        {allPricingData && pricingDataByProduct && pricingGuidanceDataByProduct
          ? tabToComponent[tab]
          : 'Loading pricing differentials. Please wait, this could take a minute or so...'}
      </div>
    </>
  );
};

const summarizeDealopsVsCPQData = (
  pricingDataByProduct: Record<string, PricingData[]>,
  pricingGuidanceDataByProduct: Record<string, PricingGuidanceData[]>,
) => {
  return Object.keys(pricingDataByProduct).map((productName) => {
    let dealOpsData = pricingDataByProduct[productName].filter(
      (d) => d.metadata.source === PricingDataOrigin.DEALOPS,
    );

    let cpqData = pricingDataByProduct[productName].filter(
      (d) => d.metadata.source === PricingDataOrigin.CPQ,
    );

    // group by monthly minimum

    let dealopsDataByMonthlyMinimum: Record<string, PricingData[]> = {};
    dealOpsData.forEach((d) => {
      let mm = d.point.x.toString();
      if (dealopsDataByMonthlyMinimum[mm] === undefined) {
        dealopsDataByMonthlyMinimum[mm] = [];
      }
      dealopsDataByMonthlyMinimum[mm].push(d);
    });

    // and then, for the monthly minimum, take the average of the prices

    let dealOpsDataSummarized = Object.keys(dealopsDataByMonthlyMinimum).map(
      (monthlyMinimum) => {
        let pricingData = dealopsDataByMonthlyMinimum[monthlyMinimum];
        let averagePrice =
          pricingData.reduce(
            (acc: number, d: PricingData) => acc + d.point.y,
            0,
          ) / pricingData.length;
        return {
          x: parseInt(monthlyMinimum),
          y: averagePrice,
        };
      },
    );

    let cpqDataByMonthlyMinimum: Record<string, PricingData[]> = {};

    cpqData.forEach((d) => {
      let mm = d.point.x.toString();
      if (cpqDataByMonthlyMinimum[mm] === undefined) {
        cpqDataByMonthlyMinimum[mm] = [];
      }
      cpqDataByMonthlyMinimum[mm].push(d);
    });

    let cpqDataSummarized = Object.keys(cpqDataByMonthlyMinimum).map(
      (monthlyMinimum) => {
        let pricingData = cpqDataByMonthlyMinimum[monthlyMinimum];
        let averagePrice =
          pricingData.reduce(
            (acc: number, d: PricingData) => acc + d.point.y,
            0,
          ) / pricingData.length;
        return {
          x: parseInt(monthlyMinimum),
          y: averagePrice,
        };
      },
    );

    // get the monthly minimums span across both dealops and cpq
    let maxMonthlyMinimum = Math.max(
      ...Object.keys(dealopsDataByMonthlyMinimum).map((mm) => parseInt(mm)),
      ...Object.keys(cpqDataByMonthlyMinimum).map((mm) => parseInt(mm)),
    );

    let minMonthlyMinimum = Math.min(
      ...Object.keys(dealopsDataByMonthlyMinimum).map((mm) => parseInt(mm)),
      ...Object.keys(cpqDataByMonthlyMinimum).map((mm) => parseInt(mm)),
    );

    let guidance = pricingGuidanceDataByProduct[productName]
      .filter((d) => {
        return d.point.x >= minMonthlyMinimum && d.point.x <= maxMonthlyMinimum;
      })
      .map((d) => d.point);

    return {
      productName: productName,
      dealops: dealOpsDataSummarized,
      cpq: cpqDataSummarized,
      guidance: guidance,
    };
  });
};

const DealopsVsCPQProductCharts = ({
  pricingDataByProduct,
  pricingGuidanceDataByProduct,
  users,
  setUsers,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  stages,
  setStages,
}: {
  pricingDataByProduct: Record<string, PricingData[]>;
  pricingGuidanceDataByProduct: Record<string, PricingGuidanceData[]>;
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  stages: Stage[];
  setStages: React.Dispatch<React.SetStateAction<Stage[]>>;
}) => {
  let summarizedData = summarizeDealopsVsCPQData(
    pricingDataByProduct,
    pricingGuidanceDataByProduct,
  );
  return (
    <>
      <div className="flex flex-row gap-2">
        <UsersFilter users={users} setUsers={setUsers} />
        <StagesFilter stages={stages} setStages={setStages} />
        {/* <TeamsFilter teams={teams} setTeams={setTeams} />
    <ProductCategoriesFilter
      productCategories={productCategories}
      setProductCategories={setProductCategories}
    /> */}
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>
      <div className="grid grid-cols-2 gap-6" style={{ gridAutoRows: '400px' }}>
        {summarizedData.map(({ productName, dealops, cpq, guidance }) => (
          <GraphContainer
            key={productName}
            className="h-full w-full"
            header={
              <div className="flex w-full items-center text-gray-900">
                <div className="font-semibold text-gray-900">{productName}</div>
              </div>
            }
          >
            <div className="flex h-full w-full flex-col">
              <CPQvsDealopsPlot data={{ dealops, cpq, guidance }} />
            </div>
          </GraphContainer>
        ))}
      </div>
    </>
  );
};

const getDealopsVsCPQSummaryChartsMap = ({
  pricingDataByProduct,
  pricingGuidanceDataByProduct,
}: {
  pricingDataByProduct: Record<string, PricingData[]>;
  pricingGuidanceDataByProduct: Record<string, PricingGuidanceData[]>;
}): { [productName: string]: JSX.Element } => {
  let summarizedData = summarizeDealopsVsCPQData(
    pricingDataByProduct,
    pricingGuidanceDataByProduct,
  );
  return Object.keys(pricingDataByProduct)
    .map((productName) => {
      return {
        [productName]: (
          <>
            {summarizedData
              .filter((x) => x.productName === productName)
              .map(({ productName, dealops, cpq, guidance }) => (
                <GraphContainer
                  key={productName}
                  className="h-full w-full"
                  header={
                    <div className="flex w-full items-center text-gray-900">
                      <div className="font-semibold text-gray-900">
                        {productName}
                      </div>
                    </div>
                  }
                >
                  <div className="flex h-full w-full flex-col">
                    <CPQvsDealopsPlot data={{ dealops, cpq, guidance }} />
                  </div>
                </GraphContainer>
              ))}
          </>
        ),
      };
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});
};

const ProductDropdown = ({
  currentProduct,
  setProduct,
  allProducts,
}: {
  currentProduct: string;
  setProduct: React.Dispatch<React.SetStateAction<string>>;
  allProducts: string[];
}) => (
  <div className="py-4 w-full max-w-lg">
    <Menu as="div" className="relative inline-block text-left w-full">
      <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-[7px] text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-pink-500 flex justify-between">
        {currentProduct}
        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {allProducts.map((productName) => (
              <Menu.Item key={productName}>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-sm text-left',
                    )}
                    onClick={() => setProduct(productName)}
                  >
                    {productName}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  </div>
);

const DealopsVsCPQPerRepCharts = ({
  pricingDataByProduct,
  pricingGuidanceDataByProduct,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  users,
  setUsers,
  stages,
  setStages,
}: {
  pricingDataByProduct: Record<string, PricingData[]>;
  pricingGuidanceDataByProduct: Record<string, PricingGuidanceData[]>;
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  stages: Stage[];
  setStages: React.Dispatch<React.SetStateAction<Stage[]>>;
}) => {
  let productNames = Object.keys(pricingDataByProduct);
  let [product, setProduct] = useState(productNames[0]);

  let summaryChartsMap = getDealopsVsCPQSummaryChartsMap({
    pricingDataByProduct,
    pricingGuidanceDataByProduct,
  });

  // group by rep
  let pricingDataByProductAndRep: Record<
    string,
    Record<string, PricingData[]>
  > = {};

  productNames.forEach((productName) =>
    pricingDataByProduct[productName].forEach((pricingData) => {
      if (pricingDataByProductAndRep[productName] === undefined) {
        pricingDataByProductAndRep[productName] = {};
      }

      if (
        pricingDataByProductAndRep[productName][
          pricingData.metadata.ownerName
        ] === undefined
      ) {
        pricingDataByProductAndRep[productName][
          pricingData.metadata.ownerName
        ] = [];
      }

      pricingDataByProductAndRep[productName][
        pricingData.metadata.ownerName
      ].push(pricingData);
    }),
  );

  // sort by owner name
  Object.keys(pricingDataByProductAndRep).forEach((productName) => {
    pricingDataByProductAndRep[productName] = Object.fromEntries(
      Object.entries(pricingDataByProductAndRep[productName]).sort(
        ([ownerA], [ownerB]) => ownerA.localeCompare(ownerB),
      ),
    );
  });

  return (
    <>
      <div className="flex flex-row gap-2">
        <ProductDropdown
          currentProduct={product}
          setProduct={setProduct}
          allProducts={Object.keys(pricingDataByProduct)}
        />
        <UsersFilter users={users} setUsers={setUsers} />
        <StagesFilter stages={stages} setStages={setStages} />
        {/* <TeamsFilter teams={teams} setTeams={setTeams} />
    <ProductCategoriesFilter
      productCategories={productCategories}
      setProductCategories={setProductCategories}
    /> */}
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>
      {productNames
        .filter((name) => name === product)
        .map((productName) => (
          <Fragment key={productName}>
            <span className="font-semibold text-lg pb-4 block text-center">
              {productName}
            </span>
            <div className="h-[400px] mb-8">
              {summaryChartsMap[productName]}
            </div>
            <div
              className="grid grid-cols-2 gap-6"
              style={{ gridAutoRows: '400px' }}
            >
              {Object.keys(pricingDataByProductAndRep[productName]).map(
                (ownerName) => (
                  <GraphContainer
                    key={ownerName}
                    className="h-full w-full"
                    header={
                      <div className="flex w-full items-center text-gray-900">
                        <div className="font-semibold text-gray-900">
                          {`Owner: ${ownerName}`}
                        </div>
                      </div>
                    }
                  >
                    <div className="flex h-full w-full flex-col">
                      <CPQvsDealopsPerProductPlot
                        pricingData={
                          pricingDataByProductAndRep[productName][ownerName]
                        }
                        pricingGuidanceData={
                          pricingGuidanceDataByProduct[productName]
                        }
                      />
                    </div>
                  </GraphContainer>
                ),
              )}
            </div>
          </Fragment>
        ))}
    </>
  );
};

export default PriceDifferentialAnalysis;
