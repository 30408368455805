import { Dialog, Transition } from '@headlessui/react';
import {
  CubeTransparentIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import { ProductSelectField } from 'src/components/Fields';
// TODO Fay: WE need to think a bit more on using types here in this shared component
import {
  DealopsProduct,
  DealopsProductPrices,
  ProductCommon,
} from '../PricingFlow/types';
import { ProductsTable } from './ProductsTable';

const Tab = ({
  name,
  selected,
  onClick,
}: {
  name: string;
  selected: boolean;
  onClick: (tab: string) => void;
}) => {
  const selectedClassNames = selected
    ? `bg-gray-50 text-gray-700`
    : `text-gray-500`;

  return (
    <button
      name={name}
      className={`w-fit rounded-lg px-3 py-2 text-sm font-semibold ${selectedClassNames}`}
      onClick={(e) => {
        if (e.currentTarget.textContent) {
          onClick(e.currentTarget.textContent);
        }
      }}
    >
      {name}
    </button>
  );
};

const SearchInput = ({ onChange }: { onChange: (val: string) => void }) => {
  return (
    <div className="flex h-10 w-72 flex-row items-center rounded-lg border border-gray-300 p-2 shadow-sm focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900">
      <MagnifyingGlassIcon
        className="mr-2 h-4 w-4 text-gray-500"
        aria-hidden="true"
      />
      <input
        className="text-md -ml-3 border-none bg-transparent text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
        placeholder={'Search for a product'}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

const ProductModalPanel = (props: {
  products: DealopsProduct[];
  productPrices: DealopsProductPrices;
  setProducts: (products: DealopsProduct[]) => void;
  closeModal: () => void;
}) => {
  const { products, productPrices, setProducts, closeModal } = props;
  const categories = [...getSkuGroups(productPrices), 'Add-ons'];
  const [currentTab, setCurrentTab] = useState<string>(categories[0]);
  const [searchQuery, setSearchQuery] = useState('');
  const [productsCopy, setProductsCopy] = useState(products);

  return (
    <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all">
      <Dialog.Title className="flex flex-row items-center px-6">
        <div className="rounded-lg border border-gray-300 p-2 shadow-sm">
          <CubeTransparentIcon className="h-6 w-6" aria-hidden="true" />
        </div>
        <div className="ml-4">
          <p className="text-lg font-medium text-gray-900">Add Products</p>
          <p className="text-sm text-gray-600">
            Select all the products you want to add in the quote.
          </p>
        </div>
      </Dialog.Title>

      <hr className="mt-4"></hr>
      {/* Nav Bar */}
      <div className="mt-4 flex flex-col items-center justify-between px-6 sm:flex-row">
        <div className="mb-2 gap-2 sm:mb-0">
          {categories.map((category) => (
            <Tab
              key={category}
              name={category}
              selected={category === currentTab}
              onClick={setCurrentTab}
            />
          ))}
        </div>
        <SearchInput onChange={setSearchQuery} />
      </div>

      {/* Grid of products */}
      <hr className="mt-2 px-6"></hr>
      <div className="h-96 overflow-scroll px-6">
        <ProductGrid
          currentTab={currentTab}
          searchQuery={searchQuery}
          productPrices={productPrices}
          products={productsCopy}
          setProducts={setProductsCopy}
        />
      </div>

      {/* Buttons */}
      <hr className="mt-4"></hr>
      <div className="mt-6 grid grid-flow-col gap-2 border-none px-6">
        <button
          type="submit"
          className="inline-flex items-center justify-center rounded-lg border border-gray-300 px-4 py-2 text-base font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="inline-flex items-center justify-center rounded-lg bg-fuchsia-900 px-4 py-2 text-base font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
          onClick={() => {
            setProducts(productsCopy);
            closeModal();
          }}
        >
          Update
        </button>
      </div>
    </Dialog.Panel>
  );
};

const ProductGrid = ({
  currentTab,
  searchQuery,
  productPrices,
  products,
  setProducts,
}: {
  currentTab: string;
  searchQuery: string;
  productPrices: DealopsProductPrices;
  products: ProductCommon[];
  setProducts: (products: ProductCommon[]) => void;
}) => {
  if (currentTab === 'Add-ons') {
    const filteredProductPrices = Object.values(productPrices).filter(
      (productPrice) => {
        const isAddOn = productPrice.skuSubgroup === 'Add-ons';
        const inSearch =
          !searchQuery ||
          productPrice.name.toLowerCase().includes(searchQuery.toLowerCase());
        return isAddOn && inSearch;
      },
    );

    if (products.length === 0) {
      return (
        <div className="flex h-full flex-col items-center justify-center">
          <p className="text-lg font-medium text-gray-900">
            No add-ons available
          </p>
          <p className="mt-2 text-sm text-gray-600">
            Add products to see available add-ons
          </p>
        </div>
      );
    }

    return (
      <>
        {products.map((product) => {
          const addOns = filteredProductPrices.filter(
            (productPrice) => productPrice.parentProduct === product.name,
          );
          if (addOns.length === 0) {
            return null;
          }
          return (
            <div
              key={product.name}
              className="text-md mt-4 font-medium text-gray-700"
            >
              {product.name}
              <div className="mt-2 grid auto-rows-fr grid-cols-2 gap-2 sm:grid-cols-4">
                {addOns.map((addOn) => {
                  const selected =
                    products.find((product) => product.name === addOn.name) !==
                    undefined;
                  return (
                    <ProductSelectField
                      className="h-full"
                      key={addOn.name}
                      name={addOn.name}
                      id={addOn.id}
                      description={addOn.unitDefinition}
                      onChange={(id: string) =>
                        selected
                          ? setProducts(
                              products.filter((product) => product.id !== id),
                            )
                          : setProducts([
                              ...products,
                              { name: addOn.name, volume: 0, id: id },
                            ])
                      }
                      checked={selected}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </>
    );
  } else {
    const filteredProductPrices = Object.values(productPrices).filter(
      (productPrice) => {
        // It's possible this logic will change with Airwallex
        const inCurrentTab = currentTab === productPrice.skuGroup;
        const inSearch =
          !searchQuery ||
          productPrice.name.toLowerCase().includes(searchQuery.toLowerCase());
        const isAddOn = productPrice.skuSubgroup === 'Add-ons';

        return inCurrentTab && inSearch && !isAddOn;
      },
    );

    if (filteredProductPrices.length === 0) {
      return (
        <div className="flex h-full flex-col items-center justify-center">
          <p className="text-lg font-medium text-gray-900">No products found</p>
          <p className="mt-2 text-sm text-gray-600">
            Try searching with different keywords
          </p>
        </div>
      );
    }

    return (
      <div className="mt-4 grid auto-rows-fr grid-cols-2 gap-2 sm:grid-cols-4">
        {filteredProductPrices.map((productPrice) => {
          const selected =
            products.find((product) => product.name === productPrice.name) !==
            undefined;
          return (
            <ProductSelectField
              className="h-full"
              key={productPrice.name}
              name={productPrice.name}
              id={productPrice.id}
              description={productPrice.unitDefinition}
              onChange={(id: string) =>
                selected
                  ? setProducts(products.filter((product) => product.id !== id))
                  : setProducts([
                      ...products,
                      { volume: 0, id: id, name: productPrice.name },
                    ])
              }
              checked={selected}
            />
          );
        })}
      </div>
    );
  }
};

function getSkuGroups(skus: DealopsProductPrices): string[] {
  const skuGroups = new Set();

  if (skus == null) {
    return [];
  }
  Object.values(skus).forEach((sku) => {
    skuGroups.add(sku.skuGroup);
  });

  return Array.from(skuGroups) as string[]; // sad
}

export const ProductsForm = (props: {
  products: DealopsProduct[];
  productPrices: DealopsProductPrices;
  setProducts: (products: DealopsProduct[]) => void;
}) => {
  const { products, productPrices, setProducts } = props;
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="mt-16 flex w-full flex-col items-center">
        <ProductsTable
          products={products}
          productPrices={productPrices}
          setProducts={setProducts}
        />
        <div className="mt-8">
          <button
            type="submit"
            className="inline-flex items-center justify-center rounded-lg bg-fuchsia-900 px-4 py-2 text-base font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <PlusIcon className="mr-2 h-4 w-4" aria-hidden="true" />
            Add Product
          </button>
        </div>
      </div>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="absolute z-10 items-center justify-center overflow-y-auto"
          onClose={closeModal}
        >
          {/* This transitions the background to a dark shade */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          {/* TODO: Fix this hardcoded padding that is used for the sidebar */}
          <div className="fixed inset-0 overflow-y-auto lg:pl-20">
            <div className="flex min-h-full items-center justify-center px-8 py-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <ProductModalPanel
                  products={products}
                  productPrices={productPrices}
                  setProducts={setProducts}
                  closeModal={closeModal}
                />
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
