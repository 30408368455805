import { cloneDeep, isNil } from 'lodash';
import list from 'src/list';
import {
  entityHasIntraregionalConcept,
  getVolumeForGeography,
} from './Components/AlpacaIssuingTable';
import {
  getAllDefaultIssuingProductsNotYetExistingForCurrentEntity,
  getCurrentIssuingEntity,
} from './Issuing';
import {
  CurrencyValueType,
  QuotePrice,
  ZERO_FLAT_AND_PERCENT,
  ZERO_PERCENT,
} from './alpaca_price_types';
import {
  AlpacaIssuingConfig,
  AlpacaIssuingProduct,
  AlpacaPricingFlow,
  AlpacaProduct,
  IssuingEntity,
  IssuingGeography,
  IssuingSubcategory,
} from './alpaca_types';
import { convertCurrencyValueForex } from './alpaca_utils';

function postProcessTemplateProducts(
  newTemplateProducts: AlpacaProduct[],
  pricingFlow: AlpacaPricingFlow,
  currentEntity: IssuingEntity,
) {
  // Remove intraregional products from template if the entity has no
  // intraregional concept
  const filteredTemplateProducts = newTemplateProducts.filter((p) => {
    if (entityHasIntraregionalConcept(currentEntity)) {
      return true;
    } else {
      if (p.categoryName === 'Issuing') {
        return p.geography !== 'intraRegional';
      }
    }
    return true;
  });
  // At the beginning of template creation, we remove a bunch of products. Now,
  // add back in anything that's still missing, after the manual additions
  const defaultIssuingProducts =
    getAllDefaultIssuingProductsNotYetExistingForCurrentEntity(
      { ...pricingFlow, products: filteredTemplateProducts },
      currentEntity,
    );
  return [...filteredTemplateProducts, ...defaultIssuingProducts];
}

function getPricingFlowWithNewIssuingConfig(
  pricingFlow: AlpacaPricingFlow,
  overrides: Partial<AlpacaIssuingConfig>,
): AlpacaPricingFlow {
  const out = {
    ...pricingFlow,
    additionalData: {
      ...pricingFlow.additionalData,
      issuingConfig: {
        ...pricingFlow.additionalData.issuingConfig,
        ...overrides,
      },
    },
  };
  return out;
}

// updates the current entity and returns a new entities[] to be used in the
// issuing config
function updateCurrentEntity(
  pricingFlow: AlpacaPricingFlow,
  overrides: Partial<IssuingEntity>,
): IssuingEntity[] {
  const existingEntityIdx = list.findIndexOrNull(
    pricingFlow.additionalData.issuingConfig.entities,
    (e) =>
      e.name ===
      pricingFlow.additionalData.issuingConfig.currentlyViewingEntity,
  );
  if (isNil(existingEntityIdx)) {
    return pricingFlow.additionalData.issuingConfig.entities;
  } else {
    const out = cloneDeep(pricingFlow.additionalData.issuingConfig.entities);
    for (const k of Object.keys(overrides) as (keyof IssuingEntity)[]) {
      (out[existingEntityIdx][k] as any) = overrides[k];
    }
    return out;
  }
}

export interface RegionalData<T> {
  domestic: T;
  intraRegional: T;
  international: T;
}

export type IssuingRegion = keyof RegionalData<any>;

function getUnconfigurableProducts(
  pricingFlow: AlpacaPricingFlow,
  currentEntity: IssuingEntity,
) {
  const unconfigurableSubcategories: Set<IssuingSubcategory> = new Set([
    'chargeback',
    'cardCreationCost',
    'cardMaintenanceCost',
    'transactionCost',
    'perfIncentives',
  ]);
  return (pricingFlow.products ?? []).filter((p) => {
    if (p.categoryName === 'Issuing' && p.country === currentEntity.name) {
      // remove all issuing products that are not non-optional costs
      return unconfigurableSubcategories.has(p.subCategory);
    }
    // leave other products alone
    return true;
  });
}
// Template Logic

const applyZeroTransactionFeesTemplate = (pricingFlow: AlpacaPricingFlow) => {
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  // Reset the products
  const newProducts = getUnconfigurableProducts(pricingFlow, currentEntity);

  /**
   * Set interchange revenue back to default prices
   * Note that every other product subcategory in this template is not editable
   */
  return {
    ...getPricingFlowWithNewIssuingConfig(pricingFlow, {
      entities: updateCurrentEntity(pricingFlow, {
        showRebates: false,
        showInterchangeRevenueShare: false,
        showTransactionFees: false,
      }),
    }),
    products: postProcessTemplateProducts(
      newProducts,
      pricingFlow,
      currentEntity,
    ),
  };
};

const applyZeroTransactionFeesWithRebatesTemplate = (
  pricingFlow: AlpacaPricingFlow,
) => {
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  // Reset the products
  const newProducts = getUnconfigurableProducts(pricingFlow, currentEntity);
  /**
   * Add rebates
   */
  const baseRebate = {
    // #UniqueAlpacaIssuingProductIds
    volume: 0,
    country: currentEntity.name,
    binType: currentEntity.binType,
    digitalWallet: null,
    subCategory: 'rebates' as const,
    isCost: true as const,
    quoteCost: ZERO_PERCENT,
    quotePrice: ZERO_PERCENT,
    hasPricingInfo: false as const,
    categoryName: 'Issuing' as const,
    isVolumeEditable: false,
  };
  const newRebates: AlpacaIssuingProduct[] = [
    {
      ...baseRebate,
      // #UniqueAlpacaIssuingProductIds
      id: `domestic_${currentEntity.binType}_${currentEntity.name}_rebates`,
      name: 'Domestic',
      geography: 'domestic',
      quoteCost: { type: CurrencyValueType.PERCENT, value: 0.5 },
    },
    {
      ...baseRebate,
      // #UniqueAlpacaIssuingProductIds
      id: `international_${currentEntity.binType}_${currentEntity.name}_rebates`,
      name: 'International',
      geography: 'international',
      quoteCost: { type: CurrencyValueType.PERCENT, value: 1 },
    },
    {
      ...baseRebate,
      // #UniqueAlpacaIssuingProductIds
      id: `intraRegional_${currentEntity.binType}_${currentEntity.name}_rebates`,
      name: 'Intra-regional',
      geography: 'intraRegional',
      quoteCost: { type: CurrencyValueType.PERCENT, value: 1 },
    },
  ];
  return {
    ...getPricingFlowWithNewIssuingConfig(pricingFlow, {
      entities: updateCurrentEntity(pricingFlow, {
        showRebates: true,
        showInterchangeRevenueShare: false,
        showTransactionFees: false,
      }),
    }),
    products: postProcessTemplateProducts(
      [...newProducts, ...newRebates],
      pricingFlow,
      currentEntity,
    ),
  };
};

const applyZeroTransactionFeesWithRebatesTieredTemplate = (
  pricingFlow: AlpacaPricingFlow,
) => {
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  // Reset the products
  const newProducts = getUnconfigurableProducts(pricingFlow, currentEntity);

  /**
   * Add rebates
   */
  const baseRebate = {
    // #UniqueAlpacaIssuingProductIds
    volume: 0,
    country: currentEntity.name,
    binType: currentEntity.binType,
    digitalWallet: null,
    subCategory: 'rebates' as const,
    isCost: true as const,
    quoteCost: ZERO_PERCENT,
    quotePrice: ZERO_PERCENT,
    hasPricingInfo: false as const,
    categoryName: 'Issuing' as const,
    isVolumeEditable: false,
  };
  const internationalAndIntraregionalQuoteCost: (
    geography: IssuingGeography,
  ) => QuotePrice = (geography) => {
    return {
      type: 'tiered',
      minimumType: CurrencyValueType.FLAT,
      tiers: [
        {
          currencyValue: { type: CurrencyValueType.PERCENT, value: 0.8 },
          minimum: convertCurrencyValueForex(
            {
              value: 0,
              currency: 'USD',
              type: CurrencyValueType.FLAT,
            },
            currentEntity.issuingEntityCurrency,
            pricingFlow,
          ),
        },
        {
          currencyValue: { type: CurrencyValueType.PERCENT, value: 0.9 },
          minimum: {
            value:
              getVolumeForGeography(
                geography,
                currentEntity.name,
                pricingFlow,
                false,
                null,
              ).value * 0.75,
            type: CurrencyValueType.FLAT,
            currency: currentEntity.monthlySpendAtScale.currency,
          },
        },
        {
          currencyValue: { type: CurrencyValueType.PERCENT, value: 1 },
          minimum: getVolumeForGeography(
            geography,
            currentEntity.name,
            pricingFlow,
            false,
            null,
          ),
        },
      ],
    };
  };
  const newRebates: AlpacaIssuingProduct[] = [
    {
      ...baseRebate,
      // #UniqueAlpacaIssuingProductIds
      id: `domestic_${currentEntity.binType}_${currentEntity.name}_rebates`,
      name: 'Domestic',
      geography: 'domestic',
      quoteCost: ZERO_PERCENT,
    },
    {
      ...baseRebate,
      // #UniqueAlpacaIssuingProductIds
      id: `international_${currentEntity.binType}_${currentEntity.name}_rebates`,
      name: 'International',
      geography: 'international',
      quoteCost: internationalAndIntraregionalQuoteCost('international'),
    },
    {
      ...baseRebate,
      // #UniqueAlpacaIssuingProductIds
      id: `intraRegional_${currentEntity.binType}_${currentEntity.name}_rebates`,
      name: 'Intra-regional',
      geography: 'intraRegional',
      quoteCost: internationalAndIntraregionalQuoteCost('intraRegional'),
    },
  ];
  return {
    ...getPricingFlowWithNewIssuingConfig(pricingFlow, {
      entities: updateCurrentEntity(pricingFlow, {
        showRebates: true,
        showInterchangeRevenueShare: false,
        showTransactionFees: false,
      }),
    }),
    products: postProcessTemplateProducts(
      [...newProducts, ...newRebates],
      pricingFlow,
      currentEntity,
    ),
  };
};

const applyZeroTransactionFeesWithRebatesTieredWithBaseTemplate = (
  pricingFlow: AlpacaPricingFlow,
) => {
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  // Reset the products
  const newProducts = getUnconfigurableProducts(pricingFlow, currentEntity);

  /**
   * Add rebates
   */
  const baseRebate = {
    // #UniqueAlpacaIssuingProductIds
    volume: 0,
    country: currentEntity.name,
    binType: currentEntity.binType,
    digitalWallet: null,
    subCategory: 'rebates' as const,
    isCost: true as const,
    quoteCost: ZERO_PERCENT,
    quotePrice: ZERO_PERCENT,
    hasPricingInfo: false as const,
    categoryName: 'Issuing' as const,
    isVolumeEditable: false,
  };
  const internationalAndIntraregionalQuoteCost: (
    geography: IssuingGeography,
  ) => QuotePrice = (geography) => {
    return {
      type: 'tiered',
      minimumType: CurrencyValueType.FLAT,
      tiers: [
        {
          currencyValue: { type: CurrencyValueType.PERCENT, value: 1 },
          minimum: convertCurrencyValueForex(
            {
              value: 0,
              currency: 'USD',
              type: CurrencyValueType.FLAT,
            },
            currentEntity.issuingEntityCurrency,
            pricingFlow,
          ),
        },
        {
          currencyValue: { type: CurrencyValueType.PERCENT, value: 1.1 },
          minimum: {
            value:
              getVolumeForGeography(
                geography,
                currentEntity.name,
                pricingFlow,
                false,
                null,
              ).value * 0.75,
            type: CurrencyValueType.FLAT,
            currency: currentEntity.monthlySpendAtScale.currency,
          },
        },
        {
          currencyValue: { type: CurrencyValueType.PERCENT, value: 1.2 },
          minimum: getVolumeForGeography(
            geography,
            currentEntity.name,
            pricingFlow,
            false,
            null,
          ),
        },
      ],
    };
  };
  const newRebates: AlpacaIssuingProduct[] = [
    {
      ...baseRebate,
      // #UniqueAlpacaIssuingProductIds
      id: `domestic_${currentEntity.binType}_${currentEntity.name}_rebates`,
      name: 'Domestic',
      geography: 'domestic',
      quoteCost: {
        type: 'tiered',
        minimumType: CurrencyValueType.FLAT,
        tiers: [
          {
            currencyValue: { type: CurrencyValueType.PERCENT, value: 0.5 },
            minimum: convertCurrencyValueForex(
              {
                value: 0,
                currency: 'USD',
                type: CurrencyValueType.FLAT,
              },
              currentEntity.issuingEntityCurrency,
              pricingFlow,
            ),
          },
          {
            currencyValue: { type: CurrencyValueType.PERCENT, value: 0.75 },
            minimum: {
              value:
                getVolumeForGeography(
                  'domestic',
                  currentEntity.name,
                  pricingFlow,
                  false,
                  null,
                ).value * 0.75,
              type: CurrencyValueType.FLAT,
              currency: currentEntity.monthlySpendAtScale.currency,
            },
          },
          {
            currencyValue: { type: CurrencyValueType.PERCENT, value: 1 },
            minimum: getVolumeForGeography(
              'domestic',
              currentEntity.name,
              pricingFlow,
              false,
              null,
            ),
          },
        ],
      },
    },
    {
      ...baseRebate,
      // #UniqueAlpacaIssuingProductIds
      id: `international_${currentEntity.binType}_${currentEntity.name}_rebates`,
      name: 'International',
      geography: 'international',
      quoteCost: internationalAndIntraregionalQuoteCost('international'),
    },
    {
      ...baseRebate,
      // #UniqueAlpacaIssuingProductIds
      id: `intraRegional_${currentEntity.binType}_${currentEntity.name}_rebates`,
      name: 'Intra-regional',
      geography: 'intraRegional',
      quoteCost: internationalAndIntraregionalQuoteCost('intraRegional'),
    },
  ];
  return {
    ...getPricingFlowWithNewIssuingConfig(pricingFlow, {
      entities: updateCurrentEntity(pricingFlow, {
        showRebates: true,
        showInterchangeRevenueShare: false,
        showTransactionFees: false,
      }),
    }),
    products: postProcessTemplateProducts(
      [...newProducts, ...newRebates],
      pricingFlow,
      currentEntity,
    ),
  };
};

const applyFixedAndVariableTransactionFeesTemplate = (
  pricingFlow: AlpacaPricingFlow,
) => {
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  // Reset the products
  const newProducts = getUnconfigurableProducts(pricingFlow, currentEntity);
  /**
   * Add new transaction fees
   */
  const zero = ZERO_FLAT_AND_PERCENT(currentEntity.issuingEntityCurrency);
  const baseTransactionFee = {
    // #UniqueAlpacaIssuingProductIds
    volume: 0,
    country: currentEntity.name,
    binType: currentEntity.binType,
    digitalWallet: null,
    subCategory: 'transactionFees' as const,
    isCost: false as const,
    quoteCost: zero,
    quotePrice: zero,
    hasPricingInfo: false as const,
    categoryName: 'Issuing' as const,
    isVolumeEditable: false,
  };
  const internationalAndIntraregionalQuotePrice: QuotePrice =
    convertCurrencyValueForex(
      {
        type: CurrencyValueType.FLAT_AND_PERCENT,
        flat: 0.6,
        percent: 0.3,
        currency: 'USD',
      },
      currentEntity.issuingEntityCurrency,
      pricingFlow,
    );
  const newTransactionFees: AlpacaIssuingProduct[] = [
    {
      ...baseTransactionFee,
      // #UniqueAlpacaIssuingProductIds
      id: `domestic_${currentEntity.binType}_${currentEntity.name}_transactionFees`,
      name: 'Domestic',
      geography: 'domestic',
      quotePrice: convertCurrencyValueForex(
        {
          type: CurrencyValueType.FLAT_AND_PERCENT,
          flat: 0.3,
          percent: 0.3,
          currency: 'USD',
        },
        currentEntity.issuingEntityCurrency,
        pricingFlow,
      ),
    },
    {
      ...baseTransactionFee,
      // #UniqueAlpacaIssuingProductIds
      id: `international_${currentEntity.binType}_${currentEntity.name}_transactionFees`,
      name: 'International',
      geography: 'international',
      quotePrice: internationalAndIntraregionalQuotePrice,
    },
    {
      ...baseTransactionFee,
      // #UniqueAlpacaIssuingProductIds
      id: `intraRegional_${currentEntity.binType}_${currentEntity.name}_transactionFees`,
      name: 'Intra-regional',
      geography: 'intraRegional',
      quotePrice: internationalAndIntraregionalQuotePrice,
    },
  ];
  return {
    ...getPricingFlowWithNewIssuingConfig(pricingFlow, {
      entities: updateCurrentEntity(pricingFlow, {
        showRebates: false,
        showInterchangeRevenueShare: false,
        showTransactionFees: true,
      }),
    }),
    products: postProcessTemplateProducts(
      [...newProducts, ...newTransactionFees],
      pricingFlow,
      currentEntity,
    ),
  };
};
const applyFixedAndVariableTransactionFeesTieredTemplate = (
  pricingFlow: AlpacaPricingFlow,
) => {
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  // Reset the products
  const newProducts = getUnconfigurableProducts(pricingFlow, currentEntity);
  /**
   * Add new transaction fees
   */

  const zero = ZERO_FLAT_AND_PERCENT(currentEntity.issuingEntityCurrency);
  const baseTransactionFee = {
    // #UniqueAlpacaIssuingProductIds
    volume: 0,
    country: currentEntity.name,
    binType: currentEntity.binType,
    digitalWallet: null,
    subCategory: 'transactionFees' as const,
    isCost: false as const,
    quoteCost: zero,
    quotePrice: zero,
    hasPricingInfo: false as const,
    categoryName: 'Issuing' as const,
    isVolumeEditable: false,
  };
  const internationalAndIntraregionalQuotePrice: (
    g: IssuingGeography,
  ) => QuotePrice = (geography) => {
    return {
      type: 'tiered',
      minimumType: CurrencyValueType.FLAT,
      tiers: [
        {
          minimum: convertCurrencyValueForex(
            {
              currency: 'USD',
              type: CurrencyValueType.FLAT,
              value: 0,
            },
            currentEntity.issuingEntityCurrency,
            pricingFlow,
          ),
          currencyValue: convertCurrencyValueForex(
            {
              type: CurrencyValueType.FLAT_AND_PERCENT,
              currency: 'USD',
              flat: 0.6,
              percent: 0.3,
            },
            currentEntity.issuingEntityCurrency,
            pricingFlow,
          ),
        },
        {
          minimum: {
            value:
              getVolumeForGeography(
                geography,
                currentEntity.name,
                pricingFlow,
                false,
                null,
              ).value * 0.75,
            type: CurrencyValueType.FLAT,
            currency: currentEntity.monthlySpendAtScale.currency,
          },
          currencyValue: convertCurrencyValueForex(
            {
              type: CurrencyValueType.FLAT_AND_PERCENT,
              currency: 'USD',
              flat: 0.55,
              percent: 0.25,
            },
            currentEntity.issuingEntityCurrency,
            pricingFlow,
          ),
        },
        {
          minimum: getVolumeForGeography(
            geography,
            currentEntity.name,
            pricingFlow,
            false,
            null,
          ),
          currencyValue: convertCurrencyValueForex(
            {
              type: CurrencyValueType.FLAT_AND_PERCENT,
              currency: 'USD',
              flat: 0.5,
              percent: 0.2,
            },
            currentEntity.issuingEntityCurrency,
            pricingFlow,
          ),
        },
      ],
    };
  };
  const newTransactionFees: AlpacaIssuingProduct[] = [
    {
      ...baseTransactionFee,
      // #UniqueAlpacaIssuingProductIds
      id: `domestic_${currentEntity.binType}_${currentEntity.name}_transactionFees`,
      name: 'Domestic',
      geography: 'domestic',
      quotePrice: {
        type: 'tiered',
        minimumType: CurrencyValueType.FLAT,
        tiers: [
          {
            minimum: convertCurrencyValueForex(
              {
                currency: 'USD',
                type: CurrencyValueType.FLAT,
                value: 0,
              },
              currentEntity.issuingEntityCurrency,
              pricingFlow,
            ),
            currencyValue: convertCurrencyValueForex(
              {
                type: CurrencyValueType.FLAT_AND_PERCENT,
                currency: 'USD',
                flat: 0.3,
                percent: 0.3,
              },
              currentEntity.issuingEntityCurrency,
              pricingFlow,
            ),
          },
          {
            minimum: {
              value:
                getVolumeForGeography(
                  'domestic',
                  currentEntity.name,
                  pricingFlow,
                  false,
                  null,
                ).value * 0.75,
              type: CurrencyValueType.FLAT,
              currency: currentEntity.monthlySpendAtScale.currency,
            },
            currencyValue: convertCurrencyValueForex(
              {
                type: CurrencyValueType.FLAT_AND_PERCENT,
                currency: 'USD',
                flat: 0.25,
                percent: 0.25,
              },
              currentEntity.issuingEntityCurrency,
              pricingFlow,
            ),
          },
          {
            minimum: getVolumeForGeography(
              'domestic',
              currentEntity.name,
              pricingFlow,
              false,
              null,
            ),
            currencyValue: convertCurrencyValueForex(
              {
                type: CurrencyValueType.FLAT_AND_PERCENT,
                currency: 'USD',
                flat: 0.2,
                percent: 0.2,
              },
              currentEntity.issuingEntityCurrency,
              pricingFlow,
            ),
          },
        ],
      },
    },
    {
      ...baseTransactionFee,
      // #UniqueAlpacaIssuingProductIds
      id: `international_${currentEntity.binType}_${currentEntity.name}_transactionFees`,
      name: 'International',
      geography: 'international',
      quotePrice: internationalAndIntraregionalQuotePrice('international'),
    },
    {
      ...baseTransactionFee,
      // #UniqueAlpacaIssuingProductIds
      id: `intraRegional_${currentEntity.binType}_${currentEntity.name}_transactionFees`,
      name: 'Intra-regional',
      geography: 'intraRegional',
      quotePrice: internationalAndIntraregionalQuotePrice('intraRegional'),
    },
  ];
  return {
    ...getPricingFlowWithNewIssuingConfig(pricingFlow, {
      entities: updateCurrentEntity(pricingFlow, {
        showRebates: false,
        showInterchangeRevenueShare: false,
        showTransactionFees: true,
      }),
    }),
    products: postProcessTemplateProducts(
      [...newProducts, ...newTransactionFees],
      pricingFlow,
      currentEntity,
    ),
  };
};

const applyInterchangeRevShareStandardTemplate = (
  pricingFlow: AlpacaPricingFlow,
) => {
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  // Reset the products
  const newProducts = getUnconfigurableProducts(pricingFlow, currentEntity);
  /**
   * Add new transaction fees
   */
  const zero = ZERO_FLAT_AND_PERCENT(currentEntity.issuingEntityCurrency);
  const baseTransactionFee = {
    // #UniqueAlpacaIssuingProductIds
    volume: 0,
    country: currentEntity.name,
    binType: currentEntity.binType,
    digitalWallet: null,
    subCategory: 'transactionFees' as const,
    isCost: false as const,
    quoteCost: zero,
    quotePrice: zero,
    hasPricingInfo: false as const,
    categoryName: 'Issuing' as const,
    isVolumeEditable: false,
  };
  const internationalAndIntraregionalTransactionFee: QuotePrice =
    convertCurrencyValueForex(
      {
        type: CurrencyValueType.FLAT_AND_PERCENT,
        currency: 'USD',
        flat: 0.5,
        percent: 0.2,
      },
      currentEntity.issuingEntityCurrency,
      pricingFlow,
    );
  const newTransactionFees: AlpacaIssuingProduct[] = [
    {
      ...baseTransactionFee,
      // #UniqueAlpacaIssuingProductIds
      id: `domestic_${currentEntity.binType}_${currentEntity.name}_transactionFees`,
      name: 'Domestic',
      geography: 'domestic',
      quotePrice: convertCurrencyValueForex(
        {
          type: CurrencyValueType.FLAT_AND_PERCENT,
          currency: 'USD',
          flat: 0.2,
          percent: 0.2,
        },
        currentEntity.issuingEntityCurrency,
        pricingFlow,
      ),
    },
    {
      ...baseTransactionFee,
      // #UniqueAlpacaIssuingProductIds
      id: `international_${currentEntity.binType}_${currentEntity.name}_transactionFees`,
      name: 'International',
      geography: 'international',
      quotePrice: internationalAndIntraregionalTransactionFee,
    },
    {
      ...baseTransactionFee,
      // #UniqueAlpacaIssuingProductIds
      id: `intraRegional_${currentEntity.binType}_${currentEntity.name}_transactionFees`,
      name: 'Intra-regional',
      geography: 'intraRegional',
      quotePrice: internationalAndIntraregionalTransactionFee,
    },
  ];
  /**
   * Add new interchange revenue share
   */
  const baseIRShare = {
    // #UniqueAlpacaIssuingProductIds
    volume: 0,
    country: currentEntity.name,
    binType: currentEntity.binType,
    digitalWallet: null,
    subCategory: 'interchangeRevenueShare' as const,
    isCost: true as const,
    quoteCost: ZERO_PERCENT,
    quotePrice: ZERO_PERCENT,
    hasPricingInfo: false as const,
    categoryName: 'Issuing' as const,
    isVolumeEditable: false,
  };
  const internationalAndIntraregionalRevShare: QuotePrice = {
    type: CurrencyValueType.PERCENT,
    value: 100,
  };
  const newIRShares: AlpacaIssuingProduct[] = [
    {
      ...baseIRShare,
      // #UniqueAlpacaIssuingProductIds
      id: `domestic_${currentEntity.binType}_${currentEntity.name}_interchangeRevenueShare`,
      name: 'Domestic',
      geography: 'domestic',
      quoteCost: {
        type: CurrencyValueType.PERCENT,
        value: 50,
      },
    },
    {
      ...baseIRShare,
      // #UniqueAlpacaIssuingProductIds
      id: `international_${currentEntity.binType}_${currentEntity.name}_interchangeRevenueShare`,
      name: 'International',
      geography: 'international',
      quoteCost: internationalAndIntraregionalRevShare,
    },
    {
      ...baseIRShare,
      // #UniqueAlpacaIssuingProductIds
      id: `intraRegional_${currentEntity.binType}_${currentEntity.name}_interchangeRevenueShare`,
      name: 'Intra-regional',
      geography: 'intraRegional',
      quoteCost: internationalAndIntraregionalRevShare,
    },
  ];
  return {
    ...getPricingFlowWithNewIssuingConfig(pricingFlow, {
      entities: updateCurrentEntity(pricingFlow, {
        showRebates: false,
        showInterchangeRevenueShare: true,
        showTransactionFees: true,
      }),
    }),
    products: postProcessTemplateProducts(
      [...newProducts, ...newTransactionFees, ...newIRShares],
      pricingFlow,
      currentEntity,
    ),
  };
};
const applyInterchangeRevShareTieredTemplate = (
  pricingFlow: AlpacaPricingFlow,
) => {
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  // Reset the products
  const newProducts = getUnconfigurableProducts(pricingFlow, currentEntity);
  /**
   * Add new transaction fees
   */
  const zero = ZERO_FLAT_AND_PERCENT(currentEntity.issuingEntityCurrency);
  const baseTransactionFee = {
    // #UniqueAlpacaIssuingProductIds
    volume: 0,
    country: currentEntity.name,
    binType: currentEntity.binType,
    digitalWallet: null,
    subCategory: 'transactionFees' as const,
    isCost: false as const,
    quoteCost: zero,
    quotePrice: zero,
    hasPricingInfo: false as const,
    categoryName: 'Issuing' as const,
    isVolumeEditable: false,
  };
  const internationalAndIntraregionalTransactionFee: QuotePrice =
    convertCurrencyValueForex(
      {
        type: CurrencyValueType.FLAT_AND_PERCENT,
        currency: 'USD',
        flat: 0.5,
        percent: 0.2,
      },
      currentEntity.issuingEntityCurrency,
      pricingFlow,
    );
  const newTransactionFees: AlpacaIssuingProduct[] = [
    {
      ...baseTransactionFee,
      // #UniqueAlpacaIssuingProductIds
      id: `domestic_${currentEntity.binType}_${currentEntity.name}_transactionFees`,
      name: 'Domestic',
      geography: 'domestic',
      quotePrice: convertCurrencyValueForex(
        {
          type: CurrencyValueType.FLAT_AND_PERCENT,
          currency: 'USD',
          flat: 0.2,
          percent: 0.2,
        },
        currentEntity.issuingEntityCurrency,
        pricingFlow,
      ),
    },
    {
      ...baseTransactionFee,
      // #UniqueAlpacaIssuingProductIds
      id: `international_${currentEntity.binType}_${currentEntity.name}_transactionFees`,
      name: 'International',
      geography: 'international',
      quotePrice: internationalAndIntraregionalTransactionFee,
    },
    {
      ...baseTransactionFee,
      // #UniqueAlpacaIssuingProductIds
      id: `intraRegional_${currentEntity.binType}_${currentEntity.name}_transactionFees`,
      name: 'Intra-regional',
      geography: 'intraRegional',
      quotePrice: internationalAndIntraregionalTransactionFee,
    },
  ];
  /**
   * Add new interchange revenue share
   */
  const baseIRShare = {
    // #UniqueAlpacaIssuingProductIds
    volume: 0,
    country: currentEntity.name,
    binType: currentEntity.binType,
    digitalWallet: null,
    subCategory: 'interchangeRevenueShare' as const,
    isCost: true as const,
    quoteCost: ZERO_PERCENT,
    quotePrice: ZERO_PERCENT,
    hasPricingInfo: false as const,
    categoryName: 'Issuing' as const,
    isVolumeEditable: false,
  };
  const internationalAndIntraregionalRevShare: (
    g: IssuingGeography,
  ) => QuotePrice = (geography) => {
    return {
      type: 'tiered',
      minimumType: CurrencyValueType.FLAT,
      tiers: [
        {
          minimum: convertCurrencyValueForex(
            {
              type: CurrencyValueType.FLAT,
              currency: 'USD',
              value: 0,
            },
            currentEntity.issuingEntityCurrency,
            pricingFlow,
          ),
          currencyValue: {
            type: CurrencyValueType.PERCENT,
            value: 60,
          },
        },
        {
          minimum: {
            value:
              getVolumeForGeography(
                geography,
                currentEntity.name,
                pricingFlow,
                false,
                null,
              ).value * 0.75,
            type: CurrencyValueType.FLAT,
            currency: currentEntity.monthlySpendAtScale.currency,
          },
          currencyValue: {
            type: CurrencyValueType.PERCENT,
            value: 70,
          },
        },
        {
          minimum: getVolumeForGeography(
            geography,
            currentEntity.name,
            pricingFlow,
            false,
            null,
          ),
          currencyValue: {
            type: CurrencyValueType.PERCENT,
            value: 80,
          },
        },
      ],
    };
  };
  const newIRShares: AlpacaIssuingProduct[] = [
    {
      ...baseIRShare,
      // #UniqueAlpacaIssuingProductIds
      id: `domestic_${currentEntity.binType}_${currentEntity.name}_interchangeRevenueShare`,
      name: 'Domestic',
      geography: 'domestic',
      quoteCost: {
        type: 'tiered',
        minimumType: CurrencyValueType.FLAT,
        tiers: [
          {
            minimum: convertCurrencyValueForex(
              {
                type: CurrencyValueType.FLAT,
                currency: 'USD',
                value: 0,
              },
              currentEntity.issuingEntityCurrency,
              pricingFlow,
            ),
            currencyValue: {
              type: CurrencyValueType.PERCENT,
              value: 60,
            },
          },
          {
            minimum: {
              value:
                getVolumeForGeography(
                  'domestic',
                  currentEntity.name,
                  pricingFlow,
                  false,
                  null,
                ).value * 0.75,
              type: CurrencyValueType.FLAT,
              currency: currentEntity.monthlySpendAtScale.currency,
            },
            currencyValue: {
              type: CurrencyValueType.PERCENT,
              value: 70,
            },
          },
          {
            minimum: getVolumeForGeography(
              'domestic',
              currentEntity.name,
              pricingFlow,
              false,
              null,
            ),
            currencyValue: {
              type: CurrencyValueType.PERCENT,
              value: 80,
            },
          },
        ],
      },
    },
    {
      ...baseIRShare,
      // #UniqueAlpacaIssuingProductIds
      id: `international_${currentEntity.binType}_${currentEntity.name}_interchangeRevenueShare`,
      name: 'International',
      geography: 'international',
      quoteCost: internationalAndIntraregionalRevShare('international'),
    },
    {
      ...baseIRShare,
      // #UniqueAlpacaIssuingProductIds
      id: `intraRegional_${currentEntity.binType}_${currentEntity.name}_interchangeRevenueShare`,
      name: 'Intra-regional',
      geography: 'intraRegional',
      quoteCost: internationalAndIntraregionalRevShare('intraRegional'),
    },
  ];
  return {
    ...getPricingFlowWithNewIssuingConfig(pricingFlow, {
      entities: updateCurrentEntity(pricingFlow, {
        showRebates: false,
        showInterchangeRevenueShare: true,
        showTransactionFees: true,
      }),
    }),
    products: postProcessTemplateProducts(
      [...newProducts, ...newTransactionFees, ...newIRShares],
      pricingFlow,
      currentEntity,
    ),
  };
};

type IssuingTemplate = {
  name: string;
  description: string;
  getNewPricingFlow: (pricingFlow: AlpacaPricingFlow) => AlpacaPricingFlow;
};

export const TEMPLATE_SELECTIONS: IssuingTemplate[] = [
  {
    name: 'Select a Template',
    description: 'Select a template to use for pricing.',
    getNewPricingFlow: (pricingFlow) => pricingFlow,
  },
  {
    name: 'Zero transaction fees',
    description:
      'No transaction fees for all transactions, with AWX receiving full interchange revenue.',
    getNewPricingFlow: applyZeroTransactionFeesTemplate,
  },
  {
    name: 'Zero transaction fees + rebates (Standard)',
    description:
      'No transaction fees for all transactions, with AWX receiving some interchange revenue, and clients receiving rebates.',
    getNewPricingFlow: applyZeroTransactionFeesWithRebatesTemplate,
  },
  {
    name: 'Zero transaction fees + rebates (Tiered)',
    description:
      'No transaction fees for all transactions, with AWX receiving some interchange revenue, and clients receiving tiered rebates.',
    getNewPricingFlow: applyZeroTransactionFeesWithRebatesTieredTemplate,
  },
  {
    name: 'Zero transaction fees + rebates (Tiered with Tier Base)',
    description:
      'No transaction fees and no interchange rev-share. Rebates are fixed and var fees.',
    getNewPricingFlow:
      applyZeroTransactionFeesWithRebatesTieredWithBaseTemplate,
  },
  {
    name: 'Fixed and Variable transaction fees (Standard)',
    description:
      'Clients pay transaction fees for all transactions, receive no interchange revenue, and AWX receives full interchange revenue.',
    getNewPricingFlow: applyFixedAndVariableTransactionFeesTemplate,
  },
  {
    name: 'Fixed and Variable transaction fees (Tiered)',
    description:
      'Clients pay transaction fees for all transactions, receive no interchange revenue, and AWX receives full interchange revenue.',
    getNewPricingFlow: applyFixedAndVariableTransactionFeesTieredTemplate,
  },
  {
    name: 'Interchange Rev-Share (Standard) plus Transaction Fees',
    description:
      'Client pays transaction fees and receives % of interchange revenue. AWX receives some interchange revenue.',
    getNewPricingFlow: applyInterchangeRevShareStandardTemplate,
  },
  {
    name: 'Interchange Rev-Share (Tiered) plus Transaction Fees',
    description:
      'Client pays transaction fees and receives tiered % of interchange revenue. AWX receives some interchange revenue.',
    getNewPricingFlow: applyInterchangeRevShareTieredTemplate,
  },
  {
    name: 'Custom',
    description: '',
    getNewPricingFlow: (pricingFlow) => pricingFlow,
  },
];
