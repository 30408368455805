import { datadogRum } from '@datadog/browser-rum';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { produce, setAutoFreeze } from 'immer';
import { isNil, round } from 'lodash';
import { useState } from 'react';
import { classNames } from 'src/dashboard/App';
import { usePricingFlowContext } from '../../PricingFlow';
import { getCurrentIssuingEntity } from '../Issuing';
import {
  Count,
  CurrencyValue,
  CurrencyValueFlat,
  CurrencyValueFlatAndPercent,
  CurrencyValuePercent,
  CurrencyValueType,
  DerivedValue,
  Tier,
  ZERO_COUNT,
  ZERO_FLAT,
  ZERO_FLAT_AND_PERCENT,
  ZERO_PERCENT,
} from '../alpaca_price_types';
import {
  ALPACA_CURRENCY_SYMBOLS,
  AlpacaIssuingPricingInformation,
  AlpacaIssuingProduct,
  AlpacaIssuingRowSelector,
  AlpacaPricingFlow,
  AlpacaSupportedCurrency,
  IssuingBinType,
  IssuingCountryCode,
  IssuingDigitalWallet,
  IssuingEntity,
  IssuingGeography,
  IssuingSubcategory,
  countryToCurrency,
  isUnpricedSubcategory,
} from '../alpaca_types';
import {
  computeDerivedAggregationsForProducts,
  convertCurrencyValueForex,
  formatCurrencyValue,
  getAverageCurrencyValue,
  getSumCurrencyValue,
} from '../alpaca_utils';
import {
  AlpacaQuotePriceLimit,
  QuotePriceEditable,
} from './AlpacaQuotePriceEditable';
import QuotePriceUneditable from './AlpacaQuotePriceUneditable';
import { VolumeEditable } from './AlpacaVolumeEditable';
import DerivedCurrencyValue from './DerivedCurrencyValue';

export function entityHasIntraregionalConcept(entity: IssuingEntity) {
  if (
    entity.name === IssuingCountryCode.US &&
    entity.binType !== IssuingBinType.CommercialOTA
  ) {
    return false;
  }
  return true;
}

function validPriceTypeForSubcategory(subcategory: IssuingSubcategory) {
  switch (subcategory) {
    case 'interchangeRevenueShare':
      return [CurrencyValueType.PERCENT];
    case 'transactionFees':
    case 'rebates':
    case 'chargeback':
    case 'interchangeRevenue':
    case 'cardCreationCost':
    case 'transactionCost':
    case 'perfIncentives':
    case 'cardMaintenanceCost':
      return [
        CurrencyValueType.FLAT,
        CurrencyValueType.PERCENT,
        CurrencyValueType.FLAT_AND_PERCENT,
      ];
    default:
      const typecheck: never = subcategory;
      datadogRum.addError(`unexpected subcategory ${typecheck}`);
      return [
        CurrencyValueType.FLAT,
        CurrencyValueType.PERCENT,
        CurrencyValueType.FLAT_AND_PERCENT,
      ];
  }
}

export function zeroForIssuingSubCategory(
  subCategory: IssuingSubcategory,
  currency: AlpacaSupportedCurrency,
) {
  switch (subCategory) {
    case 'perfIncentives':
      return ZERO_PERCENT;
    case 'chargeback':
    case 'cardCreationCost':
    case 'cardMaintenanceCost':
      return ZERO_FLAT(currency);
    case 'transactionFees':
    case 'rebates':
    case 'interchangeRevenueShare':
    case 'interchangeRevenue':
    case 'transactionCost':
      return ZERO_FLAT_AND_PERCENT(currency);
    default:
      const typecheck: never = subCategory;
      datadogRum.addError(`unexpected selector subcategory ${typecheck}`);
      return ZERO_FLAT(currency);
  }
}

function isSubcategoryEditable(
  subCategory: AlpacaIssuingProduct['subCategory'],
  pricingFlow: AlpacaPricingFlow,
) {
  switch (subCategory) {
    case 'chargeback':
    case 'cardCreationCost':
    case 'transactionCost':
    case 'perfIncentives':
    case 'cardMaintenanceCost':
      return false;
    case 'transactionFees':
    case 'rebates':
    case 'interchangeRevenueShare':
      return true;
    case 'interchangeRevenue':
      return pricingFlow.additionalData.issuingConfig
        .isCustomInterchangeRevenueEnabled;
    default:
      const typecheck: never = subCategory;
      datadogRum.addError(`unexpected subcategory ${typecheck}`);
      return false;
  }
}

function updateProduct({
  id,
  newProductOverrides,
  pricingFlow,
  updateFlow,
}: {
  id: string;
  newProductOverrides: Partial<AlpacaIssuingProduct>;
  pricingFlow: AlpacaPricingFlow;
  updateFlow: (flow: AlpacaPricingFlow, showLoading?: boolean) => void;
}) {
  // ##UniqueAlpacaIssuingProductIds
  // When updating products, we search by id, so we need their ids to be unique,
  // even when they don't correspond to a uuid from the db. This is the case
  // whenever the product (e.g. a rebate) doesn't have default pricing or cost
  // information associated with it in the pricebook.
  setAutoFreeze(false);

  updateFlow(
    produce(pricingFlow, (draftPricingFlow) => {
      const oldProduct = (draftPricingFlow.products ?? []).find(
        (p) => p.id === id,
      );
      if (oldProduct) {
        Object.assign(oldProduct, newProductOverrides);
      }
    }),
    false,
  );
}
export function currencyForIssuingProduct(
  product: AlpacaIssuingProduct,
  pricingFlow: AlpacaPricingFlow,
) {
  const issuingConfig = pricingFlow.additionalData.issuingConfig;
  const entity = issuingConfig.entities.find((e) => e.name === product.country);
  const currency = entity?.issuingEntityCurrency;
  if (currency) {
    return currency;
  } else if (issuingConfig.isShowLocalCurrencySelected) {
    return countryToCurrency[product.country];
  }
  // fallback to quote currency
  return pricingFlow.additionalData.quoteCurrency;
}

export function getVolumeForIssuingProduct(
  product: AlpacaIssuingProduct,
  pricingFlow: AlpacaPricingFlow,
): {
  volume: DerivedValue<CurrencyValueFlat>;
  transactionCount: DerivedValue<Count>;
} {
  const issuingConfig = pricingFlow.additionalData.issuingConfig;
  const entity = issuingConfig.entities.find((e) => e.name === product.country);
  const currency = currencyForIssuingProduct(product, pricingFlow);
  const zero = {
    volume: { ...ZERO_FLAT(currency), provenance: `default for missing data` },
    transactionCount: { ...ZERO_COUNT, provenance: `default for missing data` },
  };

  if (
    // If you unselect the entity that this product is from, attribute 0 volume
    // to it because the rep probably doesn't want it on their quote
    !entity ||
    !issuingConfig.selectedEntities.includes(entity.name) ||
    // If multi-country issuing is disabled, only attribute volume to products
    // from the currently visible entity
    (!issuingConfig.isMultiCountryIssuingEnabled &&
      entity.name !== issuingConfig.currentlyViewingEntity) ||
    // If the user selected another bin type, we save the products from the
    // original bin type on their pricing flow, but should not attribute any
    // volume / revenue / cost to them in aggregations
    entity.binType !== product.binType
  ) {
    return zero;
  }
  switch (product.subCategory) {
    case 'interchangeRevenueShare':
      if (!entity.showInterchangeRevenueShare) {
        return zero;
      }
      // based on interchange revenue
      const matchingInterchangeRevProducts = pricingFlow.products?.filter(
        (p) => {
          return (
            p.categoryName === 'Issuing' &&
            p.subCategory === 'interchangeRevenue' &&
            p.binType === product.binType &&
            p.country === product.country &&
            p.geography === product.geography
          );
        },
      );
      if (!matchingInterchangeRevProducts) {
        return zero;
      }
      if (matchingInterchangeRevProducts.length === 0) {
        datadogRum.addError(
          `Did not find interchange revenue product matching ${product}`,
        );
        return zero;
      }
      if (matchingInterchangeRevProducts.length > 1) {
        datadogRum.addError(
          `found too many interchange revenue product matching ${product}`,
        );
      }
      return {
        volume: {
          ...(matchingInterchangeRevProducts[0].derivedAggregations
            ?.grossRevenue ?? {
            ...ZERO_FLAT(currency),
            provenance: `default for missing derived aggregations`,
          }),
          concept: `Based on interchange revenue's (quote price * monthly volume)`,
        },
        transactionCount: matchingInterchangeRevProducts[0].derivedAggregations
          ?.estimatedTransactionCount ?? {
          ...ZERO_COUNT,
          provenance: `default for missing derived aggregations`,
        },
      };
    case 'cardCreationCost':
      return {
        volume: {
          ...ZERO_FLAT(currency),
          provenance: `irrelevant for product`,
        },
        // #AlpacaPercentsX100
        transactionCount: {
          value:
            (entity.physicalCardPercentage.value *
              entity.monthlyNumberOfCardsCreated.value) /
            100,
          type: 'count',
          concept: 'Monthly total # cards created * % physical cards',
          provenance: `${formatCurrencyValue(entity.physicalCardPercentage)} * ${formatCurrencyValue(entity.monthlyNumberOfCardsCreated)}`,
        },
      };
    case 'cardMaintenanceCost':
      return {
        volume: {
          ...ZERO_FLAT(currency),
          provenance: `irrelevant for product`,
        },
        transactionCount: {
          // ##IssuingApplePayMaintenanceCosts
          // They currently only pay a maintenance cost on apple cards.
          // #DigitalWalletSplit
          value:
            (entity.monthlyNumberOfCardsCreated.value *
              entity.addedToDigitalWalletPercentage.value) /
            100 /
            2,
          type: 'count',
          concept:
            'Monthly total # cards created * % added to digital wallets / 2\nCard maintenance costs only apply to Apple Pay',
          provenance: `(${formatCurrencyValue(entity.monthlyNumberOfCardsCreated)} * ${formatCurrencyValue(entity.addedToDigitalWalletPercentage)}) / 2`,
        },
      };
    case 'rebates':
      if (entity.showRebates) {
        return {
          volume: getVolumeForGeography(
            product.geography,
            product.country,
            pricingFlow,
            false,
            product.digitalWallet,
          ),
          transactionCount: getTxnCountForGeography(
            product.geography,
            product.country,
            pricingFlow,
            false,
            product.digitalWallet,
          ),
        };
      } else {
        return zero;
      }
    case 'transactionFees':
      if (entity.showTransactionFees) {
        return {
          volume: getVolumeForGeography(
            product.geography,
            product.country,
            pricingFlow,
            false,
            product.digitalWallet,
          ),
          transactionCount: getTxnCountForGeography(
            product.geography,
            product.country,
            pricingFlow,
            false,
            product.digitalWallet,
          ),
        };
      } else {
        return zero;
      }
    case 'perfIncentives':
      if (entity.binType === IssuingBinType.CommercialOTA) {
        return {
          volume: getVolumeForGeography(
            product.geography,
            product.country,
            pricingFlow,
            false,
            product.digitalWallet,
          ),
          transactionCount: getTxnCountForGeography(
            product.geography,
            product.country,
            pricingFlow,
            false,
            product.digitalWallet,
          ),
        };
      } else {
        return zero;
      }
    case 'interchangeRevenue':
    case 'transactionCost':
      return {
        volume: getVolumeForGeography(
          product.geography,
          product.country,
          pricingFlow,
          product.subCategory === 'transactionCost',
          product.digitalWallet,
        ),
        transactionCount: getTxnCountForGeography(
          product.geography,
          product.country,
          pricingFlow,
          product.subCategory === 'transactionCost',
          product.digitalWallet,
        ),
      };
    case 'chargeback':
      return {
        volume: {
          ...ZERO_FLAT(currency),
          provenance: `irrelevant for product`,
        },
        transactionCount: {
          value: product.volume,
          type: 'count',
          provenance: `user input`,
        },
      };
    default:
      const typecheck: never = product;
      datadogRum.addError(`Unexpected subcategory ${typecheck}`);
      return typecheck;
  }
}
export function getVolumeForGeography(
  geography: IssuingGeography | null,
  country: IssuingCountryCode,
  pricingFlow: AlpacaPricingFlow,
  splitByDigitalWallet: boolean,
  digitalWallet: IssuingDigitalWallet | null,
): DerivedValue<CurrencyValueFlat> {
  const entity = pricingFlow.additionalData.issuingConfig.entities.find(
    (e) => e.name === country,
  );
  if (!entity) {
    return {
      ...ZERO_FLAT(countryToCurrency[country]),
      provenance: 'missing issuing entity',
    };
  }
  const overallVolume = {
    ...entity.monthlySpendAtScale,
    provenance: 'monthly spend at scale',
  };
  if (geography) {
    const geoPercent = entity.regionalBreakdown[geography];
    const geoVolume = {
      // #AlpacaPercentsX100
      value: (overallVolume.value * geoPercent.value) / 100,
      type: CurrencyValueType.FLAT as const,
      currency: overallVolume.currency,
      provenance: `${formatCurrencyValue(overallVolume)} * ${formatCurrencyValue(geoPercent)}`,
    };
    if (splitByDigitalWallet) {
      const { value, concept, provenance } = (() => {
        switch (digitalWallet) {
          case 'apple':
          case 'google':
            return {
              // #AlpacaPercentsX100
              // ##DigitalWalletSplit
              // Even split between apple pay and google pay
              value:
                (geoVolume.value *
                  entity.addedToDigitalWalletPercentage.value) /
                100 /
                2,
              concept:
                'Monthly volume for geography * % added to digital wallets / 2\nAssuming an even split between Apple and Google Pay',
              provenance: `(${formatCurrencyValue(geoVolume)} * ${formatCurrencyValue(entity.addedToDigitalWalletPercentage)}) / 2`,
            };
          case 'excl':
          case null:
            return {
              // #AlpacaPercentsX100
              value:
                (geoVolume.value *
                  (100 - entity.addedToDigitalWalletPercentage.value)) /
                100,
              concept:
                'Monthly volume for geography * % not added to digital wallets',
              provenance: `${formatCurrencyValue(geoVolume)} * (100% - ${formatCurrencyValue(entity.addedToDigitalWalletPercentage)})`,
            };
          default:
            const typecheck: never = digitalWallet;
            datadogRum.addError(`unexpected digital wallet ${typecheck}`);
            return {
              value: geoVolume.value,
              concept: null,
              provenance: geoVolume.provenance,
            };
        }
      })();
      return {
        value,
        provenance,
        concept,
        type: CurrencyValueType.FLAT as const,
        currency: overallVolume.currency,
      };
    }
    return geoVolume;
  }
  return overallVolume;
}
export function getTxnCountForGeography(
  geography: IssuingGeography | null,
  country: IssuingCountryCode,
  pricingFlow: AlpacaPricingFlow,
  splitByDigitalWallet: boolean,
  digitalWallet: IssuingDigitalWallet | null,
): DerivedValue<Count> {
  const entity = pricingFlow.additionalData.issuingConfig.entities.find(
    (e) => e.name === country,
  );
  if (!entity) {
    return { ...ZERO_COUNT, provenance: 'missing entity' };
  }
  const overallCount = {
    ...entity.monthlyTransactionCount,
    provenance: 'monthly transaction count',
  };
  if (geography) {
    const geoPercent = entity.regionalBreakdown[geography];
    const geoTxnCount = {
      // #AlpacaPercentsX100
      value: (overallCount.value * geoPercent.value) / 100,
      type: 'count' as const,
      provenance: `${formatCurrencyValue(overallCount)} * ${formatCurrencyValue(geoPercent)}`,
    };
    if (splitByDigitalWallet) {
      const { value, concept, provenance } = (() => {
        switch (digitalWallet) {
          case 'apple':
          case 'google':
            return {
              // #AlpacaPercentsX100
              // #DigitalWalletSplit
              value:
                (geoTxnCount.value *
                  entity.addedToDigitalWalletPercentage.value) /
                100 /
                2,
              concept:
                'Monthly transaction count for geography * % added to digital wallets / 2\nAssuming an even split between Apple and Google Pay',
              provenance: `(${formatCurrencyValue(geoTxnCount)} * ${formatCurrencyValue(entity.addedToDigitalWalletPercentage)}) / 2`,
            };
          case 'excl':
          case null:
            return {
              // #AlpacaPercentsX100
              value:
                (geoTxnCount.value *
                  (100 - entity.addedToDigitalWalletPercentage.value)) /
                100,
              concept:
                'Monthly transaction count for geography * % not added to digital wallets',
              provenance: `${formatCurrencyValue(geoTxnCount)} * (100% - ${formatCurrencyValue(entity.addedToDigitalWalletPercentage)})`,
            };
          default:
            const typecheck: never = digitalWallet;
            datadogRum.addError(`unexpected digital wallet ${typecheck}`);
            return {
              value: geoTxnCount.value,
              concept: null,
              provenance: geoTxnCount.provenance,
            };
        }
      })();
      // #DigitalWalletSplit
      return {
        value,
        concept,
        provenance,
        type: 'count' as const,
      };
    }
    return geoTxnCount;
  }
  return overallCount;
}
function findRelevantTransactionCostTier(
  tiers: Tier<CurrencyValueFlatAndPercent, CurrencyValueFlat>[],
  geography: IssuingGeography,
  currentEntity: IssuingEntity,
  pricingFlow: AlpacaPricingFlow,
): CurrencyValueFlat {
  if (tiers.length === 0) {
    datadogRum.addError(`no tiers found`);
    return ZERO_FLAT(currentEntity.issuingEntityCurrency);
  }
  // tier minimums are stored in USD, so we need to convert to USD before
  // finding which tier we match
  const geoVolume = convertCurrencyValueForex(
    getVolumeForGeography(
      geography,
      currentEntity.name,
      pricingFlow,
      false,
      null,
    ),
    'USD',
    pricingFlow,
  );
  const sortedTiers = [...tiers].sort((a, b) => {
    return a.minimum.value - b.minimum.value;
  });
  let prevTier = sortedTiers[0];
  for (const tier of sortedTiers) {
    if (tier.minimum.value > geoVolume.value) {
      return prevTier.minimum;
    }
    prevTier = tier;
  }
  return prevTier.minimum;
}

export function getExistingIssuingProducts({
  geography,
  binType,
  country,
  subCategory,
  pricingFlow,
}: {
  geography: IssuingGeography | null;
  binType: IssuingBinType;
  country: IssuingCountryCode;
  subCategory: AlpacaIssuingProduct['subCategory'];
  pricingFlow: AlpacaPricingFlow;
}): AlpacaIssuingProduct[] {
  const matchingProducts = (pricingFlow.products ?? []).filter(
    (product): product is AlpacaIssuingProduct => {
      return (
        product.categoryName === 'Issuing' &&
        product.subCategory === subCategory &&
        product.country === country &&
        product.binType === binType &&
        (isNil(product.geography) || product.geography === geography)
      );
    },
  );
  return matchingProducts;
}

export function getListPriceOrCost(
  pricingInfo: AlpacaIssuingPricingInformation,
  geography: IssuingGeography | null,
  currentEntity: IssuingEntity,
  pricingFlow: AlpacaPricingFlow,
) {
  switch (pricingInfo.skuSubgroup) {
    case 'chargeback':
    case 'cardCreationCost':
    case 'cardMaintenanceCost':
      return pricingInfo.cost;
    case 'interchangeRevenue':
    case 'perfIncentives':
      return pricingInfo.listPrice;
    case 'transactionCost':
      if (geography) {
        const tieredCost = pricingInfo.cost;
        const tierMin = findRelevantTransactionCostTier(
          tieredCost.tiers,
          geography,
          currentEntity,
          pricingFlow,
        );
        const cost = (() => {
          // There should be three of these, for apple pay, google pay, or
          // neither
          const relevantTiers = tieredCost.tiers.filter(
            (t) => t.minimum.value === tierMin.value,
          );
          return relevantTiers[0].currencyValue;
        })();
        return cost;
      }
      datadogRum.addError(
        `asking for transaction cost pricing info without geography, ${pricingInfo}, ${geography}`,
      );
      return zeroForIssuingSubCategory(
        'transactionCost',
        currentEntity.issuingEntityCurrency,
      );
    default:
      const typecheck: never = pricingInfo;
      datadogRum.addError(`invalid subgroup ${typecheck}`);
      return ZERO_PERCENT;
  }
}

function getProductGroupsFromSelectors(
  selectors: AlpacaIssuingRowSelector[],
  pricingFlow: AlpacaPricingFlow,
  updateFlow: (flow: AlpacaPricingFlow, showLoading: boolean) => void,
) {
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  let allExistingProductGroups: AlpacaIssuingProduct[][] = [];
  selectors.forEach((selector) => {
    const existingProducts = getExistingIssuingProducts({
      ...selector,
      binType: currentEntity.binType,
      country: currentEntity.name,
      pricingFlow,
    });
    if (existingProducts.length > 0) {
      allExistingProductGroups.push(existingProducts);
    } else {
      datadogRum.addError(
        `Expected to find product but didn't find it. Check the logic at #CreateAllIssuingProducts`,
      );
    }
  });
  return allExistingProductGroups;
}

function limitForSubcategory(
  subCategory: IssuingSubcategory,
): AlpacaQuotePriceLimit | null {
  switch (subCategory) {
    case 'interchangeRevenueShare':
      return { flat: null, percent: 100 };
    case 'transactionFees':
    case 'rebates':
    case 'chargeback':
    case 'interchangeRevenue':
    case 'cardCreationCost':
    case 'transactionCost':
    case 'perfIncentives':
    case 'cardMaintenanceCost':
      return null;
    default:
      const typecheck: never = subCategory;
      datadogRum.addError(`unexpected subcategory ${typecheck}`);
      return null;
  }
}

function getQuotePriceOrCost(
  product: AlpacaIssuingProduct,
  pricingFlow: AlpacaPricingFlow,
) {
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  const zero = zeroForIssuingSubCategory(
    product.subCategory,
    currentEntity.issuingEntityCurrency,
  );
  const pricingInfo = product.pricingInformation;
  const listPriceOrCost = pricingInfo
    ? getListPriceOrCost(
        pricingInfo,
        product.geography,
        currentEntity,
        pricingFlow,
      )
    : null;
  if (
    product.subCategory === 'interchangeRevenue' &&
    !pricingFlow.additionalData.issuingConfig.isCustomInterchangeRevenueEnabled
  ) {
    return listPriceOrCost ?? zero;
  }
  if (product.isCost) {
    return product.quoteCost ?? listPriceOrCost ?? zero;
  } else {
    return product.quotePrice ?? listPriceOrCost ?? zero;
  }
}

type QuotePriceCellProps = {
  product: AlpacaIssuingProduct;
};
function QuotePriceCell(props: QuotePriceCellProps) {
  const { product } = props;
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  const pricingInfo = product.pricingInformation;
  const zero = zeroForIssuingSubCategory(
    product.subCategory,
    currentEntity.issuingEntityCurrency,
  );
  const quotePrice = getQuotePriceOrCost(product, pricingFlow);
  const listPriceOrCost = pricingInfo
    ? getListPriceOrCost(
        pricingInfo,
        product.geography,
        currentEntity,
        pricingFlow,
      )
    : zero;
  if (isSubcategoryEditable(product.subCategory, pricingFlow)) {
    return (
      <QuotePriceEditable
        quotePrice={quotePrice}
        updateQuotePrice={(newQuotePrice) => {
          if (product.isCost) {
            updateProduct({
              id: product.id,
              newProductOverrides: { quoteCost: newQuotePrice },
              pricingFlow,
              updateFlow,
            });
          } else {
            updateProduct({
              id: product.id,
              newProductOverrides: { quotePrice: newQuotePrice },
              pricingFlow,
              updateFlow,
            });
          }
        }}
        validPriceTypes={validPriceTypeForSubcategory(product.subCategory)}
        quoteCurrency={currentEntity.issuingEntityCurrency}
        stickerPrice={product.isCost ? zero : listPriceOrCost}
        cost={product.isCost ? listPriceOrCost : zero}
        tierConfig={{
          showCost:
            product.isCost && !isUnpricedSubcategory(product.subCategory),
          showStickerPrice:
            !product.isCost && !isUnpricedSubcategory(product.subCategory),
        }}
        productName={product.name}
        validTierMinimumTypes={[CurrencyValueType.FLAT]}
        limit={limitForSubcategory(product.subCategory)}
      />
    );
  } else {
    return (
      <QuotePriceUneditable
        quotePrice={quotePrice}
        currency={currentEntity.issuingEntityCurrency}
      />
    );
  }
}

type AlpacaIssuingGroupRowProps = Omit<AlpacaIssuingRowProps, 'product'> & {
  productGroup: AlpacaIssuingProduct[];
};
function AlpacaIssuingGroupRow(props: AlpacaIssuingGroupRowProps) {
  const { productGroup } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  if (productGroup.length === 0) {
    return null;
  } else if (productGroup.length === 1) {
    return <AlpacaIssuingRow product={productGroup[0]} {...props} />;
  } else {
    // products in the group represent costs, as opposed to revenues
    const isCostGroup = productGroup[0].isCost;
    if (productGroup.some((p) => p.isCost !== isCostGroup)) {
      datadogRum.addError(
        `Product's isCost did not match group's isCost: ${productGroup}`,
      );
    }
    const geographyToSummaryRowName = (name: IssuingGeography | null) => {
      switch (name) {
        case 'domestic':
          return 'Summary: Domestic';
        case 'international':
          return 'Summary: International';
        case 'intraRegional':
          return 'Summary: Intra-regional';
        case null:
        default:
          datadogRum.addError('FIXME');
          return null;
      }
    };
    const grossCostOrRevenue = getSumCurrencyValue({
      currencyValuesRaw: productGroup.map((p) => {
        return (
          (p.isCost
            ? p.derivedAggregations?.grossCost
            : p.derivedAggregations?.grossRevenue) ?? null
        );
      }),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    });
    const volume = getSumCurrencyValue({
      currencyValuesRaw: productGroup.map(
        (p) => getVolumeForIssuingProduct(p, pricingFlow).volume,
      ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    });
    const grossPercent: DerivedValue<CurrencyValuePercent> | null =
      grossCostOrRevenue
        ? {
            // #AlpacaPercentsX100
            value: round((100 * grossCostOrRevenue.value) / volume.value, 2),
            type: CurrencyValueType.PERCENT,
            concept: isCostGroup
              ? 'Monthly cost / monthly volume'
              : 'Monthly revenue / monthly volume',
            provenance: `${formatCurrencyValue(grossCostOrRevenue)} / ${formatCurrencyValue(volume)}`,
          }
        : null;
    return (
      <>
        {/* SUMMARY ROW */}
        <tr key={`summary_${productGroup[0].id}`}>
          {/* REGION */}
          <td className="border-gray-200 px-6 py-4 text-sm font-semibold text-gray-900 flex flex-row items-cent0">
            <div className="mr-2 h-5 w-5 flex-shrink-0">
              <button
                className="absolute flex h-5 w-5 items-center justify-center rounded-full border border-fuchsia-900 bg-fuchsia-50"
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
              >
                {isExpanded ? (
                  <ChevronDownIcon className="h-3 w-3" aria-hidden="true" />
                ) : (
                  <ChevronRightIcon className="h-3 w-3" aria-hidden="true" />
                )}
              </button>
            </div>
            {geographyToSummaryRowName(productGroup[0].geography)}
          </td>
          {/* VOLUME */}
          <td className="border-gray-200 py-0 text-sm font-medium text-gray-900 px-6 whitespace-nowrap">
            <DerivedCurrencyValue value={volume} />
          </td>
          {/* VALUE: e.g. cost or revenue */}
          <td className="border-gray-200 p-0 text-sm font-medium text-gray-900 px-6 whitespace-nowrap">
            <DerivedCurrencyValue
              value={getAverageCurrencyValue(
                productGroup
                  .map((p) => {
                    const quotePriceOrCost = getQuotePriceOrCost(
                      p,
                      pricingFlow,
                    );
                    if (quotePriceOrCost.type === 'tiered') {
                      return null;
                    } else {
                      return quotePriceOrCost;
                    }
                  })
                  .filter((p): p is CurrencyValue => !isNil(p)),
                currentEntity.issuingEntityCurrency,
                pricingFlow,
              )}
            />
          </td>
          {/* GROSS: e.g. cost or revenue */}
          <td className="border-gray-200 px-6 p-0 text-sm font-medium text-gray-900 whitespace-nowrap">
            <DerivedCurrencyValue value={grossCostOrRevenue} />
          </td>
          {/* GROSS AS PERCENT */}
          <td className="border-gray-200 px-6 p-0 text-sm font-medium text-gray-900 whitespace-nowrap">
            <DerivedCurrencyValue value={grossPercent} />
          </td>
        </tr>
        {/* DETAIL ROWS */}
        {isExpanded &&
          productGroup.map((product) => {
            return (
              <AlpacaIssuingRow
                product={product}
                {...props}
                bgColor="bg-slate-50"
              />
            );
          })}
      </>
    );
  }
}

type AlpacaIssuingRowProps = {
  product: AlpacaIssuingProduct;
  volumeIsDollarVolume: boolean;
  shouldShowPercentAgg: boolean;
  bgColor?: string;
};
function AlpacaIssuingRow(props: AlpacaIssuingRowProps) {
  const { product, volumeIsDollarVolume } = props;
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const shouldShowPercentAgg = volumeIsDollarVolume
    ? props.shouldShowPercentAgg
    : false;
  const { volume, transactionCount } = getVolumeForIssuingProduct(
    product,
    pricingFlow,
  );

  const grossCostOrRevenue =
    (product.isCost
      ? product.derivedAggregations?.grossCost
      : product.derivedAggregations?.grossRevenue) ?? null;
  const grossPercent: DerivedValue<CurrencyValuePercent> | null =
    grossCostOrRevenue
      ? {
          // #AlpacaPercentsX100
          value: round((100 * grossCostOrRevenue.value) / volume.value, 2),
          type: CurrencyValueType.PERCENT,
          concept: product.isCost
            ? 'Monthly cost / monthly volume'
            : 'Monthly revenue / monthly volume',
          provenance: `${formatCurrencyValue(grossCostOrRevenue)} / ${formatCurrencyValue(volume)}`,
        }
      : null;

  const quotePrice = getQuotePriceOrCost(product, pricingFlow);
  const currentEntity = getCurrentIssuingEntity(pricingFlow);

  const bgColor = props.bgColor ?? 'bg-white';
  return (
    <tr key={product.id}>
      {/* REGION */}
      <td
        className={classNames(
          bgColor,
          'border-gray-200 px-6 py-4 text-sm font-medium text-gray-900',
        )}
      >
        {product.name}
      </td>
      {/* VOLUME */}
      <td
        className={classNames(
          bgColor,
          'border-gray-200 py-0 text-sm font-medium text-gray-900',
        )}
      >
        {product.isVolumeEditable ? (
          <VolumeEditable
            volume={volume.value}
            updateVolume={(newVolume: number) => {
              throw new Error('not implemented');
            }}
            transactionCount={transactionCount.value}
            updateTransactionCount={(newTxnCount: number | undefined) => {
              switch (product.subCategory) {
                case 'chargeback':
                  return updateProduct({
                    id: product.id,
                    newProductOverrides: {
                      volume: newTxnCount,
                    },
                    pricingFlow,
                    updateFlow,
                  });
                default:
                  datadogRum.addError(
                    'these volumes should not be editable, they are derived from other fields',
                  );
              }
            }}
            quotePrice={quotePrice}
            quoteCurrency={currentEntity.issuingEntityCurrency}
            cost={quotePrice}
          />
        ) : (
          <span className="px-6">
            <DerivedCurrencyValue
              value={volumeIsDollarVolume ? volume : transactionCount}
            />
          </span>
        )}
      </td>
      {/* VALUE: e.g. cost or revenue */}
      <td
        className={classNames(
          bgColor,
          'border-gray-200 p-0 text-sm font-medium text-gray-900',
        )}
      >
        <QuotePriceCell product={product} />
      </td>
      {/* GROSS: e.g. total cost or revenue */}
      <td
        className={classNames(
          bgColor,
          'border-gray-200 px-6 p-0 text-sm font-medium text-gray-900',
        )}
      >
        <DerivedCurrencyValue value={grossCostOrRevenue} />
      </td>
      {/* GROSS AS PERCENT */}
      {shouldShowPercentAgg && (
        <td
          className={classNames(
            bgColor,
            'border-gray-200 px-6 p-0 text-sm font-medium text-gray-900',
          )}
        >
          <DerivedCurrencyValue value={grossPercent} />
        </td>
      )}
    </tr>
  );
}
type AlpacaIssuingTableProps = {
  // For the header
  title: string;
  valueDescription: string;
  volumeDescription?: string;
  // for the rows
  selectors: AlpacaIssuingRowSelector[];
} & (
  | {
      canToggleVisibility: false;
    }
  | {
      canToggleVisibility: true;
      showTable: boolean;
      setShowTable: (newVal: boolean) => void;
    }
) &
  // If volume is not dollar volume, it's transaction count.
  // Showing gross cost% or revenue% doesn't make sense when the volume is
  // transaction count, because the % is calculated as
  //   (gross cost or revenue) / ($ volume)
  (| { volumeIsDollarVolume: true; shouldShowPercentAgg: boolean }
    | { volumeIsDollarVolume: false }
  );
export default function AlpacaIssuingTable(props: AlpacaIssuingTableProps) {
  const { title, valueDescription, canToggleVisibility, volumeIsDollarVolume } =
    props;
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const showTable = canToggleVisibility ? props.showTable : true;
  const setShowTable = canToggleVisibility ? props.setShowTable : () => null;
  const shouldShowPercentAgg = volumeIsDollarVolume
    ? props.shouldShowPercentAgg
    : false;
  const button = Button({
    showTable: showTable,
    setShowTable,
    title,
  });
  if (showTable) {
    const currentEntity = getCurrentIssuingEntity(pricingFlow);
    const selectors = props.selectors.filter((s) => {
      if (entityHasIntraregionalConcept(currentEntity)) {
        return true;
      } else {
        return s.geography !== 'intraRegional';
      }
    });
    const currency = currentEntity.issuingEntityCurrency;
    // groups of products. Most of these groups are size 1, but for transaction
    // costs, there are 3 products that we roll up into one row
    const existingProductGroups = getProductGroupsFromSelectors(
      selectors,
      pricingFlow,
      updateFlow,
    );
    const isCostTable =
      existingProductGroups.length > 0
        ? existingProductGroups[0].length > 0
          ? existingProductGroups[0][0].isCost
          : false
        : false;
    const grossCostOrRevenue = (() => {
      const flatExistingProducts = existingProductGroups.flat();
      if (flatExistingProducts.length === 0) {
        return null;
      }
      if (flatExistingProducts.some((p) => p.isCost !== isCostTable)) {
        datadogRum.addError(
          `Product's isCost did not match table's isCost: ${flatExistingProducts}`,
        );
      }
      const derivedAggregations = computeDerivedAggregationsForProducts(
        flatExistingProducts,
        pricingFlow,
        currentEntity.issuingEntityCurrency,
      );
      if (flatExistingProducts[0].isCost) {
        return derivedAggregations.grossCost;
      }
      return derivedAggregations.grossRevenue;
    })();
    const volume = currentEntity.monthlySpendAtScale;
    const grossPercent: DerivedValue<CurrencyValuePercent> | null =
      grossCostOrRevenue
        ? {
            // #AlpacaPercentsX100
            value: round((100 * grossCostOrRevenue.value) / volume.value, 2),
            type: CurrencyValueType.PERCENT,
            concept: isCostTable
              ? 'Monthly cost / monthly volume'
              : 'Monthly revenue / monthly volume',
            provenance: `${formatCurrencyValue(grossCostOrRevenue)} / ${formatCurrencyValue(volume)}`,
          }
        : null;

    return (
      <div className="overflow-visible shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <table className="h-full w-full">
          <thead>
            <tr>
              <th
                scope="col"
                className={classNames(
                  canToggleVisibility ? 'py-0' : 'py-3.5',
                  'sticky top-0 z-10 w-full rounded-t-xl border-b bg-gray-50 px-6 text-left text-xs font-medium text-gray-700',
                )}
              >
                {canToggleVisibility ? button : title}
              </th>
              <th
                scope="col"
                className=" min-w-[160px] sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 sm:table-cell xl:whitespace-nowrap"
              >
                {`${props.volumeDescription ?? 'Est. volume'} (${volumeIsDollarVolume ? ALPACA_CURRENCY_SYMBOLS[currency] : '#'})`}
              </th>
              <th
                scope="col"
                className=" min-w-[160px] sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 sm:table-cell xl:whitespace-nowrap"
              >
                {valueDescription}
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 sm:table-cell xl:whitespace-nowrap has-tooltip"
              >
                {`Est. total ${valueDescription.toLowerCase()} (${ALPACA_CURRENCY_SYMBOLS[currency]})`}
              </th>
              {shouldShowPercentAgg && (
                <th
                  scope="col"
                  className="sticky top-0 z-10 hidden rounded-tr-xl border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 sm:table-cell xl:whitespace-nowrap"
                >
                  {`Est. total ${valueDescription.toLowerCase()} (%)`}
                </th>
              )}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {existingProductGroups.map((productGroup) => {
              return (
                <AlpacaIssuingGroupRow
                  productGroup={productGroup}
                  key={productGroup.length > 0 ? productGroup[0].id : 'empty'}
                  shouldShowPercentAgg={shouldShowPercentAgg}
                  volumeIsDollarVolume={volumeIsDollarVolume}
                />
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th
                colSpan={2}
                className="border-t whitespace-nowrap rounded-bl-xl bg-slate-50  px-6 py-2.5"
              ></th>
              <th
                scope="col"
                colSpan={1}
                className="border-t hidden bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 sm:table-cell xl:whitespace-nowrap"
              >
                Total
              </th>
              <th
                scope="col"
                className="border-t hidden bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 sm:table-cell xl:whitespace-nowrap"
              >
                <DerivedCurrencyValue value={grossCostOrRevenue} />
              </th>
              {shouldShowPercentAgg && (
                <th
                  scope="col"
                  className="border-t hidden rounded-br-xl bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 sm:table-cell xl:whitespace-nowrap"
                >
                  <DerivedCurrencyValue value={grossPercent} />
                </th>
              )}
            </tr>
          </tfoot>
        </table>
      </div>
    );
  } else {
    return button;
  }
}

type ButtonProps = {
  title: string;
  showTable: boolean;
  setShowTable: (newVal: boolean) => void;
};
function Button(props: ButtonProps) {
  const { showTable, setShowTable, title } = props;
  return (
    <button
      type="button"
      className={classNames(
        showTable
          ? 'bg-gray-50 hover:text-gray-900 text-xs text-slate-700'
          : 'bg-white border border-gray-300 shadow-sm hover:bg-gray-50 text-sm font-semibold text-gray-700 px-3.5 ',
        'w-fit inline-flex items-center rounded-lg py-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700',
      )}
      onClick={() => setShowTable(!showTable)}
    >
      <input
        className="rounded mr-2"
        type="checkbox"
        checked={showTable}
        onChange={() => setShowTable(!showTable)}
      />
      {title}
    </button>
  );
}
