import { useStytchB2BClient, useStytchMemberSession } from '@stytch/react/b2b';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setupDeveloperMode } from 'src/utls/developerMode';
import { setUser as setDatadogRumUser } from '../analytics/datadog';
import './App.css';

import {
  BeakerIcon,
  CalculatorIcon,
  ChartBarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  HomeIcon,
  LockClosedIcon,
} from '@heroicons/react/24/outline';
import api from 'src/api';
import Alert from 'src/components/Alert';
import Loading from 'src/components/Loading';
import { ModalProvider } from 'src/components/Modal';
import { ToastProvider } from 'src/components/Toast';
import SidebarDesktop from '../components/SidebarDesktop';
import SidebarMobile from '../components/SidebarMobile';
import { Organization, Role, User } from '../types';
import DashboardHome from './DashboardHome';
import ExperimentsPage from './Experiments/ExperimentsPage';
import InternalAnalyticsPage from './InternalAnalyticsPage';
import PricingFlowPage from './PricingFlow/PricingFlow';
import SettingsPage from './SettingsPage';
import { LAST_LOCATION_LOCAL_STORAGE_KEY } from './constants';

import { datadogRum } from '@datadog/browser-rum';
import _ from 'lodash';
import MouseTooltip from 'src/components/MouseTooltip';
import { UserContextProvider } from 'src/components/UserContext';
import AnalyticsRouter from './Analytics/AnalyticsRouter';
import PricingCurvesRouter from './PricingCurves/PricingCurvesRouter';

setupDeveloperMode();

export type NavigationPage = {
  name: string;
  to: string;
  icon?: React.FC<React.HTMLAttributes<SVGSVGElement>>;
  shortName?: string;
  component: React.FC<any>;
  hidden?: boolean;
};
export type Navigation = { [key: string]: NavigationPage };

enum Tab {
  HOME = 'home',
  PRODUCTS = 'products',
  ANALYTICS = 'analytics',
  ANALYTICS_INTERNAL = 'analytics-internal',
  SETTINGS = 'settings',
  OPPORTUNITY = 'opportunity',
  PRICINGFLOW = 'pricingflow',
  PRICINGCURVES = 'pricingcurves',
  EXPERIMENTS = 'experiments',
}

const ADMIN_SIDE_BAR: Navigation = {
  [Tab.HOME]: {
    name: 'Dashboard',
    to: '/app',
    icon: HomeIcon,
    component: DashboardHome,
  },
  [Tab.ANALYTICS]: {
    name: 'Analytics',
    to: '/app/analytics',
    icon: ChartPieIcon,
    component: AnalyticsRouter,
  },
  [Tab.SETTINGS]: {
    name: 'Settings',
    to: '/app/settings',
    icon: Cog6ToothIcon,
    component: SettingsPage,
  },
  [Tab.PRICINGFLOW]: {
    name: 'Pricing calculator for Opportunity',
    to: '/app/pricingflow',
    icon: CalculatorIcon,
    component: PricingFlowPage,
  },
  [Tab.PRICINGCURVES]: {
    name: 'Pricing curves',
    to: '/app/pricingcurves',
    icon: ChartBarIcon,
    component: PricingCurvesRouter,
  },
  [Tab.EXPERIMENTS]: {
    name: 'Experiments',
    to: '/app/experiments',
    icon: BeakerIcon,
    component: ExperimentsPage,
  },
};

const USER_SIDE_BAR = _.pick(ADMIN_SIDE_BAR, [
  Tab.HOME,
  Tab.OPPORTUNITY,
  Tab.PRICINGFLOW,
]);

export function classNames(
  ...classes: (string | boolean | undefined | null)[]
) {
  return classes.filter(Boolean).join(' ');
}

function App() {
  const { session } = useStytchMemberSession();
  const stytchClient = useStytchB2BClient();
  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();
  const [user, setUser] = useState<User | null>(null);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [tab, setTab] = useState<NavigationPage>(ADMIN_SIDE_BAR[Tab.HOME]);
  const { page } = useParams();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [skipLastLocation, setSkipLastLocation] = useState(false);

  useEffect(() => {
    if (page) {
      if (ADMIN_SIDE_BAR[page]) setTab(ADMIN_SIDE_BAR[page]);
      else navigate('/app');
    } else {
      setTab(ADMIN_SIDE_BAR[Tab.HOME]);
    }
  }, [page]);

  useEffect(() => {
    if (!session) {
      if (!skipLastLocation) {
        setSkipLastLocation(false);
        const lastLocation = `${pathname}${search}${hash}`;
        localStorage.setItem(LAST_LOCATION_LOCAL_STORAGE_KEY, lastLocation);
      }
      navigate('/login');
      return;
    }

    const fetchUser = async () => {
      try {
        // This will actually get or create the User based on the Stytch member authenticated
        const response = await api.get('user');
        if (response.data) {
          setUser(response.data);
          setOrganization(response.data.organization);
          setDatadogRumUser({
            id: response.data.id,
            organizationId: response.data.organizationId,
            role: response.data.role,
            createdAt: response.data.createdAt,
          });
        } else {
          setError(
            '⚠️ Something went wrong. Please contact support@dealops.com',
          );
        }
      } catch (error) {
        setError(
          '⚠️ Something went wrong. Please contact support@dealops.com.',
        );
        datadogRum.addError(error);
      }
    };

    fetchUser();
  }, [session, navigate]);

  const sideBar = user?.role === Role.USER ? USER_SIDE_BAR : ADMIN_SIDE_BAR;

  // if user is dealops team member, add analytics internal to sidebar
  if (
    [
      // staging
      '2f06bf21-c34a-46c2-935a-a879125e36b9',
      '4df16ada-cf66-4aa4-8178-120516d248b7',
      '5cf8f8a1-f49c-41a7-a226-2f115df42f9f',
      '824ca024-cf40-4d26-bb22-8b7a75254c2f',
      // '8d02f876-8b3b-4ec8-ae52-ebc6df564bba', // seb's ids
      // 'cd7249ca-26f7-4902-86d0-e819b5f5a84b',
      // '429f2671-4876-44e5-ae5e-bf162abc189c',
      'bea2712a-8b17-4910-86ab-b2881ea2b8a6',
      'c1b5edfc-c6d0-4d4c-9dd5-ff7d62fcc7fa',
      'e9c09daa-e65c-4895-ad3e-218a1b43c740',
      'f4706461-198d-4bbe-bf63-a21ec5e0c776',

      // prod
      '43e9e1c0-378f-4a0f-b8ec-b46a7b48187f',
      '2e7e0ae4-dea3-4020-b2d2-a436d7517f81',
      'bace5ca3-ef5e-4708-b959-664797c33763',
      '4aba3af3-98b7-4d13-9087-78f163c5ab5d',
      'cace4fed-9b03-47b2-b5b2-64f6504f22ec',
      'a8b9d361-e689-456c-a9af-9f95d0ade85b',
      'e33ffd84-74f5-401b-8de1-de443904fe42',
      '694c8984-71d9-4217-a038-02543017e31d',
      '494f14b1-4649-468f-bc5a-ad268d078bfb',
      'a9ffaf07-0980-4a44-babe-6432ba470ae9',
      'b5ac5588-02e8-492c-8422-ce742ec9f7a7',
      '0939fff1-b2bd-4716-a6a0-3e10b20c6954',
      'd1baed9c-7ec5-4642-a9db-73fd17d22f6f',
      '351b4369-e473-4729-ad31-a86c2282e38d',
      // '843c8c48-28a2-447a-ad0e-30f68399aaa5', // seb's ids
      // 'bdd36e27-34c9-4664-a9ed-ff03bc072b36',
      // '2bfc8ee9-7257-47e2-99ce-ae9b87b38689',
      '004bc572-a333-4ea2-91f2-f7aeebcde770',
      'ecb88bb0-5c50-45d4-9ba1-8b55cdb1e3af',
      'b2c42513-18a0-4f7f-8052-d566e666536f',
      '7660ca51-6a76-4b25-a3aa-32083980a16f',
    ].includes(user?.id ?? '')
  ) {
    (sideBar as Navigation)[Tab.ANALYTICS_INTERNAL] = {
      name: 'Analytics (Internal)',
      to: '/app/analytics-internal',
      icon: LockClosedIcon,
      component: InternalAnalyticsPage,
    };
  }

  const logout = () => {
    setSkipLastLocation(true);
    stytchClient.session.revoke();
  };

  return (
    <UserContextProvider>
      <MouseTooltip>
        <div className="h-full">
          {error && <Alert title="Error">{error}</Alert>}
          <SidebarMobile
            setSidebarOpen={setSidebarOpen}
            sidebarOpen={sidebarOpen}
            tab={tab}
            navigation={sideBar}
            user={user}
            onLogout={logout}
          />

          <SidebarDesktop
            tab={tab}
            navigation={sideBar}
            user={user}
            onLogout={logout}
          />

          {/* TODO: This height is based on SidebarMobile's height. Use a variable instead to couple to two. */}
          <main className="h-[calc(100%-4rem)] lg:h-full lg:pl-[57px] h-screen flex flex-col">
            <ToastProvider>
              <ModalProvider>
                {session && user && organization ? (
                  <tab.component user={user} organization={organization} />
                ) : (
                  <Loading />
                )}
              </ModalProvider>
            </ToastProvider>
          </main>
        </div>
      </MouseTooltip>
    </UserContextProvider>
  );
}

export default App;
