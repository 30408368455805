import { Dialog, Transition } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Fragment, useMemo, useState } from 'react';
import Badge from 'src/components/Badge';
import { useToast } from 'src/components/Toast';
import { classNames } from 'src/dashboard/App';
import {
  CurrencyValue,
  CurrencyValueTiered,
  CurrencyValueType,
  Minimum,
  Tier,
} from '../alpaca_price_types';
import { AlpacaSupportedCurrency } from '../alpaca_types';
import { formatCurrencyValue } from '../alpaca_utils';
import {
  AlpacaQuotePriceLimit,
  QuotePriceEditable,
} from './AlpacaQuotePriceEditable';
import { VolumeEditable } from './AlpacaVolumeEditable';

export type TierConfig = {
  tierName: string;
  showMinimum: boolean;
  showCost: boolean;
  showStickerPrice: boolean;
};

const DEFAULT_TIER_CONFIG: TierConfig = {
  tierName: 'Tier',
  showMinimum: true,
  showCost: true,
  showStickerPrice: true,
};

export function AlpacaQuotePriceEditable_TieredDetails(props: {
  show: boolean;
  quotePrice: CurrencyValueTiered;
  updateQuotePrice: (quotePrice: CurrencyValueTiered) => void;
  onClose: () => void;
  stickerPrice: CurrencyValue;
  cost: CurrencyValue;
  productName: string;
  quoteCurrency: AlpacaSupportedCurrency;
  validPriceTypes: CurrencyValueType[];
  validTierMinimumTypes: Minimum['type'][];
  limit?: AlpacaQuotePriceLimit | null;
  tierConfig?: Partial<TierConfig>;
}) {
  const {
    show,
    quotePrice,
    updateQuotePrice,
    onClose,
    stickerPrice,
    cost,
    limit,
    productName,
  } = props;
  if (quotePrice.type !== 'tiered') {
    throw new Error('quotePrice must be tiered');
  }

  const tierConfig = {
    ...DEFAULT_TIER_CONFIG,
    ...props.tierConfig,
  };

  return (
    <Sidebar
      isOpen={show}
      onClose={onClose}
      title={`Edit tiered pricing for ${productName}`}
    >
      <Editor
        quotePrice={quotePrice}
        updateQuotePrice={updateQuotePrice}
        onClose={onClose}
        stickerPrice={stickerPrice}
        cost={cost}
        quoteCurrency={props.quoteCurrency}
        validPriceTypes={props.validPriceTypes}
        validTierMinimumTypes={props.validTierMinimumTypes}
        tierConfig={tierConfig}
        limit={limit}
      />
    </Sidebar>
  );
}

function Sidebar({
  isOpen,
  onClose,
  children,
  title,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
}) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-min">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-xl font-medium leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                    </div>
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function Editor(props: {
  quotePrice: CurrencyValueTiered;
  updateQuotePrice: (quotePrice: CurrencyValueTiered) => void;
  onClose: () => void;
  stickerPrice: CurrencyValue;
  cost: CurrencyValue;
  quoteCurrency: AlpacaSupportedCurrency;
  validPriceTypes: CurrencyValueType[];
  validTierMinimumTypes: Minimum['type'][];
  tierConfig: TierConfig;
  limit?: AlpacaQuotePriceLimit | null;
}) {
  const { stickerPrice, cost, limit, validTierMinimumTypes } = props;
  const { showToast } = useToast();

  const [quotePrice, setQuotePrice] = useState<CurrencyValueTiered>(
    props.quotePrice,
  );

  function onCancel() {
    props.onClose();
  }

  function validateTiers() {
    if (validTierMinimumTypes.length === 0) {
      return true;
    } else {
      for (let i = 0; i < quotePrice.tiers.length; i++) {
        if (
          i !== 0 &&
          quotePrice.tiers[i - 1].minimum.value >=
            quotePrice.tiers[i].minimum.value
        ) {
          showToast({
            title: 'The tier volumes must be increasing',
            subtitle: '',
            type: 'error',
          });
          return false;
        }
      }
    }
    return true;
  }

  function onSave() {
    if (!validateTiers()) {
      return;
    }
    props.updateQuotePrice(quotePrice);
    props.onClose();
  }

  return (
    <div className="px-6 py-4">
      <div className="rounded-xl border border-gray-200 bg-white">
        <table className="h-full">
          <Header
            minimumType={quotePrice.minimumType}
            tierConfig={props.tierConfig}
          />
          <Table
            stickerPrice={stickerPrice}
            cost={cost}
            tiers={quotePrice.tiers}
            updateTiers={(tiers) => setQuotePrice({ ...quotePrice, tiers })}
            quoteCurrency={props.quoteCurrency}
            validPriceTypes={props.validPriceTypes}
            minimumType={quotePrice.minimumType}
            updateMinimumType={(newMinType) => {
              const newQuotePrice = {
                ...quotePrice,
                minimumType: newMinType,
                tiers: quotePrice.tiers.map((tier) => {
                  const newMinium =
                    newMinType === CurrencyValueType.FLAT
                      ? {
                          type: newMinType,
                          value: tier.minimum.value,
                          currency: props.quoteCurrency,
                        }
                      : {
                          type: newMinType,
                          value: tier.minimum.value,
                        };
                  return {
                    ...tier,
                    minimum: newMinium,
                  };
                }),
              };
              setQuotePrice(newQuotePrice);
            }}
            validTierMinimumTypes={validTierMinimumTypes}
            tierConfig={props.tierConfig}
            limit={limit}
          />
        </table>
      </div>
      <Savebar onCancel={onCancel} onSave={onSave} />
    </div>
  );
}

const HEADINGS = (props: {
  minimumType: Minimum['type'];
  tierConfig: TierConfig;
}) => {
  return useMemo(() => {
    const { tierName, showMinimum, showCost, showStickerPrice } =
      props.tierConfig;

    let headings = [tierName];
    if (showMinimum) {
      headings.push(
        props.minimumType === CurrencyValueType.FLAT
          ? 'Greater than volume'
          : 'Greater than # txns',
      );
    }
    headings.push('Quote price');
    if (showStickerPrice) {
      headings.push('Sticker price');
    }
    if (showCost) {
      headings.push('Cost');
    }
    headings.push('');
    return headings;
  }, [props.minimumType, props.tierConfig]); // Dependencies array
};

type HeaderProps = {
  minimumType: Minimum['type'];
  tierConfig: TierConfig;
};
function Header(props: HeaderProps) {
  const { tierConfig, minimumType } = props;
  return (
    <thead>
      <tr>
        {HEADINGS({ minimumType, tierConfig }).map((heading, i) => (
          <th
            key={heading ?? i}
            scope="col"
            className={classNames(
              i === 0 ? 'rounded-tl-xl' : '',
              i === HEADINGS({ minimumType, tierConfig }).length - 1
                ? 'rounded-tr-xl'
                : '',
              'sticky top-0 z-10 whitespace-nowrap border-b bg-gray-50 px-6 py-3.5 text-left text-sm font-medium text-gray-700 backdrop-blur backdrop-filter',
            )}
          >
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
}

function Table(props: {
  stickerPrice: CurrencyValue;
  cost: CurrencyValue;
  tiers: Tier[];
  updateTiers: (tiers: Tier[]) => void;
  quoteCurrency: AlpacaSupportedCurrency;
  validPriceTypes: CurrencyValueType[];
  minimumType: Minimum['type'];
  updateMinimumType: (minimumType: Minimum['type']) => void;
  validTierMinimumTypes: Minimum['type'][];
  tierConfig: TierConfig;
  limit?: AlpacaQuotePriceLimit | null;
}) {
  const {
    stickerPrice,
    cost,
    tiers,
    updateTiers,
    minimumType,
    updateMinimumType,
    validTierMinimumTypes,
    limit,
    tierConfig,
  } = props;
  const { showMinimum } = tierConfig;

  function handleDelete(index: number) {
    return () => {
      updateTiers(tiers.filter((_, i) => i !== index));
    };
  }

  function updateTier(index: number) {
    return (tier: Tier) => {
      updateTiers(tiers.map((t, i) => (i === index ? tier : t)));
    };
  }

  function duplicateLastTier() {
    const lastTier = tiers[tiers.length - 1];
    updateTiers([...tiers, lastTier]);
  }

  const minimumTypeSwitchOptions = validTierMinimumTypes
    .filter((type) => type !== minimumType)
    .map((type) => {
      switch (type) {
        case 'count': {
          return {
            label: 'txn count minimums',
            type,
          };
        }
        case CurrencyValueType.FLAT: {
          return {
            label: 'volume minimums',
            type,
          };
        }
      }
    });

  return (
    <>
      <tbody>
        {tiers.map((tier, index) => {
          return (
            <TierRow
              key={index}
              tier={tier}
              index={index}
              onDelete={handleDelete(index)}
              stickerPrice={stickerPrice}
              cost={cost}
              updateTier={updateTier(index)}
              quoteCurrency={props.quoteCurrency}
              validPriceTypes={props.validPriceTypes}
              validTierMinimumTypes={validTierMinimumTypes}
              tierConfig={tierConfig}
              limit={limit}
            />
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td
            colSpan={HEADINGS({ minimumType, tierConfig }).length}
            className="bg-gray-50 px-6 py-2 rounded-b-xl "
          >
            <div className="flex justify-start">
              <button
                className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 disabled:cursor-not-allowed disabled:opacity-50"
                onClick={duplicateLastTier}
              >
                Add new tier
              </button>
              {showMinimum &&
                // @TODO(optional) switch this to a dropdown later
                minimumTypeSwitchOptions.map((option) => {
                  return (
                    <button
                      key={option.type}
                      className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 disabled:cursor-not-allowed disabled:opacity-50 ml-6"
                      onClick={() => {
                        updateMinimumType(option.type);
                      }}
                    >
                      {`Switch to ${option.label}`}
                    </button>
                  );
                })}
            </div>
          </td>
        </tr>
      </tfoot>
    </>
  );
}

function TierRow(props: {
  tier: Tier;
  index: number;
  onDelete: () => void;
  stickerPrice: CurrencyValue;
  cost: CurrencyValue;
  updateTier: (tier: Tier) => void;
  quoteCurrency: AlpacaSupportedCurrency;
  validPriceTypes: CurrencyValueType[];
  validTierMinimumTypes: Minimum['type'][];
  tierConfig: TierConfig;
  limit?: AlpacaQuotePriceLimit | null;
}) {
  const {
    tier,
    index,
    stickerPrice,
    cost,
    limit,
    updateTier,
    quoteCurrency,
    tierConfig,
  } = props;
  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 min-w-[120px]">
        {tierConfig.tierName} {index + 1}
      </td>

      {/* Greater than volume */}
      {tierConfig.showMinimum &&
        (index === 0 ? (
          <td className="overflow-show h-full w-full p-0 align-middle text-sm text-gray-900 pl-4">
            {' '}
            {formatCurrencyValue(tier.minimum)}
          </td>
        ) : (
          <td className="overflow-show h-full w-full p-0 align-top">
            <VolumeEditable
              volume={tier.minimum.value}
              updateVolume={(volume) => {
                updateTier({
                  ...tier,
                  minimum: { ...tier.minimum, value: volume },
                });
              }}
              transactionCount={undefined}
              updateTransactionCount={() => {}}
              quotePrice={stickerPrice}
              quoteCurrency={props.quoteCurrency}
              cost={cost}
              inputTypesOverride={
                tier.minimum.type === 'count' ? 'count' : 'volume'
              }
            />
          </td>
        ))}

      {/* Quote price */}
      <td className="whitespace-nowrap overflow-show h-full w-full p-0 align-top">
        <QuotePriceEditable
          quotePrice={tier.currencyValue}
          updateQuotePrice={(quotePrice) =>
            // @ts-ignore @TODO(fay) DOUBLE CHECK THIS!!!
            updateTier({ ...tier, currencyValue: quotePrice ?? stickerPrice })
          }
          // You cannot change the price type for tiered pricing
          validPriceTypes={props.validPriceTypes}
          tierable={false}
          quoteCurrency={props.quoteCurrency} // We don't actually need this value here
          stickerPrice={stickerPrice}
          cost={cost}
          limit={limit}
          productName={'we do not need this value'}
          validTierMinimumTypes={props.validTierMinimumTypes}
        />
      </td>

      {/* Sticker price */}
      {tierConfig.showStickerPrice && (
        <td className="whitespace-nowrap px-6 py-4 text-xs font-medium">
          <button
            className=""
            title="Set quote price to sticker price"
            onClick={() => {
              updateTier({
                ...tier,
                currencyValue: stickerPrice,
              });
            }}
          >
            <Badge color="green">{formatCurrencyValue(stickerPrice)}</Badge>
          </button>
        </td>
      )}

      {/* Cost */}
      {tierConfig.showCost && (
        <td className="whitespace-nowrap px-6 py-4 text-xs font-medium text-orange-700">
          <button
            className=""
            title="Set quote price to cost"
            onClick={() => {
              updateTier({
                ...tier,
                currencyValue: cost,
              });
            }}
          >
            <Badge color="orange">{formatCurrencyValue(cost)}</Badge>
          </button>
        </td>
      )}

      {/* Delete */}
      <td>
        {index === 0 ? null : (
          <button
            className="transition-opacity duration-200 ease-in-out hover:text-gray-500 px-2 py-4"
            onClick={props.onDelete}
          >
            <TrashIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        )}
      </td>
    </tr>
  );
}

function Savebar({
  onCancel,
  onSave,
}: {
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onSave: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <div className="mt-8 flex flex-row items-center justify-end gap-2">
      <button
        type="button"
        className="col-span-full justify-center rounded-lg border border-gray-200 bg-white px-5 py-2 font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
        onClick={onCancel}
      >
        Cancel
      </button>

      <button
        type="submit"
        className="col-span-full justify-center rounded-lg border border-fuchsia-900 bg-fuchsia-900 px-5 py-2 font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
        onClick={onSave}
      >
        Save
      </button>
    </div>
  );
}
