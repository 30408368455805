import { datadogRum } from '@datadog/browser-rum';
import {
  ArrowTopRightOnSquareIcon,
  CubeTransparentIcon,
} from '@heroicons/react/24/outline';
import { Organization, User } from '../../../../types';
import {
  PenguinAdditionalData,
  PenguinCountryPricingSheet,
  PenguinPricingFlow,
  PenguinPricingSheet,
  PenguinProduct,
  PricingFlowType,
} from '../../types';
import StepDetailsTopbar from '../Components/StepDetailsTopbar';

import { ProductSelectField, SelectField } from 'src/components/Fields';

import { usePricingFlowContext } from '../../PricingFlow';
import BottomBar from '../Components/BottomBar';
import OpportunityOverviewBar from '../Components/OpportunityOverviewBar';

type ProductCategories = {
  name: string;
  description: string;
  id: string;
}[];

export default function Step1ModelSelection(props: {
  user: User;
  organization: Organization;
  nextStage: () => void;
  previousStage: () => void;
  selectedProductCategories: string[];
  setSelectedProductCategories: (categories: string[]) => void;
}) {
  const {
    nextStage,
    selectedProductCategories,
    setSelectedProductCategories,
    previousStage,
    organization,
  } = props;

  const { pricingFlow, updateFlow } =
    usePricingFlowContext<PenguinPricingFlow>();

  const updateAdditionalData = (
    additionalData: PenguinPricingFlow['additionalData'],
  ) => {
    const preselectedProducts = getPreselectedProductsForModels(
      (pricingFlow.pricingSheetData as PenguinPricingSheet)
        .countryPricingSheets['us'],
      additionalData.productCategories ?? [],
    );

    // Merge the preselected products with the existing products on pricingFlow
    const products = mergeProducts(
      pricingFlow.products ?? [],
      preselectedProducts,
    );

    updateFlow(
      {
        ...pricingFlow,
        products,
        additionalData,
      },
      false,
    );
  };

  // Add preselected products but doesn't override product info in the existing pricingFlow.products
  function mergeProducts(
    existingProducts: PenguinProduct[],
    newProducts: PenguinProduct[],
  ): PenguinProduct[] {
    return [
      ...existingProducts,
      ...newProducts.filter(
        (newProduct) =>
          !existingProducts.some(
            (existingProduct) => existingProduct.id === newProduct.id,
          ),
      ),
    ];
  }

  /*
    In Penguin pricing, we have a concept of preselected products for each product category (produt model)
    When a user selects a product category, we want to preselect the products for that category
    If previously selected categories are unselected, for now we don't remove the products from the products list on pricingFlow
  */
  function getPreselectedProductsForModels(
    pricingSheet: PenguinCountryPricingSheet,
    productCategories: string[],
  ): PenguinProduct[] {
    let preselectedProducts: PenguinProduct[] = [];

    try {
      const existingPricingCategories = new Set(
        pricingFlow.additionalData?.productCategories ?? [],
      );

      const addedCategories = productCategories.filter(
        (category) => !existingPricingCategories.has(category),
      );

      /*
        Only for newly added categories, that havent' been selected before, add the preselectedProducts for each category
      */

      addedCategories.forEach((category) => {
        const subcategories =
          pricingSheet.categories[category].preselectedProducts;

        // For each subGroup, add the products to preselectedProducts
        Object.values(subcategories)
          .flat()
          .forEach((id) => {
            const { name, tiered, ProductCode, fixedVolume } =
              pricingSheet.productInfo[id];

            preselectedProducts.push({
              id: id,
              name,
              tiered,
              volume: fixedVolume ?? 0,
              rampType: 'fixed',
              ProductCode,
            });
          });
      });
    } catch (error) {
      datadogRum.addError(new Error('Error preselecting products'), {
        message: `Error preselecting products: ${error} `,
      });
      return [];
    }

    return preselectedProducts;
  }

  const pricingSheetData = pricingFlow.pricingSheetData as PenguinPricingSheet;
  const productCategories: ProductCategories = Object.keys(
    pricingSheetData.countryPricingSheets['us'].categories,
  ).map((productGroupId) => {
    return {
      name: pricingSheetData.countryPricingSheets['us'].categories[
        productGroupId
      ].name,
      description:
        pricingSheetData.countryPricingSheets['us'].categories[productGroupId]
          .description,
      id: productGroupId,
    };
  });

  return (
    <div className="relative flex h-full flex-1 flex-row overflow-hidden">
      <OpportunityOverviewBar opportunityData={pricingFlow.opportunityData} />
      <div className="grow overflow-auto px-6">
        {/* <TopStepBar user={user} pricingFlow={pricingFlow} /> */}
        <StepDetailsTopbar
          stepName="Model Selection"
          stepDescription="Select all applicable models"
          stepIcon={
            <CubeTransparentIcon className="h-6 w-6" aria-hidden="true" />
          }
        >
          {pricingFlow.type === PricingFlowType.PENGUIN && (
            <a
              className="font-medium text-fuchsia-900 hover:text-fuchsia-800"
              href={
                'https://docs.google.com/document/d/1ce4wofqeLQZLMDFzeTJVUW-qZ-Vz4OND39_-0ZlkMUE/edit'
              }
              target="_blank"
              rel="noreferrer"
            >
              Bizops guide to usecase (model) selling
              <ArrowTopRightOnSquareIcon
                className="-mt-1 ml-1 inline-block h-5 w-5"
                aria-hidden="true"
              />
            </a>
          )}
        </StepDetailsTopbar>
        <div className="w-full grow">
          <ModelSelectionForm
            productCategories={productCategories}
            additionalData={pricingFlow.additionalData as PenguinAdditionalData}
            organizationName={organization.name ?? ''}
            updateAdditionalData={updateAdditionalData}
            selectedProductCategories={selectedProductCategories}
            setSelectedProductCategories={setSelectedProductCategories}
            nextStage={nextStage}
          />
        </div>
      </div>
      <BottomBar
        primaryButtonProps={{ label: 'Next', onClick: async () => nextStage() }}
        secondaryButtonProps={{
          label: 'Back',
          onClick: async () => previousStage(),
        }}
      />
    </div>
  );
}

const ModelSelectionForm = (props: {
  productCategories: ProductCategories;
  additionalData: PenguinAdditionalData;
  organizationName: string;
  updateAdditionalData: (additionalData: PenguinAdditionalData) => void;
  selectedProductCategories: string[];
  setSelectedProductCategories: (categories: string[]) => void;
  nextStage: () => void;
}) => {
  const {
    additionalData,
    updateAdditionalData,
    organizationName,
    setSelectedProductCategories,
    selectedProductCategories,
    productCategories,
    nextStage,
  } = props;

  return (
    <div className="mt-4 grid grid-cols-1 gap-x-4 gap-y-8 pb-10">
      <div className="mt-8 hidden w-full max-w-7xl md:w-9/12">
        <h1 className="text-lg font-medium">Select country</h1>
      </div>
      <SelectField
        className="md:w-12/12 hidden w-full max-w-7xl"
        label=""
        name="country"
        value={additionalData?.country ?? 'US'}
        onChange={(country) => {
          updateAdditionalData({
            ...additionalData,
            country: country.target.value,
          });
        }}
      >
        <option value="US">US</option>
        <option value="CA">Canada</option>
      </SelectField>

      <ModelGrid
        organizationName={organizationName}
        nextStage={nextStage}
        productCategories={productCategories}
        selectedCategories={selectedProductCategories}
        setSelectedCategories={(categories) => {
          setSelectedProductCategories(categories);
          updateAdditionalData({
            ...additionalData,
            productCategories: categories,
          });
        }}
      />
    </div>
  );
};

const ModelGrid = ({
  organizationName,
  productCategories,
  selectedCategories,
  setSelectedCategories,
  nextStage,
}: {
  organizationName: string;
  productCategories: ProductCategories;
  selectedCategories: string[];
  setSelectedCategories: (categories: string[]) => void;
  nextStage: () => void;
}) => {
  if (productCategories.length === 0) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <p className="text-lg font-medium text-gray-900">
          No products available
        </p>
      </div>
    );
  }

  return (
    <div>
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
        {productCategories.map((productCategory) => {
          const isSelected =
            selectedCategories.find(
              (selectedCategory) => selectedCategory === productCategory.id,
            ) != undefined;
          return (
            <ProductSelectField
              key={productCategory.id}
              id={productCategory.id}
              className="h-full"
              name={productCategory.name}
              description={productCategory.description}
              onChange={() => {
                if (isSelected) {
                  setSelectedCategories(
                    selectedCategories.filter(
                      (selectedCategory) =>
                        selectedCategory !== productCategory.id,
                    ),
                  );
                } else {
                  setSelectedCategories(
                    selectedCategories.concat([productCategory.id]),
                  );
                }
              }}
              checked={isSelected}
            />
          );
        })}
      </div>
      <hr className="col-span-4 my-4" />
      <div className="text-md flex w-full flex-col items-center">
        <p className="text-gray-500">Unsure about customer usecase?</p>
        <button
          className="text-fuchsia-900 underline hover:text-fuchsia-700"
          onClick={nextStage}
        >
          Select {organizationName} products directly
        </button>
      </div>
    </div>
  );
};
