import { createContext, useContext, useEffect, useState } from 'react';
import api from 'src/api';
import { TrackSuggestedVolumeUsagePayload } from 'src/types';
import { usePricingFlowContext } from '../../PricingFlow';
import { PenguinPricingFlow } from '../../types';

/**
 * This context stores suggestions for volume inputs for Auth, Balance, and Identity products in the Penguin pricing flow.
 *
 * User's input is used to initialize a suggestion. The highest suggestion is kept.
 *
 * ex - if user inputs 1000 for Auth, 1000 is the suggestion for Balance, and Identity.
 * user then inputs 2000 for Balance, 2000 is the suggestion for Identity. Final values are 1000, 2000, 2000.
 *
 * We look forward to more intelligent suggestions in the future, but for now this is enough.
 *
 * This context exposes 3 pieces of data to power these suggestions in the components that need them.
 *
 * - VolumeSuggestionProducts: a list of product names that the suggestions are for
 * - VolumeSuggestion: the highest suggestion (undefined if no suggestions have been made)
 * - setVolumeSuggestion: a function to set the suggestion
 *
 * Internally, it also tracks the usage of the suggestions. When the suggestion is set, it sends an event to the analytics endpoint.
 */

interface VolumeSuggestionsContext {
  volumeSuggestionProducts: string[];
  volumeSuggestion: number | undefined;
  setVolumeSuggestion: (suggestion: number) => void;
}

const VolumeSuggestionsContext = createContext<
  VolumeSuggestionsContext | undefined
>(undefined);

export function VolumeSuggestionsContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [volumeSuggestion, _setVolumeSuggestion] = useState<number | undefined>(
    undefined,
  );

  let { pricingFlow } = usePricingFlowContext<PenguinPricingFlow>();

  const trackVolumeSuggestionUsage = async () => {
    if (volumeSuggestion !== undefined) {
      let res = await api.post('analytics/trackSuggestedVolumeUsage', {
        pricingFlowId: pricingFlow.id,
        sfdcOpportunityId: pricingFlow.sfdcOpportunityId,
        metadata: {
          volumeSuggestion: volumeSuggestion,
        },
      } satisfies TrackSuggestedVolumeUsagePayload);
      if (res.status !== 200) {
        throw new Error('Failed to track volume suggestion usage');
      }
    }
  };

  useEffect(() => {
    if (volumeSuggestion !== undefined) {
      trackVolumeSuggestionUsage();
    }
  }, [volumeSuggestion]);

  const value = {
    volumeSuggestionProducts: ['Auth', 'Balance', 'Identity'],
    volumeSuggestion,
    setVolumeSuggestion: (suggestion: number) => {
      _setVolumeSuggestion((prev) => {
        if (suggestion <= 0) {
          // noop if suggestion is 0 or invalid
          return prev;
        }
        if (prev === undefined || prev < suggestion) {
          // keep the highest suggestion
          return suggestion;
        }
        return prev;
      });
    },
  };

  return (
    <VolumeSuggestionsContext.Provider value={value}>
      {children}
    </VolumeSuggestionsContext.Provider>
  );
}

export function useVolumeSuggestionsContext() {
  const context = useContext(VolumeSuggestionsContext);
  if (!context) {
    throw new Error(
      'useMyContext must be used within a VolumeSuggestionsContextProvider',
    );
  }
  return context;
}
