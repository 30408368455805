import { Dialog } from '@headlessui/react';
import {
  CubeTransparentIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { isNil } from 'lodash';
import { useState } from 'react';
import { ProductSelectField } from 'src/components/Fields';
import { getBadgeForProduct } from './AlpacaQuoteTable_NEW';
import {
  AlpacaCategoryName,
  AlpacaProduct,
  AlpacaProductPrice,
  AlpacaProductPrices,
} from './alpaca_types';

const Tab = ({
  name,
  selected,
  onClick,
}: {
  name: string;
  selected: boolean;
  onClick: (tab: string) => void;
}) => {
  const selectedClassNames = selected
    ? `bg-gray-50 text-gray-700`
    : `text-gray-500`;

  return (
    <button
      name={name}
      className={`w-fit rounded-lg px-3 py-2 text-sm font-semibold ${selectedClassNames}`}
      onClick={(e) => {
        if (e.currentTarget.textContent) {
          onClick(e.currentTarget.textContent);
        }
      }}
    >
      {name}
    </button>
  );
};

const SearchInput = ({ onChange }: { onChange: (val: string) => void }) => {
  return (
    <div className="flex h-10 w-72 flex-row items-center rounded-lg border border-gray-300 p-2 shadow-sm focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900">
      <MagnifyingGlassIcon
        className="mr-2 h-4 w-4 text-gray-500"
        aria-hidden="true"
      />
      <input
        className="text-md -ml-3 border-none bg-transparent text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
        placeholder={'Search for a product'}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

export const ProductModalPanel = (props: {
  products: AlpacaProduct[];
  productInfos: AlpacaProductPrices;
  setProducts: (products: any[]) => void;
  closeModal: () => void;
  categoryName: AlpacaCategoryName;
  suggestedSkuFilter?: (sku: AlpacaProductPrice) => boolean;
  productPriceSort?: (a: AlpacaProductPrice, b: AlpacaProductPrice) => number;
  helpText?: string | JSX.Element;
}) => {
  const {
    productInfos,
    closeModal,
    categoryName,
    suggestedSkuFilter,
    productPriceSort,
    helpText,
  } = props;
  const [products, setProducts] = useState<AlpacaProduct[]>(props.products);
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all">
      <Dialog.Title className="flex flex-row items-center px-6">
        <div className="rounded-lg border border-gray-300 p-2 shadow-sm">
          <CubeTransparentIcon className="h-6 w-6" aria-hidden="true" />
        </div>
        <div className="ml-4">
          <p className="text-lg font-medium text-gray-900">Add Products</p>
          <p className="text-sm text-gray-600">
            Select all the products you want to add in the quote.
          </p>
        </div>
      </Dialog.Title>

      <hr className="mt-4"></hr>
      {/* Nav Bar */}
      <div className="mt-4 flex flex-col items-center justify-between px-6 sm:flex-row">
        <div className="mb-2 gap-2 sm:mb-0">{helpText}</div>
        <SearchInput onChange={setSearchQuery} />
      </div>

      {/* Grid of products */}
      <hr className="mt-2 px-6"></hr>
      <div className="h-96 overflow-scroll px-6">
        <ProductGrid
          searchQuery={searchQuery}
          productInfos={productInfos}
          products={products}
          setProducts={setProducts}
          categoryName={categoryName}
          suggestedSkuFilter={suggestedSkuFilter}
          productPriceSort={productPriceSort}
        />
      </div>

      {/* Buttons */}
      <hr className="mt-4"></hr>
      <div className="mt-6 grid grid-flow-col gap-2 border-none px-6">
        <button
          type="submit"
          className="inline-flex items-center justify-center rounded-lg border border-gray-300 px-4 py-2 text-base font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="inline-flex items-center justify-center rounded-lg bg-fuchsia-900 px-4 py-2 text-base font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
          onClick={() => {
            props.setProducts(products);
            closeModal();
          }}
        >
          Add {products && products.length > 0 ? `(${products.length})` : ''}
        </button>
      </div>
    </Dialog.Panel>
  );
};

const ProductGrid = ({
  searchQuery,
  productInfos,
  products,
  setProducts,
  categoryName,
  suggestedSkuFilter,
  productPriceSort,
}: {
  searchQuery: string;
  productInfos: AlpacaProductPrices;
  products: any[];
  setProducts: (products: any[]) => void;
  categoryName: AlpacaCategoryName;
  suggestedSkuFilter?: (sku: AlpacaProductPrice) => boolean;
  productPriceSort?: (a: AlpacaProductPrice, b: AlpacaProductPrice) => number;
}) => {
  console.log('productInfos', productInfos);
  const filteredProductInfos = Object.values(productInfos).filter(
    (productPrice: any) => {
      return (
        !searchQuery ||
        productPrice.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    },
  );
  if (!isNil(productPriceSort)) {
    filteredProductInfos.sort(productPriceSort);
  }

  let suggestedSkus: AlpacaProductPrice[] = [];

  if (suggestedSkuFilter !== undefined) {
    suggestedSkus = filteredProductInfos.filter(suggestedSkuFilter);
  }

  if (
    categoryName === 'Collections' &&
    // productInfos has already been filtered by the skuFilter. If there are no
    // results there, it's because this whole page does not have any valid
    // products.
    Object.keys(productInfos).length === 0
  ) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <p className="text-lg font-medium text-gray-900">No products found</p>
        <p className="mt-2 text-sm text-gray-600">
          Select a Global Account first.
        </p>
      </div>
    );
  }

  if (filteredProductInfos.length === 0) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <p className="text-lg font-medium text-gray-900">No products found</p>
        <p className="mt-2 text-sm text-gray-600">
          Try searching with different keywords
        </p>
      </div>
    );
  }

  return (
    <>
      {suggestedSkus.length > 0 && (
        <>
          <header className="w-full bg-slate-100 rounded mt-4 flex flex-row">
            <span className="text-gray-500 pl-6 py-1.5 text-xs font-medium tracking-wide">
              SUGGESTED
            </span>
          </header>
        </>
      )}
      <div className="mt-4 grid auto-rows-fr grid-cols-2 gap-2 sm:grid-cols-4">
        {suggestedSkus.map((productInfo: AlpacaProductPrice) => {
          const selected =
            products.find((product) => product.name === productInfo.name) !==
            undefined;
          return (
            <ProductSelectField
              className="h-full"
              key={`suggested-${productInfo.id}`}
              name={productInfo.name}
              id={`suggested-${productInfo.id}`}
              onChange={(id: string) =>
                selected
                  ? setProducts(
                      products.filter(
                        (product) =>
                          product.id !== id.replace(/^suggested-/, ''),
                      ),
                    )
                  : setProducts([
                      ...products,
                      {
                        id: id.replace(/^suggested-/, ''),
                        volume: 0,
                        name: productInfo.name,
                        categoryName,
                      },
                    ])
              }
              checked={selected}
              badge={getBadgeForProduct(productInfo)}
            />
          );
        })}
      </div>

      {suggestedSkus.length > 0 && (
        <>
          <header className="w-full bg-slate-100 rounded mt-4 flex flex-row">
            <span className="text-gray-500 pl-6 py-1.5 text-xs font-medium tracking-wide">
              ALL
            </span>
          </header>
        </>
      )}
      <div className="mt-4 grid auto-rows-fr grid-cols-2 gap-2 sm:grid-cols-4">
        {filteredProductInfos.map((productInfo: AlpacaProductPrice) => {
          const selected =
            products.find((product) => product.name === productInfo.name) !==
            undefined;
          return (
            <ProductSelectField
              className="h-full"
              key={productInfo.id}
              name={productInfo.name}
              id={productInfo.id}
              onChange={(id: string) =>
                selected
                  ? setProducts(
                      products.filter(
                        (product) =>
                          product.id !== id.replace(/^suggested-/, ''),
                      ),
                    )
                  : setProducts([
                      ...products,
                      {
                        id: id.replace(/^suggested-/, ''),
                        volume: 0,
                        name: productInfo.name,
                        categoryName,
                      },
                    ])
              }
              checked={selected}
              badge={getBadgeForProduct(productInfo)}
            />
          );
        })}
      </div>
    </>
  );
};
