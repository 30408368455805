export function Spinner(props: { height?: number; width?: number }) {
  return (
    <div>
      <svg
        className="animate-spin"
        width={props.width ?? 20}
        height={props.height ?? 20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="10"
          cy="10"
          r="9.25"
          transform="matrix(-1 0 0 1 20 0)"
          stroke="#E4E6EA"
          strokeWidth="1.5"
        />
        <path
          d="M0.272393 7.8181C0.372855 7.41625 0.780056 7.17193 1.1819 7.27239C1.58375 7.37285 1.82807 7.78006 1.72761 8.1819L0.272393 7.8181ZM1.72761 8.1819C0.821205 11.8075 2.74296 15.497 5.82515 17.358C7.35357 18.2808 9.13918 18.7327 10.962 18.5057C12.7783 18.2796 14.6849 17.3729 16.4528 15.487L17.5472 16.513C15.5651 18.6271 13.3467 19.7204 11.1474 19.9943C8.95457 20.2673 6.83393 19.7192 5.04985 18.642C1.50704 16.503 -0.821205 12.1925 0.272393 7.8181L1.72761 8.1819Z"
          fill="url(#paint0_linear_1990_1522)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1990_1522"
            x1="-1"
            y1="9.5"
            x2="16.4191"
            y2="15.7262"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#525BBE" />
            <stop offset="1" stopColor="#525BBE" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export function InlineSpinner() {
  return (
    <div
      role="status"
      className="flex w-full flex-row items-center justify-center"
    >
      <Spinner />
    </div>
  );
}

export default function Loading() {
  return (
    <div
      role="status"
      className="flex h-screen w-full flex-row items-center justify-center"
    >
      <Spinner height={32} width={32} />
    </div>
  );
}

export function FullscreenSpinner() {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-200 bg-opacity-50">
      <Spinner height={32} width={32} />
    </div>
  );
}
