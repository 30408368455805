import axios, { AxiosError } from 'axios';
import _ from 'lodash';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from 'src/api';
import { FullscreenSpinner } from 'src/components/Loading';
import { useToast } from 'src/components/Toast';
import backgroundLines from 'src/images/background-lines.svg';
import fadedCircleBg from 'src/images/faded-circle-bg.svg';
import logoDealopsTarget from 'src/images/logos/dealops-target.svg';
import { Organization, User } from '../../types';
import FlowProgressBar from './FlowProgressBar';
import Step1ProductsAndVolume from './Step1ProductsAndVolume';
import Step2Calculator from './Step2Calculator';
import {
  DealopsPricingFlow,
  PRICING_FLOW_MUTABLE_KEYS,
  PenguinPricingCurve,
  PenguinPricingFlow,
  PenguinProductPrice,
  PenguinTieredPricingInfo,
  PricingFlow,
  PricingFlowCommon,
  PricingFlowMutableFields,
  PricingFlowReadonlyFields,
  PricingFlowStage,
  PricingFlowType,
} from './types';

import { datadogRum } from '@datadog/browser-rum';
import { getOpportunityIdFromIdOrUrl } from '../utils';
import AlpacaPricingFlowPage from './Alpaca/AlpacaPricingFlowPage';
import {
  AlpacaPricingCurve,
  AlpacaPricingFlow,
  AlpacaProductPrice,
} from './Alpaca/alpaca_types';
import { addAllDerivedAggregationsToPricingFlow } from './Alpaca/alpaca_utils';
import ComplexDemoPricingFlowPage from './ComplexDemo/ComplexDemoPricingFlowPage';
import PenguinPricingFlowPage from './Penguin/PenguinPricingFlowPage';
import pricingCurveRegistry from './Penguin/pricing_curve_registry';

interface PricingFlowProps {
  user: User;
  organization: Organization;
}

/** Returns a copy of a quote with only the specified products **/
function quoteWithProducts(quote: unknown, desiredProducts: string[]) {
  if (!quote || typeof quote !== 'object' || !('products' in quote)) {
    // Missing or broken quote, don't do anything
    return quote;
  }

  return { ...quote, products: _.pick(quote.products, desiredProducts) };
}

/** Returns a flow with the unused products removed from the quotes **/
function withoutUnusedProducts(flow: PricingFlowCommon) {
  const { products, recommendedQuote, manualQuote } = flow;

  if (!products) {
    // The types say this shouldn't happen, but it does at step 1, so let's
    // leave things as they are for now
    return flow;
  }

  const remainingProductNames = products.map((p) => p.id ?? p.name);

  return {
    ...flow,
    manualQuote: quoteWithProducts(manualQuote, remainingProductNames),
    recommendedQuote: quoteWithProducts(
      recommendedQuote,
      remainingProductNames,
    ),
  };
}

const PricingFlowContext = createContext<
  | {
      pricingFlow: PricingFlowCommon;
      updateFlow: (
        pricingFlow: PricingFlowCommon,
        showLoading?: boolean,
      ) => void;
      setStage: (stage: PricingFlowCommon['stage']) => void;
      loading: boolean;
      restartInteractionTracking: () => void;
    }
  | undefined
>(undefined);

function getPricingCurveForPenguinProduct(
  productPrice: PenguinProductPrice,
): PenguinPricingCurve {
  const curves = productPrice.pricingCurves;
  if (curves?.length > 0) {
    curves.sort((pcA, pcB) => {
      return pcA.priority - pcB.priority;
    });
    // find the relevant pricing curve
    for (const curve of curves) {
      if (pricingCurveRegistry.hasOwnProperty(curve.condition)) {
        // TODO(george) when we implement a real condition, this needs to take
        // in the pricing flow as an argument
        if (pricingCurveRegistry[curve.condition]()) {
          console.log(`activating ${curve.condition}`);
          return curve;
        }
      }
    }
    return curves[curves.length - 1];
  } else {
    datadogRum.addError(
      new Error(`Did not find pricing curves for ${productPrice.ProductCode}`),
    );
  }
  // once the migration is done, clean up everything below - we should never be
  // missing a default curve
  function transformPricingData(data: {
    [key: string]: {
      recommendedPricing: number;
      level1: number;
      level2: number;
      level3: number;
      level4: number;
    };
  }): PenguinTieredPricingInfo[] {
    const result: PenguinTieredPricingInfo[] = [];

    for (const key in data) {
      const oldTierInfo = data[key];

      result.push({
        recommendedPricing: oldTierInfo.recommendedPricing,
        level1: oldTierInfo.level1,
        level2: oldTierInfo.level2,
        level3: oldTierInfo.level3,
        level4: oldTierInfo.level4,
        tier: parseInt(key, 10),
      });
    }

    return result;
  }
  return {
    // #PricingCurveBackwardsCompatibility
    id: 'backwards-compatibility',
    name: 'backwards-compatibility',
    condition: 'backwards-compatibility',
    isActive: true,
    productId: productPrice.id,
    priority: 0,
    pricingInformation: {
      ...productPrice,
      monthlyVolumeTiers: productPrice.monthlyVolumeTiers
        ? transformPricingData(productPrice.monthlyVolumeTiers)
        : [],
      monthlyMinimumTiers: productPrice.monthlyMinimumTiers
        ? transformPricingData(productPrice.monthlyMinimumTiers)
        : [],
    },
  };
}
function getPricingCurveForAlpacaProduct(
  productPrice: AlpacaProductPrice,
): AlpacaPricingCurve {
  const curves = productPrice.pricingCurves;
  if (curves?.length > 0) {
    curves.sort((pcA, pcB) => {
      return pcA.priority - pcB.priority;
    });
    // find the relevant pricing curve
    for (const curve of curves) {
      if (pricingCurveRegistry.hasOwnProperty(curve.condition)) {
        // TODO(george) when we implement a real condition, this needs to take
        // in the pricing flow as an argument
        if (pricingCurveRegistry[curve.condition]()) {
          console.log(`activating ${curve.condition}`);
          return curve;
        }
      }
    }
    return curves[curves.length - 1];
  } else {
    datadogRum.addError(
      `Did not find pricing curves for ${productPrice.name} ${productPrice.id}`,
    );
    return {} as any;
  }
}

// #CurrentPricingCurves
type CurrentPenguinPricingCurves = { [productId: string]: PenguinPricingCurve };
function getCurrentPenguinPricingCurves(pricingFlow: PenguinPricingFlow) {
  if (pricingFlow.products) {
    return pricingFlow.products.reduce((acc, product) => {
      const productPrice =
        pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo[
          product.id
        ];
      const pricingCurve = getPricingCurveForPenguinProduct(productPrice);
      acc[product.id] = pricingCurve;
      return acc;
    }, {} as CurrentPenguinPricingCurves);
  } else {
    return {};
  }
}
type CurrentAlpacaPricingCurves = { [productId: string]: AlpacaPricingCurve };
function getCurrentAlpacaPricingCurves(pricingFlow: AlpacaPricingFlow) {
  const productPrices = Object.values(
    pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo,
  );
  return productPrices.reduce((acc, productPrice) => {
    acc[productPrice.id] = getPricingCurveForAlpacaProduct(productPrice);
    return acc;
  }, {} as CurrentAlpacaPricingCurves);
}

function getCurrentPricingCurves(pricingFlow: PricingFlowCommon) {
  switch (pricingFlow.type) {
    case PricingFlowType.PENGUIN:
    case PricingFlowType.COMPLEX_DEMO:
      return getCurrentPenguinPricingCurves(pricingFlow as PenguinPricingFlow);
    case PricingFlowType.ALPACA:
      return getCurrentAlpacaPricingCurves(pricingFlow as AlpacaPricingFlow);
    default:
      return {};
  }
}

function addDerivedAggregationsToPricingFlow(pricingFlow: PricingFlowCommon) {
  switch (pricingFlow.type) {
    case PricingFlowType.DEALOPS:
    case PricingFlowType.PENGUIN:
    case PricingFlowType.COMPLEX_DEMO:
      return pricingFlow;
    case PricingFlowType.ALPACA:
      return addAllDerivedAggregationsToPricingFlow(
        pricingFlow as AlpacaPricingFlow,
      );
    default:
      datadogRum.addError(`Unexpected pricing flow type ${pricingFlow.type}`);
      return pricingFlow;
  }
}

export function usePricingFlowContext<
  T extends
    | PricingFlowCommon
    | PenguinPricingFlow
    | AlpacaPricingFlow
    | DealopsPricingFlow,
>() {
  const pricingFlowContext = useContext(PricingFlowContext);
  if (pricingFlowContext == undefined) {
    throw new Error(
      'You should not be using the PricingFlowContext outside of the provider',
    );
  }
  const { pricingFlow } = pricingFlowContext;
  return {
    ...pricingFlowContext,
    pricingFlow: pricingFlow as T,
  };
}

function usePricingFlow({ opportunityId }: { opportunityId: string }) {
  const [loading, setLoading] = useState(true);

  // This is the section of pricingFlow the user can update
  const [pricingFlowMutableFields, setPricingFlowMutableFields] =
    useState<PricingFlowMutableFields | null>(null);

  // This is the section of the pricingFlow the server calculates and isn't writable
  const [pricingFlowReadonlyFields, setPricingFlowReadonlyFields] =
    useState<PricingFlowReadonlyFields | null>(null);
  const { showToast } = useToast();

  const pricingFlow: PricingFlowCommon | null =
    pricingFlowReadonlyFields == null || pricingFlowMutableFields == null
      ? null
      : {
          ...pricingFlowMutableFields,
          ...pricingFlowReadonlyFields,
        };

  const { restartInteractionTracking } = useTrackInteractionTime({
    callback: (timeSpent: number) => {
      if (!pricingFlow?.id) return;
      for (let attempts = 0, sent = false; !sent && attempts < 5; attempts++) {
        // #AddInteractionTimeErrors
        sent = navigator.sendBeacon(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/pricingFlow/${pricingFlow.id}/addInteractionTime?interactionTime=${timeSpent}`,
        );
      }
    },
    isReadyToTrack: Boolean(pricingFlow?.id),
  });

  const setPricingFlow = (incomingPricingFlow: PricingFlowCommon) => {
    console.log('add derived aggregations to pricing flow');
    const pricingFlow =
      addDerivedAggregationsToPricingFlow(incomingPricingFlow);
    const { readonlyFields, mutableFields } = splitPricingFlow(pricingFlow);
    setPricingFlowMutableFields(() => {
      return {
        ...mutableFields,
        currentPricingCurves: getCurrentPricingCurves(pricingFlow),
      };
    });
    setPricingFlowReadonlyFields(() => readonlyFields);
  };

  const splitPricingFlow = (pricingFlow: PricingFlowCommon) => {
    const mutableFields: PricingFlowMutableFields = _.pick(
      pricingFlow,
      PRICING_FLOW_MUTABLE_KEYS,
    );

    const readonlyFields: PricingFlowReadonlyFields = _.omit(
      pricingFlow,
      PRICING_FLOW_MUTABLE_KEYS,
    );

    return {
      mutableFields,
      readonlyFields,
    };
  };

  useEffect(() => {
    console.log('opportunityId', opportunityId);
    const fetchPricingFlow = async () => {
      setLoading(true);
      try {
        const response = await api.post('pricingFlow', {
          opportunityId: opportunityId,
        });
        setPricingFlow({
          ...response.data,
          currentPricingCurves: getCurrentPricingCurves(response.data),
        });
        if (response?.status === 201) {
          console.log('Successfully created Pricing Flow: ', response.data);
        } else {
          console.log('Successfully fetched Pricing Flow: ', response.data);
        }
      } catch (getError) {
        datadogRum.addError(getError);
        if (
          axios.isAxiosError(getError) &&
          (getError as AxiosError).response?.status === 404
        ) {
          showToast({
            title: 'No Opportunity was Found',
            subtitle: 'Opportunity ID: ' + opportunityId,
            type: 'error',
            autoDismiss: false,
          });
        } else {
          console.error('unknown error on POST PricingFlow:', getError);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPricingFlow();
  }, [opportunityId]);

  async function setStage(stage: PricingFlowCommon['stage']) {
    setLoading(true);
    let pricingFlowCopy = { ...pricingFlow };
    pricingFlowCopy.pricingSheetData = {};

    const response = await api.put('pricingFlow/' + pricingFlow?.id, {
      ...pricingFlowCopy,
      stage,
    });
    setPricingFlow(response.data);
    setLoading(false);
  }

  async function updateFlow(
    pricingFlowMutableFieldsRaw: PricingFlowMutableFields,
    showLoading: boolean = true,
  ) {
    if (showLoading) {
      setLoading(true);
    }
    restartInteractionTracking();

    const pricingFlowRaw = {
      ...pricingFlowMutableFieldsRaw,
      ...pricingFlowReadonlyFields,
    } as PricingFlow;

    const pricingFlow = withoutUnusedProducts(pricingFlowRaw);
    setPricingFlow(pricingFlow);

    // when sending to server remove pricingSheetData because it's too big
    let pricingFlowCopy = { ...pricingFlow };
    pricingFlowCopy.pricingSheetData = {};

    try {
      const response = await api.put(
        'pricingFlow/' + pricingFlow?.id,
        pricingFlowCopy,
      );
      const newPricingFlow: PricingFlow = response.data;

      // we call setPricingFlow twice because in some flow types the put response adds extra
      // information we display to the user.
      const { readonlyFields, mutableFields } =
        splitPricingFlow(newPricingFlow);
      setPricingFlowReadonlyFields(readonlyFields);
    } catch (err) {
      datadogRum.addError(err);
      console.error(err);
      showToast({
        title: 'Error updating the quote',
        subtitle: 'Please contact support@dealops.com',
        type: 'error',
      });
    }
    if (showLoading) {
      setLoading(false);
    }
  }

  return {
    pricingFlow,
    updateFlow,
    setStage,
    loading,
    restartInteractionTracking,
  };
}

// hook for tracking how long long the pricingFlow has been interacted with.
function useTrackInteractionTime({
  callback,
  isReadyToTrack,
}: {
  callback: (timeSpent: number) => void;
  isReadyToTrack: boolean;
}) {
  let lastEventTimestamp = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (!isReadyToTrack) return;
    startTracking();
    window.addEventListener('load', startTracking); // page load
    window.addEventListener('focus', startTracking); // moving towards window
    window.addEventListener('blur', stopTracking); // moving away from window
    document.addEventListener('visibilitychange', handleVisibilityChange); // switching tabs
    window.addEventListener('beforeunload', stopTracking); // closing tab

    return () => {
      stopTracking();
      window.removeEventListener('load', startTracking);
      window.removeEventListener('focus', startTracking);
      window.removeEventListener('blur', stopTracking);
      window.removeEventListener('focus', stopTracking);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', stopTracking);
    };
  }, [isReadyToTrack]);

  function startTracking() {
    lastEventTimestamp.current = Date.now();
  }

  function stopTracking() {
    if (lastEventTimestamp.current !== undefined) {
      callback(Date.now() - lastEventTimestamp.current);
      lastEventTimestamp.current = undefined;
    }
  }

  function handleVisibilityChange() {
    if (document.visibilityState === 'visible') {
      startTracking();
    }

    if (document.visibilityState === 'hidden') {
      stopTracking();
    }
  }

  function restartInteractionTracking() {
    if (lastEventTimestamp.current !== undefined) {
      stopTracking();
      startTracking();
    }
  }

  return { restartInteractionTracking };
}

export default function PricingFlowPage(props: PricingFlowProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const opportunityId = searchParams.get('opportunity');

  if (!opportunityId) {
    return (
      <>
        <div
          className="relative flex flex-col items-center justify-center bg-repeat-x"
          style={{ backgroundImage: `url(${backgroundLines})` }}
        >
          {/* Dealops target logo */}
          <div className="mt-36 h-24 w-24">
            <img
              className="absolute h-24 w-24"
              src={fadedCircleBg}
              alt="faded circle"
            />
            <div className="absolute ml-5 mt-5 flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
              <img className="h-7 w-7" src={logoDealopsTarget} alt="Dealops" />
            </div>
          </div>

          <h1 className="mx-auto max-w-7xl px-4 pt-6 text-center text-2xl font-semibold sm:px-6 lg:px-8">
            Hi {props.user.name?.split(' ')[0]}, let's work on pricing!
          </h1>
          <p className="text-l mx-auto max-w-7xl px-4 pt-2 text-center text-gray-700 sm:px-6 lg:px-8">
            Enter a Salesforce opportunity ID to get started.
          </p>

          <form
            onSubmit={(e) => {
              e.preventDefault();

              const id = getOpportunityIdFromIdOrUrl(
                e.currentTarget.opportunity.value,
              );
              searchParams.set('opportunity', id);
              setSearchParams(searchParams);
            }}
            className="mx-auto mt-8 block sm:mx-auto sm:w-full sm:max-w-sm"
          >
            <div className="mb-4">
              <input
                type="text"
                name="opportunity"
                required
                placeholder="Enter Opportunity ID or URL"
                className="block w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-fuchsia-800 focus:outline-none focus:ring-fuchsia-800 sm:text-sm"
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-fuchsia-900 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-fuchsia-950"
              >
                Find Opportunity
              </button>
            </div>
          </form>
        </div>
      </>
    );
  } else {
    return (
      <PricingFlowForOpportunity
        opportunityId={opportunityId}
        user={props.user}
        organization={props.organization}
      />
    );
  }
}

function PricingFlowForOpportunity(props: {
  opportunityId: string;
  user: User;
  organization: Organization;
}) {
  const {
    pricingFlow,
    updateFlow,
    setStage,
    loading,
    restartInteractionTracking,
  } = usePricingFlow({ opportunityId: props.opportunityId });

  if (loading || pricingFlow === null) {
    return <FullscreenSpinner />;
  }

  let pricingFlowPage = null;
  switch (pricingFlow.type) {
    case PricingFlowType.ALPACA:
      pricingFlowPage = (
        <AlpacaPricingFlowPage
          organization={props.organization}
          // todo(seb): handle interactionTracking?
          user={props.user}
        />
      );
      break;
    case PricingFlowType.PENGUIN:
      pricingFlowPage = (
        <PenguinPricingFlowPage
          organization={props.organization}
          user={props.user}
        />
      );
      break;
    case PricingFlowType.COMPLEX_DEMO:
      pricingFlowPage = (
        <ComplexDemoPricingFlowPage
          organization={props.organization}
          user={props.user}
        />
      );
      break;
    case PricingFlowType.DEALOPS:
      // These need to be migrated to their own PricingFlowPage components
      pricingFlowPage = (
        <>
          <FlowProgressBar stage={pricingFlow?.stage} setStage={setStage} />
          {pricingFlow?.stage === PricingFlowStage.ADD_PRODUCTS ? (
            <Step1ProductsAndVolume
              pricingFlow={pricingFlow}
              updateFlow={updateFlow}
              setStage={setStage}
              user={props.user}
            />
          ) : null}

          {pricingFlow?.stage === PricingFlowStage.CALCULATE_PRICE ? (
            <Step2Calculator
              pricingFlow={pricingFlow}
              updateFlow={updateFlow}
              setStage={setStage}
            />
          ) : null}
        </>
      );
      break;
    default:
      pricingFlowPage = (
        <div>Unknown pricing flow type: {pricingFlow.type}</div>
      );
  }

  return (
    <PricingFlowContext.Provider
      value={{
        pricingFlow,
        updateFlow,
        setStage,
        loading,
        restartInteractionTracking,
      }}
    >
      {pricingFlowPage}
    </PricingFlowContext.Provider>
  );
}
