import { SelectField, SelectFieldWithLabel } from 'src/components/Fields';
import { classNames } from 'src/dashboard/App';

export default function SelectInput(props: {
  name: string;
  value: any;
  children: React.ReactElement | React.ReactElement[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
  selectClassName?: string;
}) {
  const { name, value, onChange, children, className, selectClassName } = props;
  return (
    <div className="h-full w-full bg-white p-0 align-top align-middle transition-all duration-200 focus-within:bg-transparent hover:bg-fuchsia-50">
      <SelectField
        label={undefined}
        name={name}
        value={value}
        onChange={onChange}
        selectClassName={classNames(
          selectClassName ?? '',
          'text-sm cursor-pointer bg-transparent w-full h-full m-0 px-4 py-3 focus:ring-fuchsia-200 focus:border-fuchsia-800 ring-transparent border-transparent border-2 ring-2 rounded-md transition-all duration-200',
        )}
        className={classNames(className ?? '', 'h-full w-full')}
      >
        {children}
      </SelectField>
    </div>
  );
}

export function SelectInputWithLabel(props: {
  name: string;
  label: string;
  value: any;
  children: React.ReactElement | React.ReactElement[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
  selectClassName?: string;
}) {
  const { name, label, value, onChange, children, className, selectClassName } =
    props;
  return (
    <SelectFieldWithLabel
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      selectClassName={classNames(
        selectClassName ?? '',
        'text-sm cursor-pointer bg-transparent w-full h-full m-0 px-4 py-3 focus:ring-fuchsia-200 focus:border-fuchsia-800 ring-transparent border-transparent border-2 ring-2 rounded-md transition-all duration-200',
      )}
      className={classNames(className ?? '', 'h-full w-full')}
    >
      {children}
    </SelectFieldWithLabel>
  );
}
