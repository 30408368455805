import { useEffect } from 'react';
import { User } from '../../../../types';

import BottomBar from '../../Penguin/Components/BottomBar';
import { usePricingFlowContext } from '../../PricingFlow';
import AlpacaQuoteTable_NEW from '../AlpacaQuoteTable_NEW';
import AlpacaHeader from '../Components/AlpacaHeader';
import AlpacaHelpButton from '../Components/AlpacaHelpButton';
import AlpacaOpportunityInfoButton from '../Components/AlpacaOpportunityInfoButton';
import {
  AlpacaPayoutProduct,
  AlpacaPayoutProductPrice,
  AlpacaPricingFlow,
  AlpacaProductPrice,
} from '../alpaca_types';
import { convertCurrencyValueForex } from '../alpaca_utils';

function initializePayouts(
  pricingFlow: AlpacaPricingFlow,
  updateFlow: (flow: AlpacaPricingFlow, showLoading?: boolean) => void,
) {
  const suggestedPayoutsProductPrices = Object.values(
    pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo,
  ).filter(
    (pp): pp is AlpacaPayoutProductPrice =>
      pp.skuGroup === 'Payouts' && pp.isSuggested === true,
  );
  const newProducts: AlpacaPayoutProduct[] = suggestedPayoutsProductPrices.map(
    (pp) => {
      const pricingCurve = pricingFlow.currentPricingCurves[pp.id];
      return {
        id: pp.id,
        name: pp.name,
        categoryName: 'Payouts',
        volume: 0,
        quotePrice: pricingCurve.pricingInformation.listPrice
          ? convertCurrencyValueForex(
              pricingCurve.pricingInformation.listPrice,
              pricingFlow.additionalData.quoteCurrency,
              pricingFlow,
            )
          : null,
      };
    },
  );
  updateFlow(
    {
      ...pricingFlow,
      products: [...(pricingFlow.products ?? []), ...newProducts],
    },
    false,
  );
}

export default function Payouts(props: {
  user: User;
  nextStep: () => void;
  previousStep: () => void;
}) {
  const { nextStep, previousStep } = props;

  return (
    <>
      <AlpacaHeader
        title="Payouts"
        rightChildren={[
          <AlpacaOpportunityInfoButton />,
          <AlpacaHelpButton url="https://airwallex.atlassian.net/wiki/spaces/SBAH/pages/2580710329/Sales+Play+Transfers" />,
        ]}
      />
      <Calculator />
      <BottomBar
        primaryButtonProps={{
          label: 'Next',
          onClick: async () => nextStep(),
        }}
        secondaryButtonProps={{
          label: 'Back',
          onClick: async () => previousStep(),
        }}
      />
    </>
  );
}

function Calculator() {
  const { updateFlow, pricingFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();

  useEffect(() => {
    const payoutsProducts = (pricingFlow.products ?? []).filter(
      (p) => p.categoryName === 'Payouts',
    );
    if (payoutsProducts.length === 0) {
      initializePayouts(pricingFlow, updateFlow);
    }
  }, []);

  return (
    <div id="step_2" className="">
      <div className="px-8 py-4 flex flex-col gap-2 mb-28">
        <AlpacaQuoteTable_NEW
          pricingFlow={pricingFlow as AlpacaPricingFlow}
          skuFilter={(sku: AlpacaProductPrice) => {
            return (
              sku &&
              sku.skuGroup === 'Payouts' &&
              sku.skuSubgroup === 'International Local Payout (USD/USD)'
            );
          }}
          suggestedSkuFilter={(sku: AlpacaProductPrice) => {
            return (
              sku && sku.skuGroup === 'Payouts' && sku.isSuggested === true
            );
          }}
          updateFlow={(pricingFlow) => updateFlow(pricingFlow, false)}
          title={'Local Payouts'}
          categoryName="Payouts"
        />
        <AlpacaQuoteTable_NEW
          pricingFlow={pricingFlow as AlpacaPricingFlow}
          skuFilter={(sku: AlpacaProductPrice) => {
            return (
              sku &&
              sku.skuGroup === 'Payouts' &&
              sku.skuSubgroup === 'International SWIFT (USD/USD)'
            );
          }}
          suggestedSkuFilter={(sku: AlpacaProductPrice) => {
            return (
              sku && sku.skuGroup === 'Payouts' && sku.isSuggested === true
            );
          }}
          updateFlow={(pricingFlow) => updateFlow(pricingFlow, false)}
          title={'SWIFT Payouts'}
          categoryName="Payouts"
        />
      </div>
    </div>
  );
}
