import { Menu, Transition } from '@headlessui/react';

import { datadogRum } from '@datadog/browser-rum';
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  ChevronDownIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { produce, setAutoFreeze } from 'immer';
import { isEqual, isNil, pick } from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedNumberField } from 'src/components/Fields';
import { useToast } from 'src/components/Toast';
import Toggle from 'src/components/Toggle';
import Tooltip from 'src/components/Tooltip';
import Waterfall, {
  Datum,
  GrossProfitAndTakeRateTooltip,
} from 'src/components/graphs/WaterfallChart';
import { classNames } from 'src/dashboard/App';
import list from 'src/list';
import { User } from 'src/types';
import BottomBar from '../Penguin/Components/BottomBar';
import { usePricingFlowContext } from '../PricingFlow';
import AlpacaAdvancedSettings from './Components/AlpacaAdvancedSettings';
import AlpacaHeader from './Components/AlpacaHeader';
import AlpacaHelpButton from './Components/AlpacaHelpButton';
import AlpacaIssuingTable, {
  entityHasIntraregionalConcept,
  getExistingIssuingProducts,
  getListPriceOrCost,
  getTxnCountForGeography,
  getVolumeForGeography,
  zeroForIssuingSubCategory,
} from './Components/AlpacaIssuingTable';
import AlpacaOpportunityInfoButton from './Components/AlpacaOpportunityInfoButton';
import DerivedCurrencyValue from './Components/DerivedCurrencyValue';
import DragBarWithPercentInput from './Components/Issuing/AlpacaDragBarWithPercentInput/AlpacaDragBarWithPercentInput';
import AlpacaRegionalBreakdown from './Components/Issuing/AlpacaRegionalBreakdown';
import { RegionalData, TEMPLATE_SELECTIONS } from './IssuingData';
import {
  Count,
  CurrencyValueFlat,
  CurrencyValuePercent,
  CurrencyValueType,
  DerivedValue,
} from './alpaca_price_types';
import {
  ALPACA_CURRENCY_SYMBOLS,
  AlpacaIssuingConfig,
  AlpacaIssuingPricingInformation,
  AlpacaIssuingProduct,
  AlpacaIssuingProductPrice,
  AlpacaIssuingRowSelector,
  AlpacaPricingFlow,
  AlpacaProduct,
  DEFAULT_ISSUING_ENTITY_CONFIG,
  ISSUING_DIGITAL_WALLETS,
  ISSUING_GEOGRAPHIES,
  ISSUING_SUBCATEGORIES,
  ISSUING_SUBCATEGORIES_WITH_GEOGRAPHY,
  IssuingBinType,
  IssuingCountryCode,
  IssuingDigitalWallet,
  IssuingEntity,
  IssuingGeography,
  countryToCurrency,
  issuingCountryCodeToCountry,
  issuingCountryCodeToEmoji,
} from './alpaca_types';
import {
  formatCurrencyValue,
  getSumCount,
  getSumCurrencyValue,
  nanToZero,
} from './alpaca_utils';

function getProductInfoFromSelectors({
  geography,
  binType,
  digitalWallet,
  country,
  subGroup,
  pricingFlow,
}: {
  geography: IssuingGeography | null;
  binType: IssuingBinType | null;
  digitalWallet: IssuingDigitalWallet | null;
  country: IssuingCountryCode;
  subGroup: AlpacaIssuingPricingInformation['skuSubgroup'];
  pricingFlow: AlpacaPricingFlow;
}): AlpacaIssuingProductPrice {
  const matchingProductPrices = Object.values(
    pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo,
  ).filter((productPrice) => {
    return (
      productPrice.skuGroup === 'Issuing' &&
      productPrice.skuSubgroup === subGroup &&
      productPrice.country === country &&
      (isNil(productPrice.geography) || productPrice.geography === geography) &&
      (isNil(productPrice.binType) || productPrice.binType === binType) &&
      (isNil(productPrice.digitalWallet) ||
        isNil(digitalWallet) ||
        productPrice.digitalWallet === digitalWallet)
    );
  });
  if (matchingProductPrices.length !== 1) {
    datadogRum.addError(
      `Expected only one product to match Issuing selectors ${matchingProductPrices} ${geography} ${binType} ${digitalWallet} ${country} ${subGroup}`,
    );
  }
  return matchingProductPrices[0] as AlpacaIssuingProductPrice;
}

const geographyToName = (geography: IssuingGeography) => {
  switch (geography) {
    case 'domestic':
      return 'Domestic';
    case 'international':
      return 'International';
    case 'intraRegional':
      return 'Intra-regional';
    default:
      const typecheck: never = geography;
      datadogRum.addError(`unexpected geography ${typecheck}`);
      return '';
  }
};

function newIssuingProductsFromSelector(
  selector: AlpacaIssuingRowSelector,
  pricingFlow: AlpacaPricingFlow,
  productOverrides?: Partial<AlpacaIssuingProduct>,
): AlpacaIssuingProduct[] {
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  const currency = currentEntity.issuingEntityCurrency;
  if (
    selector.geography === 'intraRegional' &&
    !entityHasIntraregionalConcept(currentEntity)
  ) {
    return [];
  }

  const baseProduct = {
    country: currentEntity.name,
    binType: currentEntity.binType,
    geography: selector.geography,
    categoryName: 'Issuing' as const,
    volume: 0,
  };
  switch (selector.subCategory) {
    // Unpriced revenue
    case 'transactionFees': {
      return [
        {
          ...baseProduct,
          // #UniqueAlpacaIssuingProductIds
          id: `${selector.geography}_${currentEntity.binType}_${currentEntity.name}_${selector.subCategory}`,
          subCategory: selector.subCategory,
          name: geographyToName(selector.geography),
          isCost: false,
          quotePrice: zeroForIssuingSubCategory(selector.subCategory, currency),
          hasPricingInfo: false,
          isVolumeEditable: false,
          digitalWallet: null,
          ...(productOverrides ?? ({} as any)),
        },
      ];
    }
    // Priced revenue
    case 'interchangeRevenue':
    case 'perfIncentives': {
      const productInfo = getProductInfoFromSelectors({
        ...selector,
        subGroup: selector.subCategory,
        binType: currentEntity.binType,
        country: currentEntity.name,
        digitalWallet: null,
        pricingFlow,
      });
      const pricingInfo = pricingFlow.currentPricingCurves[productInfo.id]
        .pricingInformation as AlpacaIssuingPricingInformation;
      return [
        {
          ...baseProduct,
          id: `${productInfo.id}_${selector.geography}_${currentEntity.binType}_${currentEntity.name}_${selector.subCategory}`,
          name: productInfo.name,
          subCategory: selector.subCategory,
          isCost: false,
          quotePrice: getListPriceOrCost(
            pricingInfo,
            productInfo.geography,
            currentEntity,
            pricingFlow,
          ),
          hasPricingInfo: true,
          pricingInformation: pricingInfo,
          isVolumeEditable: false,
          digitalWallet: null,
          ...(productOverrides ?? ({} as any)),
        },
      ];
    }
    // Unpriced cost
    case 'rebates':
    case 'interchangeRevenueShare': {
      return [
        {
          ...baseProduct,
          // #UniqueAlpacaIssuingProductIds
          id: `${selector.geography}_${currentEntity.binType}_${currentEntity.name}_${selector.subCategory}`,
          subCategory: selector.subCategory,
          name: geographyToName(selector.geography),
          isCost: true,
          quoteCost: zeroForIssuingSubCategory(selector.subCategory, currency),
          quotePrice: null,
          hasPricingInfo: false,
          isVolumeEditable: false,
          digitalWallet: null,
          ...(productOverrides ?? ({} as any)),
        },
      ];
    }
    // Priced cost
    case 'chargeback':
    case 'cardMaintenanceCost':
    case 'cardCreationCost': {
      const productInfo = getProductInfoFromSelectors({
        ...selector,
        subGroup: selector.subCategory,
        binType: currentEntity.binType,
        country: currentEntity.name,
        digitalWallet: null,
        pricingFlow,
      });
      const pricingInfo = pricingFlow.currentPricingCurves[productInfo.id]
        .pricingInformation as AlpacaIssuingPricingInformation;
      return [
        {
          ...baseProduct,
          id: `${productInfo.id}_${selector.geography}_${currentEntity.binType}_${currentEntity.name}_${selector.subCategory}`,
          name: productInfo.name,
          subCategory: selector.subCategory,
          isCost: true,
          quotePrice: null,
          quoteCost:
            getListPriceOrCost(
              pricingInfo,
              productInfo.geography,
              currentEntity,
              pricingFlow,
            ) ?? zeroForIssuingSubCategory(selector.subCategory, currency),
          hasPricingInfo: true,
          pricingInformation: pricingInfo,
          isVolumeEditable: selector.subCategory === 'chargeback',
          digitalWallet: null,
          ...(productOverrides ?? ({} as any)),
        },
      ];
    }
    case 'transactionCost': {
      const subCategory = selector.subCategory;
      return ISSUING_DIGITAL_WALLETS.map((wallet) => {
        const productInfo = getProductInfoFromSelectors({
          ...selector,
          subGroup: subCategory,
          binType: currentEntity.binType,
          country: currentEntity.name,
          digitalWallet: wallet,
          pricingFlow,
        });
        const pricingInfo = pricingFlow.currentPricingCurves[productInfo.id]
          .pricingInformation as AlpacaIssuingPricingInformation;
        return {
          ...baseProduct,
          id: `${productInfo.id}_${selector.geography}_${currentEntity.binType}_${currentEntity.name}_${selector.subCategory}`,
          name: productInfo.name,
          subCategory: subCategory,
          isCost: true,
          quotePrice: null,
          quoteCost:
            getListPriceOrCost(
              pricingInfo,
              productInfo.geography,
              currentEntity,
              pricingFlow,
            ) ?? zeroForIssuingSubCategory(subCategory, currency),
          hasPricingInfo: true,
          pricingInformation: pricingInfo,
          isVolumeEditable: false,
          digitalWallet: wallet,
          ...(productOverrides ?? ({} as any)),
        };
      });
    }
    default:
      const typecheck: never = selector;
      datadogRum.addError(`unexpected subcategory ${typecheck}`);
      return typecheck;
  }
}

function getProductGroupsToCreateFromSelectors(
  selectors: AlpacaIssuingRowSelector[],
  pricingFlow: AlpacaPricingFlow,
) {
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  let newProductsToCreate: AlpacaIssuingProduct[] = [];
  selectors.forEach((selector) => {
    const existingProducts = getExistingIssuingProducts({
      ...selector,
      binType: currentEntity.binType,
      country: currentEntity.name,
      pricingFlow,
    });
    if (existingProducts.length === 0) {
      const newProducts = newIssuingProductsFromSelector(selector, pricingFlow);
      newProductsToCreate.push(...newProducts);
    }
  });
  return newProductsToCreate;
}
function objectsAreEqual(
  obj1: Record<string, any>,
  obj2: Record<string, any>,
  keys: string[],
) {
  for (let key of keys) {
    if (!isEqual(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
}
function productsAreEqual(
  products1: AlpacaProduct[],
  products2: AlpacaProduct[],
) {
  const issuingProducts1 = products1.filter(
    (p): p is AlpacaIssuingProduct => p.categoryName === 'Issuing',
  );
  const issuingProducts2 = products2.filter(
    (p): p is AlpacaIssuingProduct => p.categoryName === 'Issuing',
  );
  for (const product1 of issuingProducts1) {
    const product2 = issuingProducts2.find((p2) => product1.id === p2.id);
    if (isNil(product2)) {
      return false;
    }
    if (!objectsAreEqual(product1, product2, ['quoteCost', 'quotePrice'])) {
      return false;
    }
  }
  for (const product2 of issuingProducts1) {
    const product1 = issuingProducts2.find((p2) => product2.id === p2.id);
    if (isNil(product1)) {
      return false;
    }
  }
  return true;
}

const updateIssuingConfig = ({
  newConfigOverrides,
  oldPricingFlow,
  updateFlow,
  showLoading,
}: {
  newConfigOverrides: Partial<AlpacaIssuingConfig>;
  oldPricingFlow: AlpacaPricingFlow;
  updateFlow: (pricingFlow: AlpacaPricingFlow, showLoading: boolean) => void;
  showLoading: boolean;
}) => {
  updateFlow(
    {
      ...oldPricingFlow,
      additionalData: {
        ...oldPricingFlow.additionalData,
        issuingConfig: {
          ...oldPricingFlow.additionalData.issuingConfig,
          ...newConfigOverrides,
        },
      },
    },
    showLoading,
  );
};
export const updateEntity = ({
  newEntityOverrides,
  entityName,
  oldPricingFlow,
  updateFlow,
  showLoading,
}: {
  newEntityOverrides: Partial<IssuingEntity>;
  entityName: IssuingCountryCode;
  oldPricingFlow: AlpacaPricingFlow;
  updateFlow: (pricingFlow: AlpacaPricingFlow, showLoading: boolean) => void;
  showLoading: boolean;
}) => {
  const existingEntity =
    oldPricingFlow.additionalData.issuingConfig.entities.find(
      (e) => e.name === entityName,
    );

  if (!isNil(existingEntity)) {
    const updatedEntity = {
      ...existingEntity,
      ...newEntityOverrides,
    };

    const updatedEntities =
      oldPricingFlow.additionalData.issuingConfig.entities.map((entity) =>
        entity.name === entityName ? updatedEntity : entity,
      );

    const updatedPricingFlow = {
      ...oldPricingFlow,
      additionalData: {
        ...oldPricingFlow.additionalData,
        issuingConfig: {
          ...oldPricingFlow.additionalData.issuingConfig,
          entities: updatedEntities,
        },
      },
    };

    updateFlow(updatedPricingFlow, showLoading);
  } else {
    throw new Error('trying to update nonexistant entity');
  }
};

export function getAllDefaultIssuingProductsNotYetExistingForCurrentEntity(
  pricingFlow: AlpacaPricingFlow,
  currentEntity: IssuingEntity,
) {
  // ##CreateAllIssuingProducts
  const allSelectors: AlpacaIssuingRowSelector[] =
    ISSUING_SUBCATEGORIES.flatMap((subCategory) => {
      if (
        subCategory === 'perfIncentives' &&
        currentEntity.binType !== IssuingBinType.CommercialOTA
      ) {
        return [];
      }
      if (ISSUING_SUBCATEGORIES_WITH_GEOGRAPHY.includes(subCategory as any)) {
        return [...ISSUING_GEOGRAPHIES].map((geography) => {
          return {
            subCategory,
            geography,
          } as AlpacaIssuingRowSelector;
        });
      } else {
        return [
          {
            subCategory,
            geography: null,
          } as AlpacaIssuingRowSelector,
        ];
      }
    });
  const newProductsToCreate = getProductGroupsToCreateFromSelectors(
    allSelectors,
    pricingFlow,
  );
  return newProductsToCreate;
}

export function getCurrentIssuingEntity(
  pricingFlow: AlpacaPricingFlow,
): IssuingEntity {
  const issuingConfig = pricingFlow.additionalData.issuingConfig;
  const curEntity = issuingConfig.entities.find(
    (e) => e.name === issuingConfig.currentlyViewingEntity,
  );
  if (!isNil(curEntity)) {
    return curEntity;
  } else {
    if (issuingConfig.entities.length > 0) {
      return issuingConfig.entities[0];
    }
  }
  console.log(issuingConfig);
  throw new Error('No issuing entity found');
}

interface IssuingProps {
  nextStep: () => void;
  previousStep: () => void;
  user: User;
}

export default function Issuing({
  nextStep,
  previousStep,
  user,
}: IssuingProps) {
  const [showAdvancedSettings, setShowAdvancedSettings] =
    useState<boolean>(false);
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const currentEntity = getCurrentIssuingEntity(pricingFlow);

  useEffect(() => {
    const newProductsToCreate =
      getAllDefaultIssuingProductsNotYetExistingForCurrentEntity(
        pricingFlow,
        currentEntity,
      );
    if (newProductsToCreate.length > 0) {
      updateFlow(
        {
          ...pricingFlow,
          products: [...(pricingFlow.products ?? []), ...newProductsToCreate],
        },
        false,
      );
    }
  }, [currentEntity.name, currentEntity.binType]);

  return (
    <>
      <IssuingAdvancedSettings
        showAdvancedSettings={showAdvancedSettings}
        setShowAdvancedSettings={setShowAdvancedSettings}
      />
      <IssuingHeader setShowAdvancedSettings={setShowAdvancedSettings} />
      <CountrySelector />
      <div className="px-8 py-4">
        <div className="pt-2">
          <CardTransactions />
          <TemplateSelector />
          <RevenueSection />
          <Costs />
          <RebatesAndInterchangeRevenueShare />
          <SummaryTable />
        </div>
      </div>
      <div className="pt-28 h-20" />
      <BottomBar
        primaryButtonProps={{
          label: 'Next',
          onClick: async () => nextStep(),
        }}
        secondaryButtonProps={{
          label: 'Back',
          onClick: async () => previousStep(),
        }}
      />
    </>
  );
}

type IssuingAdvancedSettingsProps = {
  showAdvancedSettings: boolean;
  setShowAdvancedSettings: (newShow: boolean) => void;
};
const IssuingAdvancedSettings = (props: IssuingAdvancedSettingsProps) => {
  const { showToast } = useToast();
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const { showAdvancedSettings, setShowAdvancedSettings } = props;
  const issuingConfig = pricingFlow.additionalData.issuingConfig;

  const [
    isCustomInterchangeRevenueEnabled,
    setIsCustomInterchangeRevenueEnabled,
  ] = useState<boolean>(issuingConfig.isCustomInterchangeRevenueEnabled);
  const [isShowLocalCurrencySelected, setIsShowLocalCurrencySelected] =
    useState<boolean>(issuingConfig.isShowLocalCurrencySelected);
  const [isMultiCountryIssuingEnabled, setIsMultiCountryIssuingEnabled] =
    useState<boolean>(issuingConfig.isMultiCountryIssuingEnabled);
  const [selectedEntities, setSelectedEntities] = useState<
    IssuingCountryCode[]
  >(issuingConfig.selectedEntities);
  const [
    shouldCopySettingsForNewEntities,
    setShouldCopySettingsForNewEntities,
  ] = useState<boolean>(true);

  const existingCountryCodes = issuingConfig.entities.map((e) => e.name);
  const willAddNewEntities = selectedEntities.some((newEntity) => {
    return !existingCountryCodes.includes(newEntity);
  });
  const currentEntity = getCurrentIssuingEntity(pricingFlow);

  return (
    <AlpacaAdvancedSettings
      open={showAdvancedSettings}
      setOpen={setShowAdvancedSettings}
    >
      <>
        <div className="flex items-center justify-between pt-8 pb-2">
          <label className="text-sm font-medium text-gray-700 mr-4">
            Enable custom interchange revenue
          </label>
          <Toggle
            enabled={isCustomInterchangeRevenueEnabled}
            onToggle={(newVal: boolean) => {
              setIsCustomInterchangeRevenueEnabled(() => newVal);
            }}
          />
        </div>
        <div className="flex items-center justify-between py-2">
          <label className="text-sm font-medium text-gray-700 mr-4">
            Use local currency
          </label>
          <Toggle
            enabled={isShowLocalCurrencySelected}
            onToggle={(newVal: boolean) => {
              setIsShowLocalCurrencySelected(newVal);
            }}
          />
        </div>
        {issuingConfig.isShowLocalCurrencySelected !==
          isShowLocalCurrencySelected && (
          <div className="text-sm text-gray-700 bg-slate-50 rounded-lg p-2">
            <span className="text-gray-500 font-medium">
              <InformationCircleIcon className="size-4 inline mr-2 mb-1" />
              Changing this setting will convert all existing currency values to
              the{' '}
              {isShowLocalCurrencySelected
                ? 'local currency of each country'
                : `quote currency (${pricingFlow.additionalData.quoteCurrency})`}{' '}
              using current currency conversion rates.
            </span>
          </div>
        )}
        <div className="flex items-center justify-between py-2">
          <label className="text-sm font-medium text-gray-700 mr-4">
            Enable multi-country issuing
          </label>
          <Toggle
            enabled={isMultiCountryIssuingEnabled}
            onToggle={(newVal: boolean) => {
              setIsMultiCountryIssuingEnabled(newVal);
            }}
          />
        </div>
        <hr className="mt-2 mb-4" />
        {isMultiCountryIssuingEnabled && (
          <>
            <div className="flex items-center justify-between pb-2">
              <label className="text-sm font-medium text-gray-700 mr-4 mb-2">
                Select countries
              </label>
            </div>
            {/* Countries */}
            <div className="flex flex-col gap-y-4">
              {Object.values(IssuingCountryCode).map((countryCode) => (
                <div
                  className="flex items-center justify-between"
                  key={countryCode}
                >
                  <span>
                    {issuingCountryCodeToEmoji[countryCode]}{' '}
                    {issuingCountryCodeToCountry[countryCode]}
                  </span>
                  <Toggle
                    enabled={selectedEntities.includes(countryCode)}
                    onToggle={(checked) => {
                      if (checked) {
                        if (!selectedEntities.includes(countryCode)) {
                          setSelectedEntities([
                            ...selectedEntities,
                            countryCode,
                          ]);
                        }
                      } else {
                        if (selectedEntities.length === 1) {
                          showToast({
                            title: 'Please keep one country selected.',
                            subtitle: '',
                            type: 'error',
                          });
                          return;
                        }
                        setSelectedEntities(
                          selectedEntities.filter((e) => e !== countryCode),
                        );
                      }
                    }}
                  />
                </div>
              ))}
            </div>
            {/* Copy settings from current */}
            {willAddNewEntities && (
              <>
                <hr className="mt-4 mb-2" />
                <div className="flex items-center justify-between py-2">
                  <label className="text-sm font-medium text-gray-700 mr-4">
                    Copy settings for new entities from current entity
                  </label>
                  <Toggle
                    enabled={shouldCopySettingsForNewEntities}
                    onToggle={(newVal: boolean) => {
                      setShouldCopySettingsForNewEntities(newVal);
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}
        {/* save bar */}
        <div className="mt-8 flex flex-row items-center justify-end gap-2">
          <button
            type="button"
            className="col-span-full justify-center rounded-lg border border-gray-200 bg-white px-5 py-2 font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
            onClick={() => setShowAdvancedSettings(false)}
          >
            Cancel
          </button>

          <button
            type="submit"
            className="col-span-full justify-center rounded-lg border border-fuchsia-900 bg-fuchsia-900 px-5 py-2 font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
            onClick={() => {
              const maybeNewEntities = selectedEntities.flatMap(
                (countryCode) => {
                  if (!existingCountryCodes.includes(countryCode)) {
                    const newEntityCurrency = isShowLocalCurrencySelected
                      ? countryToCurrency[countryCode]
                      : pricingFlow.additionalData.quoteCurrency;
                    if (shouldCopySettingsForNewEntities) {
                      setAutoFreeze(false);
                      const newEntity = produce(
                        {
                          ...DEFAULT_ISSUING_ENTITY_CONFIG(
                            countryCode,
                            newEntityCurrency,
                          ),
                          ...pick(currentEntity, [
                            'binType',
                            'monthlySpendAtScale',
                            'avgTransactionSize',
                            'monthlyNumberOfCardsCreated',
                            'monthlyTransactionCount',
                            'addedToDigitalWalletPercentage',
                            'walletLoadedBreakdown',
                            'regionalBreakdown',
                            'showRebates',
                            'showInterchangeRevenueShare',
                            'showTransactionFees',
                          ]),
                        },
                        (draftNewEntity) => {
                          if (!entityHasIntraregionalConcept(draftNewEntity)) {
                            draftNewEntity.regionalBreakdown.domestic =
                              currentEntity.regionalBreakdown.domestic;
                            draftNewEntity.regionalBreakdown.intraRegional = {
                              type: CurrencyValueType.PERCENT,
                              value: 0,
                            };
                            draftNewEntity.regionalBreakdown.international = {
                              type: CurrencyValueType.PERCENT,
                              value:
                                currentEntity.regionalBreakdown.intraRegional
                                  .value +
                                currentEntity.regionalBreakdown.international
                                  .value,
                            };
                          }
                        },
                      );
                      return [newEntity];
                    } else {
                      return [
                        DEFAULT_ISSUING_ENTITY_CONFIG(
                          countryCode,
                          newEntityCurrency,
                        ),
                      ];
                    }
                  } else {
                    return [];
                  }
                },
              );
              console.log(maybeNewEntities);
              const updatedEntities = [
                ...issuingConfig.entities.map((e) => {
                  return {
                    ...e,
                    issuingEntityCurrency: isShowLocalCurrencySelected
                      ? countryToCurrency[e.name]
                      : pricingFlow.additionalData.quoteCurrency,
                  };
                }),
                ...maybeNewEntities,
              ];
              const shouldCurrencyValuesBeConsistent = (() => {
                if (
                  // if toggling on show local currencies
                  isShowLocalCurrencySelected &&
                  !pricingFlow.additionalData.issuingConfig
                    .isShowLocalCurrencySelected
                ) {
                  return false;
                }
                const newEntityCurrencies = updatedEntities.map(
                  (e) => e.issuingEntityCurrency,
                );
                const oldEntityCurrencies =
                  pricingFlow.additionalData.issuingConfig.entities.map(
                    (e) => e.issuingEntityCurrency,
                  );
                if (
                  // if adding any entities that weren't there before
                  newEntityCurrencies.some(
                    (newCurrency) => !oldEntityCurrencies.includes(newCurrency),
                  )
                ) {
                  return false;
                }
                return true;
              })();
              updateFlow(
                {
                  ...pricingFlow,
                  additionalData: {
                    ...pricingFlow.additionalData,
                    shouldCurrencyValuesBeConsistent,
                    issuingConfig: {
                      ...pricingFlow.additionalData.issuingConfig,
                      isCustomInterchangeRevenueEnabled,
                      isMultiCountryIssuingEnabled,
                      isShowLocalCurrencySelected,
                      selectedEntities,
                      entities: updatedEntities,
                    },
                  },
                },
                false,
              );
              setShowAdvancedSettings(false);
            }}
          >
            Save
          </button>
        </div>
      </>
    </AlpacaAdvancedSettings>
  );
};

function AdvancedSettingsButton(props: {
  setShowAdvancedSettings: (show: boolean) => void;
}) {
  const { setShowAdvancedSettings } = props;
  return (
    <button
      type="button"
      onClick={() => {
        setShowAdvancedSettings(true);
      }}
      className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
    >
      <InformationCircleIcon
        className="-ml-0.5 mr-2 h-5 w-5"
        aria-hidden="true"
      />
      Advanced Settings
    </button>
  );
}

const IssuingHeader = ({
  setShowAdvancedSettings,
}: {
  setShowAdvancedSettings: (show: boolean) => void;
}) => {
  return (
    <AlpacaHeader
      title="Issuing"
      rightChildren={[
        <AlpacaOpportunityInfoButton />,
        <AdvancedSettingsButton
          setShowAdvancedSettings={setShowAdvancedSettings}
        />,
        <AlpacaHelpButton
          urls={[
            {
              name: '💳 Sales Play: Issuing',
              url: 'https://airwallex.atlassian.net/wiki/spaces/SBAH/pages/2541912116/Sales+Play+Borderless+Cards+Issuing#Pricing-&-Commercials:-How-we-make-money,-what-customers-pay-and-discounting-framework',
            },
            {
              name: 'Strategic Merchants Interchange guide',
              url: '',
            },
          ]}
        />,
      ]}
      borderBottom={false}
    />
  );
};

const CountrySelector = () => {
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const issuingConfig = pricingFlow.additionalData.issuingConfig;
  const ALL_TABS = [
    {
      name: IssuingCountryCode.US,
      icon: issuingCountryCodeToEmoji[IssuingCountryCode.US],
    },
    {
      name: IssuingCountryCode.UK,
      icon: issuingCountryCodeToEmoji[IssuingCountryCode.UK],
    },
    {
      name: IssuingCountryCode.AU,
      icon: issuingCountryCodeToEmoji[IssuingCountryCode.AU],
    },
    {
      name: IssuingCountryCode.HK,
      icon: issuingCountryCodeToEmoji[IssuingCountryCode.HK],
    },
    {
      name: IssuingCountryCode.SG,
      icon: issuingCountryCodeToEmoji[IssuingCountryCode.SG],
    },
    {
      name: IssuingCountryCode.NL,
      icon: issuingCountryCodeToEmoji[IssuingCountryCode.NL],
    },
  ];

  const enabledTabs = ALL_TABS.filter((tab) => {
    if (issuingConfig.isMultiCountryIssuingEnabled) {
      return issuingConfig.selectedEntities.includes(tab.name);
    } else {
      return tab.name === issuingConfig.currentlyViewingEntity;
    }
  });
  const selectedTab = issuingConfig.currentlyViewingEntity;
  if (!enabledTabs.map((t) => t.name).includes(selectedTab)) {
    updateIssuingConfig({
      newConfigOverrides: {
        currentlyViewingEntity: issuingConfig.selectedEntities[0],
      },
      oldPricingFlow: pricingFlow,
      updateFlow,
      showLoading: false,
    });
  }

  return (
    <div className="px-8 border-b border-gray-200">
      <nav className="-mb-px flex space-x-2" aria-label="Tabs">
        {enabledTabs.map((tab) => (
          <div
            key={tab.name}
            className={classNames(
              selectedTab === tab.name
                ? 'border-fuchsia-800 text-fuchsia-900'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
              'group inline-flex items-center border-b-2 py-2 px-1 text-sm font-medium hover:cursor-pointer',
            )}
            onClick={() => {
              updateIssuingConfig({
                newConfigOverrides: { currentlyViewingEntity: tab.name },
                oldPricingFlow: pricingFlow,
                updateFlow,
                showLoading: false,
              });
            }}
            aria-current={selectedTab === tab.name ? 'page' : undefined}
          >
            {tab.icon} <span className="pl-2">{tab.name}</span>
          </div>
        ))}
      </nav>
    </div>
  );
};

const Dropdown = ({
  id,
  label,
  options,
  selectedOption,
  setSelectedOption,
}: {
  id: string;
  label: string;
  options: string[];
  selectedOption: string;
  setSelectedOption: (newSelectedOption: IssuingBinType) => void;
}) => (
  <div>
    <label
      htmlFor={id}
      className="block mb-2 text-sm font-medium text-gray-900"
    >
      {label}
    </label>
    <select
      id={id}
      onChange={(e) => {
        setSelectedOption(e.target.value as IssuingBinType);
      }}
      value={selectedOption}
      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-800 focus:border-fuchsia-800 block w-full p-2.5 "
    >
      {options.map((option) => (
        <option key={option} selected={selectedOption === option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);

const CardTransactions = () => {
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  const currency = currentEntity.issuingEntityCurrency;
  return (
    <>
      <h2 className="text-lg font-semibold pb-8">Card Transactions</h2>
      <div className="grid grid-cols-4 gap-4">
        <Dropdown
          id="BINtype"
          label="BIN Type"
          options={Object.values(IssuingBinType).map((type) => `${type} Debit`)}
          selectedOption={`${currentEntity.binType} Debit`}
          setSelectedOption={(newBINtype: string) => {
            updateEntity({
              newEntityOverrides: {
                binType: newBINtype.replace(' Debit', '') as IssuingBinType,
              },
              entityName: currentEntity.name,
              oldPricingFlow: pricingFlow,
              updateFlow,
              showLoading: false,
            });
          }}
        />
        <div>
          <FormattedNumberField
            type="text"
            label={`Monthly Spend at Scale (${currency})`}
            value={currentEntity.monthlySpendAtScale.value}
            numberDecimals={2}
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 "
            updateValue={(value: number) => {
              updateEntity({
                newEntityOverrides: {
                  monthlySpendAtScale: {
                    value: nanToZero(value),
                    type: CurrencyValueType.FLAT,
                    currency,
                  },
                  monthlyTransactionCount: {
                    type: 'count',
                    value: nanToZero(
                      value / currentEntity.avgTransactionSize.value,
                    ),
                  },
                },
                entityName: currentEntity.name,
                oldPricingFlow: pricingFlow,
                updateFlow,
                showLoading: false,
              });
            }}
            prefix={ALPACA_CURRENCY_SYMBOLS[currency]}
          />
        </div>
        <div>
          <FormattedNumberField
            type="text"
            value={currentEntity.avgTransactionSize.value}
            label={`Avg. Transaction Size (${currency})`}
            numberDecimals={2}
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 "
            updateValue={(value: number) => {
              updateEntity({
                newEntityOverrides: {
                  avgTransactionSize: {
                    value: nanToZero(value),
                    type: CurrencyValueType.FLAT,
                    currency,
                  },
                  monthlyTransactionCount: {
                    type: 'count',
                    value: nanToZero(
                      currentEntity.monthlySpendAtScale.value / value,
                    ),
                  },
                },
                entityName: currentEntity.name,
                oldPricingFlow: pricingFlow,
                updateFlow,
                showLoading: false,
              });
            }}
            prefix={ALPACA_CURRENCY_SYMBOLS[currency]}
          />
        </div>
        <div>
          <FormattedNumberField
            readOnly={true}
            type="text"
            label="Monthly transaction count (calculated)"
            value={currentEntity.monthlyTransactionCount.value}
            numberDecimals={0}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 "
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 py-4">
        <div>
          <FormattedNumberField
            type="text"
            label="Number of Cards Created Monthly"
            value={currentEntity.monthlyNumberOfCardsCreated.value}
            numberDecimals={0}
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 "
            updateValue={(value: number) => {
              updateEntity({
                newEntityOverrides: {
                  monthlyNumberOfCardsCreated: {
                    value,
                    type: 'count',
                  },
                },
                entityName: currentEntity.name,
                oldPricingFlow: pricingFlow,
                updateFlow,
                showLoading: false,
              });
            }}
          />
        </div>
        <div className="col-span-3 flex flex-row gap-4">
          <div>
            <DragBarWithPercentInput
              label={
                <>
                  Choose the percent of cards that will be loaded to a{' '}
                  <b>digital wallet</b>
                </>
              }
              defaultValue={currentEntity.addedToDigitalWalletPercentage.value}
              updateEntityHandler={(value: number) =>
                updateEntity({
                  newEntityOverrides: {
                    addedToDigitalWalletPercentage: {
                      value,
                      type: CurrencyValueType.PERCENT,
                    },
                  },
                  entityName: currentEntity.name,
                  oldPricingFlow: pricingFlow,
                  updateFlow,
                  showLoading: false,
                })
              }
            />
          </div>
          <div>
            <DragBarWithPercentInput
              label={
                <>
                  Choose the percent of cards that will be <b>physical cards</b>
                </>
              }
              defaultValue={currentEntity.physicalCardPercentage.value}
              updateEntityHandler={(value: number) => {
                updateEntity({
                  newEntityOverrides: {
                    physicalCardPercentage: {
                      value,
                      type: CurrencyValueType.PERCENT,
                    },
                  },
                  entityName: currentEntity.name,
                  oldPricingFlow: pricingFlow,
                  updateFlow,
                  showLoading: false,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-12 py-4">
        <AlpacaRegionalBreakdown
          updateRegionalBreakdown={(
            newRegionalBreakdown: RegionalData<CurrencyValuePercent>,
          ) => {
            updateEntity({
              newEntityOverrides: {
                regionalBreakdown: newRegionalBreakdown,
              },
              entityName: currentEntity.name,
              oldPricingFlow: pricingFlow,
              updateFlow,
              showLoading: false,
            });
          }}
        />
        <div className="col-span-2">
          <InterchangeRevenueTable />
        </div>
      </div>
    </>
  );
};

// More details here: https://docs.google.com/presentation/d/1l8CQj-A3dSeYEzZrVB02T9cprbekfP1GwKQqLuTMj9g/edit?usp=sharing

const TemplateSelector = () => {
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  let currentlyActiveTemplate = list.findIndexOrNull(
    TEMPLATE_SELECTIONS.slice(1, -1), // ignore "select a template" and "custom"
    (template) => {
      const templatePricingFlow = template.getNewPricingFlow(pricingFlow);
      const templateEntity = getCurrentIssuingEntity(templatePricingFlow);
      return (
        objectsAreEqual(
          templatePricingFlow.additionalData.issuingConfig,
          pricingFlow.additionalData.issuingConfig,
          [
            'isCustomInterchangeRevenueEnabled',
            'isShowLocalCurrencySelected',
            'isMultiCountryIssuingEnabled',
          ],
        ) &&
        objectsAreEqual(templateEntity, currentEntity, [
          'showRebates',
          'showInterchangeRevenueShare',
          'showTransactionFees',
        ]) &&
        productsAreEqual(
          templatePricingFlow.products ?? [],
          pricingFlow.products ?? [],
        )
      );
    },
  );
  if (isNil(currentlyActiveTemplate)) {
    if (pricingFlow.additionalData.issuingConfig.hasSelectedTemplate) {
      // custom
      currentlyActiveTemplate = TEMPLATE_SELECTIONS.length - 1;
    } else {
      // select a template
      currentlyActiveTemplate = 0;
    }
  } else {
    // we sliced off the first element when doing the search, so we need to add
    // its index back here
    currentlyActiveTemplate = currentlyActiveTemplate + 1;
  }
  return (
    <div className="flex flex-col gap-2 my-4">
      <h2 className="text-sm font-semibold text-gray-500">Template Option</h2>
      <Menu as="div" className="relative inline-block text-left w-1/2">
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <div className="flex justify-between items-center w-full">
              <div>
                <div className="text-left">
                  <p>{TEMPLATE_SELECTIONS[currentlyActiveTemplate].name}</p>
                  <p className="text-sm text-gray-500">
                    {TEMPLATE_SELECTIONS[currentlyActiveTemplate].description}
                  </p>
                </div>
              </div>
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </Menu.Button>
        </div>

        <Transition
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-20 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {TEMPLATE_SELECTIONS.map((template, index) => {
                if (index === 0) {
                  // "select a template"
                  return (
                    <Menu.Item key={template.name}>
                      {() => (
                        <div className="block px-4 py-2 w-full text-left">
                          <p className="text-gray-700 text-sm font-semibold">
                            {template.name}
                          </p>
                          <p className="text-sm text-gray-500">
                            {template.description}
                          </p>
                        </div>
                      )}
                    </Menu.Item>
                  );
                } else if (index === TEMPLATE_SELECTIONS.length - 1) {
                  // do not show the "custom" option. If the user is currently
                  // using a template, then selects custom, we can't detect that
                  // they've unselected the previous template, because their
                  // pricing flow looks the same and we determine which template
                  // was selected based on the pricing flow state.
                  return null;
                } else {
                  return (
                    // real selectable options
                    <Menu.Item key={template.name}>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            const newPricingFlow =
                              template.getNewPricingFlow(pricingFlow);
                            updateFlow(
                              {
                                ...newPricingFlow,
                                additionalData: {
                                  ...newPricingFlow.additionalData,
                                  issuingConfig: {
                                    ...newPricingFlow.additionalData
                                      .issuingConfig,
                                    hasSelectedTemplate: true,
                                  },
                                },
                              },
                              false,
                            );
                          }}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full',
                          )}
                        >
                          <div>
                            <div className="text-left">
                              <p className="mb-1">
                                {template.name}
                                {template.name ===
                                  'Zero transaction fees + rebates (Standard)' && (
                                  <span className="inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 ml-2">
                                    POPULAR
                                  </span>
                                )}
                              </p>
                              <p className="text-sm text-gray-500">
                                {template.description}
                              </p>
                            </div>
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                  );
                }
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

const InterchangeRevenueTable = () => {
  return (
    <AlpacaIssuingTable
      title={'Interchange Revenue'}
      valueDescription="IC rate"
      selectors={[
        {
          geography: 'domestic',
          subCategory: 'interchangeRevenue',
        },
        {
          geography: 'intraRegional',
          subCategory: 'interchangeRevenue',
        },
        {
          geography: 'international',
          subCategory: 'interchangeRevenue',
        },
      ]}
      canToggleVisibility={false}
      volumeIsDollarVolume={true}
      shouldShowPercentAgg={false}
    />
  );
};

const RevenueSection = () => {
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  return (
    <div className="flex flex-col gap-4 mt-9">
      <div className="">
        <h2 className="text-lg font-semibold">Revenue</h2>
        <p className="text-sm text-gray-700">
          Interchange Revenue and Transaction Fees
        </p>
      </div>
      <div className="col-span-2">
        <InterchangeRevenueTable />
      </div>
      {currentEntity.binType === IssuingBinType.CommercialOTA && (
        <AlpacaIssuingTable
          title={'B2B Travel Performance Incentives'}
          valueDescription="Perf incentive revenue"
          selectors={[
            {
              geography: 'domestic',
              subCategory: 'perfIncentives',
            },
            {
              geography: 'intraRegional',
              subCategory: 'perfIncentives',
            },
            {
              geography: 'international',
              subCategory: 'perfIncentives',
            },
          ]}
          canToggleVisibility={false}
          volumeIsDollarVolume={true}
          shouldShowPercentAgg={false}
        />
      )}

      <AlpacaIssuingTable
        title={'Add transaction fees'}
        valueDescription="Transaction Fees Revenue"
        selectors={[
          {
            geography: 'domestic',
            subCategory: 'transactionFees',
          },
          {
            geography: 'intraRegional',
            subCategory: 'transactionFees',
          },
          {
            geography: 'international',
            subCategory: 'transactionFees',
          },
        ]}
        showTable={currentEntity.showTransactionFees}
        setShowTable={(newShowTable) =>
          updateEntity({
            newEntityOverrides: { showTransactionFees: newShowTable },
            entityName: currentEntity.name,
            oldPricingFlow: pricingFlow,
            updateFlow,
            showLoading: false,
          })
        }
        canToggleVisibility={true}
        volumeIsDollarVolume={true}
        shouldShowPercentAgg={false}
      />

      <div className="flex flex-row-reverse justify-between items-center">
        <div className="flex justify-between items-center gap-x-10 p-4 border rounded-xl">
          <p className="text-base text-slate-700">Total estimated revenue</p>
          <p className="text-sm font-semibold text-zinc-900">
            {formatCurrencyValue(
              getSumCurrencyValue({
                currencyValuesRaw: (pricingFlow.products ?? [])
                  .filter((p) => {
                    return (
                      p.categoryName === 'Issuing' &&
                      p.isCost === false &&
                      p.binType === currentEntity.binType &&
                      p.country === currentEntity.name
                    );
                  })
                  .map((p) => {
                    return p.derivedAggregations?.grossRevenue;
                  })
                  .filter((cv): cv is DerivedValue<CurrencyValueFlat> => {
                    return !isNil(cv);
                  }),
                quoteCurrency: currentEntity.issuingEntityCurrency,
                pricingFlow,
              }),
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

const Costs = () => {
  const { pricingFlow } = usePricingFlowContext<AlpacaPricingFlow>();
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  return (
    <div className="mt-6 flex flex-col gap-4">
      <h2 className="text-lg font-semibold">Costs</h2>
      <div className="col-span-2">
        {/* Transaction Costs Table */}
        <AlpacaIssuingTable
          title={'Transaction costs'}
          valueDescription="Cost"
          selectors={[
            {
              geography: 'domestic',
              subCategory: 'transactionCost',
            },
            {
              geography: 'intraRegional',
              subCategory: 'transactionCost',
            },
            {
              geography: 'international',
              subCategory: 'transactionCost',
            },
          ]}
          canToggleVisibility={false}
          volumeIsDollarVolume={true}
          shouldShowPercentAgg={true}
        />
      </div>
      <div className="col-span-2">
        {/* Other Misc Costs Table */}
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <AlpacaIssuingTable
            title={'Other costs'}
            valueDescription="Cost"
            selectors={[
              {
                geography: null,
                subCategory: 'chargeback',
              },
              {
                geography: null,
                subCategory: 'cardCreationCost',
              },
              {
                geography: null,
                subCategory: 'cardMaintenanceCost',
              },
            ]}
            canToggleVisibility={false}
            volumeIsDollarVolume={false}
          />
        </div>
      </div>

      <div className="flex flex-row-reverse justify-between items-center">
        <div className="flex justify-between items-center gap-x-10 p-4 border rounded-xl">
          <p className="text-base text-slate-700">Total estimated cost</p>
          <p className="text-sm font-semibold text-zinc-900">
            {formatCurrencyValue(
              getSumCurrencyValue({
                currencyValuesRaw: (pricingFlow.products ?? [])
                  .filter((p) => {
                    return (
                      p.categoryName === 'Issuing' &&
                      p.isCost === true &&
                      p.binType === currentEntity.binType &&
                      p.country === currentEntity.name &&
                      p.subCategory !== 'rebates' &&
                      p.subCategory !== 'interchangeRevenueShare'
                    );
                  })
                  .map((p) => {
                    return p.derivedAggregations?.grossCost;
                  })
                  .filter((cv): cv is DerivedValue<CurrencyValueFlat> => {
                    return !isNil(cv);
                  }),
                quoteCurrency: currentEntity.issuingEntityCurrency,
                pricingFlow,
              }),
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

const RebatesAndInterchangeRevenueShare = () => {
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  return (
    <div className="mt-6 flex flex-col gap-4">
      <h2 className="text-lg font-semibold">
        Rebates and interchange revenue share
      </h2>
      <div className="col-span-2">
        <AlpacaIssuingTable
          title={'Rebates'}
          valueDescription="Rebate"
          selectors={[
            {
              geography: 'domestic',
              subCategory: 'rebates',
            },
            {
              geography: 'intraRegional',
              subCategory: 'rebates',
            },
            {
              geography: 'international',
              subCategory: 'rebates',
            },
          ]}
          showTable={currentEntity.showRebates}
          setShowTable={(newShowTable) =>
            updateEntity({
              newEntityOverrides: { showRebates: newShowTable },
              entityName: currentEntity.name,
              oldPricingFlow: pricingFlow,
              updateFlow,
              showLoading: false,
            })
          }
          canToggleVisibility={true}
          volumeIsDollarVolume={true}
          shouldShowPercentAgg={true}
        />
      </div>
      <div className="col-span-2">
        <AlpacaIssuingTable
          title={'Interchange Revenue Share'}
          valueDescription="Rev share"
          selectors={[
            {
              geography: 'domestic',
              subCategory: 'interchangeRevenueShare',
            },
            {
              geography: 'intraRegional',
              subCategory: 'interchangeRevenueShare',
            },
            {
              geography: 'international',
              subCategory: 'interchangeRevenueShare',
            },
          ]}
          showTable={currentEntity.showInterchangeRevenueShare}
          setShowTable={(newShowTable) =>
            updateEntity({
              newEntityOverrides: {
                showInterchangeRevenueShare: newShowTable,
              },
              entityName: currentEntity.name,
              oldPricingFlow: pricingFlow,
              updateFlow,
              showLoading: false,
            })
          }
          canToggleVisibility={true}
          volumeIsDollarVolume={true}
          volumeDescription="Est. IC revenue"
          shouldShowPercentAgg={true}
        />
      </div>
    </div>
  );
};

const getProductsForCurrentEntity = (
  pricingFlow: AlpacaPricingFlow,
): AlpacaIssuingProduct[] => {
  let entity = getCurrentIssuingEntity(pricingFlow);

  let relevantProducts = pricingFlow.products?.filter(
    (product): product is AlpacaIssuingProduct => {
      return (
        product.categoryName === 'Issuing' &&
        product.binType === entity.binType &&
        product.country === entity.name
      );
    },
  );

  return relevantProducts ?? [];
};

interface IssuingSummaryTableRow {
  name: string;
  estVolume?: DerivedValue<CurrencyValueFlat>;
  estTxnCount?: DerivedValue<Count>;
  ////////////////////////////////////////////////////////////////////////////
  // Revenue Section
  icRev?: DerivedValue<CurrencyValueFlat>;
  performanceIncentives?: DerivedValue<CurrencyValueFlat>;
  transactionFees?: DerivedValue<CurrencyValueFlat>;
  // sum of icRev, performanceIncentives, transactionFees
  grossRevenue?: DerivedValue<CurrencyValueFlat>;
  /////////////////
  // Costs Section
  transactionCosts?: DerivedValue<CurrencyValueFlat>;
  // This is a summary row field, so it will always be empty
  chargebackCosts?: DerivedValue<CurrencyValueFlat>;
  // This is a summary row field, so it will always be empty
  cardCreationCosts?: DerivedValue<CurrencyValueFlat>;
  // This is a summary row field, so it will always be empty
  cardMaintenanceCosts?: DerivedValue<CurrencyValueFlat>;
  // sum of transactionCosts, chargebackCosts, cardCreationCosts,
  // cardMaintenanceCosts. Does NOT include rebates and rev share, even though
  // these are also effectively costs!
  grossCost?: DerivedValue<CurrencyValueFlat>;
  ////////////////////////////////////////////////////////////////////////////
  // Rebates & IC Section
  rebates?: DerivedValue<CurrencyValueFlat>;
  icRevShare?: DerivedValue<CurrencyValueFlat>;
  rebatesAndRevShare?: DerivedValue<CurrencyValueFlat>;
  ////////////////////////////////////////////////////////////////////////////
  // Summary Columns
  grossProfit?: DerivedValue<CurrencyValueFlat>;
  takeRate?: DerivedValue<CurrencyValuePercent>;
}

const computeSummaryDataForGeography = (
  geography: IssuingGeography,
  currentEntity: IssuingEntity,
  pricingFlow: AlpacaPricingFlow,
  products: AlpacaIssuingProduct[],
): IssuingSummaryTableRow => {
  const estVolume = getVolumeForGeography(
    geography,
    currentEntity.name,
    pricingFlow,
    false,
    null,
  );
  const grossProfit = getSumCurrencyValue({
    currencyValuesRaw: products
      .filter(
        ({ geography: g, binType }) =>
          g === geography && binType === currentEntity.binType,
      )
      .map((x) => x.derivedAggregations?.grossProfit ?? null),
    quoteCurrency: currentEntity.issuingEntityCurrency,
    pricingFlow,
  });
  return {
    name: geographyToName(geography),
    estVolume,
    estTxnCount: getTxnCountForGeography(
      geography,
      currentEntity.name,
      pricingFlow,
      false,
      null,
    ),
    // Revenue
    icRev: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, subCategory }) =>
            g === geography && subCategory === 'interchangeRevenue',
        )
        .map(
          ({ derivedAggregations }) =>
            derivedAggregations?.grossRevenue ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    performanceIncentives: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, subCategory }) =>
            g === geography && subCategory === 'perfIncentives',
        )
        .map(
          ({ derivedAggregations }) =>
            derivedAggregations?.grossRevenue ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    transactionFees: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, subCategory }) =>
            g === geography && subCategory === 'transactionFees',
        )
        .map(
          ({ derivedAggregations }) =>
            derivedAggregations?.grossRevenue ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    grossRevenue: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, subCategory }) =>
            g === geography &&
            [
              'interchangeRevenue',
              'transactionFees',
              'perfIncentives',
            ].includes(subCategory),
        )
        .map(
          ({ derivedAggregations }) =>
            derivedAggregations?.grossRevenue ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    // Costs
    transactionCosts: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, isCost, subCategory }) =>
            g === geography && isCost && subCategory === 'transactionCost',
        )
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    chargebackCosts: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, isCost, subCategory }) =>
            g === geography && isCost && subCategory === 'chargeback',
        )
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    cardCreationCosts: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, isCost, subCategory }) =>
            g === geography && isCost && subCategory === 'cardCreationCost',
        )
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    cardMaintenanceCosts: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, isCost, subCategory }) =>
            g === geography && isCost && subCategory === 'cardMaintenanceCost',
        )
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    grossCost: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, isCost, subCategory }) =>
            g === geography &&
            isCost &&
            [
              'cardMaintenanceCost',
              'cardCreationCost',
              'chargeback',
              'transactionCost',
            ].includes(subCategory),
        )
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    // Kickback
    rebates: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, subCategory }) =>
            g === geography && subCategory === 'rebates',
        )
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    icRevShare: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, subCategory }) =>
            g === geography && subCategory === 'interchangeRevenueShare',
        )
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    rebatesAndRevShare: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, subCategory }) =>
            g === geography &&
            ['interchangeRevenueShare', 'rebates'].includes(subCategory),
        )
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    // Profit and Take Rate
    grossProfit,
    takeRate: {
      value: (100 * grossProfit.value) / estVolume.value,
      type: CurrencyValueType.PERCENT as const,
      concept: 'Monthly gross profit / monthly volume',
      provenance: `${formatCurrencyValue(grossProfit ?? null)} / ${formatCurrencyValue(estVolume ?? null)}`,
    },
  };
};

const computeAllSummaryData = (
  currentEntity: IssuingEntity,
  pricingFlow: AlpacaPricingFlow,
  products: AlpacaIssuingProduct[],
): IssuingSummaryTableRow => {
  const grossProfit = getSumCurrencyValue({
    currencyValuesRaw: products
      .filter(({ geography: g, binType }) => binType === currentEntity.binType)
      .map((x) => x.derivedAggregations?.grossProfit ?? null),
    quoteCurrency: currentEntity.issuingEntityCurrency,
    pricingFlow,
  });
  const estVolume = {
    ...currentEntity.monthlySpendAtScale,
    provenance: 'Monthly spend at scale',
  };

  const takeRate = {
    value: (100 * grossProfit.value) / currentEntity.monthlySpendAtScale.value,
    type: CurrencyValueType.PERCENT as const,
    concept: 'Monthly gross profit / monthly volume',
    provenance: `${formatCurrencyValue(grossProfit ?? null)} / ${formatCurrencyValue(estVolume ?? null)}`,
  };
  return {
    name: 'All',
    estVolume,
    estTxnCount: getSumCount([
      getTxnCountForGeography(
        'domestic',
        currentEntity.name,
        pricingFlow,
        false,
        null,
      ),
      getTxnCountForGeography(
        'international',
        currentEntity.name,
        pricingFlow,
        false,
        null,
      ),
      getTxnCountForGeography(
        'intraRegional',
        currentEntity.name,
        pricingFlow,
        false,
        null,
      ),
    ]),
    // Revenue
    icRev: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(({ subCategory }) => subCategory === 'interchangeRevenue')
        .map(
          ({ derivedAggregations }) =>
            derivedAggregations?.grossRevenue ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    performanceIncentives: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(({ subCategory }) => subCategory === 'perfIncentives')
        .map(
          ({ derivedAggregations }) =>
            derivedAggregations?.grossRevenue ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    transactionFees: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(({ subCategory }) => subCategory === 'transactionFees')
        .map(
          ({ derivedAggregations }) =>
            derivedAggregations?.grossRevenue ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    grossRevenue: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(({ geography: g, subCategory }) =>
          ['interchangeRevenue', 'transactionFees', 'perfIncentives'].includes(
            subCategory,
          ),
        )
        .map(
          ({ derivedAggregations }) =>
            derivedAggregations?.grossRevenue ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    // Costs
    transactionCosts: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, isCost, subCategory }) =>
            isCost && subCategory === 'transactionCost',
        )
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    chargebackCosts: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, isCost, subCategory }) =>
            isCost && subCategory === 'chargeback',
        )
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    cardCreationCosts: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, isCost, subCategory }) =>
            isCost && subCategory === 'cardCreationCost',
        )
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    cardMaintenanceCosts: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, isCost, subCategory }) =>
            isCost && subCategory === 'cardMaintenanceCost',
        )
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    grossCost: {
      ...getSumCurrencyValue({
        currencyValuesRaw: products
          ?.filter(
            ({ geography: g, isCost, subCategory }) =>
              isCost &&
              [
                'cardMaintenanceCost',
                'cardCreationCost',
                'chargeback',
                'transactionCost',
              ].includes(subCategory),
          )
          .map(
            ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
          ),
        quoteCurrency: currentEntity.issuingEntityCurrency,
        pricingFlow,
      }),
      concept:
        'This also includes the non-regional chargeback, card creation, and maintenance costs.',
    },
    // Kickback
    rebates: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(({ geography: g, subCategory }) => subCategory === 'rebates')
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    icRevShare: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(
          ({ geography: g, subCategory }) =>
            subCategory === 'interchangeRevenueShare',
        )
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    rebatesAndRevShare: getSumCurrencyValue({
      currencyValuesRaw: products
        ?.filter(({ geography: g, subCategory }) =>
          ['interchangeRevenueShare', 'rebates'].includes(subCategory),
        )
        .map(
          ({ derivedAggregations }) => derivedAggregations?.grossCost ?? null,
        ),
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    }),
    // Profit and Take Rate
    grossProfit,
    takeRate,
  };
};

const SummaryTable = () => {
  // toggles for revenue/cost/rebate columns
  const [showRevenueColumns, setShowRevenueColumns] = useState(false);
  const [showCostColumns, setShowCostColumns] = useState(false);
  const [showRebateAndRevShareColumns, setShowRebateAndRevShareColumns] =
    useState(false);

  const { pricingFlow } = usePricingFlowContext<AlpacaPricingFlow>();

  let currentEntity = getCurrentIssuingEntity(pricingFlow);

  let products = getProductsForCurrentEntity(pricingFlow);
  const shouldShowIntraRegional = entityHasIntraregionalConcept(currentEntity);
  let domesticSummary = computeSummaryDataForGeography(
    'domestic',
    currentEntity,
    pricingFlow,
    products,
  );
  let intraRegionalSummary = computeSummaryDataForGeography(
    'intraRegional',
    currentEntity,
    pricingFlow,
    products,
  );
  let internationalSummary = computeSummaryDataForGeography(
    'international',
    currentEntity,
    pricingFlow,
    products,
  );

  const summaryData: IssuingSummaryTableRow[] = [
    domesticSummary,
    ...(shouldShowIntraRegional ? [intraRegionalSummary] : []),
    internationalSummary,
  ];
  const allSummaryData = computeAllSummaryData(
    currentEntity,
    pricingFlow,
    products,
  );

  return (
    <>
      <div className="mt-12">
        <h2 className="text-lg font-semibold">Summary</h2>
        <div
          className={classNames(
            'overflow-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg my-4',
          )}
        >
          <table className="h-full w-full">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="min-w-[160px] sticky top-0 z-10 w-full rounded-t-xl border-b bg-gray-50 px-6 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className=" min-w-[160px] sticky top-0 z-10 hidden border-b bg-gray-50 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                >
                  Est. volume
                </th>
                <th
                  scope="col"
                  className=" min-w-[160px] sticky top-0 z-10 hidden border-b bg-gray-50 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                >
                  Est. txn count
                </th>
                <Tooltip
                  as="th"
                  scope="col"
                  className="min-w-[160px] sticky top-0 z-10 hidden border-b bg-gray-50 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                  location="LEFT"
                  text="IC revenue + performance incentives + transaction fees"
                >
                  <div className="flex items-center">Gross revenue</div>
                </Tooltip>

                {showRevenueColumns && (
                  <>
                    <Tooltip
                      as="th"
                      scope="col"
                      className="min-w-[160px] sticky top-0 z-10 hidden border-b border-b-slate-300 border-l border-l-slate-400 bg-slate-200 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                      location="LEFT"
                      text="Interchange revenue"
                    >
                      IC revenue
                    </Tooltip>
                    <Tooltip
                      as="th"
                      scope="col"
                      className=" min-w-[160px] sticky top-0 z-10 hidden border-b border-b-slate-300 bg-slate-200 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                      location="LEFT"
                      text="Performance incentives (OTA Bin Type)"
                    >
                      Perf incentives
                    </Tooltip>
                    <Tooltip
                      as="th"
                      scope="col"
                      className=" min-w-[160px] sticky top-0 z-10 hidden border-b border-b-slate-300 bg-slate-200 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                      location="LEFT"
                      text="Transaction fees (revenue source)"
                    >
                      Txn fees
                    </Tooltip>
                  </>
                )}
                <th
                  scope="col"
                  className={`min-w-[20px] sticky top-0 z-10 hidden border-b ${showRevenueColumns ? '' : 'border-l border-l-slate-400'} border-r border-r-slate-400 border-b border-b-slate-300 bg-slate-200 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap`}
                >
                  <button
                    onClick={() => setShowRevenueColumns(!showRevenueColumns)}
                  >
                    {showRevenueColumns ? (
                      <ArrowsPointingInIcon className="mt-1 h-4 w-4 text-gray-800 hover:text-gray-400" />
                    ) : (
                      <ArrowsPointingOutIcon className="mt-1 h-4 w-4 text-gray-800 hover:text-gray-400" />
                    )}
                  </button>
                </th>

                <Tooltip
                  as="th"
                  scope="col"
                  className=" min-w-[160px] sticky top-0 z-10 hidden border-b bg-gray-50 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                  location="LEFT"
                  text="Costs: transactions + chargebacks + card creation + card maintenance"
                >
                  <div className="flex items-center">Gross cost</div>
                </Tooltip>
                {showCostColumns && (
                  <>
                    <Tooltip
                      as="th"
                      scope="col"
                      className="min-w-[160px] sticky top-0 z-10 hidden border-b border-b-slate-300 border-l border-l-slate-400 bg-slate-200 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                      location="LEFT"
                      text="Transaction costs"
                    >
                      Txn costs
                    </Tooltip>
                    <Tooltip
                      as="th"
                      scope="col"
                      className=" min-w-[160px] sticky top-0 z-10 hidden border-b border-b-slate-300 bg-slate-200 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                      location="LEFT"
                      text="Est. chargeback volume * cost per chargeback"
                    >
                      Chargeback costs
                    </Tooltip>
                    <Tooltip
                      as="th"
                      scope="col"
                      className=" min-w-[160px] sticky top-0 z-10 hidden border-b border-b-slate-300 bg-slate-200 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                      location="LEFT"
                      text="# of physical cards * cost per card"
                    >
                      Card creation costs
                    </Tooltip>
                    <Tooltip
                      as="th"
                      scope="col"
                      className=" min-w-[160px] sticky top-0 z-10 hidden border-b border-b-slate-300 bg-slate-200 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                      location="LEFT"
                      text="Card maintenance cost for Apple Pay only"
                    >
                      Card maint. costs
                    </Tooltip>
                  </>
                )}
                <th
                  scope="col"
                  className={`min-w-[20px] sticky top-0 z-10 hidden border-b ${showCostColumns ? '' : 'border-l border-l-slate-400'} border-r border-r-slate-400 border-b border-b-slate-300 bg-slate-200 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap`}
                >
                  <button onClick={() => setShowCostColumns(!showCostColumns)}>
                    {showCostColumns ? (
                      <ArrowsPointingInIcon className="mt-1 h-4 w-4 text-gray-800 hover:text-gray-400" />
                    ) : (
                      <ArrowsPointingOutIcon className="mt-1 h-4 w-4 text-gray-800 hover:text-gray-400" />
                    )}
                  </button>
                </th>
                <Tooltip
                  as="th"
                  scope="col"
                  className=" min-w-[160px] sticky top-0 z-10 hidden border-b bg-gray-50 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                  location="LEFT"
                  text="Rebates + IC revenue share"
                >
                  <div className="flex items-center">Rebates & rev share</div>
                </Tooltip>
                {showRebateAndRevShareColumns && (
                  <>
                    <Tooltip
                      as="th"
                      scope="col"
                      className="min-w-[160px] sticky top-0 z-10 hidden border-b border-b-slate-300 border-l border-l-slate-400 bg-slate-200 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                      location="LEFT"
                      text="Rebates to customer"
                    >
                      Rebates
                    </Tooltip>
                    <Tooltip
                      as="th"
                      scope="col"
                      className=" min-w-[160px] sticky top-0 z-10 hidden border-b border-b-slate-300 bg-slate-200 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                      location="LEFT"
                      text="Interchange revenue share"
                    >
                      IC rev share
                    </Tooltip>
                  </>
                )}
                <th
                  scope="col"
                  className={`min-w-[20px] sticky top-0 z-10 hidden ${showRebateAndRevShareColumns ? '' : 'border-l border-l-slate-400'} border-r border-r-slate-400 border-b border-b-slate-300 bg-slate-200 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap`}
                >
                  <button
                    onClick={() =>
                      setShowRebateAndRevShareColumns(
                        !showRebateAndRevShareColumns,
                      )
                    }
                  >
                    {showRebateAndRevShareColumns ? (
                      <ArrowsPointingInIcon className="mt-1 h-4 w-4 text-gray-800 hover:text-gray-400" />
                    ) : (
                      <ArrowsPointingOutIcon className="mt-1 h-4 w-4 text-gray-800 hover:text-gray-400" />
                    )}
                  </button>
                </th>

                <Tooltip
                  as="th"
                  scope="col"
                  className=" min-w-[160px] sticky top-0 z-10 hidden border-b bg-gray-50 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                  location="LEFT"
                  text="All revenues - all costs"
                >
                  Gross profit
                </Tooltip>
                <Tooltip
                  as="th"
                  scope="col"
                  className=" min-w-[160px] sticky top-0 z-10 hidden border-b bg-gray-50 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
                  location="LEFT"
                  text="Gross profit / est. volume"
                >
                  Take rate
                </Tooltip>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {summaryData.map((regionalSummaryData) => (
                <tr key={regionalSummaryData.name}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {regionalSummaryData.name}
                  </td>
                  <td>
                    <DerivedCurrencyValue
                      value={regionalSummaryData.estVolume ?? null}
                      tooltipLocation="RIGHT"
                      className={
                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                      }
                    />
                  </td>
                  <td>
                    <DerivedCurrencyValue
                      value={regionalSummaryData.estTxnCount ?? null}
                      tooltipLocation="RIGHT"
                      className={
                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                      }
                    />
                  </td>
                  <td>
                    <DerivedCurrencyValue
                      value={regionalSummaryData.grossRevenue ?? null}
                      tooltipLocation="RIGHT"
                      colorConfig={{
                        type: 'REVENUE',
                      }}
                      className="whitespace-nowrap px-3 py-4 text-sm"
                    />
                  </td>
                  {showRevenueColumns && (
                    <>
                      <td className="bg-slate-200 border-l border-l-slate-300 border-b border-b-slate-300">
                        <DerivedCurrencyValue
                          value={regionalSummaryData.icRev ?? null}
                          tooltipLocation="RIGHT"
                          colorConfig={{
                            type: 'REVENUE',
                          }}
                          className="whitespace-nowrap px-3 py-4 text-sm"
                        />
                      </td>
                      <td className="bg-slate-200 border-b border-b-slate-300">
                        <DerivedCurrencyValue
                          value={
                            regionalSummaryData.performanceIncentives ?? null
                          }
                          tooltipLocation="RIGHT"
                          colorConfig={{
                            type: 'REVENUE',
                          }}
                          className="whitespace-nowrap px-3 py-4 text-sm"
                        />
                      </td>
                      <td className="bg-slate-200 border-b border-b-slate-300">
                        <DerivedCurrencyValue
                          value={regionalSummaryData.transactionFees ?? null}
                          tooltipLocation="RIGHT"
                          colorConfig={{
                            type: 'REVENUE',
                          }}
                          className="whitespace-nowrap px-3 py-4 text-sm"
                        />
                      </td>
                    </>
                  )}
                  <td
                    className={`${showRevenueColumns ? '' : 'border-l border-l-slate-300'} border-b border-b-slate-300 border-r border-r-slate-300 bg-slate-200`}
                  >
                    {/* Collapsible column cell */}
                  </td>
                  <td>
                    <DerivedCurrencyValue
                      value={regionalSummaryData.grossCost ?? null}
                      colorConfig={{ type: 'COST' }}
                      className="whitespace-nowrap px-3 py-4 text-sm"
                    />
                  </td>
                  {showCostColumns && (
                    <>
                      <td className="bg-slate-200 border-l border-l-slate-300 border-b border-b-slate-300">
                        <DerivedCurrencyValue
                          value={regionalSummaryData.transactionCosts ?? null}
                          colorConfig={{
                            type: 'COST',
                          }}
                          className="whitespace-nowrap px-3 py-4 text-sm"
                        />
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500  bg-slate-200 border-b border-b-slate-300">
                        &mdash;
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500  bg-slate-200 border-b border-b-slate-300">
                        &mdash;
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500  bg-slate-200 border-b border-b-slate-300">
                        &mdash;
                      </td>
                    </>
                  )}
                  <td
                    className={`${showCostColumns ? '' : 'border-l border-l-slate-300'} border-b border-b-slate-300 border-r border-r-slate-300 bg-slate-200`}
                  >
                    {/* Collapsible column cell */}
                  </td>
                  <td>
                    <DerivedCurrencyValue
                      value={regionalSummaryData.rebatesAndRevShare ?? null}
                      colorConfig={{ type: 'COST' }}
                      className="whitespace-nowrap px-3 py-4 text-sm"
                    />
                  </td>
                  {showRebateAndRevShareColumns && (
                    <>
                      <td className="bg-slate-200 border-b border-b-slate-300 border-l border-l-slate-300">
                        <DerivedCurrencyValue
                          value={regionalSummaryData.rebates ?? null}
                          colorConfig={{
                            type: 'COST',
                          }}
                          className="whitespace-nowrap px-3 py-4 text-sm"
                        />
                      </td>
                      <td className="bg-slate-200 border-b border-b-slate-300">
                        <DerivedCurrencyValue
                          value={regionalSummaryData.icRevShare ?? null}
                          colorConfig={{
                            type: 'COST',
                          }}
                          className="whitespace-nowrap px-3 py-4 text-sm"
                        />
                      </td>
                    </>
                  )}
                  <td
                    className={`${showRebateAndRevShareColumns ? '' : 'border-l border-l-slate-300'} border-b border-b-slate-300 border-r border-r-slate-300 bg-slate-200`}
                  >
                    {/* Collapsible column cell */}
                  </td>
                  <td>
                    <DerivedCurrencyValue
                      value={regionalSummaryData.grossProfit ?? null}
                      colorConfig={{
                        type: 'REVENUE',
                      }}
                      className="whitespace-nowrap px-3 py-4 text-sm font-semibold"
                    />
                  </td>
                  <td>
                    <DerivedCurrencyValue
                      value={regionalSummaryData.takeRate ?? null}
                      colorConfig={{ type: 'REVENUE' }}
                      className="whitespace-nowrap px-3 py-4 text-sm text-gray-700 font-semibold"
                    />
                  </td>
                </tr>
              ))}
              <tr key={'all'}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 bg-gray-50 sm:pl-6">
                  All
                </td>

                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                  <DerivedCurrencyValue
                    value={allSummaryData.estVolume ?? null}
                    tooltipLocation="RIGHT"
                  />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                  <DerivedCurrencyValue
                    value={allSummaryData.estTxnCount ?? null}
                    tooltipLocation="RIGHT"
                  />
                </td>
                <td className="bg-gray-50">
                  <DerivedCurrencyValue
                    colorConfig={{
                      type: 'REVENUE',
                    }}
                    className="whitespace-nowrap px-3 py-4 text-sm"
                    tooltipLocation="RIGHT"
                    value={allSummaryData.grossRevenue ?? null}
                  />
                </td>
                {showRevenueColumns && (
                  <>
                    <td className="bg-slate-200 border-b border-b-slate-300 border-l border-l-slate-300">
                      <DerivedCurrencyValue
                        value={allSummaryData.icRev ?? null}
                        colorConfig={{
                          type: 'REVENUE',
                        }}
                        className="whitespace-nowrap px-3 py-4 text-sm"
                        tooltipLocation="RIGHT"
                      />
                    </td>
                    <td className="bg-slate-200 border-b border-b-slate-300">
                      <DerivedCurrencyValue
                        value={allSummaryData.performanceIncentives ?? null}
                        colorConfig={{
                          type: 'REVENUE',
                        }}
                        className="whitespace-nowrap px-3 py-4 text-sm"
                        tooltipLocation="RIGHT"
                      />
                    </td>
                    <td className="bg-slate-200 border-b border-b-slate-300">
                      <DerivedCurrencyValue
                        colorConfig={{
                          type: 'REVENUE',
                        }}
                        className="whitespace-nowrap px-3 py-4 text-sm"
                        value={allSummaryData.transactionFees ?? null}
                        tooltipLocation="RIGHT"
                      />
                    </td>
                  </>
                )}
                <td
                  className={`${showRevenueColumns ? '' : 'border-l border-l-slate-300'} border-b border-b-slate-300 border-r border-r-slate-300 bg-slate-200`}
                >
                  {/* Collapsible Column Cell */}
                </td>
                <td className="bg-gray-50">
                  <DerivedCurrencyValue
                    colorConfig={{
                      type: 'COST',
                    }}
                    className="whitespace-nowrap px-3 py-4 text-sm"
                    value={allSummaryData.grossCost ?? null}
                  />
                </td>
                {showCostColumns && (
                  <>
                    <td className="bg-slate-200 border-b border-b-slate-300 border-l border-l-slate-300">
                      <DerivedCurrencyValue
                        value={allSummaryData.transactionCosts ?? null}
                        colorConfig={{
                          type: 'COST',
                        }}
                        className="whitespace-nowrap px-3 py-4 text-sm"
                      />
                    </td>
                    <td className="bg-slate-200 border-b border-b-slate-300">
                      <DerivedCurrencyValue
                        value={allSummaryData.chargebackCosts ?? null}
                        colorConfig={{
                          type: 'COST',
                        }}
                        className="whitespace-nowrap px-3 py-4 text-sm"
                      />
                    </td>
                    <td className="bg-slate-200 border-b border-b-slate-300">
                      <DerivedCurrencyValue
                        value={allSummaryData.cardCreationCosts ?? null}
                        colorConfig={{
                          type: 'COST',
                        }}
                        className="whitespace-nowrap px-3 py-4 text-sm"
                      />
                    </td>
                    <td className="bg-slate-200 border-b border-b-slate-300">
                      <DerivedCurrencyValue
                        colorConfig={{
                          type: 'COST',
                        }}
                        className="whitespace-nowrap px-3 py-4 text-sm"
                        value={allSummaryData.cardMaintenanceCosts ?? null}
                      />
                    </td>
                  </>
                )}
                <td
                  className={`${showCostColumns ? '' : 'border-l border-l-slate-300'} border-b border-b-slate-300 border-r border-r-slate-300 bg-slate-200`}
                >
                  {/* Collapsible Column Cell */}
                </td>
                <td className="bg-gray-50">
                  <DerivedCurrencyValue
                    value={allSummaryData.rebatesAndRevShare ?? null}
                    colorConfig={{
                      type: 'COST',
                    }}
                    className="whitespace-nowrap px-3 py-4 text-sm"
                  />
                </td>
                {showRebateAndRevShareColumns && (
                  <>
                    <td className="bg-slate-200  border-b border-b-slate-300 border-l border-l-slate-300">
                      <DerivedCurrencyValue
                        value={allSummaryData.rebates ?? null}
                        colorConfig={{
                          type: 'COST',
                        }}
                        className="whitespace-nowrap px-3 py-4 text-sm"
                      />
                    </td>
                    <td className="whitespace-nowrap py-4 text-sm  bg-slate-200  border-b border-b-slate-300">
                      <DerivedCurrencyValue
                        value={allSummaryData.icRevShare ?? null}
                        colorConfig={{
                          type: 'COST',
                        }}
                        className="whitespace-nowrap px-3 py-4 text-sm"
                      />
                    </td>
                  </>
                )}
                <td
                  className={`${showRebateAndRevShareColumns ? '' : 'border-l border-l-slate-300'} border-b border-b-slate-300 border-r border-r-slate-300 bg-slate-200`}
                >
                  {/* Collapsible Column Cell */}
                </td>
                <td className="bg-gray-50">
                  <DerivedCurrencyValue
                    value={allSummaryData.grossProfit ?? null}
                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-700 font-semibold"
                    colorConfig={{
                      type: 'REVENUE',
                    }}
                    tooltipTextOverride={`${formatCurrencyValue(allSummaryData.grossRevenue ?? null)} - ${formatCurrencyValue(allSummaryData.grossCost ?? null)} - ${formatCurrencyValue(allSummaryData.rebatesAndRevShare ?? null)}`}
                  />
                </td>
                <td className="bg-gray-50">
                  <DerivedCurrencyValue
                    value={allSummaryData.takeRate ?? null}
                    colorConfig={{ type: 'REVENUE' }}
                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-700 font-semibold"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <IssuingWaterfallChart
          summaryData={summaryData}
          currency={currentEntity.issuingEntityCurrency}
        />
      </div>
    </>
  );
};

function IssuingWaterfallChart(props: {
  summaryData: IssuingSummaryTableRow[];
  currency: string;
}) {
  const { summaryData, currency } = props;
  let pv = 0;
  const data: (Datum | undefined)[] = summaryData.map((data) => {
    const oldPv = pv;
    const grossProfit = data.grossProfit?.value ?? 0;
    pv = grossProfit + oldPv;
    return {
      name: data.name,
      value: grossProfit,
      transparentValue: oldPv,
      tooltipData: {
        'Gross profit': data.grossProfit,
        'Take rate': data.takeRate,
      },
    };
  });
  const filteredData = data.filter((d) => d !== undefined) as Datum[];

  const { pricingFlow } = usePricingFlowContext<AlpacaPricingFlow>();
  const currentEntity = getCurrentIssuingEntity(pricingFlow);

  const relevantIssuingProducts = getProductsForCurrentEntity(pricingFlow);
  const allSummaryData = computeAllSummaryData(
    currentEntity,
    pricingFlow,
    relevantIssuingProducts,
  );
  // Add non-regional costs to waterfall chart
  const sumOtherCosts = (() => {
    const sumCosts = getSumCurrencyValue({
      currencyValuesRaw: [
        allSummaryData?.cardCreationCosts ?? null,
        allSummaryData?.cardMaintenanceCosts ?? null,
        allSummaryData?.chargebackCosts ?? null,
      ],
      quoteCurrency: currentEntity.issuingEntityCurrency,
      pricingFlow,
    });
    // sumCosts comes back as a positive for display in the summary table, but
    // we should treat it as a negative for the waterfall chart
    return { ...sumCosts, value: -1 * sumCosts.value };
  })();
  if (sumOtherCosts.value !== 0) {
    filteredData?.push({
      name: 'Other costs',
      value: sumOtherCosts.value,
      transparentValue: pv,
      tooltipData: {
        'Gross profit': sumOtherCosts,
        'Take rate': null,
      },
    });
  }
  // Add gross profit to watefall chart
  filteredData?.push({
    name: 'Total',
    value: allSummaryData?.grossProfit?.value ?? 0,
    transparentValue: 0,
    tooltipData: {
      'Gross profit': allSummaryData?.grossProfit,
      'Take rate': allSummaryData?.takeRate,
    },
  });

  return (
    <>
      {/* Legend */}
      <div className="w-full flex justify-end pt-4">
        <div className="flex flex-row gap-2">
          <div className="flex flex-row gap-2 items-center">
            <div
              className="h-2 w-2 rounded-md"
              style={{ backgroundColor: '#22C55E' }}
            />
            <span className="text-sm text-slate-700">Positive</span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div
              className="h-2 w-2 rounded-md"
              style={{ backgroundColor: '#DC2626' }}
            />
            <span className="text-sm text-slate-700">Negative</span>
          </div>

          <div className="flex flex-row gap-2 items-center">
            <div
              className="h-2 w-2 rounded-md"
              style={{ backgroundColor: '#3B82F6' }}
            />
            <span className="text-sm text-slate-700">Monthly gross profit</span>
          </div>
        </div>
      </div>
      {/* Chart */}
      <div className="w-full h-[300px]">
        <Waterfall
          data={filteredData}
          currency={currency}
          TooltipComponent={GrossProfitAndTakeRateTooltip}
        />
      </div>
    </>
  );
}
