import { ChartBarSquareIcon } from '@heroicons/react/24/outline';
import { isNil } from 'lodash';
import { Legend } from 'src/components/graphs/helpers';
import StackedBarChart, { Datum } from 'src/components/graphs/StackedBarChart';
import { classNames } from 'src/dashboard/App';
import { formatCurrency } from 'src/dashboard/utils';
import {
  PenguinPricingFlowWithProductVolumes,
  PenguinProductPrices,
} from '../../types';
import {
  calculateFinalMonthlyCost,
  getVolumeForEachTier,
} from './PenguinQuoteTable';

type MonthlySpendChartProps = {
  pricingFlow: PenguinPricingFlowWithProductVolumes;
  productPrices: PenguinProductPrices;
};

export function MonthlySpendChart(props: MonthlySpendChartProps) {
  const { pricingFlow, productPrices } = props;
  const monthlyMinimumAtScale =
    pricingFlow.manualQuote?.monthlyMinimum ??
    pricingFlow.recommendedQuote?.monthlyMinimum;
  const monthlyMinimumAtScaleDecimals =
    monthlyMinimumAtScale.toString().split('.')[1]?.length ?? 0;

  const formatPrice = (
    price: number,
    isPercentPricing: boolean,
    numDecimals: number,
  ) => {
    if (price == null) {
      return 'N/A';
    }
    if (isPercentPricing === true) {
      return (
        price.toLocaleString('en-US', {
          minimumFractionDigits: numDecimals,
        }) + '%'
      );
    } else {
      return formatCurrency({
        amount: price,
        currency: 'USD',
        rounding: true,
        minimumFractionDigits: numDecimals,
      });
    }
  };
  const formattedMonthlyMinimumAtScale = formatPrice(
    monthlyMinimumAtScale,
    false,
    monthlyMinimumAtScaleDecimals,
  );

  const productsExcludingSupportAndImplServices = pricingFlow.products.filter(
    (p) => {
      const productPrice = productPrices[p.id];
      return (
        !isNil(productPrice) &&
        !['Support', 'Implementation Services', 'Partnerships'].includes(
          productPrice.skuGroup,
        )
      );
    },
  );

  return (
    <div className="flex flex-col gap-8 pt-12 px-8">
      <div className="flex flex-col gap-2">
        <div className="text-lg text-gray-900 font-semibold">
          Estimated monthly spend
        </div>
      </div>
      <div className="flex flex-row max-[950px]:flex-col gap-12">
        <div className="">
          <table className="divide-y divide-gray-300">
            <thead>
              <tr>
                <th className="min-w-[120px] border-b text-left text-xs font-medium text-gray-700 h-full"></th>
                <th className="bg-gray-50 border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 whitespace-nowrap">
                  No commitment
                </th>
                <th
                  className="border-b px-6 py-3.5 bg-gray-50 text-left text-xs font-medium text-gray-700 whitespace-nowrap"
                  style={{
                    borderTop: '1px solid #71A3F4', // blue-400
                    borderLeft: '1px solid #71A3F4', // blue-400
                    borderRight: '1px solid #71A3F4', // blue-400
                  }}
                >
                  {formattedMonthlyMinimumAtScale} commitment
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {productsExcludingSupportAndImplServices.map((product, i) => {
                const quotePrice =
                  pricingFlow.manualQuote?.products?.[product.id] ??
                  pricingFlow.recommendedQuote?.products?.[product.id] ??
                  0;
                const displayedQuotePrice = (() => {
                  if (quotePrice.type === 'tiered') {
                    const volumes = getVolumeForEachTier({
                      tiers: quotePrice.tiers,
                      volume: product.volume,
                    });
                    // get the index of the LAST volume that's non-zero, this is the estimated tier
                    const lastNonZeroVolumeIndex = volumes.reduce(
                      (acc, volume, index) => {
                        return volume > 0 ? index : acc;
                      },
                      0,
                    );
                    const estimatedTier =
                      quotePrice.tiers[lastNonZeroVolumeIndex];
                    return estimatedTier.price;
                  } else {
                    return quotePrice.price;
                  }
                })();

                const productData =
                  pricingFlow.pricingSheetData.countryPricingSheets.us
                    .productInfo[product.id];
                const pricingData =
                  pricingFlow.currentPricingCurves[product.id]
                    ?.pricingInformation;
                const stickerPrice = pricingData?.selfServe;
                return (
                  <tr key={product.id}>
                    <td
                      className={classNames(
                        'text-sm font-medium text-gray-900 px-6 py-2.5 text-left',
                      )}
                    >
                      {product.name}
                    </td>
                    <td className="text-sm font-medium text-gray-900 px-6 py-2.5 text-left">
                      {formatPrice(
                        stickerPrice,
                        productData?.priceType === 'percent',
                        2,
                      )}
                    </td>
                    <td
                      className={classNames(
                        'text-sm font-medium text-gray-900 px-6 py-2.5 text-left border-l border-blue-400 border-r',
                        i ===
                          productsExcludingSupportAndImplServices.length - 1 &&
                          'border-b',
                      )}
                    >
                      {formatPrice(
                        displayedQuotePrice,
                        productData?.priceType === 'percent',
                        2,
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="w-full max-w-[750px]">
          <Chart
            pricingFlow={pricingFlow}
            formattedMonthlyMinimumAtScale={formattedMonthlyMinimumAtScale}
          />
        </div>
      </div>
    </div>
  );
}

function Chart(props: {
  pricingFlow: PenguinPricingFlowWithProductVolumes;
  formattedMonthlyMinimumAtScale: string;
}) {
  const { pricingFlow, formattedMonthlyMinimumAtScale } = props;
  const monthlySpendAtScale = calculateFinalMonthlyCost({
    products: pricingFlow.products,
    recommendedQuote: pricingFlow.recommendedQuote,
    manualQuote: pricingFlow.manualQuote,
    productPrices:
      pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo,
    ignoreProfessionalServices: true,
  });
  const stickerSpendAtScale = calculateFinalMonthlyCost({
    products: pricingFlow.products,
    recommendedQuote: pricingFlow.recommendedQuote,
    manualQuote: pricingFlow.manualQuote,
    productPrices:
      pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo,
    ignoreProfessionalServices: true,
    useSelfServeInsteadOfManualPrice: true,
  });
  const savings = stickerSpendAtScale - monthlySpendAtScale;
  const maxSpend = Math.max(stickerSpendAtScale, monthlySpendAtScale);
  const barSpacer = maxSpend * 0.01;
  const groups: Datum[] = [
    {
      name: 'No commitment',
      topBar: 0,
      middleTransparentBar: 0,
      bottomBar: stickerSpendAtScale,
      tooltipData: {},
    },
    {
      name: `${formattedMonthlyMinimumAtScale} commitment`,
      topBar: savings > 0 ? savings - barSpacer : 0, // You need to subtract barSpacer so the middle bar has some room
      middleTransparentBar: savings > 0 ? barSpacer : 0,
      bottomBar: monthlySpendAtScale,
      tooltipData: {},
    },
  ];
  return (
    <div className="w-full border border-gray-200 rounded-xl">
      <div className="justify-between flex flex-row px-6 py-4">
        <div className="font-semibold text-gray-900 flex flex-row gap-1 items-center">
          <ChartBarSquareIcon width={20} height={20} />
          Monthly usage spend
        </div>
        <Legend
          mappings={[
            { label: 'Savings', color: '#22C55E' },
            { label: 'Spend', color: '#3B82F6' },
          ]}
        />
      </div>
      <div className="w-full h-[300px]">
        <StackedBarChart
          data={groups}
          TooltipComponent={() => null}
          currency={'USD'}
        />
      </div>
    </div>
  );
}
