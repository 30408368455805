import BottomBar from '../../Penguin/Components/BottomBar';
import { usePricingFlowContext } from '../../PricingFlow';
import AlpacaQuoteTable_NEW from '../AlpacaQuoteTable_NEW';
import AlpacaHeader from '../Components/AlpacaHeader';
import AlpacaHelpButton from '../Components/AlpacaHelpButton';
import AlpacaOpportunityInfoButton from '../Components/AlpacaOpportunityInfoButton';
import { AlpacaPricingFlow, AlpacaProductPrice } from '../alpaca_types';
import { getProductsWithUpdatedCollections } from './Collections';

export default function GlobalAccounts(props: {
  nextStep: () => void;
  previousStep: () => void;
}) {
  const { nextStep, previousStep } = props;
  const { updateFlow, pricingFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();

  return (
    <div id="GlobalAccounts">
      {/* Header */}
      <AlpacaHeader
        title="Global Accounts"
        rightChildren={[
          <AlpacaOpportunityInfoButton />,
          <AlpacaHelpButton
            url={
              'https://airwallex.atlassian.net/wiki/spaces/SBAH/pages/2583330888/Sales+Play+Global+Accounts+Wallets'
            }
          />,
        ]}
      />

      <div className="px-8 py-4">
        <div className="pt-2">
          <AlpacaQuoteTable_NEW
            pricingFlow={pricingFlow as AlpacaPricingFlow}
            skuFilter={(price: AlpacaProductPrice) =>
              price.skuGroup === 'Global Accounts'
            }
            productSort={(a, b) => {
              // The first two characters of the name are the country code
              return a.name.localeCompare(b.name);
            }}
            productPriceSort={(a, b) => {
              return a.name.localeCompare(b.name);
            }}
            updateFlow={(newPricingFlow) => {
              const products = getProductsWithUpdatedCollections({
                oldPricingFlow: pricingFlow,
                newPricingFlow,
              });

              updateFlow(
                {
                  ...newPricingFlow,
                  products,
                },
                false,
              );
            }}
            title={'Products'}
            categoryName={'Global Accounts'}
          />
        </div>
        <div className="h-16" />
        <BottomBar
          primaryButtonProps={{
            label: 'Next',
            onClick: async () => nextStep(),
          }}
          secondaryButtonProps={{
            label: 'Back',
            onClick: async () => previousStep(),
          }}
        />
      </div>
    </div>
  );
}
