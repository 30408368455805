import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import {
  ArrowTrendingUpIcon,
  ClockIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  TagIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import Badge, { BadgeColor } from 'src/components/Badge';
import { InlineSpinner } from 'src/components/Loading';
import { formatCurrency } from 'src/dashboard/utils';
import { classNames } from '../../../App';
import { usePricingFlowContext } from '../../PricingFlow';
import {
  PenguinOpportunityData,
  PenguinPricingFlow,
  PricingFlowCommon,
} from '../../types';

export function getOpportunityOverviewBarFields(
  penguinOpportunityData: PenguinOpportunityData,
): Array<{
  icon: React.FC<React.HTMLAttributes<SVGSVGElement>>;
  name: string;
  value: React.ReactNode;
}> {
  let penguinBadgeColor: BadgeColor = 'gray';
  switch (penguinOpportunityData.Opportunity_StageName) {
    case 'Closed Won':
      penguinBadgeColor = 'green';
      break;
    case 'Closed Lost':
      penguinBadgeColor = 'red';
      break;
  }

  return [
    {
      icon: UserIcon,
      name: 'Owner',
      value: penguinOpportunityData.User_Name ?? '–',
    },
    {
      icon: TagIcon,
      name: 'GTM segment',
      value: penguinOpportunityData.Opportunity_GTM_Segment__c ?? '–',
    },
    {
      icon: ArrowTrendingUpIcon,
      name: 'Type',
      value: penguinOpportunityData.Opportunity_Type ?? '–',
    },
    {
      icon: ClockIcon,
      name: 'Stage',
      value: penguinOpportunityData.Opportunity_StageName ? (
        <Badge color={penguinBadgeColor}>
          {penguinOpportunityData.Opportunity_StageName}
        </Badge>
      ) : (
        '–'
      ),
    },
    {
      icon: GlobeAltIcon,
      name: 'Product region',
      value: penguinOpportunityData.Opportunity_Product_Region__c ?? '–',
    },
    {
      icon: CurrencyDollarIcon,
      name: 'Qualified ACV',
      value: formatCurrency({
        amount: penguinOpportunityData.Opportunity_Qualified_ACV__c ?? 0,
        currency: 'USD',
      }),
    },
  ];
}

export function getOpportunitySFDCUrl(pricingFlow: PricingFlowCommon): string {
  return (
    pricingFlow.sfdcInstanceUrl +
    '/lightning/r/Opportunity/' +
    pricingFlow.sfdcOpportunityId +
    '/view'
  );
}

export default function OpportunityOverviewBar(props: {
  opportunityData: PricingFlowCommon['opportunityData'];
  stretchWidth?: boolean;
  loading?: boolean;
}) {
  const { loading, opportunityData } = props;
  const { pricingFlow } = usePricingFlowContext<PenguinPricingFlow>();

  const penguinOpportunityData: PenguinOpportunityData =
    opportunityData as PenguinOpportunityData;

  const fields = getOpportunityOverviewBarFields(penguinOpportunityData);

  return (
    <div className="relative z-40 w-64 border-r border-gray-200 bg-white text-xs font-medium">
      <div className="flex-grow overflow-auto pb-28">
        <div className="my-4 line-clamp-5 px-6 text-xl hover:line-clamp-none">
          {pricingFlow.sfdcOpportunityName}
        </div>
        <div className="bg-gray-100 px-6 py-2 text-gray-500">DETAILS</div>
        <div className="flex flex-wrap items-center divide-y">
          {fields.map(
            (field: { icon: any; name: string; value: any }, i: number) => {
              return (
                <div
                  key={i}
                  className="ml-6 flex w-full items-center gap-2 py-4 pr-4 "
                >
                  <div className="flex h-10 w-10 justify-center">
                    <field.icon
                      className="m-2 text-gray-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="whitespace-nowrap uppercase text-gray-400">
                      {field.name}
                    </div>
                    <div
                      className={classNames(
                        loading ? 'opacity-0' : 'opacity-100',
                        'text-md whitespace-nowrap text-gray-600 transition-opacity duration-500 ease-in-out',
                      )}
                    >
                      {field.value}
                    </div>
                    {loading && <InlineSpinner />}
                  </div>
                </div>
              );
            },
          )}
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 flex h-20 flex-row items-center justify-center border-t bg-white">
        <a
          href={getOpportunitySFDCUrl(pricingFlow)}
          target="_blank"
          rel="noopener noreferrer"
          className="col-span-full inline-flex"
        >
          <button className="flex inline-flex items-center  justify-center gap-2 rounded-lg border border-gray-200 px-5 py-2 font-semibold text-gray-700 shadow-sm hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900">
            Open in SFDC
            <ArrowTopRightOnSquareIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        </a>
      </div>
    </div>
  );
}
