// TODO(george) once we start making real curves, we'll need to figure out what
// the right inputs are for these functions
const pricingCurveRegistry: Record<string, () => boolean> = {
  georgeTestCurve: () => {
    return true;
  },
  default: () => {
    return true;
  },
};

export default pricingCurveRegistry;
