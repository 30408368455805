import { datadogRum } from '@datadog/browser-rum';
import { isEqual } from 'lodash';
import { AlpacaSupportedCurrency } from './alpaca_types';

interface CurrencyValueCommon {
  type: CurrencyValueType;
}

export enum CurrencyValueType {
  FLAT = 'flat',
  PERCENT = 'percent',
  FLAT_AND_PERCENT = 'flat_and_percent',
}

export type DerivedValue<T extends CurrencyValue | Count> = T & {
  // The concept behind the calculation, e.g. "only applies to apple pay"
  concept?: string | null;
  // The numbers that went into the calculation, e.g. "33 / 100"
  provenance: string;
};
export interface CurrencyValueFlat extends CurrencyValueCommon {
  type: CurrencyValueType.FLAT;
  currency: AlpacaSupportedCurrency;
  value: number;
}

export interface CurrencyValuePercent extends CurrencyValueCommon {
  type: CurrencyValueType.PERCENT;
  value: number;
}

export interface CurrencyValueFlatAndPercent extends CurrencyValueCommon {
  type: CurrencyValueType.FLAT_AND_PERCENT;
  currency: AlpacaSupportedCurrency;
  flat: number;
  percent: number;
}

export type CurrencyValue =
  | CurrencyValueFlat
  | CurrencyValuePercent
  | CurrencyValueFlatAndPercent;

export type Count = { type: 'count'; value: number };

export type Minimum = Count | CurrencyValueFlat;
export interface Tier<
  CV extends CurrencyValue = CurrencyValue,
  M extends Minimum = Minimum,
> {
  currencyValue: CV;
  minimum: M;
}

export interface CurrencyValueTiered<
  CV extends CurrencyValue = CurrencyValue,
  M extends Minimum = Minimum,
> {
  type: 'tiered';
  minimumType: Minimum['type'];
  tiers: Tier<CV, M>[];
}
export type QuotePrice<
  T extends CurrencyValue | CurrencyValueTiered =
    | CurrencyValue
    | CurrencyValueTiered,
> = (CurrencyValue | CurrencyValueTiered) & T;

export function isQuotePriceEqual(a: QuotePrice, b: QuotePrice): boolean {
  if (a.type !== b.type) {
    return false;
  }
  if (a.type === 'tiered' || b.type === 'tiered') {
    if (a.type === 'tiered' && b.type === 'tiered') {
      if (a.tiers.length !== b.tiers.length) {
        return false;
      }
      for (let i = 0; i < a.tiers.length; i++) {
        if (
          !isCurrencyValueEqual(
            a.tiers[i].currencyValue,
            b.tiers[i].currencyValue,
          )
        ) {
          return false;
        }
        if (!isEqual(a.tiers[i].minimum, b.tiers[i].minimum)) {
          return false;
        }
      }
      return true;
    }
    return false;
  } else {
    return isCurrencyValueEqual(a, b);
  }
}

export function isCurrencyValueEqual(
  a: CurrencyValue,
  b: CurrencyValue,
): boolean {
  if (a.type !== b.type) {
    return false;
  }
  switch (a.type) {
    case CurrencyValueType.FLAT:
      return (
        a.currency === (b as CurrencyValueFlat).currency &&
        a.value === (b as CurrencyValueFlat).value
      );
    case CurrencyValueType.PERCENT:
      return a.value === (b as CurrencyValuePercent).value;
    case CurrencyValueType.FLAT_AND_PERCENT:
      return (
        a.currency === (b as CurrencyValueFlatAndPercent).currency &&
        a.flat === (b as CurrencyValueFlatAndPercent).flat &&
        a.percent === (b as CurrencyValueFlatAndPercent).percent
      );
    default:
      const typecheck: never = a;
      datadogRum.addError(`unexpected currency value ${typecheck}`);
      return false;
  }
}

export function ZERO_FLAT_AND_PERCENT(
  currency: AlpacaSupportedCurrency,
): CurrencyValueFlatAndPercent {
  return {
    flat: 0,
    percent: 0,
    currency,
    type: CurrencyValueType.FLAT_AND_PERCENT,
  };
}

export function ZERO_FLAT(currency: AlpacaSupportedCurrency) {
  return { currency, type: CurrencyValueType.FLAT as const, value: 0 };
}
export const ZERO_PERCENT = {
  type: CurrencyValueType.PERCENT as const,
  value: 0,
};
export const ZERO_COUNT = { type: 'count' as const, value: 0 };
