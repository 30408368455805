import { Transition } from '@headlessui/react';
import { ChevronUpIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import {
  Label,
  NumberField,
  RadioButtonSelectField,
  SelectField,
  TextField,
} from 'src/components/Fields';
import {
  AlpacaAdditionalData,
  AlpacaSupportedCurrency,
} from './Alpaca/alpaca_types';
import {
  DealopsAdditionalData,
  PenguinAdditionalData,
  PricingFlowCommon,
  PricingFlowType,
} from './types';

export const DealOverviewForm = (props: {
  pricingFlow: PricingFlowCommon;
  updateFlow: (flow: PricingFlowCommon, showLoading?: boolean) => void;
  type: PricingFlowCommon['type'];
}) => {
  const { pricingFlow, updateFlow, type } = props;
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const updateAdditionalData = (
    additionalData: PricingFlowCommon['additionalData'],
  ) => {
    updateFlow(
      {
        ...pricingFlow,
        additionalData,
      },
      false,
    );
  };

  let form: JSX.Element | null = null;
  switch (type) {
    case PricingFlowType.DEALOPS:
      form = (
        <DealopsOverviewForm
          additionalData={pricingFlow.additionalData as DealopsAdditionalData}
          updateAdditionalData={updateAdditionalData}
        />
      );
      break;
    case PricingFlowType.PENGUIN:
      form = (
        <PenguinOverviewForm
          additionalData={pricingFlow.additionalData as PenguinAdditionalData}
          updateAdditionalData={updateAdditionalData}
        />
      );
      break;
    case PricingFlowType.ALPACA:
      form = (
        <AlpacaOverviewForm
          additionalData={pricingFlow.additionalData as AlpacaAdditionalData}
          updateAdditionalData={updateAdditionalData}
        />
      );
      break;
    default:
      form = (
        <div>Form not implemented – unsupported pricing flow type {type}</div>
      );
      break;
  }

  return (
    <div className="mt-8 w-full rounded-lg border border-gray-200 px-4 sm:px-6 lg:px-8">
      <div
        className="flex flex-row items-center justify-between py-4 text-sm font-semibold text-fuchsia-900"
        onClick={() => {
          setShowAdvancedSettings(!showAdvancedSettings);
        }}
      >
        <div className="flex flex-row">
          <Cog6ToothIcon className="mr-2 w-5" aria-hidden="true" />
          Advanced Settings
        </div>
        <ChevronUpIcon
          className={`w-5 text-gray-900 ${
            !showAdvancedSettings && 'rotate-180'
          }`}
          aria-hidden="true"
        />
      </div>

      <Transition
        className="overflow-hidden"
        show={showAdvancedSettings}
        enter="transition-all duration-300"
        enterFrom="max-h-0 opacity-0"
        enterTo="max-h-[48rem] opacity-100"
        leave="transition-all duration-300"
        leaveFrom="max-h-[48rem] opacity-100"
        leaveTo="max-h-0 opacity-0"
      >
        {form}
      </Transition>
    </div>
  );
};

export const DealopsOverviewForm = (props: {
  additionalData: DealopsAdditionalData;
  updateAdditionalData: (additionalData: DealopsAdditionalData) => void;
}) => {
  const { additionalData, updateAdditionalData } = props;

  return (
    <div className="mt-4 grid grid-cols-2 gap-x-4 gap-y-8 pb-10">
      <div className="col-span-full text-sm text-gray-600">
        Providing these details can help you get better pricing on this deal.
      </div>
      <div className="col-span-full">
        <Label id="monthly_budget">Monthly budget</Label>
        <div className="col-span-full flex appearance-none flex-row items-center justify-between rounded-md border border-gray-300 px-3 py-0 text-gray-900 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm">
          <div className="flex w-full items-center">
            <span className="items-center">$</span>
            <NumberField
              id="monthly_budget"
              name="monthly_budget"
              type="number"
              value={
                additionalData?.monthlyBudget
                  ? additionalData.monthlyBudget.toString()
                  : undefined
              }
              required={true}
              onChange={(e) => {
                updateAdditionalData({
                  ...additionalData,
                  monthlyBudget: parseFloat(e.target.value),
                });
              }}
              className="flex-grow border-0 pl-1 text-gray-900 focus:outline-none focus:ring-0 focus:ring-offset-0 sm:text-sm"
            />
          </div>
          <select
            id="currency"
            className="focus:outine-none border-0 py-0 text-gray-900 focus:ring-0 focus:ring-offset-0 sm:text-sm"
          >
            <option value="usd">USD</option>
            <option value="usd">CAD</option>
          </select>
        </div>
      </div>

      <SelectField
        label="Contract length"
        name="contract_length"
        className="col-span-1"
      >
        <option value="1 year">1 year</option>
        <option value="2 years">2 years</option>
        <option value="3 years">3 years</option>
      </SelectField>

      <SelectField
        label="Preferred payment terms"
        name="preferred_payment_terms"
        className="col-span-1"
      >
        <option value={'N-15'}>N-15</option>
        <option value={'N-30'}>N-30</option>
        <option value={'N-60'}>N-60</option>
        <option value={'N-90'}>N-90</option>
      </SelectField>

      <RadioButtonSelectField
        label="Is the customer open to minimum monthly spend?"
        name="monthly_minimum_spend"
        onChange={() => {}}
        className="col-span-full"
        options={[
          { id: 'monthly_minimum_spend_yes', title: 'Yes' },
          { id: 'monthly_minimum_spend_no', title: 'No' },
          {
            id: 'monthly_minimum_spend_i_dont_know',
            title: "I don't know",
          },
        ]}
      />

      <RadioButtonSelectField
        label="Will the customer give us marketing rights?"
        name="marketing_rights"
        onChange={() => {}}
        className="col-span-full"
        options={[
          { id: 'marketing_rights_yes', title: 'Yes' },
          { id: 'marketing_rights_no', title: 'No' },
          { id: 'marketing_rights_i_dont_know', title: "I don't know" },
        ]}
      />

      <TextField
        label={'Competitors'}
        name={'competitors'}
        type={'text'}
        className="col-span-full"
      />
    </div>
  );
};

export const PenguinOverviewForm = (props: {
  additionalData: PenguinAdditionalData;
  updateAdditionalData: (additionalData: PenguinAdditionalData) => void;
}) => {
  const { additionalData, updateAdditionalData } = props;

  return (
    <div className="mt-4 grid grid-cols-2 gap-x-4 gap-y-8 pb-10">
      <div className="col-span-full text-sm text-gray-600">
        Providing these details can help you get better pricing on this deal.
      </div>
      {/* @TODO(fay) implement competitors input */}
      <TextField
        label={'Competitors'}
        name={'competitors'}
        type={'text'}
        className="col-span-full"
      />
    </div>
  );
};

export const AlpacaOverviewForm = (props: {
  additionalData: AlpacaAdditionalData;
  updateAdditionalData: (additionalData: AlpacaAdditionalData) => void;
}) => {
  const { additionalData, updateAdditionalData } = props;

  return (
    <div className="mt-4 grid grid-cols-2 gap-x-4 gap-y-8 pb-10">
      <div className="col-span-full text-sm text-gray-600">
        Providing these details can help you get better pricing on this deal.
      </div>

      <SelectField
        label="Currency"
        name="currency"
        value={additionalData?.quoteCurrency ?? 'USD'}
        onChange={(event) => {
          updateAdditionalData({
            ...additionalData,
            quoteCurrency: event.target.value as AlpacaSupportedCurrency,
          });
        }}
        className="col-span-full"
      >
        <option value="USD">USD</option>
        <option value="AUD">AUD</option>
      </SelectField>
    </div>
  );
};
