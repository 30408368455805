import { datadogRum } from '@datadog/browser-rum';
import {
  ArrowTopRightOnSquareIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import api from 'src/api';
import { Spinner } from 'src/components/Loading';
import { useToast } from 'src/components/Toast';
import { ShareLink } from '../../ShareButton';
import { AlpacaPricingFlow } from '../alpaca_types';

type FinalizeQuoteStage = 'manager_approval' | 'update_salesforce' | 'congrats';

type AlpacaFinalizeQuoteModalProps = {
  close: () => void;
  pricingFlow: AlpacaPricingFlow;
};

// @TODO(fay) we probably want to keep state once we have the sfdc integration done
export default function AlpacaFinalizeQuoteModal(
  props: AlpacaFinalizeQuoteModalProps,
) {
  const [stage, setStage] = useState<FinalizeQuoteStage>('manager_approval');
  const { close, pricingFlow } = props;

  switch (stage) {
    case 'manager_approval':
      return (
        <ManagerApproval
          close={close}
          nextStage={() => setStage('update_salesforce')}
        />
      );
    case 'update_salesforce':
      return (
        <UpdateSalesforce
          close={close}
          nextStage={() => setStage('congrats')}
          pricingFlow={pricingFlow}
        />
      );
    case 'congrats':
      return <Congrats close={close} />;
    default:
      const typecheck: never = stage;
      datadogRum.addError(`finalize quote stage ${typecheck}`);
      return null;
  }
}

function ManagerApproval(props: { close: () => void; nextStage: () => void }) {
  return (
    <div className="mt-4 mx-[-24px] mb-[-24px]">
      <div className="bg-gray-100 px-6 py-2 text-xs font-medium text-gray-500 uppercase">
        Step 1 – Manager approval
      </div>

      <div className="px-6">
        <div className="text-sm text-slate-600 py-4">
          Copy the link and send it to your manager.
        </div>

        <ShareLink url={window.location.href} />

        {/* Warning */}
        <div className="rounded-md bg-orange-50 p-4 mt-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-orange-700"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-orange-800">
                Ensure you have received approval from your manager before
                proceeding to step 2.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className="flex flex-row justify-between pt-4 mt-6 border-t border-100 gap-4">
        <button
          type="button"
          className="rounded-md flex-1 bg-white ml-6 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={props.close}
        >
          Close
        </button>
        <button
          type="button"
          className="rounded-md flex-1 bg-white mr-6 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={props.nextStage}
        >
          I already have approval
        </button>
      </div>
    </div>
  );
}

function UpdateSalesforce(props: {
  close: () => void;
  nextStage: () => void;
  pricingFlow: AlpacaPricingFlow;
}) {
  const { close, nextStage, pricingFlow } = props;
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const updateSfdc = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        'quotes?pricingFlowId=' + pricingFlow.id,
        {
          currentPricingCurves: pricingFlow.currentPricingCurves,
        },
      );
      const url = `${pricingFlow.sfdcInstanceUrl}/lightning/r/Opportunity/${pricingFlow.sfdcOpportunityId}/view`;
      window.open(url, '_blank');
      nextStage();
    } catch (error) {
      datadogRum.addError(error);
      console.log(error);
      showToast({
        title: 'Error: could not update opportunity in SFDC',
        subtitle:
          'Something went wrong, please contact us at support@dealops.com.',
        type: 'error',
        autoDismiss: false,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="mt-4 mx-[-24px] mb-[-24px] ">
      <div className="bg-gray-100 px-6 py-2 text-xs font-medium text-gray-500 uppercase">
        Step 2 – Update Salesforce
      </div>

      <div className="px-6 pb-24">
        <div className="text-sm text-slate-600 py-4">
          Dealops will update the Salesforce Opportunity with the products and
          gross monthly profit from this quote.
        </div>
      </div>

      {/* Buttons */}
      <div className="flex flex-row justify-between pt-4 mt-6 border-t border-100 gap-4">
        <button
          type="button"
          className="rounded-md flex-1 bg-white ml-6 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={close}
        >
          Close
        </button>
        <button
          type="button"
          className="rounded-md flex-1 bg-fuchsia-900 mr-6 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-fuchsia-800"
          onClick={updateSfdc}
        >
          {loading ? (
            <div className="flex flex-row justify-center">
              <Spinner height={24} width={24} />
            </div>
          ) : (
            `Update SFDC Opportunity`
          )}
        </button>
      </div>
    </div>
  );
}

function Congrats(props: { close: () => void }) {
  return (
    <div className="mt-4 mx-[-24px] mb-[-24px]">
      <div className="px-6">
        {/* Emoji */}
        <div className="text-slate-800 text-xl w-full flex flex-col items-center justify-center gap-6">
          <span className="text-[56px]">😍</span>
          <span>Congrats on pricing this deal!</span>
        </div>

        {/* Notice */}
        <div className="rounded-md bg-slate-50 p-4 mt-6">
          <div className="flex">
            <div className="flex-shrink-0">🎯</div>
            <div className="ml-3 flex-1 flex-col flex gap-2">
              <p className="text-sm text-slate-600">
                Once the customer has accepted the proposal and completed KYC,
                create a pricing config for this quote at the following link and
                upload to Salesforce:
              </p>
              <a
                href=""
                target="_blank"
                className="text-sm text-fuchsia-950 hover:text-fuchsia-900 flex flex-row gap-2 items-center"
              >
                <span className="font-semibold">Config link</span>{' '}
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className="flex flex-row justify-between pt-4 mt-6 border-t border-100 gap-4">
        <button
          type="button"
          className="rounded-md flex-1 bg-white mx-6 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={props.close}
        >
          Close
        </button>
      </div>
    </div>
  );
}
