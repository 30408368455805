import { useRef, useState } from 'react';
import { FormattedNumberField } from 'src/components/Fields';
import useContextMenu from 'src/components/useContextMenu';
import EditableIndicator from '../../Penguin/Components/EditableIndicator';
import {
  CurrencyValue,
  CurrencyValueType,
  Minimum,
  QuotePrice,
} from '../alpaca_price_types';
import {
  ALPACA_CURRENCY_SYMBOLS,
  AlpacaSupportedCurrency,
} from '../alpaca_types';
import {
  AlpacaQuotePriceEditable_TieredDetails,
  TierConfig,
} from './AlpacaQuotePriceEditable_TieredDetails';
import AlpacaWarningPopup from './AlpacaWarningPopup';

export type AlpacaQuotePriceLimit = {
  flat: number | null;
  percent: number | null;
};

export const QuotePriceEditable = (props: {
  quotePrice: QuotePrice;
  updateQuotePrice: (newQuotePrice: QuotePrice | null) => void;
  validPriceTypes: CurrencyValueType[];
  // Generally the component will display currency based on the
  // quotePrice.currency. However if the quotePrice doesn't currently have a
  // currency then if you want to switch to a type that requires a currency, we
  // will use this quoteCurrency
  quoteCurrency: AlpacaSupportedCurrency;
  stickerPrice: CurrencyValue;
  cost: CurrencyValue;
  productName: string;
  // limit the values that the user can enter
  limit?: AlpacaQuotePriceLimit | null;
  tierable?: boolean;
  validTierMinimumTypes: Minimum['type'][]; // Valid minimum types for tiered pricing
  tierConfig?: Partial<TierConfig>;
}) => {
  const {
    quotePrice,
    updateQuotePrice,
    validPriceTypes,
    quoteCurrency,
    stickerPrice,
    cost,
    limit,
    productName,
    validTierMinimumTypes,
    tierConfig,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      const currentRow = inputRef.current?.closest('tr');
      if (currentRow) {
        const nextRow = currentRow.nextElementSibling as HTMLElement;
        if (nextRow) {
          const nextInput = nextRow.querySelector<HTMLInputElement>(
            'input[data-quote-price-editable]',
          );
          if (nextInput) {
            nextInput.focus();
          }
        } else {
          inputRef.current?.blur();
        }
      }
    }
  };

  const tierable = props.tierable ?? true;

  const [isPercentExceeding100, setIsPercentExceeding100] =
    useState<boolean>(false);

  const { clicked, setClicked } = useContextMenu();

  const handleChangeQuotePriceType = (quotePriceType: QuotePrice['type']) => {
    // Handle menu item click here
    switch (quotePrice.type) {
      case CurrencyValueType.FLAT:
        switch (quotePriceType) {
          case CurrencyValueType.PERCENT:
            updateQuotePrice({
              ...quotePrice,
              type: CurrencyValueType.PERCENT,
            });
            break;
          case CurrencyValueType.FLAT_AND_PERCENT:
            updateQuotePrice({
              ...quotePrice,
              type: quotePriceType,
              flat: quotePrice.value,
              percent: 0,
            });
            break;
          case 'tiered':
            updateQuotePrice({
              type: quotePriceType,
              minimumType: CurrencyValueType.FLAT,
              tiers: [
                {
                  currencyValue: {
                    type: CurrencyValueType.FLAT,
                    value: quotePrice.value,
                    currency: quotePrice.currency,
                  },
                  minimum: {
                    type: CurrencyValueType.FLAT,
                    value: 0,
                    currency: quoteCurrency,
                  },
                },
              ],
            });
            break;
        }
        break;
      case CurrencyValueType.PERCENT:
        switch (quotePriceType) {
          case CurrencyValueType.FLAT:
            updateQuotePrice({
              ...quotePrice,
              type: quotePriceType,
              currency: quoteCurrency,
            });
            break;
          case CurrencyValueType.FLAT_AND_PERCENT:
            updateQuotePrice({
              ...quotePrice,
              type: quotePriceType,
              flat: 0,
              percent: quotePrice.value,
              currency: quoteCurrency,
            });
            break;
          case 'tiered':
            updateQuotePrice({
              type: quotePriceType,
              minimumType: CurrencyValueType.FLAT,
              tiers: [
                {
                  currencyValue: {
                    type: CurrencyValueType.PERCENT,
                    value: quotePrice.value,
                  },
                  minimum: {
                    type: CurrencyValueType.FLAT,
                    value: 0,
                    currency: quoteCurrency,
                  },
                },
              ],
            });
            break;
        }
        break;
      case CurrencyValueType.FLAT_AND_PERCENT:
        switch (quotePriceType) {
          case CurrencyValueType.FLAT:
            updateQuotePrice({
              ...quotePrice,
              type: quotePriceType,
              value: quotePrice.flat,
            });
            break;
          case CurrencyValueType.PERCENT:
            updateQuotePrice({
              ...quotePrice,
              type: quotePriceType,
              value: quotePrice.percent,
            });
            break;
          case 'tiered':
            updateQuotePrice({
              type: quotePriceType,
              minimumType: CurrencyValueType.FLAT,
              tiers: [
                {
                  currencyValue: {
                    type: CurrencyValueType.FLAT_AND_PERCENT,
                    flat: quotePrice.flat,
                    percent: quotePrice.percent,
                    currency: quotePrice.currency,
                  },
                  minimum: {
                    type: CurrencyValueType.FLAT,
                    value: 0,
                    currency: quoteCurrency,
                  },
                },
              ],
            });
            break;
        }
        break;
      case 'tiered':
        // If the quote price is tiered, you can only remove tiers first, before switching quotePrice.type
        updateQuotePrice(quotePrice.tiers[0].currencyValue);
        break;
      default:
        throw new Error(`Invalid quote price type: ${quotePrice}`);
    }
  };

  /**
   * Left click menu if tiered, for editing tiers
   */
  const [showTieredDetails, setShowTieredDetails] = useState(false);

  switch (quotePrice.type) {
    case CurrencyValueType.FLAT:
      return (
        <>
          <div
            onContextMenu={(e) => {
              e.preventDefault();
              setClicked(true);
            }}
          >
            <EditableIndicator>
              <FormattedNumberField
                type="text"
                value={quotePrice.value}
                numberDecimals={2}
                className="w-0 grow cursor-pointer border-none bg-transparent px-0 text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent pl-4 py-4"
                validateIncomingValue={(n: number) => {
                  if (limit?.flat) {
                    return limit.flat >= n;
                  }
                  return true;
                }}
                updateValue={(value: number) => {
                  updateQuotePrice({
                    ...quotePrice,
                    value,
                  });
                }}
                prefix={ALPACA_CURRENCY_SYMBOLS[quotePrice.currency]}
                ref={inputRef}
                data-quote-price-editable
                onKeyDown={handleKeyDown}
              />
            </EditableIndicator>
          </div>
          <ChangeQuotePriceTypeMenu
            handleChangeQuotePriceType={handleChangeQuotePriceType}
            shown={clicked}
            validPriceTypes={validPriceTypes}
            quotePrice={quotePrice}
            tierable={tierable}
          />
        </>
      );
    case CurrencyValueType.PERCENT:
      return (
        <>
          <div
            onContextMenu={(e) => {
              e.preventDefault();
              setClicked(true);
            }}
            className="relative"
          >
            <AlpacaWarningPopup
              message={'Percentage value exceeds 100%'}
              show={isPercentExceeding100}
              className="absolute p-2 z-50 -top-8 min-w-52 bg-gray-700 text-white text-xs rounded"
              onHide={() => {
                setIsPercentExceeding100(false);
              }}
            />
            <EditableIndicator>
              <FormattedNumberField
                type="text"
                value={quotePrice.value}
                numberDecimals={2}
                className="w-0 grow cursor-pointer border-none bg-transparent px-0 text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent  pl-4 py-4"
                validateIncomingValue={(n: number) => {
                  if (limit?.percent) {
                    return limit.percent >= n;
                  }
                  return true;
                }}
                updateValue={(value: number) => {
                  if (quotePrice.value !== value) {
                    // only check if exceeding on update
                    setIsPercentExceeding100(value > 100);
                  }
                  updateQuotePrice({
                    ...quotePrice,
                    value: value,
                  });
                }}
                suffix="%"
                ref={inputRef}
                data-quote-price-editable
                onKeyDown={handleKeyDown}
              />
            </EditableIndicator>
          </div>
          <ChangeQuotePriceTypeMenu
            handleChangeQuotePriceType={handleChangeQuotePriceType}
            shown={clicked}
            validPriceTypes={validPriceTypes}
            quotePrice={quotePrice}
            tierable={tierable}
          />
        </>
      );
    case CurrencyValueType.FLAT_AND_PERCENT:
      return (
        <>
          <div
            onContextMenu={(e) => {
              e.preventDefault();
              setClicked(true);
            }}
            className="relative"
          >
            <AlpacaWarningPopup
              message={'Percentage value exceeds 100%'}
              show={isPercentExceeding100}
              className="absolute p-2 -top-8 min-w-52 z-50 bg-gray-700 text-white text-xs rounded"
              onHide={() => {
                setIsPercentExceeding100(false);
              }}
            />
            <EditableIndicator>
              <FormattedNumberField
                type="text"
                value={quotePrice.flat}
                numberDecimals={2}
                // @TODO(fay) this grow-1.5 is a hack, it looks nice when the fixed part of the price is xx.xx or less
                className="w-0 grow-[1.5] cursor-pointer border-none bg-transparent px-0 text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent pl-4 py-4"
                validateIncomingValue={(n: number) => {
                  if (limit?.flat) {
                    return limit.flat >= n;
                  }
                  return true;
                }}
                updateValue={(value: number) => {
                  updateQuotePrice({
                    ...quotePrice,
                    flat: value,
                  });
                }}
                prefix={ALPACA_CURRENCY_SYMBOLS[quotePrice.currency]}
                ref={inputRef}
                data-quote-price-editable
                onKeyDown={handleKeyDown}
              />
              <FormattedNumberField
                type="text"
                value={quotePrice.percent}
                numberDecimals={2}
                className="w-0 grow-[2] cursor-pointer border-none bg-transparent px-0 text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent py-4"
                validateIncomingValue={(n: number) => {
                  if (limit?.percent) {
                    return limit.percent >= n;
                  }
                  return true;
                }}
                updateValue={(value: number) => {
                  if (quotePrice.percent !== value) {
                    // only check if exceeding on update
                    setIsPercentExceeding100(value > 100);
                  }
                  updateQuotePrice({
                    ...quotePrice,
                    percent: value,
                  });
                }}
                prefix="+ "
                suffix="%"
                ref={inputRef}
                data-quote-price-editable
                onKeyDown={handleKeyDown}
              />
            </EditableIndicator>
          </div>
          <ChangeQuotePriceTypeMenu
            handleChangeQuotePriceType={handleChangeQuotePriceType}
            shown={clicked}
            validPriceTypes={validPriceTypes}
            quotePrice={quotePrice}
            tierable={tierable}
          />
        </>
      );
    case 'tiered':
      return (
        <>
          <div
            onContextMenu={(e) => {
              e.preventDefault();
              setClicked(true);
            }}
            onClick={() => setShowTieredDetails(true)}
          >
            <EditableIndicator className="py-4 pl-4 h-full text-sm">
              Tiered
            </EditableIndicator>
          </div>
          <ChangeQuotePriceTypeMenu
            handleChangeQuotePriceType={handleChangeQuotePriceType}
            shown={clicked}
            validPriceTypes={validPriceTypes}
            quotePrice={quotePrice}
            tierable={tierable}
          />
          <AlpacaQuotePriceEditable_TieredDetails
            show={showTieredDetails}
            quotePrice={quotePrice}
            updateQuotePrice={updateQuotePrice}
            onClose={() => setShowTieredDetails(false)}
            stickerPrice={stickerPrice}
            cost={cost}
            limit={limit}
            productName={productName}
            quoteCurrency={quoteCurrency}
            validPriceTypes={validPriceTypes}
            validTierMinimumTypes={validTierMinimumTypes}
            tierConfig={tierConfig}
          />
        </>
      );
  }
};

function ChangeQuotePriceTypeMenu(props: {
  handleChangeQuotePriceType: (quotePriceType: QuotePrice['type']) => void;
  shown: boolean;
  validPriceTypes?: CurrencyValueType[];
  quotePrice: QuotePrice;
  tierable?: boolean;
}) {
  const {
    handleChangeQuotePriceType,
    shown,
    validPriceTypes,
    quotePrice,
    tierable,
  } = props;

  const CurrencyValueTypeNameMapping = {
    [CurrencyValueType.FLAT]: 'Flat',
    [CurrencyValueType.PERCENT]: 'Percent',
    [CurrencyValueType.FLAT_AND_PERCENT]: 'Flat and Percent',
  };
  const TIERED_LIST = [
    {
      name: 'Remove tiers',
      onClick: () => handleChangeQuotePriceType('tiered'),
    },
  ];
  const validPriceTypeOptions = (validPriceTypes ?? [])
    .filter((type) => type !== quotePrice.type)
    .map((quotePriceType) => ({
      name: CurrencyValueTypeNameMapping[quotePriceType],
      onClick: () => handleChangeQuotePriceType(quotePriceType),
    }));
  let VALID_PRICE_TYPE_LIST: { name: string; onClick: () => void }[] = [
    ...validPriceTypeOptions,
  ];
  if (tierable) {
    VALID_PRICE_TYPE_LIST.push({
      name: 'Add tiers',
      onClick: () => handleChangeQuotePriceType('tiered'),
    });
  }
  const menuItems =
    quotePrice.type === 'tiered' ? TIERED_LIST : VALID_PRICE_TYPE_LIST;

  return (
    <div className="relative">
      {shown && (
        <div
          className="absolute z-[400] flex flex-col gap-1 bg-white border border-gray-200 shadow-lg rounded-md transition ease-out duration-100"
          style={{ top: 2, left: 0 }}
        >
          {menuItems.length === 0 && (
            <div className="text-gray-500 font-semibold block pl-4 pr-6 py-2 text-sm text-left">
              Tiers not supported
              {/* Actually there are no options to switch to, but I think it's kinda confusing to say no options (options for what? they might ask) so this is easier */}
            </div>
          )}
          {validPriceTypeOptions.length > 0 && quotePrice.type !== 'tiered' && (
            <div className="text-gray-500 font-semibold block pl-4 pr-6 py-2 text-sm text-left">
              Change price type
            </div>
          )}
          {menuItems.map((item) => (
            <button
              key={item.name}
              className="hover:bg-gray-100 hover:text-gray-900 text-gray block pl-4 pr-6 py-2 text-sm text-left"
              onClick={item.onClick}
            >
              {item.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
