import { datadogRum } from '@datadog/browser-rum';
import { useStytchB2BClient, useStytchMemberSession } from '@stytch/react/b2b';
import { DiscoveredOrganization } from '@stytch/vanilla-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from 'src/components/Loading';
import utils from 'src/utils';
import dealopsLogo from '../images/logos/dealops.svg';
import { LAST_LOCATION_LOCAL_STORAGE_KEY } from './constants';

type OrganizationListProps = {
  organizations: DiscoveredOrganization[];
  handleOrgSelection: (org: DiscoveredOrganization) => void;
};

const OrganizationList = ({
  organizations,
  handleOrgSelection,
}: OrganizationListProps) => {
  return (
    <div className="flex min-h-full flex-1 flex-col px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="my-10 flex items-center gap-3 text-2xl font-bold leading-9 tracking-tight text-gray-900">
          <img className="h-10 w-auto" src={dealopsLogo} alt="Dealops" />
          Organizations
        </h2>
        {organizations.length === 0 ? (
          <div className="text-s mt-5 text-center leading-9 tracking-tight text-gray-900">
            No organizations found
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {organizations.map((org) => (
              <button
                key={org.organization.organization_id}
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-fuchsia-800 focus-within:ring-offset-2 hover:border-gray-400"
                onClick={() => handleOrgSelection(org)}
              >
                <div className="min-w-0 flex-1">
                  <p className="text-sm font-medium text-gray-900">
                    {org.organization.organization_name}
                  </p>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const Authenticate = () => {
  const stytchClient = useStytchB2BClient();
  const { session } = useStytchMemberSession();
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState<
    DiscoveredOrganization[] | null
  >(null);

  const handleOrgSelection = (org: DiscoveredOrganization) => {
    stytchClient.discovery.intermediateSessions.exchange({
      organization_id: org.organization.organization_id,
      session_duration_minutes: parseInt(
        process.env.REACT_APP_SESSION_DURATION_MINUTES!!,
      ),
    });
  };

  useEffect(() => {
    if (session) {
      const lastLocation = localStorage.getItem(
        LAST_LOCATION_LOCAL_STORAGE_KEY,
      );
      localStorage.removeItem(LAST_LOCATION_LOCAL_STORAGE_KEY);
      navigate(lastLocation ?? '/app');
    } else {
      const stytch_token_type = utils.retrieveParamFromURL('stytch_token_type');
      const token = utils.retrieveParamFromURL('token');

      if (stytch_token_type === 'sso') {
        (async () => {
          try {
            const res = await stytchClient.sso.authenticate({
              sso_token: token,
              session_duration_minutes: parseInt(
                process.env.REACT_APP_SESSION_DURATION_MINUTES!!,
              ),
            });
          } catch (e) {
            datadogRum.addError(e);
          }
        })();
      } else if (stytch_token_type === 'discovery_oauth') {
        (async () => {
          try {
            const { discovered_organizations } =
              await stytchClient.oauth.discovery.authenticate({
                discovery_oauth_token: token,
              });
            const authenticated_organizations = discovered_organizations.filter(
              (org) => org.member_authenticated,
            );
            if (authenticated_organizations.length === 1) {
              handleOrgSelection(authenticated_organizations[0]);
            } else {
              setOrganizations(authenticated_organizations);
            }
          } catch (e) {
            datadogRum.addError(e);
          }
        })();
      } else {
        throw new Error('stytch_token_type is not supported');
      }
    }
  }, [stytchClient, session, navigate]);

  if (organizations) {
    return (
      <OrganizationList
        organizations={organizations}
        handleOrgSelection={handleOrgSelection}
      />
    );
  } else {
    return <Loading />;
  }
};
