import { useState } from 'react';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import { PricingData, PricingGuidanceData } from './ProductASPsDashboard';

export const CPQvsDealopsPlot = ({
  data,
}: {
  data: {
    dealops: { x: number; y: number }[];
    cpq: { x: number; y: number }[];
    guidance: { x: number; y: number }[];
  };
}) => {
  const [showDealops, setShowDealops] = useState(true);
  const [showCPQ, setShowCPQ] = useState(true);
  const [showGuidance, setShowGuidance] = useState(true);

  const handleClick = (source: string) => {
    if (source === 'Dealops') {
      setShowDealops(!showDealops);
    } else if (source === 'CPQ') {
      setShowCPQ(!showCPQ);
    } else if (source === 'Guidance') {
      setShowGuidance(!showGuidance);
    }
  };

  return (
    <ResponsiveContainer width="100%">
      <ScatterChart
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid />
        <Legend
          verticalAlign="top"
          align="right"
          height={36}
          iconSize={10}
          formatter={(value) => (
            <span
              style={{
                fontSize: '14px',
                fontWeight: 'regular',
                color: '#475467',
              }}
              onClick={() => handleClick(value)}
            >
              {value}
            </span>
          )}
        />
        <XAxis
          type="number"
          dataKey="x"
          name="Monthly Minimum"
          label={{
            value: 'Monthly Minimums ($)',
            position: 'insideBottom',
            offset: -10,
            className: 'font-medium text-xs text-gray-900',
          }}
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(value)
          }
          tick={{ fontSize: 12, fill: '#475467' }}
          axisLine={{ stroke: '#475467' }}
        />
        <YAxis
          type="number"
          dataKey="y"
          name="Price"
          label={{
            value: 'Price ($)',
            angle: -90,
            offset: -5,
            position: 'insideLeft',
            className: 'font-medium text-xs text-gray-900',
          }}
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(value)
          }
          tick={{ fontSize: 12, fill: '#475467' }}
          axisLine={{ stroke: '#475467' }}
        />
        <ZAxis type="category" dataKey="z" name="Source" />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          formatter={(value: any) =>
            typeof value === 'number'
              ? new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(value)
              : value
          }
        />
        <Scatter
          name="Guidance"
          data={
            showGuidance
              ? data.guidance.map((point) => {
                  return { ...point, z: 'Guidance' };
                })
              : []
          }
          fill="#16B364"
        />
        <Scatter
          name="CPQ"
          data={
            showCPQ
              ? data.cpq.map((point) => {
                  return { ...point, z: 'CPQ' };
                })
              : []
          }
          fill="#0BA5EC"
        />
        <Scatter
          name="Dealops"
          data={
            showDealops
              ? data.dealops.map((point) => {
                  return { ...point, z: 'Dealops' };
                })
              : []
          }
          fill="#981781"
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export const CPQvsDealopsPerProductPlot = ({
  pricingData,
  pricingGuidanceData,
}: {
  pricingData: PricingData[];
  pricingGuidanceData: PricingGuidanceData[];
}) => {
  const [showDealops, setShowDealops] = useState(true);
  const [showCPQ, setShowCPQ] = useState(true);
  const [showGuidance, setShowGuidance] = useState(true);

  const handleLegendClick = (source: string) => {
    if (source === 'Dealops') {
      setShowDealops(!showDealops);
    } else if (source === 'CPQ') {
      setShowCPQ(!showCPQ);
    } else if (source === 'Guidance') {
      setShowGuidance(!showGuidance);
    }
  };

  let cpqData = pricingData
    .filter((product) => product.metadata.source === 'CPQ')
    .map((product) => product.point);
  let dealopsData = pricingData
    .filter((product) => product.metadata.source === 'Dealops')
    .map((product) => product.point);

  // need to filter monthly minimums to only include those
  // that are less than or equal to the max monthly minimum
  let suggestionData = pricingGuidanceData
    .map((product) => product.point)
    .filter(
      (point) =>
        point.x <=
        Math.max(
          ...cpqData.map((point) => point.x),
          ...dealopsData.map((point) => point.x),
        ),
    );

  return (
    <ResponsiveContainer width="100%">
      <ScatterChart
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid />
        <Legend
          verticalAlign="top"
          align="right"
          height={36}
          iconSize={10}
          formatter={(value) => (
            <span
              style={{
                fontSize: '14px',
                fontWeight: 'regular',
                color: '#475467',
              }}
              onClick={() => handleLegendClick(value)}
            >
              {value}
            </span>
          )}
        />
        <XAxis
          type="number"
          dataKey="x"
          name="Monthly Minimum"
          label={{
            value: 'Monthly Minimums ($)',
            position: 'insideBottom',
            offset: -10,
            className: 'font-medium text-xs text-gray-900',
          }}
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(value)
          }
          tick={{ fontSize: 12, fill: '#475467' }}
          axisLine={{ stroke: '#475467' }}
        />
        <YAxis
          type="number"
          dataKey="y"
          name="Price"
          label={{
            value: 'Price ($)',
            angle: -90,
            offset: -5,
            position: 'insideLeft',
            className: 'font-medium text-xs text-gray-900',
          }}
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(value)
          }
          tick={{ fontSize: 12, fill: '#475467' }}
          axisLine={{ stroke: '#475467' }}
        />
        <ZAxis type="category" dataKey="z" name="Source" />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          formatter={(value: string | number) =>
            typeof value === 'number'
              ? new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(value)
              : value
          }
        />
        <Scatter
          name="Guidance"
          data={
            showGuidance
              ? suggestionData.map((point) => {
                  return { ...point, z: 'Guidance' };
                })
              : []
          }
          fill="#16B364"
        />
        <Scatter
          name="CPQ"
          data={
            showCPQ
              ? cpqData.map((point) => {
                  return { ...point, z: 'CPQ' };
                })
              : []
          }
          fill="#0BA5EC"
        />
        <Scatter
          name="Dealops"
          data={
            showDealops
              ? dealopsData.map((point) => {
                  return { ...point, z: 'Dealops' };
                })
              : []
          }
          fill="#981781"
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};
