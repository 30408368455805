import React, { ErrorInfo } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';

import './index.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './dashboard/App';
import Login from './dashboard/Login';
import Contact from './marketing-site/Contact';
import LandingPage from './marketing-site/LandingPage';
import NotFound from './marketing-site/NotFound';
import Security from './marketing-site/Security';
import reportWebVitals from './reportWebVitals';

import { StytchB2BProvider } from '@stytch/react/b2b';
import { StytchB2BHeadlessClient } from '@stytch/vanilla-js/b2b/headless';

import { Authenticate } from './dashboard/Authenticate';

import '@ungap/has-own';
import { addError, init as initDatadogRum } from './analytics/datadog';
import Fallback from './components/Fallback';
import GraphDemo from './components/graphs/GraphDemo';
import MouseTooltip from './components/MouseTooltip';
import { RedirectPage } from './marketing-site/Redirect';
import utils from './utils';

if (process.env.REACT_APP_STYTCH_PUBLIC_KEY == null) {
  throw new Error('Missing env var REACT_APP_STYTCH_PUBLIC_KEY');
}

const stytchOptions = {
  cookieOptions: {
    availableToSubdomains: true,

    domain: utils.getDomainWithPeriodPrefix(
      process.env.REACT_APP_CLIENT_BASE_URL,
    ),
  },
};

const stytch = new StytchB2BHeadlessClient(
  process.env.REACT_APP_STYTCH_PUBLIC_KEY,
  stytchOptions,
);

initDatadogRum();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={Fallback}
      onError={(error: Error, info: ErrorInfo) => {
        const renderingError = new Error(error.message);
        renderingError.name = `ReactRenderingError`;
        renderingError.stack = info.componentStack ?? undefined;
        renderingError.cause = error;
        addError(renderingError);
      }}
    >
      <StytchB2BProvider stytch={stytch}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/security" element={<Security />} />
            <Route path="/security/:page" element={<Security />} />
            <Route path="/app" element={<App />} />
            <Route path="/app/:page/*" element={<App />} />
            <Route path="/login" element={<Login />} />
            <Route path="/authenticate" element={<Authenticate />} />
            <Route
              path="/work-with-us"
              element={
                <RedirectPage url="https://dealops.notion.site/Founding-engineer-at-Dealops-b6e4ae1467484b6fb9ff5e5b8b72ced4?pvs=4" />
              }
            />
            <Route
              path="/graphdemo"
              element={
                <MouseTooltip>
                  <GraphDemo />
                </MouseTooltip>
              }
            />
          </Routes>
        </BrowserRouter>
      </StytchB2BProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
