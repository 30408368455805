import { AlpacaProduct } from './Alpaca/alpaca_types';

export type PricingFlow = DealopsPricingFlow | PenguinPricingFlow;

export enum PricingFlowType {
  DEALOPS = 'DEALOPS',
  PENGUIN = 'PENGUIN',
  COMPLEX_DEMO = 'COMPLEX_DEMO',
  ALPACA = 'ALPACA',
}

export enum PricingFlowStage {
  NOT_STARTED = 'NOT_STARTED',
  ADD_PRODUCTS = 'ADD_PRODUCTS',
  CALCULATE_PRICE = 'CALCULATE_PRICE',
}

// these are the fields on the pricingFlow the user can edit and update
export type PricingFlowMutableFields = {
  additionalData: unknown;
  products:
    | (
        | (ProductCommon & unknown)
        | DealopsProductWithVolume
        | PenguinProduct
        | AlpacaProduct
      )[]
    | null;
  manualQuote: unknown;
  finalQuote: unknown;
  stage: PricingFlowStage;
  cloneFromPricingFlowId?: string;
  // Not directly mutated by the user, but actions they take can affect which
  // pricing curves are in use on the pricing flow
  currentPricingCurves: unknown;
};
export const PRICING_FLOW_MUTABLE_KEYS: [
  'products',
  'additionalData',
  'manualQuote',
  'finalQuote',
  'stage',
  'currentPricingCurves',
] = [
  'products',
  'additionalData',
  'manualQuote',
  'finalQuote',
  'stage',
  'currentPricingCurves',
];

// these fields are supplied by the server and can't be directly updated
export type PricingFlowReadonlyFields = Readonly<{
  id: string;
  organizationId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;

  // SFDC info
  sfdcInstanceUrl: string;
  sfdcOpportunityId: string;
  sfdcQuoteId: string | null;
  sfdcOpportunityName: string | null;

  pricingSheet: string;

  type: PricingFlowType;
  opportunityData: unknown;
  recommendedQuote: unknown;
  approvalLevels: unknown;
  pricingSheetData: unknown;
}>;

export type PricingFlowCommon = PricingFlowMutableFields &
  PricingFlowReadonlyFields;

export type ProductCommon = {
  id: string;
  name: string;
  volume: number; // @TODO: If we change this to be a CurrencyValue, we will need to update these functions (search "@TODO(CurrencyValueVolumes)")
};

/*******************************
 * DEALOPS PRICING FLOW
 ******************************/
export type DealopsPricingFlow = PricingFlowCommon & {
  // Quote data
  type: PricingFlowType.DEALOPS;
  opportunityData: DealopsOpportunityData;
  additionalData: DealopsAdditionalData;
  products: DealopsProduct[];
  recommendedQuote: DealopsRecommendedQuote;
  manualQuote: DealopsManualQuote;
  finalQuote: DealopsManualQuote;
  approvalLevels: DealopsApprovalLevels;
  pricingSheetData: DealopsProductPrices;
};

export type DealopsOpportunityData = {
  User_Name: string | null;
  Account_Industry: string | null;
  Account_BillingCountry: string | null;
  Opportunity_StageName: string | null;
  Opportunity_Probability: number | null;
  Opportunity_ExpectedRevenue: number | null;
};

export type DealopsAdditionalData = {
  monthlyBudget: number | null;
  contractLength: number | null;
  marketingRights: boolean | null;
  competitors: string[] | null;
};

export type DealopsProduct = ProductCommon & {
  volume: number;
  transactionSize?: number;
  rampUpType?: RampUpTypeIdentifier;
  customRampUp?: number[];
  linearRampUpConfig?: { start: number; months: number };
  fixedRampUp?: number;
};

export type DealopsRecommendedQuote = {
  monthlyMinimum: number;
  products: { [key: string]: number };
  productPricingLogs?: { [key: string]: [string] };
};

export type DealopsManualQuote = {
  monthlyMinimum?: number;
  products?: { [key: string]: number };
};

export type DealopsApprovalLevels = { [key: string]: number };

export type DealopsProductPrice = {
  id: string;
  skuGroup: string;
  parentProduct: string;
  name: any;
  skuSubgroup: string;
  financeApproval: number;
  selfServe: number;
  unitDefinition: string;
  monthlyMinimumTiers: {
    min_spend: number;
    currency: string;
    recommendedPricing: number;
    level_1: number;
    level_2: number;
    level_3: number;
    level_4: number;
  };
  priceType: string;
};
export type DealopsProductPrices = { [key: string]: DealopsProductPrice };

/*******************************
 * PENGUIN PRICING FLOW
 ******************************/
export type PenguinPricingFlow = PricingFlowCommon & {
  // Quote data
  type: PricingFlowType.PENGUIN;
  opportunityData: PenguinOpportunityData;
  additionalData: PenguinAdditionalData;
  /** Products selected by rep. Only the ID is used to generate quotes. **/
  products: PenguinProduct[];
  recommendedQuote: PenguinRecommendedQuote;
  manualQuote: PenguinManualQuote;
  currentPricingCurves: Record<string, PenguinPricingCurve>;
  /** @deprecated Not used at all today **/
  finalQuote: PenguinManualQuote;
  approvalLevels: PenguinApprovalLevels;
  pricingSheetData: PenguinPricingSheet;
};

export type PenguinPricingFlowWithProductVolumes = Omit<
  PenguinPricingFlow,
  'products'
> & {
  products: PenguinProductWithVolume[];
};

export type PenguinOpportunityData = {
  Opportunity_GTM_Segment__c: string | null; // string | null "GTM segment"
  Opportunity_StageName: string | null; // string | null "Stage"
  Opportunity_Qualified_ACV__c: number | null; // number | null "Qualified ACV"
  User_Name: string | null; // string "Opportunity Owner"
  Opportunity_Product_Region__c: string | null; // string "Product region"
  Opportunity_Type: string | null; // string "Type"
};

export type PenguinAdditionalData = {
  productCategories: string[] | null;
  customStage: string | null;
  country: string | null;
  minimumRampUp: (number | null)[] | null;
  subscriptionTerms: number;
  startDate: string;
  supportPackageTimezone: string | null;
};

export type PenguinProduct = Omit<ProductCommon, 'volume'> & {
  id: string;
  name: string;
  ProductCode: string;
  tiered?: {
    ProductCode: string;
  }[];
  volume?: number;
  transactionSize?: number;
} & RampUpType;

export type PenguinProductWithVolume = PenguinProduct & {
  volume: number;
};

export type RampUpTypeIdentifier =
  | 'fixed'
  | 'custom'
  | 'linear'
  | 'linear_quarterly';

type RampUpType =
  | CustomRampUpType
  | LinearRampUpType
  | QuarterlyLinearRampUpType
  | FixedRampUpType;

export type FixedRampUpType = {
  rampType: 'fixed';
};

export type CustomRampUpType = {
  rampType: 'custom';
  customRampUp: number[];
};

export type LinearRampUpType = {
  rampType: 'linear';
  linearRampUpConfig: { start: number; months: number };
};

export type QuarterlyLinearRampUpType = {
  rampType: 'linear_quarterly';
  linearRampUpConfig: { start: number; months: number };
};

export type PenguinRecommendedQuote = {
  monthlyMinimum: number;
  products: { [productName: string]: FixedPrice };
  productPricingLogs?: { [key: string]: [string] };
};

export interface FixedPrice {
  type: 'fixed';
  price: number;
}

export interface Tier {
  minimum: number;
  price: number;
}

export interface TieredPrice {
  type: 'tiered';
  tiers: Tier[];
}

export type PenguinManualQuote = {
  monthlyMinimum?: number;
  /** Prices for each product **/
  products?: { [key: string]: FixedPrice | TieredPrice };
};

export type PenguinApprovalLevels = {
  [key: string]: FixedApprovalLevel | TieredApprovalLevels;
};

export type FixedApprovalLevel = {
  type: 'fixed';
  level: number;
};

export type TieredApprovalLevels = {
  type: 'tiered';
  levels: number[];
};

export enum PriceType {
  PERCENT = 'percent',
  NUMERICAL = 'numerical',
}

export type PenguinTieredPricingInfo = PenguinPricingInfo & {
  tier: number;
};
type PenguinPricingInfo = {
  recommendedPricing: number;
  level1: number;
  level2?: number;
  level3?: number;
  level4?: number;
};

// defines how a product is priced. Read out of PricingCurve table
export type PenguinPricingInformation = {
  monthlyVolumeTiers: PenguinTieredPricingInfo[];
  monthlyMinimumTiers: PenguinTieredPricingInfo[];
  nonTieredPricing: PenguinPricingInfo;
  useVolumePricing: boolean;
  selfServe: number;
  financeApproval: number;
};

// ##PricingCurveType
// This type mirrors the prisma schema. Find the corresponding # comment to see
// where the data is sourced from
export type PricingCurve = {
  id: string;
  productId: string;
  name: string;
  isActive: boolean;
  condition: string;
  priority: number;
  pricingInformation: unknown;
};
export type PenguinPricingCurve = PricingCurve & {
  pricingInformation: PenguinPricingInformation;
};

export type PenguinProductPrice = {
  id: string;
  parentProduct?: string;
  parentProductId?: string;
  name: string;
  ProductCode: string;
  tiered?: {
    ProductCode: string;
  }[];
  priceType: PriceType;
  unitDefinition: string;
  volumeType?: 'currency' | 'count';
  skuGroup: string;
  skuSubgroup: string;
  pricingTierStepSize?: number;
  exclusiveGroup?: string;
  fixedVolume?: number;
  priceBilledForNumberOfMonths: number;
  priceComputationType: 'fixed' | 'dynamic';
  priceCap?: number;
  priceFloor?: number;
  displayOrder: number;

  // Feature Flags
  hideBehindFlag?: string;
  showBehindFlag?: string;
  isActive?: boolean;

  pricingCurves: PenguinPricingCurve[];
  // TODO(george)
  // We're in the process of migrating pricing information to being stored in a
  // PricingCurve object, rather than directly on the product.
  // The following types will all be removed once that migration is complete.

  financeApproval: number;
  selfServe: number;
  useVolumePricing: boolean;
  nonTieredPricing: {
    recommendedPricing: number;
    level1: number;
    level2: number;
    level3: number;
  };
  monthlyMinimumTiers: {
    [key: string]: {
      min_spend: number;
      currency: string;
      recommendedPricing: number;
      level1: number;
      level2: number;
      level3: number;
      level4: number;
    };
  };
  monthlyVolumeTiers?: {
    [key: string]: {
      recommendedPricing: number;
      level1: number;
      level2: number;
      level3: number;
      level4: number;
    };
  };
};

type PenguinCountryPricingSheets = {
  [key in 'us' | 'ca']: PenguinCountryPricingSheet;
};
export type PenguinPricingSheet = {
  countryPricingSheets: PenguinCountryPricingSheets;
};

export type PenguinCountryPricingSheet = {
  categories: PenguinCategories;
  productInfo: PenguinProductPrices;
};

export type PenguinProductPrices = {
  [key: string]: PenguinProductPrice;
};

export type PenguinCategory = {
  name: string;
  description: string;
  products: string[];
  subcategories: { [key: string]: Subcategory };
  preselectedProducts: { [key: string]: string[] };
};

export type Subcategory = {
  name: string;
  products: string[];
};

export type PenguinCategories = { [key: string]: PenguinCategory };

export enum PlatformType {
  UNSET = '',
  SELF_HOSTED = 'Self hosted',
  CLOUD = 'Cloud',
  MANAGED_CLOUD = 'Managed cloud',
  SELF_HOSTED_AND_AIR_GAPPED = 'Self hosted and air gapped',
}

export enum Segment {
  UNSET = '',
  ENTERPRISE = 'enterprise',
  BUSINESS = 'business',
}

export enum ProductTab {
  LICENSE_TYPES = 'License types',
  PLATFORM_FEES = 'Platform fees',
  ADD_ON_FEATURES = 'Add-on features',
  ADD_ON_SERVICES = 'Add-on services',
}

export type SalesforceProduct = {
  productFamily: string;
  productType: string;
  productName: string;
  listPrice: string;
  minimumSalePrice: string;
  productId: string;
};
export type SalesforceProductWithVolume = SalesforceProduct & {
  volume?: number;
};

export type ProductMapping = {
  volumeMin: number | null;
  volumeMax: number | null;
  salesforceProductId: string;
};
export type DealopsProductDefinition = {
  id: string;
  tab: ProductTab;
  platformType: PlatformType;
  segment: Segment;
  name: string;
  productId: string;
  productMapping: ProductMapping[];
  defaultVolume: number;
};

export type DealopsProductWithVolume = DealopsProductDefinition & {
  volume?: number;
};
