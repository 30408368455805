import {
  ArrowDownCircleIcon,
  CalendarIcon,
  ChartBarIcon,
  CheckBadgeIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  FaceSmileIcon,
  PauseCircleIcon,
  PauseIcon,
  PencilIcon,
  PlayCircleIcon,
  PlayIcon,
  StopCircleIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import _ from 'lodash';
import { ReactNode, useState } from 'react';
import AppPageHeader from 'src/components/AppPageHeader';
import {
  Bar,
  DistributionBar,
} from 'src/components/graphs/DistributionComparison';
import { ProgressBar } from 'src/components/graphs/GoalTracker';
import GraphContainer, {
  RenderChildWithSize,
} from 'src/components/graphs/GraphContainer';
import { Legend } from 'src/components/graphs/helpers';
import { HorizontalBarGraph } from 'src/components/graphs/HorizontalBarChart';
import LinePlot, { Line } from 'src/components/graphs/LinePlot';
import {
  Colors,
  Group,
  StackedColumnChart,
} from 'src/components/graphs/StackedColumnChart';
import Tabs from 'src/components/Tabs';

interface ExperimentsPageProps {}

const UpDownArrow = () => {
  return (
    <div className="flex flex-col items-center ml-2 group">
      <ChevronUpIcon className="w-3 h-3 text-gray-400 group-hover:text-gray-900" />
      <ChevronDownIcon className="w-3 h-3 text-gray-400 group-hover:text-gray-900" />
    </div>
  );
};

const DropdownButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative inline-block text-left">
      <button
        className="flex items-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none"
        onClick={toggleDropdown}
        type="button"
      >
        {isPaused ? (
          <PlayIcon className="h-5 w-5 mr-1" />
        ) : (
          <PauseIcon className="h-5 w-5 mr-1" />
        )}
        <span className="flex-grow text-left">
          {isPaused ? 'Resume' : 'Pause'}
        </span>
        <ChevronDownIcon className="h-5 w-5 pl-2" />
      </button>
      {isOpen && (
        <div className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow-lg absolute mt-2 right-0 w-60">
          <ul
            className="py-2 text-sm text-gray-700"
            aria-labelledby="dropdownDefaultButton"
          >
            <li>
              <a
                href="#"
                className="block px-4 py-2 hover:bg-gray-100 flex items-start"
                onClick={() => {
                  setIsPaused(!isPaused);
                  toggleDropdown();
                }}
              >
                {isPaused ? (
                  <PlayCircleIcon className="w-5 h-5 text-gray-500 mr-3" />
                ) : (
                  <PauseCircleIcon className="w-5 h-5 text-gray-500 mr-3" />
                )}
                <span className="font-medium">
                  {isPaused
                    ? 'Resume Experimentation'
                    : 'Pause Experimentation'}
                </span>
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block px-4 py-2 hover:bg-gray-100 flex items-start"
                onClick={() => {
                  toggleDropdown();
                }}
              >
                <StopCircleIcon className="w-5 h-5 text-red-500 mr-3" />
                <div>
                  <span className="font-medium">Stop Experimentation</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

function AppHeaderRightChildren() {
  const button = (label: ReactNode) => (
    <button
      type="button"
      className="flex items-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none"
      onClick={() => {}}
    >
      {label}
    </button>
  );

  return (
    <div className="flex space-x-2">
      {button(
        <>
          <CalendarIcon className="h-5 w-5 mr-2" /> Last 30 days
        </>,
      )}
      {button(
        <>
          <ArrowDownCircleIcon className="h-5 w-5 mr-2" /> Export
        </>,
      )}
      {button(
        <>
          <PencilIcon className="h-5 w-5 mr-2" /> Edit
        </>,
      )}
      <DropdownButton />
    </div>
  );
}

export default function ExperimentsPage(props: ExperimentsPageProps) {
  const [selectedTab, setSelectedTab] = useState('Overview');
  return (
    <div className="">
      <AppPageHeader
        title={'30% ASP Lift: Fraud Prevention'}
        backPageLabel="Experiments"
        rightChildren={AppHeaderRightChildren()}
      />

      <div className="pt-1 px-4 sm:px-6 lg:px-8">
        <Tabs
          tabs={[
            {
              name: 'Overview',
              onClick: () => setSelectedTab('Overview'),
              current: selectedTab === 'Overview',
            },
            {
              name: 'Exposure',
              onClick: () => setSelectedTab('Exposure'),
              current: selectedTab === 'Exposure',
            },
            {
              name: 'Metrics',
              onClick: () => setSelectedTab('Metrics'),
              current: selectedTab === 'Metrics',
            },
          ]}
        />
      </div>

      <div className="grid grid-cols-4 gap-4 p-4 sm:p-6 lg:p-8 pt-4 sm:pt-4 lg:pt-4">
        <div className="col-span-4 h-[180px] w-full flex flex-row gap-x-4">
          <GoalTracker target={0.2} progress={0.75} />
          <Summary />
        </div>
        <div className="col-span-1 h-[180px]">
          <DistributionBarChart bars={DISTRIBUTION_BAR_CHART_ARGS} />
        </div>
        <div className="col-span-1 h-[180px]">
          <HorizontalBarChart
            bars={HORIZONTAL_BAR_CHART_ARGS}
            title="Avg time to close"
            unit="days"
          />
        </div>
        <div className="col-span-1 h-[180px]">
          <HorizontalBarChart
            bars={HORIZONTAL_BAR_CHART_ARGS_2}
            title="ACV"
            unit="USD"
          />
        </div>
        <div className="col-span-1 h-[180px]">
          <HorizontalBarChart
            bars={HORIZONTAL_BAR_CHART_ARGS_3}
            title="Avg discount"
            unit="%"
          />
        </div>
        <div className="col-span-2 h-[380px]">
          <ClosedWonDeals lines={LINE_PLOT_EXAMPLE_1_ARGS.lines} />
        </div>
        <div className="col-span-2 h-100 row-span-3">
          <div className="h-full w-full pt-2">
            <div className="flex flex-col gap-y-4 rounded-md p-4 outline outline-gray-200">
              {/* Header */}
              <div className="flex w-full items-center text-gray-900">
                <div className="mr-1 w-6 stroke-gray-600">
                  <UserGroupIcon className="text-gray-800" />
                </div>{' '}
                <div className="font-medium text-gray-900">
                  Closed-won deals by rep
                </div>
              </div>

              <div className="border-b border-gray-200"></div>
              <div className="flex-1 self-stretch overflow-hidden">
                {/* Table */}
                <ClosedWonByRepTable />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2 h-[180px] self-start">
          <StackedColumnChartExample {...STACKED_COLUMN_CHART_EXAMPLE_ARGS} />
        </div>
      </div>
    </div>
  );
}

function ClosedWonByRepTable() {
  const data = [
    { name: 'Nick M.', variant: 'Variation 1', deals: 3 },
    { name: 'Bryon S.', variant: 'Variation 1', deals: 1 },
    { name: 'Laura M.', variant: 'Variation 1', deals: 2 },
    { name: 'Alex W.', variant: 'Variation 1', deals: 3 },
    { name: 'Katie M.', variant: 'Baseline', deals: 2 },
    { name: 'Sarah D.', variant: 'Baseline', deals: 2 },
    { name: 'Lisa M.', variant: 'Baseline', deals: 2 },
    { name: 'Sarah D.', variant: 'Baseline', deals: 0 },
    { name: 'Camille L.', variant: 'Baseline', deals: 0 },
    { name: 'Hayden B.', variant: 'Baseline', deals: 0 },
    { name: 'Amelie D.', variant: 'Baseline', deals: 0 },
    { name: 'George H.', variant: 'Baseline', deals: 1 },
  ];
  return (
    <div className="w-full rounded-xl bg-white">
      <table className="w-full border-separate border-spacing-0">
        <thead>
          <tr>
            <th className="min-w-[172px]  rounded-tl-md bg-gray-50 px-6 py-2 text-left text-xs font-medium text-gray-700">
              <div className="flex items-center">
                Rep <UpDownArrow />
              </div>
            </th>
            <th className="bg-gray-50 px-6 py-2 text-left text-xs font-medium text-gray-700">
              <div className="flex items-center">
                Variant <UpDownArrow />
              </div>
            </th>
            <th className="rounded-tr-md border-gray-200 bg-gray-50 px-6 py-2 text-left text-xs font-medium  text-gray-700">
              <div className="flex items-center">
                Closed-won deals <UpDownArrow />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((rep, index) => (
            <tr key={index} className="text-xs text-gray-600 hover:bg-gray-50">
              <td className="whitespace-nowrap flex items-center border-t border-gray-200 px-6 py-2">
                <UserCircleIcon className="h-7 w-7 mr-4" />
                <div className="font-medium text-gray-900">{rep.name}</div>
              </td>
              <td className="whitespace-nowrap border-t border-gray-200 px-6 py-2">
                <span
                  className={` px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${rep.variant === 'Variation 1' ? 'bg-blue-100 text-blue-800' : 'bg-pink-100 text-pink-800'}`}
                >
                  {rep.variant}
                </span>
              </td>
              <td className="whitespace-nowrap border-t border-gray-200 px-6 py-2">
                {rep.deals}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="my-2 justify-end flex">
        <Pagination currentPage={1} totalPages={2} />
      </div>
    </div>
  );
}

const Pagination = ({
  currentPage,
  totalPages,
}: {
  currentPage: number;
  totalPages: number;
}) => {
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <ul className="flex items-center -space-x-px h-10 text-base">
      <li>
        <button
          onClick={() => {}}
          className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          disabled={currentPage === 1}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon
            className="w-3 h-3 rtl:rotate-180"
            aria-hidden="true"
          />
        </button>
      </li>
      {pages.map((page) => (
        <li key={page}>
          <button
            onClick={() => {}}
            className={`flex items-center justify-center px-4 h-10 leading-tight border ${
              page === currentPage
                ? 'z-10 text-gray-600 border-gray-300 bg-gray-50 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                : 'text-gray-500 bg-white border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
            }`}
            aria-current={page === currentPage ? 'page' : undefined}
          >
            {page}
          </button>
        </li>
      ))}
      <li>
        <button
          onClick={() => {}}
          className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          disabled={currentPage === totalPages}
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon
            className="w-3 h-3 rtl:rotate-180"
            aria-hidden="true"
          />
        </button>
      </li>
    </ul>
  );
};

function Summary() {
  return (
    <GraphContainer
      header={
        <div className="flex items-center">
          <CheckBadgeIcon className="mr-1 w-6 stroke-gray-600 text-gray-800" />
          <div className="font-semibold text-gray-900">Summary</div>
        </div>
      }
      className="flex grow"
    >
      <div className="flex flex-row gap-x-12 w-full">
        <div className="flex flex-col gap-y-1">
          <span>Duration</span>
          <span className="font-medium text-2xl">4 weeks</span>
        </div>
        <div className="flex flex-col gap-y-1">
          <span>Reps involved</span>
          <span className="font-medium text-2xl">60</span>
        </div>
        <div className="flex flex-col gap-y-1">
          <span>Teams involved</span>
          <span className="font-medium text-2xl">2</span>
        </div>
        <div className="flex flex-col gap-y-1">
          <span>Recommended next step</span>
          <span className="text-md font-medium">Roll out variant 1</span>
        </div>
      </div>
    </GraphContainer>
  );
}

function GoalTracker({
  target,
  progress,
}: {
  target: number;
  progress: number;
}) {
  return (
    <GraphContainer
      header={
        <div className="flex items-center">
          <CheckBadgeIcon className="mr-1 w-6 stroke-gray-600 text-gray-800" />
          <div className="font-semibold text-gray-900">Goal</div>
        </div>
      }
      className="w-[380px]"
    >
      <div className="mb-4 flex w-full items-center justify-between">
        <div>
          <div className="text-xl font-normal text-gray-500">
            Improvement in <span className="font-bold text-black">ASPs</span> is{' '}
            <span className="font-bold text-black">
              {(progress * 100).toFixed(0)}%
            </span>
          </div>
          <div className="text-md text-gray-500">
            Your target is {(target * 100).toFixed(0)}%
          </div>
        </div>
        <FaceSmileIcon className="w-12" />
      </div>
      <RenderChildWithSize
        className="w-full"
        childFunction={(width, height) => {
          return <ProgressBar width={width} progress={progress} />;
        }}
      />
    </GraphContainer>
  );
}

const DISTRIBUTION_BAR_CHART_ARGS = [
  { label: 'Variation 1', value: 103, color: '#981781' },
  { label: 'Baseline', value: 105, color: '#35B9E9' },
];

export function DistributionBarChart({ bars }: { bars: Bar[] }) {
  return (
    <div className="w-full, h-full">
      <GraphContainer
        header={
          <div className="flex items-center justify-between">
            <div className="flex w-full items-center text-gray-900">
              <div className="mr-1 w-6 stroke-gray-600">
                <ChartBarIcon className="text-gray-800" />
              </div>{' '}
              <div className="font-semibold text-gray-900">Closed deals</div>
            </div>
            <div className="whitespace-nowrap text-sm text-gray-700">
              {/* nothing here */}
            </div>
          </div>
        }
      >
        <div className="mb-4 flex w-full items-center justify-between">
          <div className="text-md font-normal text-gray-500">
            <span className="font-bold text-black ">103</span> with Variation 1{' '}
            <span className="font-bold text-black ">105</span> with Baseline
          </div>
        </div>
        <DistributionBar
          bars={bars}
          formatTooltip={(bar) => {
            return (
              <div>
                <p>{bar.value}</p>
              </div>
            );
          }}
        />
      </GraphContainer>
    </div>
  );
}

const HORIZONTAL_BAR_CHART_ARGS = [
  {
    label: 'Variation 1',
    value: 12,
    color: '#981781',
    info: '12 days',
  },
  {
    label: 'Baseline',
    value: 14,
    color: '#35B9E9',
    info: '14 days',
  },
];

const HORIZONTAL_BAR_CHART_ARGS_2 = [
  {
    label: 'Variation 1',
    value: 65,
    color: '#981781',
    info: '$65k',
  },
  {
    label: 'Baseline',
    value: 58,
    color: '#35B9E9',
    info: '$58k',
  },
];

const HORIZONTAL_BAR_CHART_ARGS_3 = [
  {
    label: 'Variation 1',
    value: 17,
    color: '#981781',
    info: '17%',
  },
  {
    label: 'Baseline',
    value: 26,
    color: '#35B9E9',
    info: '26%',
  },
];
function HorizontalBarChart({
  title,
  unit,
  bars,
}: {
  title: string;
  unit: string;
  bars: (Bar & { info: string })[];
}) {
  return (
    <GraphContainer
      header={
        <div className="flex items-center justify-between">
          <div className="flex w-full items-center text-gray-900">
            <div className="mr-1 w-6 stroke-gray-600">
              <ChartBarIcon className="text-gray-800" />
            </div>{' '}
            <div className="font-semibold text-gray-900">{title}</div>
          </div>
          <div className="whitespace-nowrap text-sm text-gray-700">
            in {unit}
          </div>
        </div>
      }
    >
      <RenderChildWithSize
        className="h-full w-full overflow-hidden ml-[-20px]"
        childFunction={(width, height) => {
          return (
            <HorizontalBarGraph
              width={width}
              height={100}
              xLimit={Math.round(
                (_.max(bars.map((b) => b.value)) as number) * 1.1,
              )}
              bars={bars}
              formatTooltip={(bar) => {
                return (
                  <div>
                    <p>{bar.info}</p>
                  </div>
                );
              }}
            />
          );
        }}
      />
    </GraphContainer>
  );
}

export const LINE_PLOT_EXAMPLE_1_ARGS = {
  lines: [
    {
      label: 'Variation 1',
      color: '#35B9E9',
      points: [
        { xLocation: new Date('2024-02-22').getTime(), value: 0 },
        { xLocation: new Date('2024-02-23').getTime(), value: 10 },
        { xLocation: new Date('2024-02-24').getTime(), value: 20 },
        { xLocation: new Date('2024-02-25').getTime(), value: 25 },
        { xLocation: new Date('2024-02-26').getTime(), value: 27 },
        { xLocation: new Date('2024-02-27').getTime(), value: 28 },
        { xLocation: new Date('2024-02-28').getTime(), value: 29 },
        { xLocation: new Date('2024-02-29').getTime(), value: 29.5 },
        { xLocation: new Date('2024-03-01').getTime(), value: 30 },
        { xLocation: new Date('2024-03-02').getTime(), value: 30.2 },
        { xLocation: new Date('2024-03-03').getTime(), value: 30.1 },
        { xLocation: new Date('2024-03-04').getTime(), value: 30.3 },
        { xLocation: new Date('2024-03-05').getTime(), value: 30.2 },
        { xLocation: new Date('2024-03-06').getTime(), value: 30.4 },
        { xLocation: new Date('2024-03-07').getTime(), value: 30.3 },
        { xLocation: new Date('2024-03-08').getTime(), value: 30.1 },
        { xLocation: new Date('2024-03-09').getTime(), value: 30.4 },
        { xLocation: new Date('2024-03-10').getTime(), value: 30.2 },
        { xLocation: new Date('2024-03-11').getTime(), value: 30.3 },
      ],
    },
    {
      label: 'Baseline',
      color: '#981781',
      points: [
        { xLocation: new Date('2024-02-22').getTime(), value: 0 },
        { xLocation: new Date('2024-02-23').getTime(), value: 8 },
        { xLocation: new Date('2024-02-24').getTime(), value: 15 },
        { xLocation: new Date('2024-02-25').getTime(), value: 20 },
        { xLocation: new Date('2024-02-26').getTime(), value: 23 },
        { xLocation: new Date('2024-02-27').getTime(), value: 25 },
        { xLocation: new Date('2024-02-28').getTime(), value: 26 },
        { xLocation: new Date('2024-02-29').getTime(), value: 26.5 },
        { xLocation: new Date('2024-03-01').getTime(), value: 27 },
        { xLocation: new Date('2024-03-02').getTime(), value: 27.2 },
        { xLocation: new Date('2024-03-03').getTime(), value: 27.3 },
        { xLocation: new Date('2024-03-04').getTime(), value: 27.5 },
        { xLocation: new Date('2024-03-05').getTime(), value: 27.6 },
        { xLocation: new Date('2024-03-06').getTime(), value: 27.7 },
        { xLocation: new Date('2024-03-07').getTime(), value: 27.8 },
        { xLocation: new Date('2024-03-08').getTime(), value: 27.9 },
        { xLocation: new Date('2024-03-09').getTime(), value: 28 },
        { xLocation: new Date('2024-03-10').getTime(), value: 28.1 },
        { xLocation: new Date('2024-03-11').getTime(), value: 28.2 },
      ],
    },
  ],
};

export function ClosedWonDeals({
  lines,
}: {
  lines: (Line & { label: string })[];
}) {
  return (
    <GraphContainer
      className="h-full w-full pt-2"
      header={
        <div className="flex w-full items-center text-gray-900">
          <div className="mr-1 w-6 stroke-gray-600">
            <ChartBarIcon className="text-gray-800" />
          </div>{' '}
          <div className="font-semibold text-gray-900">
            Closed won deals over time
          </div>
        </div>
      }
    >
      <div className="flex h-full w-full flex-col">
        <Legend className="self-end" mappings={lines} />
        <RenderChildWithSize
          className="h-full w-full overflow-hidden"
          childFunction={(width, height) => {
            return (
              <LinePlot
                width={width}
                height={height}
                start={new Date('2024-02-22').getTime()}
                end={new Date('2024-03-11').getTime()}
                lines={lines}
                formatXAxis={(x: number) => {
                  const date = new Date(x);
                  const month = date.getMonth() + 1;
                  const day = date.getDate();
                  return `${day}/${month}`;
                }}
                formatTooltip={(linesAndPoints) => {
                  return (
                    <div>
                      {linesAndPoints.map(({ line, point }) => {
                        return (
                          <div>
                            <p style={{ color: line.color }}>{point.value}</p>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />
            );
          }}
        />
      </div>
    </GraphContainer>
  );
}

export const STACKED_COLUMN_CHART_EXAMPLE_ARGS = {
  groups: [
    {
      label: 'SMB',
      bars: {
        ['Baseline']: { value: 12 },
        ['Variation 1']: { value: 49 },
      },
    },
    {
      label: 'Mid-market',
      bars: {
        ['Baseline']: { value: 38 },
        ['Variation 1']: { value: 40 },
      },
    },
    {
      label: 'Enterprise',
      bars: {
        ['Baseline']: { value: 10 },
        ['Variation 1']: { value: 7 },
      },
    },
  ],
  colors: {
    ['Baseline']: '#981781',
    ['Variation 1']: '#35B9E9',
  },
};

type GroupWithUser = Group & {
  bars: { [key: string]: { value: number } };
};

export function StackedColumnChartExample({
  groups,
  colors,
}: {
  groups: GroupWithUser[];
  colors: Colors;
}) {
  return (
    <GraphContainer
      className="h-96"
      header={
        <div className="flex items-center justify-between">
          <div className="flex w-full items-center text-gray-900">
            <div className="mr-1 w-6 stroke-gray-600">
              <UserPlusIcon className="text-gray-800" />
            </div>{' '}
            <div className="font-semibold text-gray-900">
              Closed won deals by segment
            </div>
          </div>
          <Legend
            mappings={Object.entries(colors).map(([label, color]) => ({
              label,
              color,
            }))}
            className="justify-self-end"
          />
        </div>
      }
    >
      <RenderChildWithSize
        className="h-full w-full overflow-hidden"
        childFunction={(width, height) => {
          return (
            <StackedColumnChart
              width={width}
              height={height}
              yLimit={90}
              groups={groups}
              colors={colors}
              formatTooltip={(bar) => {
                return (
                  <div>
                    <p>{bar.value}</p>
                  </div>
                );
              }}
            />
          );
        }}
      />
    </GraphContainer>
  );
}
