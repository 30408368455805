import { round } from 'lodash';

type DataPoint = {
  xLocation: number;
  value: number;
  userId: number | null;
  competitorId: number | null;
};
export interface PriceDifferentialLine {
  label: string;
  color: string;
  points: DataPoint[];
}

export interface ProductData {
  name: string;
  productCategoryId: string;
  lines: PriceDifferentialLine[];
}

function generateBaseCurve(
  xLocations: number[],
  firstValue: number,
  decayRange: { min: number; max: number },
  isDataFromQuote: boolean,
): DataPoint[] {
  return xLocations
    .reduce((acc: number[], _, i) => {
      const lastValue = acc[i - 1] !== undefined ? acc[i - 1] : firstValue;
      const decayFactor = randomInRange(decayRange.min, decayRange.max);
      return [...acc, lastValue * decayFactor];
    }, [])
    .map((value, index) => {
      const randomUserId = randomIntInRange(1, 9);
      const randomCompetitorId = randomIntInRange(1, 4);

      return {
        xLocation: xLocations[index],
        value: round(value, 3),
        userId: isDataFromQuote ? randomUserId : null,
        competitorId: isDataFromQuote ? randomCompetitorId : null,
      };
    });
}

function generateDerivedCurve(
  baseCurve: DataPoint[],
  decayRange: { min: number; max: number },
  isDataFromQuote: boolean,
) {
  return baseCurve.map(({ xLocation, value, userId }) => {
    const decayFactor = randomInRange(decayRange.min, decayRange.max);
    const randomUserId = randomIntInRange(1, 9);
    const randomCompetitorId = randomIntInRange(1, 4);
    return {
      xLocation,
      value: round(value * decayFactor, 3),
      userId: isDataFromQuote ? randomUserId : null,
      competitorId: isDataFromQuote ? randomCompetitorId : null,
    };
  });
}
function randomInRange(low: number, high: number) {
  return Math.random() * (high - low) + low;
}
function randomIntInRange(low: number, high: number) {
  return Math.floor(Math.random() * (high - low + 1) + low);
}

function generatePriceDifferentialData(): ProductData[] {
  const products = [
    { name: 'Authentication', productCategoryId: '1' },
    { name: 'Fraud Detection', productCategoryId: '1' },
    { name: 'Payment Processing', productCategoryId: '2' },
    { name: 'Transaction Monitoring', productCategoryId: '2' },
    { name: 'Custom AI Models (Basic)', productCategoryId: '3' },
    { name: 'Custom AI Models (Advanced)', productCategoryId: '3' },
    { name: 'Developer Support (Basic)', productCategoryId: '4' },
    { name: 'Developer Support (Advanced)', productCategoryId: '4' },
    { name: 'Real-time Analytics (Basic)', productCategoryId: '5' },
    { name: 'Real-time Analytics (Advanced)', productCategoryId: '5' },
  ];
  return products.map((product) => {
    const xLocations = Array.from(
      { length: 20 },
      (_, i) => (i + 1) * 200 + 150,
    );
    const firstValue = randomInRange(0.5, 3);

    const l1Curve = generateBaseCurve(
      xLocations,
      firstValue,
      {
        min: randomInRange(0.9, 0.94),
        max: randomInRange(0.96, 0.99),
      },
      false,
    );
    const quotedPriceCurve = generateDerivedCurve(
      l1Curve,
      {
        min: 0.95,
        max: 1.2,
      },
      true,
    );
    const suggestedCurve = generateDerivedCurve(
      l1Curve,
      {
        min: 1.15,
        max: 1.3,
      },
      false,
    );
    return {
      ...product,
      lines: [
        {
          label: 'L1',
          color: '#FF4405',
          points: l1Curve,
          userPoints: l1Curve,
        },
        {
          label: 'Avg Quoted Price',
          color: '#16B364',
          points: quotedPriceCurve,
          userPoints: quotedPriceCurve,
        },
        {
          label: 'Suggested',
          color: '#0BA5EC',
          points: suggestedCurve,
          userPoints: suggestedCurve,
        },
      ],
    };
  });
}
export const PRICE_DIFFERENTIAL_DATA: ProductData[] =
  generatePriceDifferentialData();
