import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import {
  ForwardedRef,
  forwardRef,
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useId,
  useState,
} from 'react';
import { NumericFormat } from 'react-number-format';
import { classNames } from 'src/dashboard/App';
import { formatNumber } from 'src/dashboard/utils';
import { safeParseFloat } from 'src/utils';

const formClasses =
  'block w-full appearance-none rounded-md shadow-sm border border-gray-300 px-3 py-1.5 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm';

export function Label({ id, children }: { id: string; children: string }) {
  return (
    <label
      htmlFor={id}
      className="mb-2 block text-sm font-medium text-gray-700"
    >
      {children}
    </label>
  );
}

export const NumberField = forwardRef(
  (
    props: {
      className?: string;
      id?: string;
      name?: string;
      type?: string;
      value?: string | number;
      required?: boolean;
      onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
      onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { className, id, name, type, value, required, onChange, onBlur } =
      props;

    return (
      <input
        ref={ref}
        className={className}
        id={id}
        name={name}
        type={type}
        value={value}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        onWheel={(e) => e.currentTarget.blur()}
      />
    );
  },
);

export const FormattedNumberField = forwardRef(
  (
    {
      numberDecimals,
      className,
      value,
      updateValue,
      validateIncomingValue,
      prefix,
      suffix,
      isSuggested,
      label,
      readOnly = false,
      ...extraProps
    }: {
      numberDecimals: number;
      className?: string;
      value: number;
      updateValue: (arg0: number) => void;
      validateIncomingValue?: (arg0: number) => boolean;
      prefix?: string;
      suffix?: string;
      isSuggested?: boolean;
      label?: string | JSX.Element;
      readOnly?: boolean;
      [key: string]: any;
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const formatInputString = (floatValue: number) =>
      formatNumber(floatValue, numberDecimals);

    const [inputString, setInputString] = useState<string>(
      formatInputString(value),
    );
    useEffect(() => {
      setInputString(formatInputString(value));
    }, [value]);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    return (
      <>
        {isSuggested && (
          <div className="absolute top-4 left-[14.5px]">
            <label className="text-gray-400">Suggested</label>
          </div>
        )}
        {label &&
          (typeof label === 'string' ? (
            <label className={'block mb-2 text-sm font-medium text-gray-900'}>
              {label}
            </label>
          ) : (
            label
          ))}

        <NumericFormat
          disabled={readOnly}
          className={isSuggested ? `${className} pt-8` : className}
          value={inputString}
          valueIsNumericString={true}
          getInputRef={ref}
          onValueChange={(values, sourceInfo) => {
            setInputString(values.value);
          }}
          allowLeadingZeros={false}
          thousandSeparator={','}
          decimalScale={numberDecimals}
          fixedDecimalScale={!isFocused}
          allowNegative={false}
          onBlur={(e) => {
            setIsFocused(false);

            const inputFloatValue =
              inputString !== '' ? safeParseFloat(inputString) : 0;
            const isValid =
              validateIncomingValue === undefined ||
              validateIncomingValue(inputFloatValue);
            const newValue = isValid ? inputFloatValue : value;

            setInputString(formatInputString(newValue));
            updateValue(newValue);
          }}
          onFocus={() => {
            if (value === 0) {
              setInputString('');
            }
            setIsFocused(true);
          }}
          prefix={prefix}
          suffix={suffix}
          {...extraProps}
        />
      </>
    );
  },
);

export function TextField({
  label,
  type = 'text',
  className,
  name,
  autoComplete,
  required = false,
  onChange,
  onBlur,
  placeholder,
  value,
  defaultValue,
  ...props
}: {
  label: string | undefined;
  type: string;
  className?: string;
  name: string;
  autoComplete?: string;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  [key: string]: any;
}) {
  let id = useId();

  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <input
        id={name}
        type={type}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
        value={value}
        defaultValue={defaultValue}
        className={formClasses}
        required={required}
        placeholder={placeholder}
      />
    </div>
  );
}

export const SelectField = forwardRef(
  (
    {
      label,
      className,
      name,
      children,
      onChange,
      value,
      defaultValue,
      selectClassName,
    }: {
      label?: string;
      className?: string;
      name: string;
      children: ReactElement[] | ReactElement;
      onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
      value?: string;
      defaultValue?: string;
      selectClassName?: string;
    },
    ref: ForwardedRef<HTMLSelectElement>,
  ) => {
    let id = useId();

    return (
      <div className={className}>
        {label && <Label id={id}>{label}</Label>}
        <select
          ref={ref}
          id={id}
          children={children}
          className={clsx(selectClassName ?? formClasses, 'pr-8')}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
        />
      </div>
    );
  },
);

export const SelectFieldWithLabel = forwardRef(
  (
    {
      label,
      className,
      name,
      children,
      onChange,
      value,
      defaultValue,
      selectClassName,
    }: {
      label?: string;
      className?: string;
      name: string;
      children: ReactElement[] | ReactElement;
      onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
      value?: string;
      defaultValue?: string;
      selectClassName?: string;
    },
    ref: ForwardedRef<HTMLSelectElement>,
  ) => {
    let id = useId();

    return (
      <div className={className}>
        {label && <Label id={id}>{label}</Label>}
        <select
          ref={ref}
          id={id}
          children={children}
          className={clsx(selectClassName ?? formClasses, 'pr-8')}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
        />
      </div>
    );
  },
);

export function RadioButtonSelectField({
  label,
  className,
  name,
  onChange,
  value,
  options,
}: {
  label: string | undefined;
  className: string | undefined;
  name: string;
  onChange: (value: string) => void;
  value?: string;
  options: { id: string; title: string }[];
}) {
  let id = useId();

  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <fieldset className="mt-4">
        <legend className="sr-only">{label}</legend>
        <div className="flex flex-row gap-2">
          {options.map((option) => (
            <label
              key={option.id}
              htmlFor={option.id} // Associate the label with the radio input
              className="flex w-full cursor-pointer items-center rounded-lg border border-gray-300 px-3.5 py-2.5 shadow-sm"
            >
              <input
                id={option.id}
                name={name}
                type="radio"
                defaultChecked={option.id === value}
                className="h-4 w-4 border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900 focus:border-fuchsia-900"
                onChange={() => onChange(option.id)}
              />
              <span className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                {option.title}
              </span>
            </label>
          ))}
        </div>
      </fieldset>
    </div>
  );
}

export function RadioButtonSelectFieldCol({
  label,
  className,
  name,
  onChange,
  value,
  options,
}: {
  label: string | undefined;
  className: string | undefined;
  name: string;
  onChange: (value: string) => void;
  value: string | null;
  options: {
    id: string;
    title: string;
    data?: { label: string; value: string }[];
  }[];
}) {
  let id = useId();

  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <fieldset className="mt-4 rounded-lg border border-gray-300 bg-white">
        <legend className="sr-only">{label}</legend>
        <div className="flex flex-col divide-y ">
          {options.map((option) => (
            <label
              key={option.id}
              htmlFor={option.id} // Associate the label with the radio input
              className="flex w-full cursor-pointer flex-col  px-4 py-3"
            >
              <div className="flex w-full flex-row">
                <div className="block flex-grow text-sm font-medium leading-6 text-gray-900">
                  {option.title}
                </div>
                <input
                  id={option.id}
                  name={name}
                  type="radio"
                  defaultChecked={option.id === value}
                  className="ml-8 h-4 w-4 border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900 focus:border-fuchsia-900"
                  onChange={() => onChange(option.id)}
                />
              </div>

              {option.data &&
                option.data.map((data) => (
                  <div className="mt-2 flex w-full flex-row">
                    <div className=" block flex-grow text-sm  leading-6 text-gray-600">
                      {data.label}
                    </div>
                    <div className=" block text-sm  leading-6 text-gray-600">
                      {data.value}
                    </div>
                  </div>
                ))}
            </label>
          ))}
        </div>
      </fieldset>
    </div>
  );
}

type RadioBoxFieldProps = {
  checked: boolean;
  name: string; // grouping with other radio options
  value: string; // option-specific checks, e.g. ischecked, associating label with the checkbox
  title: string; // displayed title
  description: ReactNode; // displayed details
  onChange: () => void;
};
export function RadioBoxField(props: RadioBoxFieldProps) {
  const { checked, name, value, title, description, onChange } = props;
  return (
    <div
      className={classNames(
        checked ? 'border-fuchsia-900' : 'border-gray-300',
        'relative flex flex-col divide-y rounded-lg border p-2 shadow-sm',
      )}
    >
      <label
        key={value}
        htmlFor={value}
        className={`flex h-full cursor-pointer justify-between px-3.5 py-2.5`}
      >
        <div className="flex w-full flex-col">
          <div className="flex justify-between items-center mb-2">
            <span className="text-md block font-medium text-slate-900">
              {title}
            </span>
            <input
              value={value}
              id={value}
              name={name}
              type="radio"
              checked={checked}
              className="h-4 w-4 rounded-full border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900 focus:border-fuchsia-900"
              onChange={onChange}
            />
          </div>
          {description}
        </div>
      </label>
    </div>
  );
}

type RadioBoxConfig<Value> = {
  title: string;
  value: Value;
  description: ReactNode;
};
type RadioBoxFieldsProps<Value> = {
  radioBoxConfigs: RadioBoxConfig<Value>[];
  checked: Value | null;
  name: string;
  onSelectOption: (value: Value) => void;
};
export function RadioBoxFields<Value extends string>(
  props: RadioBoxFieldsProps<Value>,
) {
  return (
    <>
      {props.radioBoxConfigs.map((config) => {
        return (
          <RadioBoxField
            key={config.value}
            name={props.name}
            checked={props.checked === config.value}
            onChange={() => {
              props.onSelectOption(config.value);
            }}
            value={config.value}
            title={config.title}
            description={config.description}
          />
        );
      })}
    </>
  );
}

export function ProductSelectField({
  className,
  name,
  id,
  addOns,
  description,
  onChange,
  checked,
  displayTransactionSize = false,
  transactionSize,
  onTransactionSizeChange,
  displaySupportPackageTimezone = false,
  setSupportPackageTimezone,
  supportPackageTimezone,
  children,
  disabled = false,
  badge,
}: {
  className?: string | undefined;
  name: string | ReactNode;
  id: string;
  addOns?: { id: string; name: string; checked: boolean }[];
  description?: string;
  onChange: (value: string, transactionSize?: number) => void;
  checked?: boolean;
  displayTransactionSize?: boolean;
  transactionSize?: number;
  onTransactionSizeChange?: (name: string, transactionSize: number) => void;
  displaySupportPackageTimezone?: boolean;
  setSupportPackageTimezone?: (timezone: string | null) => void;
  supportPackageTimezone?: string | null;
  children?: ReactNode;
  disabled?: boolean;
  badge?: JSX.Element;
}) {
  return (
    <div
      className={classNames(
        checked ? 'border-fuchsia-900' : 'border-gray-300',
        disabled ? 'border-slate-100' : '',
        'relative flex flex-col divide-y rounded-lg border p-2 shadow-sm',
      )}
    >
      <label
        key={id}
        htmlFor={id} // Associate the label with the radio input
        className={`flex h-full cursor-pointer justify-between px-3.5 py-2.5 ${className}`}
      >
        {/* Add padding right so text doesn't get too close to the checkbox */}
        <div className="flex flex-col pr-3 gap-1">
          <span
            className={classNames(
              'text-sm block font-medium',
              disabled ? 'text-slate-600' : 'text-slate-900',
            )}
          >
            {name} {badge}
          </span>
          <span className="block text-xs text-slate-500">{description}</span>
        </div>
        <input
          id={id}
          name={id}
          type="checkbox"
          checked={checked}
          className="h-4 w-4 rounded-full border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900 focus:border-fuchsia-900"
          onChange={() => onChange(id)}
          disabled={disabled}
        />
      </label>
      {/* Add ons */}

      {addOns && addOns.length > 0 && (
        <Transition
          show={checked}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="flex flex-col gap-3">
            {addOns.map((addOn) => (
              <label
                key={addOn.id}
                htmlFor={addOn.id}
                className="flex cursor-pointer flex-row justify-between px-3.5 py-3"
              >
                <span className="block pr-3 text-sm text-gray-500">
                  {addOn.name}
                </span>
                <input
                  id={addOn.id}
                  name={addOn.name}
                  type="checkbox"
                  defaultChecked={addOn.checked}
                  checked={addOn.checked}
                  className="h-4 w-4 rounded-full border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900 focus:border-fuchsia-900"
                  onChange={() => onChange(addOn.id)}
                />
              </label>
            ))}
          </div>
        </Transition>
      )}

      {/* Transaction size input */}
      {checked && displayTransactionSize && onTransactionSizeChange && (
        <div className="flex flex-col justify-between px-3.5 py-3">
          <span className="block text-sm text-gray-500">
            Average transaction size ($)
          </span>
          <input
            id={id}
            name={id}
            type="number"
            defaultValue={transactionSize}
            className="mt-2 h-8 w-full rounded-md border-gray-300  text-sm focus:ring-fuchsia-900 focus:border-fuchsia-900"
            onChange={(e) => {
              e.preventDefault();
              onTransactionSizeChange(id, safeParseFloat(e.target.value));
            }}
          />
        </div>
      )}

      {/* Timezone for Support products */}
      {checked &&
        displaySupportPackageTimezone &&
        setSupportPackageTimezone && (
          <div className="flex flex-col justify-between px-3.5 py-3">
            <div className="flex flex-col text-sm text-gray-500">
              <span>Timezone</span>
              <select
                className="mt-2 w-full rounded-md border-gray-300 text-sm text-gray-600 focus:ring-fuchsia-900 focus:border-fuchsia-900"
                defaultValue={supportPackageTimezone ?? 'UTC'}
                onChange={(e) => {
                  e.preventDefault();
                  setSupportPackageTimezone(e.target.value as string);
                }}
              >
                <option value="UTC">UTC</option>
                <option value="PST">PST</option>
              </select>
            </div>
          </div>
        )}

      {children}
    </div>
  );
}
