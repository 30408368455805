import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import api from 'src/api';
import { useToast } from 'src/components/Toast';
import backgroundLines from 'src/images/background-lines.svg';
import fadedCircleBg from 'src/images/faded-circle-bg.svg';
import logoDealopsTarget from 'src/images/logos/dealops-target.svg';
import { usePricingFlowContext } from '../../PricingFlow';
import {
  PenguinAdditionalData,
  PenguinOpportunityData,
  PenguinPricingFlow,
  PenguinProduct,
  PricingFlowStage,
} from '../../types';
import {
  MODEL_SELECTION,
  PRICING_ADJUSTMENTS,
} from '../PenguinPricingFlowPage';
import { getVolumeRampUpMonthsForLinear } from '../Steps/Step3VolumeSelection';
import {
  getOpportunityOverviewBarFields,
  getOpportunitySFDCUrl,
} from './OpportunityOverviewBar';

type PenguinPricingFlowNotStartedPageProps = {
  validateStep: (pricingFlow: PenguinPricingFlow) => {
    value: boolean;
    error: string | null;
  };
};

/**
 *
 * @param startDate 'YYYY-MM-DD'
 * @returns true if the date string converted to UTC date is the 28-31st of the month
 */
function isDateStringAtMonthEnd(startDate: string): boolean {
  const date = new Date(
    Date.UTC(
      parseInt(startDate.substring(0, 4), 10), // Year
      parseInt(startDate.substring(5, 7), 10) - 1, // Month (0-indexed)
      parseInt(startDate.substring(8, 10), 10), // Day
    ),
  );

  const dayOfMonth = date.getUTCDate();

  return (
    dayOfMonth === 28 ||
    dayOfMonth === 29 ||
    dayOfMonth === 30 ||
    dayOfMonth === 31
  );
}

export function validateNotStartedFields(params: {
  startDate: string | undefined;
  subscriptionTerms: number | undefined;
}) {
  const { startDate, subscriptionTerms } = params;

  if (startDate == null || isDateStringAtMonthEnd(startDate)) {
    return {
      value: false,
      error: 'Please select a start date that is not end of month (28th-31st)',
    };
  }
  if (
    subscriptionTerms == null ||
    !Number.isInteger(subscriptionTerms) ||
    subscriptionTerms < 1 ||
    subscriptionTerms > 120
  ) {
    return {
      value: false,
      error: 'Subscription terms should be between 1 and 120 months',
    };
  }
  return {
    value: true,
    error: null,
  };
}

const PenguinPricingFlowNotStartedPage = (
  props: PenguinPricingFlowNotStartedPageProps,
) => {
  const { validateStep } = props;
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<PenguinPricingFlow>();

  const fields = getOpportunityOverviewBarFields(
    pricingFlow.opportunityData as PenguinOpportunityData,
  );

  const [subscriptionTerms, setSubscriptionTerms] = useState<
    number | undefined
  >(pricingFlow.additionalData?.subscriptionTerms ?? undefined);
  const [startDate, setStartDate] = useState<string | undefined>(
    pricingFlow.additionalData?.startDate ?? undefined,
  );
  const [previousPricingFlows, setPreviousPricingFlows] = useState<
    PenguinPricingFlow[]
  >([]);
  const { showToast } = useToast();
  const [showTemplates, setShowTemplates] = useState<Boolean>(false);

  async function fetchPreviousPricingFlows() {
    const response = await api.get('pricingFlows');
    if (response.data) {
      setPreviousPricingFlows(
        response.data.filter(
          (flow: PenguinPricingFlow) => flow.id !== pricingFlow.id,
        ),
      );
    }
  }

  useEffect(() => {
    fetchPreviousPricingFlows();
  }, []);

  function setArrayLengthFillWithNull(
    x: (number | null)[],
    newLength: number,
  ): (number | null)[] {
    // If the new length is less than or equal to the current length, slice the array
    if (newLength <= x.length) {
      return x.slice(0, newLength);
    }

    // If the new length is greater, create a new array with the additional elements filled with null
    const additionalElements = new Array(newLength - x.length).fill(null);
    return [...x, ...additionalElements];
  }

  function setArrayLengthFillWithNumber(
    x: number[],
    newLength: number,
    value: number,
  ): number[] {
    // If the new length is less than or equal to the current length, slice the array
    if (newLength <= x.length) {
      return x.slice(-newLength, x.length);
    }

    // If the new length is greater, create a new array with the additional elements filled with null
    const additionalElements = new Array(newLength - x.length).fill(value);
    return [...x, ...additionalElements];
  }

  function validate() {
    const { value, error } = validateNotStartedFields({
      startDate,
      subscriptionTerms,
    });
    if (!value) {
      if (!error) {
        throw new Error('Validation failed but error message is not set');
      }
      showToast({
        title: error,
        subtitle: '',
        type: 'error',
      });
    }
    return value;
  }

  const startPricingFlowOrShowTemplates = () => {
    if (validate()) {
      let newProducts = pricingFlow.products;
      if (
        previousPricingFlows.length > 0 &&
        (!newProducts || newProducts?.length == 0)
      ) {
        setShowTemplates(true);
      } else {
        startPricingFlow(null);
      }
    }
  };

  const startPricingFlow = async (pricingFlowToCloneId: string | null) => {
    if (validate()) {
      if (subscriptionTerms == null || startDate == null) {
        throw new Error(
          'subscriptionTerms and startDate should not be null, validation was incorrectly passed',
        );
      }

      let newProducts = pricingFlow.products;
      let newMinimumRampUp = pricingFlow.additionalData?.minimumRampUp;
      let manualQuote = pricingFlow.manualQuote;
      let oldSubscriptionTerms = pricingFlow.additionalData?.subscriptionTerms;

      if (pricingFlowToCloneId) {
        const flowToClone = previousPricingFlows.find(
          (flow) => flow.id === pricingFlowToCloneId,
        );
        if (flowToClone) {
          newProducts = flowToClone.products;
          manualQuote = flowToClone.manualQuote;
          oldSubscriptionTerms = flowToClone.additionalData.subscriptionTerms;
        }
      }

      if (oldSubscriptionTerms !== subscriptionTerms) {
        // If we change the subscription terms but we already configured some products with volume ramp ups, or monthly minimum ramp ups, we need to adjust the products to account for the change
        // for each product, modify volume ramp ups so the config matches the subscription terms
        newProducts = newProducts?.map((product: PenguinProduct) => {
          switch (product.rampType) {
            case 'custom':
              product.customRampUp = setArrayLengthFillWithNumber(
                product.customRampUp,
                subscriptionTerms,
                product.customRampUp[product.customRampUp.length - 1],
              );
              break;
            case 'linear_quarterly':
            case 'linear':
              product.linearRampUpConfig = {
                ...product.linearRampUpConfig,
                months: getVolumeRampUpMonthsForLinear(subscriptionTerms),
              };
              break;
          }
          return product;
        });
        // for monthly minimum ramp, modify the number of months to match the subscription terms
        newMinimumRampUp = pricingFlow.additionalData?.minimumRampUp
          ? setArrayLengthFillWithNull(
              pricingFlow.additionalData?.minimumRampUp,
              subscriptionTerms,
            )
          : null;
      }
      updateFlow({
        ...pricingFlow,
        additionalData: {
          ...(pricingFlow.additionalData as PenguinAdditionalData),
          customStage: pricingFlowToCloneId
            ? PRICING_ADJUSTMENTS
            : MODEL_SELECTION,
          subscriptionTerms,
          startDate,
          minimumRampUp: newMinimumRampUp,
        },
        products: newProducts,
        stage: pricingFlowToCloneId
          ? PricingFlowStage.CALCULATE_PRICE
          : PricingFlowStage.ADD_PRODUCTS,
        manualQuote: manualQuote,
        cloneFromPricingFlowId: pricingFlowToCloneId ?? undefined,
      });
    }
  };

  return (
    <div
      className="relative flex flex-col items-center justify-center bg-repeat-x"
      style={{ backgroundImage: `url(${backgroundLines})` }}
    >
      {/* Dealops target logo */}
      <div className="mt-20 h-24 w-24">
        <img
          className="absolute h-24 w-24"
          src={fadedCircleBg}
          alt="faded circle"
        />
        <div className="absolute ml-5 mt-5 flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
          <img className="h-7 w-7" src={logoDealopsTarget} alt="Dealops" />
        </div>
      </div>

      {/** Heading */}
      <h1 className="mx-auto max-w-7xl px-4 pt-6 text-center text-2xl font-semibold sm:px-6 lg:px-8">
        {showTemplates
          ? 'Use one of your previous quotes as a template or start from scratch'
          : 'Pricing calculator'}
      </h1>
      <p className="text-l mx-auto max-w-7xl px-4 pt-2 text-center text-gray-700 sm:px-6 lg:px-8">
        {showTemplates
          ? "If you select one of your previous quotes as a template, we'll copy over product selection and manually configured prices."
          : "Let's work on pricing for this opportunity!"}
      </p>

      {/** Opportunity details */}
      {!showTemplates ? (
        <div className="mb-20 mt-10 w-[400px] rounded-2xl border border-gray-200 bg-white">
          <div className="flex flex-col items-center justify-center gap-1 px-4 py-4">
            <span className="text-xs text-gray-500">
              Opportunity #{pricingFlow.sfdcOpportunityId}
            </span>
            <span className="text-2xl text-gray-900">
              {pricingFlow.sfdcOpportunityName}
            </span>
          </div>
          <div className="bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500">
            DETAILS
          </div>

          <div className="flex flex-col gap-2.5 px-4 py-4">
            {fields.map((field) => (
              <div
                key={field.name}
                className="flex w-full flex-row justify-between"
              >
                <span className="text-sm text-gray-500">{field.name}</span>
                <span className="text-sm font-medium text-gray-900">
                  {field.value}
                </span>
              </div>
            ))}
          </div>

          <div className="mt-5 bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500">
            TERMS
          </div>

          <div className="flex flex-row justify-between px-4 py-4">
            <div className="flex flex-col gap-2">
              <span className="text-sm text-gray-900"> Start date</span>
              <input
                type="date"
                className="text-md w-44 rounded-lg border border-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-sm text-gray-900"> Subscription terms</span>
              <input
                className="text-md w-44 rounded-lg border border-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent"
                placeholder={'in months'}
                type="number"
                min="1"
                step="1"
                id="subscriptionTerms"
                value={subscriptionTerms}
                onChange={(e) =>
                  setSubscriptionTerms(parseInt(e.target.value) || undefined)
                }
              />
            </div>
          </div>

          {/** Buttons */}
          <div className="flex items-center gap-12 border-t border-gray-200 px-4 py-4">
            <button
              onClick={() =>
                window.open(
                  getOpportunitySFDCUrl(pricingFlow),
                  '_blank',
                  'noopener,noreferrer',
                )
              }
              className="flex w-full flex-row items-center gap-2 rounded-lg px-3 py-2 text-sm font-bold text-fuchsia-900 hover:text-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
              role="link"
              tabIndex={0}
            >
              See in SFDC
              <ArrowTopRightOnSquareIcon
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>
            <button
              onClick={startPricingFlowOrShowTemplates}
              className="w-full rounded-lg bg-fuchsia-900 px-3 py-2 text-sm font-bold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
            >
              Start
            </button>
          </div>
        </div>
      ) : (
        <PreviousPricingFlows
          pricingFlows={previousPricingFlows}
          pricingFlow={pricingFlow}
          startPricingFlow={startPricingFlow}
          setShowTemplates={setShowTemplates}
        />
      )}
    </div>
  );
};

const PreviousPricingFlows = (props: {
  pricingFlow: PenguinPricingFlow;
  startPricingFlow: (pricingFlowToCloneId: string | null) => void;
  pricingFlows: PenguinPricingFlow[];
  setShowTemplates: (value: Boolean) => void;
}) => {
  const { pricingFlow, startPricingFlow, setShowTemplates, pricingFlows } =
    props;

  const [showAll, setShowAll] = useState<Boolean>(false);

  const initialNumberOfQuotes = 3;
  const flowsToShow = showAll
    ? pricingFlows
    : pricingFlows.slice(0, initialNumberOfQuotes);
  return (
    <div className="mb-20 mt-10 w-[500px] divide-y rounded-2xl border border-gray-200  text-sm">
      <div className="flex flex-col items-center justify-center gap-1 px-4 py-4">
        <span className="text-xs text-gray-500">
          Opportunity #{pricingFlow.sfdcOpportunityId}
        </span>
        <span className="text-center text-2xl text-gray-900">
          {pricingFlow.sfdcOpportunityName}
        </span>
      </div>
      <div className="bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500">
        PREVIOUS QUOTES
      </div>

      {flowsToShow.map((flow: PenguinPricingFlow, index) => (
        <div
          key={index}
          className="flex items-center justify-between gap-12 px-4 py-4"
        >
          <div className="flex-1 truncate">
            <a
              className="hover:text-fuchsia-900"
              href={`/app/pricingflow?opportunity=${flow.sfdcOpportunityId}`}
              target="_blank"
              title={flow.sfdcOpportunityName ?? ''}
            >
              {flow.sfdcOpportunityName}
            </a>
          </div>
          <div>
            <button
              onClick={() => {
                startPricingFlow(flow.id);
              }}
              className="flex inline-flex items-center justify-center gap-2 rounded-lg border border-gray-200 px-5 py-2 font-semibold text-gray-700 shadow-sm hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
            >
              Use as template
              <DocumentDuplicateIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </div>
        </div>
      ))}

      {!showAll && initialNumberOfQuotes < pricingFlows.length && (
        <div className="flex justify-center py-4">
          <button
            onClick={() => setShowAll(true)}
            className="inline-flex items-center justify-center rounded-lg border border-gray-200 px-5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
          >
            Show all previous quotes ({pricingFlows.length})
          </button>
        </div>
      )}

      <div className="flex items-center gap-12 border-t border-gray-200 px-4 py-4">
        <button
          onClick={() => setShowTemplates(false)}
          className="flex inline-flex items-center  justify-center gap-2 rounded-lg border border-gray-200 px-5 py-2 font-semibold text-gray-700 shadow-sm hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
          role="link"
          tabIndex={0}
        >
          Back
        </button>
        <div className="ml-auto">
          <button
            onClick={() => startPricingFlow(null)}
            className=" rounded-lg bg-fuchsia-900 px-5 py-2 text-sm font-bold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
          >
            Start from scratch
          </button>
        </div>
      </div>
    </div>
  );
};

export default PenguinPricingFlowNotStartedPage;
