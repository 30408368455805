import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import remarkGfm from 'remark-gfm';
import Loading from 'src/components/Loading';
import SidebarDesktop from '../components/SidebarDesktop';
import SidebarMobile from '../components/SidebarMobile';
import { Navigation } from '../dashboard/App';
import './markdown.css';

const navigation: Navigation = {
  home: {
    name: 'Return home',
    to: '/',
    icon: ArrowUturnLeftIcon,
    component: () => <Loading />,
  },
  security: {
    name: 'Security policy',
    to: '/security',
    component: () => (
      <MarkdownRenderer
        file={require('./security_policies/Security Policy (Information Security Policy).md')}
      />
    ),
  },
  'security-faq': {
    name: 'Security FAQs',
    to: '/security/security-faq',
    component: () => (
      <MarkdownRenderer
        file={require('./security_policies/Security FAQs.md')}
      />
    ),
  },
  subprocessors: {
    name: 'Subprocessors',
    to: '/security/subprocessors',
    component: () => (
      <MarkdownRenderer
        file={require('./security_policies/Subprocessors.md')}
      />
    ),
  },
  accessibility: {
    name: 'Accessibility',
    to: '/security/accessibility',
    component: () => (
      <MarkdownRenderer
        file={require('./security_policies/Accessibility Policy.md')}
      />
    ),
  },
  bcdr: {
    name: 'Business Continuity & Disaster Recovery Plan (BCDR)',
    shortName: 'BCDR',
    to: '/security/bcdr',
    component: () => (
      <MarkdownRenderer
        file={require('./security_policies/Business Continuity & Disaster Recovery Plan.md')}
      />
    ),
  },
  'incident-response': {
    name: 'Incident Response Plan',
    shortName: 'IRP',
    to: '/security/incident-response',
    component: () => (
      <MarkdownRenderer
        file={require('./security_policies/Incident Response Plan.md')}
      />
    ),
  },
  tos: {
    name: 'Terms of service',
    shortName: 'ToS',
    to: '/security/tos',
    component: () => (
      <MarkdownRenderer
        file={require('./security_policies/Terms of Service.md')}
      />
    ),
  },
  privacy: {
    name: 'Privacy Policy',
    to: '/security/privacy',
    component: () => (
      <MarkdownRenderer
        file={require('./security_policies/Privacy Policy.md')}
      />
    ),
  },
};

export default function Security() {
  const [tab, setTab] = useState(navigation['security']);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { page } = useParams();

  useEffect(() => {
    if (page) {
      setTab(navigation[page]);
    } else {
      setTab(navigation['security']);
    }
  }, [page]);

  return (
    <div>
      <SidebarMobile
        setSidebarOpen={setSidebarOpen}
        sidebarOpen={sidebarOpen}
        tab={tab}
        navigation={navigation}
      />

      <SidebarDesktop tab={tab} navigation={navigation} />

      <main className="py-10 lg:pl-40">
        <div className="px-4 sm:px-6 lg:px-8">
          {tab.component ? <tab.component /> : <Loading />}
        </div>
      </main>
    </div>
  );
}

function MarkdownRenderer(props: { file: any }) {
  const { file } = props;
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    const fetchMarkdown = async () => {
      const response = await fetch(file);
      const text = await response.text();
      setMarkdown(text);
    };
    fetchMarkdown();
  }, [file]);

  return (
    <div className="prose">
      <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
    </div>
  );
}
