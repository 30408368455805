import { usePricingFlowContext } from '../../PricingFlow';
import { CurrencyValueType, QuotePrice } from '../alpaca_price_types';
import { AlpacaPricingFlow, AlpacaSupportedCurrency } from '../alpaca_types';
import {
  convertCurrencyValueForex,
  formatCurrencyValue,
} from '../alpaca_utils';

// @TODO(fay) get rid of this...
type NumberFieldProps = {
  quotePrice: QuotePrice;
  currency: AlpacaSupportedCurrency;
};
function NumberField(props: NumberFieldProps) {
  const { pricingFlow } = usePricingFlowContext<AlpacaPricingFlow>();
  const { quotePrice } = props;
  switch (quotePrice.type) {
    case CurrencyValueType.FLAT:
    case CurrencyValueType.PERCENT:
    case CurrencyValueType.FLAT_AND_PERCENT:
      const convertedQuotePrice = convertCurrencyValueForex(
        quotePrice,
        props.currency,
        pricingFlow,
      );
      return formatCurrencyValue(convertedQuotePrice);
    case 'tiered':
      throw new Error(
        `if youre showing something tiered, use QuotePriceEditable`,
      );
  }
}

type QuotePriceUneditableProps = {
  quotePrice: QuotePrice;
  currency: AlpacaSupportedCurrency;
};
export default function QuotePriceUneditable(props: QuotePriceUneditableProps) {
  const { quotePrice } = props;
  return (
    <div className="flex h-full w-full items-center px-6 whitespace-nowrap">
      <NumberField quotePrice={quotePrice} currency={props.currency} />
    </div>
  );
}
