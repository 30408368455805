/**
 * Asserts that option is unreachable, according to TypeScript. Throws if ever
 * called at runtime.
 *
 * Designed to enforce exhaustivity in `default` cases for `switch` statements
 * and `else` branches on unions.
 *
 * @example
 * Here's an example with a `switch`, where `unreachable` ensures that if we
 * added a new member to `"apple" | "orange"`, we'd get a type error until
 * handle the new case:
 * ```
 * declare const type: "apple" | "orange"
 * switch (type) {
 *   case "apple":
 *	   console.log("granny smith")
 *		 break;
 *   case "orange"
 *	   console.log("mandarin")
 *		 break;
 *	 default:
 *	   unreachable(type)
 * }
 * ```
 */
export function unreachable(option: never): never {
  throw new Error(`Encountered unreachable branch with ${option}`);
}
