import { Menu, Transition } from '@headlessui/react';
import {
  ArrowDownCircleIcon,
  CalendarIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import { isNil } from 'lodash';
import { Fragment, useState } from 'react';
import GraphContainer, {
  RenderChildWithSize,
} from 'src/components/graphs/GraphContainer';
import { Legend } from 'src/components/graphs/helpers';
import LinePlot from 'src/components/graphs/LinePlot';
import { classNames } from '../../App';
import CustomCheckbox from '../Components/CustomCheckbox';
import {
  PriceDifferentialLine,
  PRICE_DIFFERENTIAL_DATA,
  ProductData,
} from './PriceDifferentialData';

function Header() {
  return (
    <div className="align-items flex justify-between">
      {/* Left side of header */}
      <div>
        <div className="text-2xl font-semibold">
          Price Differential Analysis
        </div>
      </div>
      {/* Right side of header */}
      <div className="flex flex-row items-center gap-2">
        <button
          type="button"
          className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
        >
          <ArrowDownCircleIcon
            className="-ml-0.5 mr-2 h-5 w-5"
            aria-hidden="true"
          />
          Export
        </button>
      </div>
    </div>
  );
}

const Tabs = () => {
  const tabs = [
    { name: 'Payments', current: true },
    { name: 'Identity Verification', current: false },
    { name: 'Mortgage Lending', current: false },
    { name: 'Financial Management', current: false },
  ];

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-pink-800 focus:outline-none focus:ring-pink-800 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <span
                key={tab.name}
                className={classNames(
                  tab.current
                    ? 'border-pink-700 text-pink-800'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium',
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

interface User {
  name: string;
  id: string;
  isChecked: boolean;
  teamId: string;
}

interface UsersFilterProps {
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  teams: Team[];
}

const UsersFilter = (props: UsersFilterProps) => {
  const { users, setUsers, teams } = props;
  const [searchTerm, setSearchTerm] = useState('');

  const handleUserSelection = (sfdcUserId: string) => {
    setUsers(
      users.map((user) =>
        user.id === sfdcUserId ? { ...user, isChecked: !user.isChecked } : user,
      ),
    );
  };

  const searchTermMatches = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const selectedUsers = users.filter((user) => user.isChecked);

  return (
    <div className="py-4">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <PlusCircleIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Users
            {selectedUsers.length > 0 && (
              <>
                <div className="border-l h-5 mx-2 border-gray-200"></div>
                <span className="inline-flex items-center px-2.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800">
                  {selectedUsers.length}
                </span>
              </>
            )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[300px]">
            <div>
              <div className="flex items-center">
                <div className="relative w-full">
                  <MagnifyingGlassIcon
                    className="absolute left-2 top-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <input
                    type="text"
                    placeholder="Search users..."
                    className="block border-none w-full py-2 pl-8 pr-3 text-sm focus:border-pink-800 focus:outline-none focus:ring-pink-800"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <hr className="border-gray-200" />
              {searchTermMatches.map(({ name, isChecked, id, teamId }, idx) => (
                <Menu.Item key={id}>
                  {({ active }) => {
                    let teamName = teams.find(
                      (team) => team.id === teamId,
                    )?.name;
                    return (
                      <div
                        className={classNames(
                          isChecked
                            ? 'font-medium text-gray-900'
                            : 'text-gray-500',
                          active ? 'bg-gray-100' : '', // on hover, add background color
                          idx === searchTermMatches.length - 1 ? 'pb-3' : '', // add bottom padding to last user
                          'flex items-center px-4 py-2 text-sm w-full',
                        )}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUserSelection(id);
                        }}
                      >
                        <CustomCheckbox isChecked={isChecked} />
                        <span className="ml-2 flex-1">
                          {name} {teamName ? `(${teamName})` : ''}
                        </span>
                      </div>
                    );
                  }}
                </Menu.Item>
              ))}

              {searchTermMatches.length === 0 && (
                <span className="block px-4 py-2 text-sm text-gray-500">
                  No users found.
                </span>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

interface Team {
  name: string;
  id: string;
  isChecked: boolean;
}

interface TeamsFilterProps {
  teams: Team[];
  setTeams: React.Dispatch<React.SetStateAction<Team[]>>;
}

const TeamsFilter = (props: TeamsFilterProps) => {
  const { teams, setTeams } = props;
  const [searchTerm, setSearchTerm] = useState('');

  const handleTeamSelection = (teamId: string) => {
    setTeams(
      teams.map((team) =>
        team.id === teamId ? { ...team, isChecked: !team.isChecked } : team,
      ),
    );
  };

  const searchTermMatches = teams.filter((team) =>
    team.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const selectedTeams = teams.filter((team) => team.isChecked);

  return (
    <div className="py-4">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <PlusCircleIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Teams
            {selectedTeams.length > 0 && (
              <>
                <div className="border-l h-5 mx-2 border-gray-200"></div>
                <span className="inline-flex items-center px-2.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800">
                  {selectedTeams.length}
                </span>
              </>
            )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[200px]">
            <div>
              <div className="flex items-center">
                <div className="relative w-full">
                  <MagnifyingGlassIcon
                    className="absolute left-2 top-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <input
                    type="text"
                    placeholder="Search teams..."
                    className="block border-none w-full py-2 pl-8 pr-3 text-sm focus:border-pink-800 focus:outline-none focus:ring-pink-800"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <hr className="border-gray-200" />
              {searchTermMatches.map(({ name, isChecked, id }, idx) => (
                <Menu.Item key={id}>
                  {({ active }) => (
                    <div
                      className={classNames(
                        isChecked
                          ? 'font-medium text-gray-900'
                          : 'text-gray-500',
                        active ? 'bg-gray-100' : '', // on hover, add background color
                        idx === searchTermMatches.length - 1 ? 'pb-3' : '', // add bottom padding to last user
                        'flex items-center px-4 py-2 text-sm w-full',
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        handleTeamSelection(id);
                      }}
                    >
                      <CustomCheckbox isChecked={isChecked} />
                      <span className="ml-2 flex-1">{name}</span>
                    </div>
                  )}
                </Menu.Item>
              ))}

              {searchTermMatches.length === 0 && (
                <span className="block px-4 py-2 text-sm text-gray-500">
                  No teams found.
                </span>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

interface ProductCategoriesFilterProps {
  productCategories: ProductCategory[];
  setProductCategories: React.Dispatch<React.SetStateAction<ProductCategory[]>>;
}

const ProductCategoriesFilter = (props: ProductCategoriesFilterProps) => {
  const { productCategories, setProductCategories } = props;
  const [searchTerm, setSearchTerm] = useState('');

  const handleProductCategorySelection = (productCategoryId: string) => {
    setProductCategories(
      productCategories.map((category) =>
        category.id === productCategoryId
          ? { ...category, isChecked: !category.isChecked }
          : category,
      ),
    );
  };

  const searchTermMatches = productCategories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const selectedCategories = productCategories.filter(
    (category) => category.isChecked,
  );

  return (
    <div className="py-4">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <PlusCircleIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Product Categories
            {selectedCategories.length > 0 && (
              <>
                <div className="border-l h-5 mx-2 border-gray-200"></div>
                <span className="inline-flex items-center px-2.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800">
                  {selectedCategories.length}
                </span>
              </>
            )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[250px]">
            <div>
              <div className="flex items-center">
                <div className="relative w-full">
                  <MagnifyingGlassIcon
                    className="absolute left-2 top-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <input
                    type="text"
                    placeholder="Search categories..."
                    className="block border-none w-full py-2 pl-8 pr-3 text-sm focus:border-pink-800 focus:outline-none focus:ring-pink-800"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <hr className="border-gray-200" />
              {searchTermMatches.map(({ name, isChecked, id }, idx) => (
                <Menu.Item key={id}>
                  {({ active }) => (
                    <div
                      className={classNames(
                        isChecked
                          ? 'font-medium text-gray-900'
                          : 'text-gray-500',
                        active ? 'bg-gray-100' : '', // on hover, add background color
                        idx === searchTermMatches.length - 1 ? 'pb-3' : '', // add bottom padding to last user
                        'flex items-center px-4 py-2 text-sm w-full',
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        handleProductCategorySelection(id);
                      }}
                    >
                      <CustomCheckbox isChecked={isChecked} />
                      <span className="ml-2 flex-1">{name}</span>
                    </div>
                  )}
                </Menu.Item>
              ))}

              {searchTermMatches.length === 0 && (
                <span className="block px-4 py-2 text-sm text-gray-500">
                  No categories found.
                </span>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
interface CompetitorsFilterProps {
  competitors: Competitor[];
  setCompetitors: React.Dispatch<React.SetStateAction<Competitor[]>>;
}

const CompetitorsFilter = (props: CompetitorsFilterProps) => {
  const { competitors, setCompetitors } = props;
  const [searchTerm, setSearchTerm] = useState('');

  const handleCompetitorSelection = (competitorId: string) => {
    setCompetitors(
      competitors.map((competitor) =>
        competitor.id === competitorId
          ? { ...competitor, isChecked: !competitor.isChecked }
          : competitor,
      ),
    );
  };

  const searchTermMatches = competitors.filter((competitor) =>
    competitor.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const selectedCompetitors = competitors.filter(
    (competitor) => competitor.isChecked,
  );

  return (
    <div className="py-4">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <PlusCircleIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Competitors
            {selectedCompetitors.length > 0 && (
              <>
                <div className="border-l h-5 mx-2 border-gray-200"></div>
                <span className="inline-flex items-center px-2.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800">
                  {selectedCompetitors.length}
                </span>
              </>
            )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[250px]">
            <div>
              <div className="flex items-center">
                <div className="relative w-full">
                  <MagnifyingGlassIcon
                    className="absolute left-2 top-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <input
                    type="text"
                    placeholder="Search competitors..."
                    className="block border-none w-full py-2 pl-8 pr-3 text-sm focus:border-pink-800 focus:outline-none focus:ring-pink-800"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <hr className="border-gray-200" />
              {searchTermMatches.map(({ name, isChecked, id }, idx) => (
                <Menu.Item key={id}>
                  {({ active }) => (
                    <div
                      className={classNames(
                        isChecked
                          ? 'font-medium text-gray-900'
                          : 'text-gray-500',
                        active ? 'bg-gray-100' : '', // on hover, add background color
                        idx === searchTermMatches.length - 1 ? 'pb-3' : '', // add bottom padding to last user
                        'flex items-center px-4 py-2 text-sm w-full',
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        handleCompetitorSelection(id);
                      }}
                    >
                      <CustomCheckbox isChecked={isChecked} />
                      <span className="ml-2 flex-1">{name}</span>
                    </div>
                  )}
                </Menu.Item>
              ))}

              {searchTermMatches.length === 0 && (
                <span className="block px-4 py-2 text-sm text-gray-500">
                  No competitors found.
                </span>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

const DateFilter = () => {
  return (
    <div className="py-4">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <CalendarIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Last 90 Days
          </Menu.Button>
        </div>
      </Menu>
    </div>
  );
};

const LineChart = ({
  name,
  lines,
}: {
  name: string;
  lines: PriceDifferentialLine[];
}) => (
  <GraphContainer
    className="h-full w-full"
    header={
      <div className="flex w-full items-center text-gray-900">
        <div className="font-semibold text-gray-900">{name}</div>
      </div>
    }
  >
    <div className="flex h-full w-full flex-col">
      <Legend className="self-end" mappings={lines} />
      <RenderChildWithSize
        className="h-full w-full overflow-hidden"
        childFunction={(width, height) => {
          return (
            <LinePlot
              width={width}
              height={height}
              start={300}
              end={2100}
              lines={lines}
              xAxisLabel="Monthly Minimum ($)"
              yAxisLabel="Price ($)"
              formatXAxis={(x: number) => {
                return `$ ${x}`;
              }}
              formatTooltip={(linesAndPoints) => {
                return (
                  <div>
                    {linesAndPoints.map(({ line, point }) => {
                      return (
                        <div key={point.value + point.xLocation}>
                          <p style={{ color: line.color }}>
                            Price: ${point.value}
                          </p>
                          <p>Monthly Minimum: ${point.xLocation}</p>
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            />
          );
        }}
      />
    </div>
  </GraphContainer>
);

const Charts = ({ productData }: { productData: ProductData[] }) => {
  return (
    <div className="grid grid-cols-2 gap-6" style={{ gridAutoRows: '400px' }}>
      {productData.map((product) => (
        <LineChart
          key={product.name}
          name={product.name}
          lines={product.lines}
        />
      ))}
    </div>
  );
};

interface ProductCategory {
  name: string;
  id: string;
  isChecked: boolean;
}
interface Competitor {
  name: string;
  id: string;
  isChecked: boolean;
}

const PriceDifferentialAnalysisDemo = () => {
  const [users, setUsers] = useState([
    {
      name: 'Behr Wu',
      id: '1',
      isChecked: false,
      teamId: '1',
    },
    {
      name: 'George Hyun',
      id: '2',
      isChecked: false,
      teamId: '2',
    },
    {
      name: 'Spyri Karasavva',
      id: '3',
      isChecked: false,
      teamId: '3',
    },
    {
      name: 'Fay Wu',
      id: '4',
      isChecked: false,
      teamId: '4',
    },
    {
      name: 'Tyler Altenhofen',
      id: '5',
      isChecked: false,
      teamId: '1',
    },
    {
      name: 'Sebastian Messier',
      id: '6',
      isChecked: false,
      teamId: '2',
    },
    {
      name: 'Roman Zubenko',
      id: '7',
      isChecked: false,
      teamId: '3',
    },
    {
      name: 'Zara McCord',
      id: '8',
      isChecked: false,
      teamId: '4',
    },
    {
      name: 'Sherry Hyun',
      id: '9',
      isChecked: false,
      teamId: '1',
    },
  ]);

  const ALL_TEAMS = [
    {
      name: 'Enterprise EMEA',
      id: '1',
      isChecked: false,
    },
    {
      name: 'Enterprise US',
      id: '2',
      isChecked: false,
    },
    {
      name: 'SME EMEA',
      id: '3',
      isChecked: false,
    },
    {
      name: 'SME US',
      id: '4',
      isChecked: false,
    },
  ];

  const [teams, setTeams] = useState(ALL_TEAMS);

  const [productCategories, setProductCategories] = useState([
    {
      name: 'Auth & Security',
      id: '1',
      isChecked: false,
    },
    {
      name: 'Payment Processing',
      id: '2',
      isChecked: false,
    },
    {
      name: 'AI & Data Services',
      id: '3',
      isChecked: false,
    },
    {
      name: 'Developer Tools & Support',
      id: '4',
      isChecked: false,
    },
    {
      name: 'Analytics & Reporting',
      id: '5',
      isChecked: false,
    },
  ]);
  const [competitors, setCompetitors] = useState([
    {
      name: 'Stripe',
      id: '1',
      isChecked: false,
    },
    {
      name: 'Adyen',
      id: '2',
      isChecked: false,
    },
    {
      name: 'Braintree',
      id: '3',
      isChecked: false,
    },
    {
      name: 'None',
      id: '4',
      isChecked: false,
    },
  ]);

  function filterProductData(
    productData: ProductData[],
    selectedUserIds: string[],
    selectedProductCategoryIds: string[],
    selectedCompetitorIds: string[],
  ) {
    // Apply Product Categories Filter

    let filteredProductData =
      selectedProductCategoryIds.length > 0
        ? productData.filter((product) =>
            selectedProductCategoryIds.includes(product.productCategoryId),
          )
        : [...productData];

    // Apply Users Filter
    return filteredProductData.map((product) => {
      // Create a new product object to avoid mutating the original productData
      const newProduct = { ...product };
      // Filter the user points based on selected user IDs
      newProduct.lines = product.lines.map((line) => {
        const filteredUserPoints = line.points.filter(
          (points) =>
            (isNil(points.userId) ||
              selectedUserIds.includes(points.userId.toString())) &&
            (isNil(points.competitorId) ||
              selectedCompetitorIds.includes(points.competitorId.toString())),
        );

        return {
          ...line,
          points: filteredUserPoints, // set points to filtered user points
        };
      });
      return newProduct;
    });
  }

  const selectedUsers = users.filter((user) => user.isChecked);
  const selectedTeams = teams.filter((team) => team.isChecked);

  // Note: teams filter takes precedence over users filter
  // If no teams are selected, then users filter is applied
  // If teams are selected, then users filter is not applied

  let filteredProducts = filterProductData(
    PRICE_DIFFERENTIAL_DATA,
    users
      .filter((user) => {
        if (selectedUsers.length === 0) {
          return true;
        }
        return user.isChecked;
      })
      .filter((user) => {
        if (selectedTeams.length === 0) {
          return true;
        }
        return selectedTeams.some(
          (team) => team.id === user.teamId && team.isChecked,
        );
      })
      .map((user) => user.id),
    productCategories
      .filter((productCategory) => productCategory.isChecked)
      .map((productCategory) => productCategory.id),
    competitors
      .filter((competitor) => {
        if (!competitors.some((c) => c.isChecked)) {
          return true;
        }
        return competitor.isChecked;
      })
      .map((competitor) => competitor.id),
  );

  console.log(filteredProducts);

  return (
    <div className="mx-auto p-6 w-full">
      <Header />
      <Tabs />
      <div className="flex flex-row gap-2">
        <UsersFilter users={users} teams={ALL_TEAMS} setUsers={setUsers} />
        <TeamsFilter teams={teams} setTeams={setTeams} />
        <ProductCategoriesFilter
          productCategories={productCategories}
          setProductCategories={setProductCategories}
        />
        <CompetitorsFilter
          competitors={competitors}
          setCompetitors={setCompetitors}
        />
        <DateFilter />
      </div>
      <Charts productData={filteredProducts} />
    </div>
  );
};

export default PriceDifferentialAnalysisDemo;
