import {
  CalendarDaysIcon,
  ClockIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import Badge, { BadgeColor } from 'src/components/Badge';
import { InlineSpinner } from 'src/components/Loading';
import { classNames } from 'src/dashboard/App';
import { formatCurrency } from '../../utils';
import RelatedDealTable from './RelatedDealTable';
import { RelatedDeal } from './types';

export default function RelatedDealDetail(props: { deal: RelatedDeal | null }) {
  return (
    <div className="w-full">
      <RelatedDealOverviewBar deal={props.deal} />
      <RelatedDealTable deal={props.deal} />
    </div>
  );
}

function RelatedDealOverviewBar(props: { deal: RelatedDeal | null }) {
  const opportunity = props.deal?.opportunity;
  const account = props.deal?.account;
  const user = props.deal?.user;

  let badgeColor: BadgeColor = 'gray';
  switch (opportunity?.StageName) {
    case 'Closed Won':
      badgeColor = 'green';
      break;
    case 'Closed Lost':
      badgeColor = 'red';
      break;
  }

  return (
    <div className="inline-block w-full rounded-lg border border-gray-200 bg-white px-8 py-6">
      <div className="flex w-full flex-row flex-wrap justify-between gap-6">
        <div className="flex flex-row gap-3">
          <div className="flex h-10 w-10 items-center justify-center rounded-full border border-gray-200 bg-green-700 font-bold text-white shadow">
            {props.deal?.name[0]}
          </div>
          <div className="flex flex-col gap-1">
            {/* @TODO(fay) the text wrap isn't nice */}
            <div
              className="text-md whitespace-nowrap text-gray-600"
              title={props.deal?.name}
            >
              {props.deal?.name}
            </div>
            <div
              className={classNames(
                account ? 'opacity-100' : 'opacity-0',
                'whitespace-nowrap text-sm text-gray-400 transition-opacity duration-500 ease-in-out',
              )}
            >
              View in SFDC
              {/* @TODO(fay) add link to salesforce? */}
            </div>
            {!account && <InlineSpinner />}
          </div>
        </div>
        <div className="flex flex-row gap-3">
          <div className="flex h-10 w-10 justify-center rounded-full border border-gray-200 shadow">
            <CalendarDaysIcon
              className="m-2 text-gray-600"
              aria-hidden="true"
            />
          </div>
          <div className="flex flex-col gap-1">
            <div className="whitespace-nowrap text-sm text-gray-400">
              Creation date
            </div>
            <div
              className={classNames(
                opportunity ? 'opacity-100' : 'opacity-0',
                'text-md whitespace-nowrap text-gray-600 transition-opacity duration-500 ease-in-out',
              )}
            >
              {opportunity ? opportunity.CreatedDate.substring(0, 10) : null}
            </div>
            {!opportunity && <InlineSpinner />}
          </div>
        </div>
        <div className="flex flex-row gap-3">
          <div className="flex h-10 w-10 justify-center rounded-full border border-gray-200 shadow">
            <GlobeAltIcon className="m-2 text-gray-600" aria-hidden="true" />
          </div>
          <div className="flex flex-col gap-1">
            <div className="whitespace-nowrap text-sm text-gray-400">
              Country
            </div>
            <div
              className={classNames(
                account ? 'opacity-100' : 'opacity-0',
                'text-md whitespace-nowrap text-gray-600 transition-opacity duration-500 ease-in-out',
              )}
            >
              {account && account?.BillingCountry}
            </div>
            {!account && <InlineSpinner />}
          </div>
        </div>
        <div className="flex flex-row gap-3">
          <div className="flex h-10 w-10 justify-center rounded-full border border-gray-200 shadow">
            <ClockIcon className="m-2 text-gray-600" aria-hidden="true" />
          </div>
          <div className="flex flex-col gap-1">
            <div className="whitespace-nowrap text-sm text-gray-400">Stage</div>
            <div
              className={classNames(
                opportunity ? 'opacity-100' : 'opacity-0',
                'text-md whitespace-nowrap text-gray-600 transition-opacity duration-500 ease-in-out',
              )}
            >
              {opportunity && (
                <Badge color={badgeColor}>{opportunity?.StageName}</Badge>
              )}
            </div>
            {!opportunity && <InlineSpinner />}
          </div>
        </div>
        <div className="flex flex-row gap-3">
          <div className="flex h-10 w-10 justify-center rounded-full border border-gray-200 shadow">
            <UserIcon className="m-2 text-gray-600" aria-hidden="true" />
          </div>
          <div className="flex flex-col gap-1">
            <div className="whitespace-nowrap text-sm text-gray-400">Owner</div>
            <div
              className={classNames(
                user ? 'opacity-100' : 'opacity-0',
                'text-md whitespace-nowrap text-gray-600 transition-opacity duration-500 ease-in-out',
              )}
            >
              {user && user?.Name}
            </div>
            {!user && <InlineSpinner />}
          </div>
        </div>
        <div className="flex flex-row gap-3">
          <div className="flex h-10 w-10 justify-center rounded-full border border-gray-200 shadow">
            <CurrencyDollarIcon
              className="m-2 text-gray-600"
              aria-hidden="true"
            />
          </div>
          <div className="flex flex-col gap-1">
            <div className="whitespace-nowrap text-sm text-gray-400">
              Expected revenue
            </div>
            <div
              className={classNames(
                opportunity ? 'opacity-100' : 'opacity-0',
                'text-md whitespace-nowrap text-gray-600 transition-opacity duration-500 ease-in-out',
              )}
            >
              {opportunity &&
                formatCurrency({
                  amount: opportunity?.ExpectedRevenue,
                  currency: 'USD',
                })}
            </div>
            {!opportunity && <InlineSpinner />}
          </div>
        </div>
      </div>
    </div>
  );
}
