import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { useToast } from 'src/components/Toast';
import { Organization, User } from '../../../types';
import { usePricingFlowContext } from '../PricingFlow';
import FlowProgressBar from './FlowProgressBar';
import Issuing from './Issuing';
import AlpacaStep1NotStarted from './Steps/AlpacaStep1NotStarted';
import AlpacaStep2ProductCategories from './Steps/AlpacaStep2ProductCategories';
import Collections from './Steps/Collections';
import DealSummary from './Steps/DealSummary';
import GlobalAccounts from './Steps/GlobalAccounts';
import Payouts from './Steps/Payouts';
import TreasuryFX from './Steps/TreasuryFX';
import {
  AlpacaCategoryName,
  AlpacaPricingFlow,
  AlpacaProductCategory,
} from './alpaca_types';
import {
  checkPricingFlowConsistency,
  makePricingFlowCurrencyValuesConsistent,
} from './alpaca_utils';

// ##AlpacaCategoriesAndSteps
// The strings in the CanonicalStepOrder must be a superset of the AlpacaCategoryName type
export type AlpacaStep =
  | AlpacaCategoryName
  | 'Opp Overview'
  | 'Quote Inputs'
  | 'Deal Summary';
export const ALPACA_CANONICAL_STEP_ORDER: AlpacaStep[] = [
  'Opp Overview',
  'Quote Inputs',
  // #AlpacaCategoryNames
  'Global Accounts',
  'Collections',
  'Treasury FX',
  'Payouts',
  'Issuing',
  'Deal Summary',
];

interface StepValidations {
  [key: string]: (pricingFlow: AlpacaPricingFlow) => {
    value: boolean;
    error: string | null;
  };
}

const StepValidations: StepValidations = {
  ['Opp Overview']: (pricingFlow: AlpacaPricingFlow) => {
    return { value: true, error: null };
  },
  ['Quote Inputs']: (pricingFlow: AlpacaPricingFlow) => {
    if ((pricingFlow.additionalData.productCategories ?? []).length === 0) {
      return {
        value: false,
        error: 'Please select at least one product category to continue',
      };
    } else if (
      pricingFlow.additionalData.productCategories?.some(
        ({ category }) => category === 'Collections',
      ) &&
      !pricingFlow.additionalData.productCategories?.some(
        ({ category }) => category === 'Global Accounts',
      )
    ) {
      return {
        value: false,
        error: 'Collections requires adding Global Accounts',
      };
    }
    return { value: true, error: null };
  },
  ['Global Accounts']: (pricingFlow: AlpacaPricingFlow) => {
    return { value: true, error: null };
  },
  ['Collections']: (pricingFlow: AlpacaPricingFlow) => {
    return { value: true, error: null };
  },
  ['Treasury FX']: (pricingFlow: AlpacaPricingFlow) => {
    return { value: true, error: null };
  },
  ['Payouts']: (pricingFlow: AlpacaPricingFlow) => {
    return { value: true, error: null };
  },
  ['Issuing']: (pricingFlow: AlpacaPricingFlow) => {
    return { value: true, error: null };
  },
  ['Deal Summary']: (pricingFlow: AlpacaPricingFlow) => {
    return { value: true, error: null };
  },
};

interface PricingFlowProps {
  user: User;
  organization: Organization;
}

export default function AlpacaPricingFlowPage(props: PricingFlowProps) {
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();

  const additionalData = pricingFlow.additionalData;
  const selectedProductCategories: AlpacaProductCategory[] =
    additionalData?.productCategories ?? [];

  const currentStep: AlpacaStep = additionalData?.customStage ?? 'Opp Overview';

  /**
   * ##AlpacaForexEffects
   */
  const previousQuoteCurrency = useRef(additionalData?.quoteCurrency);
  const previousIssuingEntityCurrencies = useRef(
    additionalData?.issuingConfig.entities.map((e) => e.issuingEntityCurrency),
  );
  const previousIssuingIsShowLocalCurrencySelected = useRef(
    additionalData?.issuingConfig.isShowLocalCurrencySelected,
  );
  // @TODO(fay) when we're done debugging we can remove the console logs

  useEffect(() => {
    function shouldRunConsistencyFunction(): boolean {
      console.log('check shouldRunConsistencyFunction');
      if (previousQuoteCurrency.current !== additionalData?.quoteCurrency) {
        console.log(
          'previousQuoteCurrency.current !== additionalData?.quoteCurrency ',
          previousQuoteCurrency.current,
          additionalData?.quoteCurrency,
        );
        return true;
      }
      if (
        !_.isEqual(
          previousIssuingEntityCurrencies.current,
          additionalData?.issuingConfig.entities.map(
            (e) => e.issuingEntityCurrency,
          ),
        )
      ) {
        console.log(
          '!_.isEqual(previousIssuingEntityCurrencies.current, additionalData?.issuingConfig.entities.map(e => e.issuingEntityCurrency)) ',
          previousIssuingEntityCurrencies.current,
          additionalData?.issuingConfig.entities.map(
            (e) => e.issuingEntityCurrency,
          ),
        );
        return true;
      }
      if (
        previousIssuingIsShowLocalCurrencySelected.current !==
        additionalData?.issuingConfig.isShowLocalCurrencySelected
      ) {
        console.log(
          'previousIssuingIsShowLocalCurrencySelected.current !== additionalData?.issuingConfig.isShowLocalCurrencySelected ',
          previousIssuingIsShowLocalCurrencySelected.current,
          additionalData?.issuingConfig.isShowLocalCurrencySelected,
        );
        return true;
      }
      return false;
    }
    if (shouldRunConsistencyFunction())
      // ##MakePricingFlowCurrencyValuesConsistent
      makePricingFlowCurrencyValuesConsistent({ pricingFlow, updateFlow });
    previousQuoteCurrency.current = additionalData?.quoteCurrency;
    previousIssuingEntityCurrencies.current =
      additionalData?.issuingConfig.entities.map(
        (e) => e.issuingEntityCurrency,
      );
    previousIssuingIsShowLocalCurrencySelected.current =
      additionalData?.issuingConfig.isShowLocalCurrencySelected;
  }, [additionalData]);

  /**
   *
   * @todo(fay) remove this later when we're not worried about the pricing flow being inconsistent
   */
  checkPricingFlowConsistency(pricingFlow);

  const { showToast } = useToast();

  const setFlowToStep = (step: AlpacaStep) => {
    const targetStepIndex = ALPACA_CANONICAL_STEP_ORDER.indexOf(step);

    for (let i = 0; i < targetStepIndex; i++) {
      const validatationStep = ALPACA_CANONICAL_STEP_ORDER[i];
      const validationResult = StepValidations[validatationStep](pricingFlow);
      if (!validationResult.value) {
        showToast({
          title: validationResult.error ?? 'Please complete all previous steps',
          subtitle: '',
          type: 'error',
        });
        step = validatationStep;
        break;
      }
    }

    if (step === currentStep) {
      return;
    }

    updateFlow({
      ...pricingFlow,
      additionalData: {
        ...pricingFlow.additionalData,
        customStage: step,
      },
    });
    // reset scroll position
    window.scrollTo(0, 0);
  };

  const nextStep = () => {
    const orderedSteps = selectedProductCategories
      .map((pc) => pc.category)
      .sort((a, b) => {
        return (
          ALPACA_CANONICAL_STEP_ORDER.indexOf(a) -
          ALPACA_CANONICAL_STEP_ORDER.indexOf(b)
        );
      });
    if (currentStep === 'Opp Overview') {
      setFlowToStep('Quote Inputs');
      return;
    } else if (currentStep === 'Quote Inputs') {
      if (orderedSteps.length > 0) {
        setFlowToStep(orderedSteps[0]);
      }
      return;
    } else if (currentStep === 'Deal Summary') {
      return;
    } else {
      const currentStepIndex = orderedSteps.indexOf(currentStep);
      const wasLastStep = currentStepIndex >= orderedSteps.length - 1;
      const nextStep = wasLastStep
        ? 'Deal Summary'
        : orderedSteps[currentStepIndex + 1];
      setFlowToStep(nextStep);
    }
  };
  const previousStep = () => {
    const orderedSteps = selectedProductCategories
      .map((pc) => pc.category)
      .sort((a, b) => {
        return (
          ALPACA_CANONICAL_STEP_ORDER.indexOf(a) -
          ALPACA_CANONICAL_STEP_ORDER.indexOf(b)
        );
      });
    if (currentStep === 'Opp Overview') {
      return;
    } else if (currentStep === 'Quote Inputs') {
      setFlowToStep('Opp Overview');
      return;
    } else if (currentStep === 'Deal Summary') {
      if (orderedSteps.length > 0) {
        setFlowToStep(orderedSteps[orderedSteps.length - 1]);
      }
      return;
    } else {
      const currentStepIndex = orderedSteps.indexOf(currentStep);
      const wasFirstStep = currentStepIndex === 0;
      const prevStep = wasFirstStep
        ? 'Quote Inputs'
        : orderedSteps[currentStepIndex - 1];
      setFlowToStep(prevStep);
    }
  };

  if (currentStep === 'Opp Overview') {
    // handling this separately because we do not show the flow progress bar at
    // this step
    return <AlpacaStep1NotStarted />;
  }

  return (
    <>
      <FlowProgressBar
        step={currentStep}
        setStep={setFlowToStep}
        customSteps={selectedProductCategories.map((pc) => pc.category)}
      />
      {currentStep === 'Quote Inputs' ? (
        <AlpacaStep2ProductCategories nextStep={nextStep} />
      ) : null}
      {currentStep === 'Treasury FX' ? (
        // TODO
        <TreasuryFX
          nextStep={nextStep}
          previousStep={previousStep}
          user={props.user}
        />
      ) : null}

      {currentStep === 'Payouts' ? (
        // DEFER
        <Payouts
          nextStep={nextStep}
          previousStep={previousStep}
          user={props.user}
        />
      ) : null}

      {currentStep === 'Global Accounts' ? (
        // DEFER
        <GlobalAccounts nextStep={nextStep} previousStep={previousStep} />
      ) : null}

      {currentStep === 'Collections' ? (
        // DEFER
        <Collections nextStep={nextStep} previousStep={previousStep} />
      ) : null}
      {currentStep === 'Issuing' ? (
        <Issuing
          nextStep={nextStep}
          previousStep={previousStep}
          user={props.user}
        />
      ) : null}

      {currentStep === 'Deal Summary' ? (
        <DealSummary
          pricingFlow={pricingFlow}
          updateFlow={updateFlow}
          nextStep={nextStep}
          previousStep={previousStep}
          user={props.user}
          organization={props.organization}
        />
      ) : null}
    </>
  );
}
