import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip as RechartTooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { formatCurrencyValue } from 'src/dashboard/PricingFlow/Alpaca/alpaca_utils';

export type Datum = {
  name: string;
  value: number;
  transparentValue: number;
  tooltipData: {};
};

type TooltipComponentProps = {
  label: any;
  payload: Payload<number, string>[];
};
// Later we can add configuration for if the y-axis is not a currency
export default function Waterfall(props: {
  data: Datum[];
  currency: string;
  TooltipComponent: React.FC<TooltipComponentProps>;
}) {
  const { data, currency, TooltipComponent } = props;
  return (
    <ResponsiveContainer width="100%">
      <BarChart
        width={650}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 0,
          bottom: 20,
          left: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          label={{
            value: 'Product',
            position: 'insideBottom',
            offset: -10,
            className: 'font-medium text-xs text-gray-900',
          }}
          tick={{ fontSize: 12, fill: '#475467' }}
          axisLine={{ stroke: '#475467' }}
        />
        <YAxis
          label={{
            value: 'Gross margin',
            angle: -90,
            offset: -36,
            position: 'insideLeft',
            className: 'font-medium text-xs text-gray-900',
          }}
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: currency,
            }).format(value)
          }
          tick={{ fontSize: 12, fill: '#475467' }}
          axisLine={{ stroke: '#475467' }}
        />
        <RechartTooltip
          content={
            <CustomTooltip currency={currency} Tooltip={TooltipComponent} />
          }
          cursor={{ fill: 'transparent' }}
        />
        <Bar
          dataKey="transparentValue"
          stackId="a"
          fill="transparent"
          radius={[0, 0, 10, 10]}
        />
        <Bar dataKey="value" stackId="a" fill="#82ca9d" radius={[4, 4, 4, 4]}>
          {data.map((item, index) => {
            if (item.name === 'Total') {
              return <Cell key={index} fill="#3B82F6" />;
            }
            if (item.value < 0) {
              return <Cell key={index} fill="#DC2626" />;
            }
            return <Cell key={index} fill="#22C55E" />;
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

interface CustomTooltipProps extends TooltipProps<number, string> {
  currency: string;
  Tooltip: React.FC<TooltipComponentProps>;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  Tooltip,
}) => {
  if (active && payload && payload.length) {
    return <Tooltip label={label} payload={payload} />;
  }

  return null;
};

export const GrossProfitAndTakeRateTooltip: React.FC<TooltipComponentProps> = ({
  label,
  payload,
}) => {
  return (
    <div className="bg-white border border-slate-200 rounded-md py-2 px-4 text-slate-700 flex flex-col gap-2 min-w-[180px]">
      <span className="">{`${label}`}</span>
      {payload.map((entry, index) => {
        if (entry.dataKey != 'value') return null;
        let color = '#9ca3af';
        if (entry.payload.value < 0) color = '#DC2626';
        if (entry.payload.value > 0) color = '#22C55E';
        if (entry.payload.name === 'Total') color = '#3B82F6';

        return (
          <div key={index} className="flex flex-col gap-1">
            <span style={{ color }}>
              Gross margin:{' '}
              {formatCurrencyValue(entry.payload.tooltipData['Gross margin'])}
            </span>
            <span key={index} style={{ color }}>
              Take rate:{' '}
              {formatCurrencyValue(entry.payload.tooltipData['Take rate'])}
            </span>
          </div>
        );
      })}
    </div>
  );
};
