import { isString } from 'lodash';
import { PricingFlowType } from './dashboard/PricingFlow/types';

export type Domain = {
  domain: string;
  stytchOrganizationId: string;
  stytchConnectionId: string;
  createdAt: Date;
  updatedAt: Date;
};
export enum Role {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export type User = {
  id: string;
  stytchMemberId: string;
  name: string | null;
  role: Role;
  organizationId: string;
  organization?: Organization;
  slackUserId: string | null;
  createdAt: Date;
  updatedAt: Date;
  salesforceUserId: string | null;
};

export type Category = {
  name: string;
  description: string;
  additionalData: Record<string, any>;
};

export type Organization = {
  id: string;
  stytchOrganizationId: string;
  name: string | null;
  users: User[];
  connections: Connection[];
  categories: Category[];
  pricingFlowType?: PricingFlowType;
  createdAt: Date;
  updatedAt: Date;
};

export type Provider = 'SALESFORCE' | 'SLACK';

export type ConnectionType = 'AMPERSAND';

export type Connection = {
  id: string;
  organizationId: string;
  organization?: Organization;
  provider: Provider;
  type: ConnectionType;
  ampersandConnectionConfig?: AmpersandConnectionConfig | null;
  ampersandConnectionConfigId: string | null;
  slackConnectionConfig?: SlackConnectionConfig | null;
  slackConnectionConfigId: string | null;
};

export type AmpersandConnectionConfig = {
  id: string;
  connectionId: string;
  installationId: string;
  instanceURL: string;
};

export type SlackConnectionConfig = {
  id: string;
  accessToken: string | null;
  teamId: string | null;
  connection?: Connection | null;
};

export interface SalesforceProduct {
  Id: string;
  Name: string;
  Type: string;
  Family: string;
  IsActive: boolean;
  IsDeleted: boolean;
  DisplayUrl: string;
  ExternalId: string;
  IsArchived: boolean;
  CreatedById: string;
  CreatedDate?: Date;
  Description: string;
  ProductCode: string;
  ProductClass: string;
  LastViewedDate?: Date;
  SystemModstamp: Date;
  LastModifiedById: string;
  LastModifiedDate: Date;
  StockKeepingUnit: string;
  LastReferencedDate?: Date;
  ExternalDataSourceId: string;
  QuantityUnitOfMeasure: string;
}

export type JSONType<T> = {
  [K in keyof T]: T[K] extends Date
    ? string // Replace Date with string
    : T[K] extends object // or Array
      ? JSONType<T[K]> // Recursively apply JSONType
      : T[K];
} & { _dateFields: string[] } & { [key: string]: any };

export function UnstringifyDates<T>(obj: JSONType<T>): T {
  const result: any = {};
  const dateFields = obj._dateFields;
  for (const key in obj) {
    if (key !== '_dateFields') {
      if (dateFields && dateFields.includes(key)) {
        if (isString(obj[key])) {
          result[key] = new Date(obj[key]);
        } else {
          console.error(
            `Error: expected a string for key ${key}, found ${typeof obj[
              key
            ]}.`,
          );
          result[key] = obj[key];
        }
      } else {
        result[key] = obj[key];
      }
    }
  }
  return result as T;
}

export type JSONValue =
  | string
  | number
  | boolean
  | null
  | JSONArray<JSONValue>
  | JSONObject
  | JSONType<any>;

export interface JSONObject {
  [key: string]: JSONValue;
}

// Make a type JSONArray which takes a type parameter T and extends Array<T>, where T is any JSONValue.
export interface JSONArray<T extends JSONValue> extends Array<T> {}

/**
 * Rule builder types
 */
export type Rule = {
  id: string;
  organizationId: string;
  groupName: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  description: string;
  ruleJson: RuleJson;
};

export type RuleJson = {
  kind: 'Rule';
  condition: AndComplexCondition;
  actions: Action[];
  state: 'active' | 'inactive';
  trigger: {
    kind: 'OpportunityTrigger';
    event: 'OpportunityEvent.CREATED'; // @TODO(fay)
  };
};

export type AndComplexCondition = {
  kind: 'ComplexCondition';
  operator: 'and';
  conditions: OrComplexCondition[];
};

export type OrComplexCondition = {
  kind: 'ComplexCondition';
  operator: 'or';
  conditions: Condition[];
};

export type Condition = {
  kind: 'Condition';
  operator: string | null;
  valueA: FunctionCall | null;
  valueB: string | null;
};

export type Action = {
  kind: 'Action';
  func: {
    name: string;
    arguments: string[];
  };
};

export type FunctionCall = {
  name: string;
  arguments: string[];
};

export type ConditionValueA = {
  fieldName: string;
  fieldType: string;
  functionCall: FunctionCall;
  objectName: string;
  possibleValues: string[];
  // possibleOperators: string[], @TODO(jacob) add "is" and "is not" or "=" and "!="
};

export interface SalesforcePricebookEntry {
  Id: string;
  Pricebook2Id: string;
  Product2Id: string;
  IsActive: boolean;
  UseStandardPrice: boolean;
  UnitPrice: number;
  CreatedById: string;
  CreatedDate: Date;
  LastModifiedById: string;
  LastModifiedDate: Date;
  SystemModstamp: Date;
  LastReferencedDate: Date;
  LastViewedDate: Date;
}

export function isSalesforcePricebookEntry(
  obj: any,
): obj is SalesforcePricebookEntry {
  return (
    obj.Id !== undefined &&
    obj.Pricebook2Id !== undefined &&
    obj.Product2Id !== undefined &&
    obj.IsActive !== undefined &&
    obj.UseStandardPrice !== undefined &&
    obj.UnitPrice !== undefined &&
    obj.CreatedById !== undefined &&
    obj.CreatedDate !== undefined &&
    obj.LastModifiedById !== undefined &&
    obj.LastModifiedDate !== undefined &&
    obj.SystemModstamp !== undefined &&
    obj.LastReferencedDate !== undefined &&
    obj.LastViewedDate !== undefined
  );
}

// #TrackSuggestedVolumeUsagePayloadSync
export interface TrackSuggestedVolumeUsagePayload {
  pricingFlowId: string;
  sfdcOpportunityId: string;
  metadata: {
    volumeSuggestion: number;
  };
}
