import { datadogRum } from '@datadog/browser-rum';
import {
  AdjustmentsHorizontalIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import api from 'src/api';
import { useToast } from 'src/components/Toast';
import { usePricingFlowContext } from '../../PricingFlow';
import ShareButton from '../../ShareButton';
import {
  PenguinPricingFlow,
  PricingFlowCommon,
  PricingFlowType,
} from '../../types';
import AdvancedSettings from '../Components/AdvancedSettings';
import BottomBar from '../Components/BottomBar';
import PenguinQuoteTable, {
  getMonthlyMinimumRampValues,
} from '../Components/PenguinQuoteTable';

function getQuoteSFDCUrl(pricingFlow: PricingFlowCommon): string {
  switch (pricingFlow.type) {
    case PricingFlowType.PENGUIN:
      const instanceUrl = pricingFlow.sfdcInstanceUrl ?? '';

      const baseUrl = instanceUrl.includes('staging')
        ? instanceUrl.replace(
            '.sandbox.my.salesforce.com',
            '--sbqq.sandbox.vf.force.com/apex/sb?id=',
          )
        : instanceUrl.replace(
            '.my.salesforce.com',
            '--sbqq.vf.force.com/apex/sb?id=',
          );

      const jsonData = {
        componentDef: 'one:alohaPage',
        attributes: {
          address: baseUrl + pricingFlow.sfdcQuoteId,
        },
        state: {},
      };

      const encodedJson = btoa(JSON.stringify(jsonData));

      return (
        instanceUrl.replace(
          'my.salesforce.com',
          'lightning.force.com/one/one.app#',
        ) + encodedJson
      );
    case PricingFlowType.COMPLEX_DEMO:
      return `${pricingFlow.sfdcInstanceUrl}/lightning/r/Quote/${pricingFlow.sfdcQuoteId}/view`;
    default:
      throw new Error('Unsupported pricing flow type');
  }
}

export default function Step4PricingCalculator(props: {
  previousStage: () => void;
}) {
  const { previousStage } = props;
  const { pricingFlow, updateFlow, restartInteractionTracking } =
    usePricingFlowContext<PenguinPricingFlow>();
  const { showToast } = useToast();

  const createQuote = async () => {
    restartInteractionTracking();
    console.log('call POST quotes?pricingFlowId={}');

    const isValid = (): boolean => {
      const monthlyMinimumRampValues = getMonthlyMinimumRampValues({
        pricingFlow,
      });
      if (monthlyMinimumRampValues != null) {
        const isIncreasing = monthlyMinimumRampValues.every(
          (value, i) => i === 0 || value >= monthlyMinimumRampValues[i - 1],
        );
        if (!isIncreasing) {
          showToast({
            title: 'Monthly minimum ramp must be increasing',
            subtitle:
              'Please check the monthly minimum ramp ups and make sure each month is the same or increasing.',
            type: 'error',
            autoDismiss: false,
          });
          return false;
        }
      }
      return true;
    };

    try {
      if (!isValid()) {
        return 'idle';
      }
      const response = await api.post(
        'quotes?pricingFlowId=' + pricingFlow.id,
        { currentPricingCurves: pricingFlow.currentPricingCurves },
      );

      const url = getQuoteSFDCUrl(response.data);
      showToast({
        title: 'Quote successfully generated!',
        subtitle: (
          <a href={url} target="_blank" className="underline">
            Click here to see your quote in SFDC
          </a>
        ),
        type: 'success',
        autoDismiss: false,
      });
      window.open(url, '_blank');
      return 'success';
    } catch (error) {
      datadogRum.addError(error);
      console.log(error);
      showToast({
        title: 'Error: could not generate quote in SFDC',
        subtitle:
          'Something went wrong, please contact us at support@dealops.com.',
        type: 'error',
        autoDismiss: false,
      });
      return 'error';
    }
  };
  const [open, setOpen] = useState(false);

  const showAdvancedSettingsModal = () => {
    setOpen(true);
  };

  return (
    <div>
      <AdvancedSettings open={open} setOpen={setOpen} />
      {/* Header */}
      <div className="flex flex-col gap-4 border-b border-gray-200 px-6 py-4 xl:flex-row xl:justify-between">
        <div className="flex flex-row items-center gap-4">
          {/* Currency icon */}
          <div className="h-14 w-14">
            <div className="absolute flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
              <CurrencyDollarIcon className="h-7 w-7" aria-hidden="true" />
            </div>
          </div>

          <div className="flex flex-col">
            <h1 className="text-lg font-semibold">
              Pricing for {pricingFlow.sfdcOpportunityName}
            </h1>
            <p className="text-base text-gray-700">
              Adjust the quote for this opportunity based on your company
              guidance.
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <button
            type="button"
            onClick={() => {
              showAdvancedSettingsModal();
            }}
            className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
          >
            <AdjustmentsHorizontalIcon
              className="-ml-0.5 mr-2 h-5 w-5"
              aria-hidden="true"
            />
            Advanced Settings
          </button>
          <ShareButton textToCopy={window.location.href} />
        </div>
      </div>
      <div className="px-8 py-4">
        <div className="pt-2">
          <PenguinQuoteTable />
        </div>
        <div className="h-32" />
      </div>
      <BottomBar
        primaryButtonProps={{
          label: 'Generate quote in SFDC',
          successLabel: 'Success!',
          errorLabel: 'Error',
          onClick: createQuote,
        }}
        secondaryButtonProps={{
          label: 'Back',
          onClick: async () => previousStage(),
        }}
      />
    </div>
  );
}
