import { datadogRum } from '@datadog/browser-rum';
import { Transition } from '@headlessui/react';
import {
  ArrowDownRightIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import _, { isNil, sum } from 'lodash';
import {
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import Badge, { BadgeColor } from 'src/components/Badge';
import { FormattedNumberField } from 'src/components/Fields';
import Waterfall, {
  Datum,
  GrossProfitAndTakeRateTooltip,
} from 'src/components/graphs/WaterfallChart';
import { useTooltip } from 'src/components/MouseTooltip';
import Tooltip from 'src/components/Tooltip';
import { classNames } from 'src/dashboard/App';
import { formatCurrency } from 'src/dashboard/utils';
import { unreachable } from 'src/typeUtils';
import {
  CurrencyValueFlat,
  CurrencyValuePercent,
  CurrencyValueType,
} from '../../Alpaca/alpaca_price_types';
import { usePricingFlowContext } from '../../PricingFlow';
import {
  FixedPrice,
  PenguinAdditionalData,
  PenguinApprovalLevels,
  PenguinManualQuote,
  PenguinPricingFlow,
  PenguinPricingFlowWithProductVolumes,
  PenguinPricingInformation,
  PenguinProductPrice,
  PenguinProductPrices,
  PenguinProductWithVolume,
  PenguinRecommendedQuote,
  PriceType,
  PricingFlowType,
  Tier,
  TieredPrice,
} from '../../types';
import EditableIndicator from './EditableIndicator';
import { MonthlySpendChart } from './MonthlySpendChart';
import AnnualCosts from './PenguinQuoteTable__AnnualCosts';
import TieredDetails from './TieredDetails';

function generateFakeCost(
  l1Price: number,
  productName: string,
  productData: PenguinProductPrice,
) {
  let hash = 0;
  for (let i = 0; i < productName.length; i++) {
    hash = (Math.imul(31, hash) + productName.charCodeAt(i)) | 0;
  }
  hash = hash >>> 0;
  const normalizedValue = (hash % 1000000) / 1000000;
  // Scale and shift the value to the desired range [0.5, 0.9]
  const mappedValue = 0.5 + normalizedValue * 0.4;

  if (productData.priceType === 'percent') {
    return (mappedValue * l1Price) / 100;
  }
  return mappedValue * l1Price;
}

function hasVolumeRampUp(product: PenguinProductWithVolume) {
  return (
    product.rampType === 'custom' ||
    product.rampType === 'linear_quarterly' ||
    product.rampType === 'linear'
  );
}

export function calculateLinearRampUp({
  steps,
  volume,
  start,
}: {
  steps: number;
  volume: number;
  start: number;
}): number[] {
  const step = (volume - start) / steps;
  return Array.from({ length: steps }, (_, i) => {
    return Math.round(Math.min(start + step * (i + 1), volume));
  });
}

function getVolumeRampUpRows(product: PenguinProductWithVolume) {
  if (product.rampType === 'custom' && product.customRampUp) {
    return product.customRampUp;
  } else if (product.rampType === 'linear' && product.linearRampUpConfig) {
    const { start, months } = product.linearRampUpConfig;
    return calculateLinearRampUp({
      steps: months,
      volume: product.volume,
      start: start,
    });
  } else if (
    product.rampType === 'linear_quarterly' &&
    product.linearRampUpConfig
  ) {
    const { start, months } = product.linearRampUpConfig;
    const numQuarters = Math.ceil(months / 3);
    const rampUpByQuarter = calculateLinearRampUp({
      steps: numQuarters,
      volume: product.volume,
      start: start,
    });
    const rampUpByMonths = _.flatten(
      rampUpByQuarter.map((valueForQuarter) => [
        valueForQuarter,
        valueForQuarter,
        valueForQuarter,
      ]),
    );
    return rampUpByMonths.slice(0, months);
  }
  return [];
}

export function calculateCostForEachMonth(params: {
  products: PenguinProductWithVolume[];
  recommendedQuote: PenguinRecommendedQuote;
  manualQuote: PenguinManualQuote;
  productPrices: PenguinProductPrices;
  subscriptionTerms: number;
}) {
  const {
    products,
    recommendedQuote,
    manualQuote,
    productPrices,
    subscriptionTerms,
  } = params;

  let monthlyCosts: number[] = Array(subscriptionTerms).fill(0);
  products.forEach((product) => {
    const manualPrice = manualQuote?.products?.[product.id];
    const quotePrice = manualPrice ?? recommendedQuote.products?.[product.id];
    const skuGroup = productPrices[product.id].skuGroup;

    if (skuGroup === 'Support' || skuGroup === 'Implementation Services') {
      return;
    }
    if (hasVolumeRampUp(product)) {
      const volumePerMonth = getVolumeRampUpRows(product);
      for (let i = 0; i < monthlyCosts.length; i++) {
        // The volume ramp up could be shorter than the subscription term,
        // so for the remaining months we would just use the volume at scale
        const volumeAtThisMonth = volumePerMonth[i] ?? product.volume;

        monthlyCosts[i] += monthlyRevenueForQuote(
          quotePrice,
          volumeAtThisMonth,
          product.transactionSize,
          productPrices[product.id],
        );
      }
    } else {
      const cost = monthlyRevenueForQuote(
        quotePrice,
        product.volume,
        product.transactionSize,
        productPrices[product.id],
      );

      for (let i = 0; i < monthlyCosts.length; i++) {
        monthlyCosts[i] += cost;
      }
    }
  });
  return monthlyCosts;
}

export function calculateFinalMonthlyCost({
  products,
  recommendedQuote,
  manualQuote,
  productPrices,
  ignoreProfessionalServices = false,
  useSelfServeInsteadOfManualPrice = false,
}: {
  products: PenguinProductWithVolume[];
  recommendedQuote: PenguinRecommendedQuote;
  manualQuote: PenguinManualQuote;
  productPrices: PenguinProductPrices;
  ignoreProfessionalServices?: boolean;
  useSelfServeInsteadOfManualPrice?: boolean;
}) {
  let monthlyCost = 0;
  products.forEach((product) => {
    const manualPrice = manualQuote?.products?.[product.id];
    const quotePrice = useSelfServeInsteadOfManualPrice
      ? { type: 'fixed' as const, price: productPrices[product.id].selfServe }
      : (manualPrice ?? recommendedQuote.products?.[product.id]);

    const skuGroup = productPrices[product.id].skuGroup;

    if (ignoreProfessionalServices) {
      if (skuGroup === 'Support' || skuGroup === 'Implementation Services') {
        return;
      }
    }

    monthlyCost += monthlyRevenueForQuote(
      quotePrice,
      product.volume,
      product.transactionSize,
      productPrices[product.id],
    );
  });
  return monthlyCost;
}

export function getVolumeForEachTier({
  tiers,
  volume,
}: {
  tiers: Tier[];
  volume: number;
}): number[] {
  let remainingVolume = volume;
  const volumeForEachTier = new Array(tiers.length).fill(0);

  for (let index = 0; index < tiers.length; index++) {
    const currentTier: Tier = tiers[index];
    const nextTier: Tier | undefined = tiers[index + 1];

    const volumeAvailableForCurrentTier = nextTier
      ? nextTier.minimum - currentTier.minimum
      : Infinity;

    const volumeThatCountsTowardCurrentTier = Math.min(
      remainingVolume,
      volumeAvailableForCurrentTier,
    );
    volumeForEachTier[index] = volumeThatCountsTowardCurrentTier;

    remainingVolume -= volumeThatCountsTowardCurrentTier;
    if (remainingVolume <= 0) {
      break;
    }
  }
  return volumeForEachTier;
}

export function monthlyRevenueForQuote(
  productQuotePrice: FixedPrice | TieredPrice,
  expectedVolume: number,
  transactionSize: number | undefined,
  productData: PenguinProductPrice,
): number {
  const priceBilledForNumberOfMonths =
    productData.priceBilledForNumberOfMonths ?? 1;

  if (priceBilledForNumberOfMonths == null) {
    datadogRum.addError(new Error(`priceBilledForNumberOfMonths is null`), {
      message: `priceBilledForNumberOfMonths is null for ${productData.name}, falling back to 1`,
    });
  }

  switch (productQuotePrice.type) {
    case 'fixed':
      if (transactionSize == null) {
        if (productData.priceType === 'percent') {
          return (
            (expectedVolume * productQuotePrice.price) /
            (priceBilledForNumberOfMonths * 100)
          );
        } else {
          return (
            (expectedVolume * productQuotePrice.price) /
            priceBilledForNumberOfMonths
          );
        }
      } else {
        const priceCap = productData.priceCap ?? productQuotePrice.price;
        const priceFloor = productData.priceFloor ?? productQuotePrice.price;
        return (
          expectedVolume *
          Math.max(
            priceFloor,
            Math.min(
              priceCap,
              (productQuotePrice.price * transactionSize * 0.01) /
                priceBilledForNumberOfMonths,
            ),
          )
        );
      }
    case 'tiered':
      const { tiers } = productQuotePrice;
      if (tiers.length < 2) {
        throw new Error(
          `Tiered quote must have at least two tiers, got ${tiers.length}`,
        );
      }

      const volumeForTiers = getVolumeForEachTier({
        tiers: tiers,
        volume: expectedVolume,
      });

      let revenue = 0;
      for (let [tier, volume] of _.zip<Tier, number>(tiers, volumeForTiers) as [
        Tier,
        Number,
      ][]) {
        revenue += (volume as number) * tier.price;
      }

      if (productData.priceType === 'percent') {
        return revenue / (priceBilledForNumberOfMonths * 100);
      }
      return revenue / priceBilledForNumberOfMonths;
    default:
      unreachable(productQuotePrice);
  }
}

export default function PenguinQuoteTable(props: {}) {
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<PenguinPricingFlowWithProductVolumes>();
  const { recommendedQuote, manualQuote, products, approvalLevels } =
    pricingFlow;

  const productPrices =
    pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo;

  const [productIdToEditTiers, setProductIdToEditTiers] = useState<
    string | undefined
  >(undefined);

  const [isTierEditorOpen, setIsTierEditorOpen] = useState(false);

  const showTierEditorForProduct = useCallback(
    (productId: string) => {
      setProductIdToEditTiers(productId);
      setIsTierEditorOpen(true);
    },
    [setProductIdToEditTiers, setIsTierEditorOpen],
  );

  const closeTierEditor = useCallback(
    () => setIsTierEditorOpen(false),
    [setIsTierEditorOpen],
  );

  if (!recommendedQuote || !productPrices) {
    console.log('ERROR: no recommended quote');
    // @TODO(fay) better error message about how we couldn't generate a recommendation and log the error
    return <>Couldn't generate recommended pricing</>;
  } else {
    // loaded
    console.log('Loaded recommendedQuote and productPrices');
  }

  const monthlyCost = calculateFinalMonthlyCost({
    products,
    recommendedQuote,
    manualQuote,
    productPrices,
    ignoreProfessionalServices: true,
  });

  const { professionalServicesProducts, mainProducts } = products.reduce(
    (acc, product: PenguinProductWithVolume) => {
      const group = ['Support', 'Implementation Services'].includes(
        productPrices[product.id].skuGroup,
      )
        ? 'professionalServicesProducts'
        : 'mainProducts';
      acc[group].push(product);
      return acc;
    },
    {
      professionalServicesProducts: [] as PenguinProductWithVolume[],
      mainProducts: [] as PenguinProductWithVolume[],
    },
  );

  return (
    <div className="flex flex-col">
      <TieredDetails
        isOpen={isTierEditorOpen}
        productPrices={productPrices}
        pricingFlow={pricingFlow}
        updateFlow={updateFlow}
        productIdToEdit={productIdToEditTiers}
        onCancel={closeTierEditor}
        onSave={closeTierEditor}
      />

      {mainProducts.length > 0 && (
        <ProductTable
          sectionName="Products"
          products={mainProducts}
          productPrices={productPrices}
          recommendedQuote={recommendedQuote}
          manualQuote={manualQuote}
          approvalLevels={approvalLevels}
          updateFlow={updateFlow}
          penguinOpportunityPricingFlow={pricingFlow}
          showTierEditorForProduct={showTierEditorForProduct}
          showVolumeColumn={true}
          subscriptionTerms={pricingFlow.additionalData.subscriptionTerms}
        />
      )}

      {professionalServicesProducts.length > 0 && (
        <ProductTable
          // @ts-ignore
          sectionName={
            // @ts-ignore
            pricingFlow.type === PricingFlowType.COMPLEX_DEMO
              ? 'Support'
              : 'Platform and Professional Services'
          }
          products={professionalServicesProducts}
          productPrices={productPrices}
          recommendedQuote={recommendedQuote}
          manualQuote={manualQuote}
          approvalLevels={approvalLevels}
          updateFlow={updateFlow}
          penguinOpportunityPricingFlow={pricingFlow}
          showTierEditorForProduct={showTierEditorForProduct}
          showVolumeColumn={false}
          subscriptionTerms={pricingFlow.additionalData.subscriptionTerms}
          // @ts-ignore
          isSupportProduct={pricingFlow.type === PricingFlowType.COMPLEX_DEMO}
        />
      )}

      <div className="border-dash mb-4 mt-2 h-0 self-stretch border-b-2 border-dashed border-gray-200"></div>

      <div className="self-end">
        <MonthlyMinimum
          recommendedQuote={recommendedQuote}
          manualQuote={manualQuote}
          updateFlow={updateFlow}
          pricingFlow={pricingFlow}
          monthlyCost={monthlyCost}
        />

        <AnnualCosts pricingFlow={pricingFlow} productPrices={productPrices} />
      </div>
      <MonthlySpendChart
        pricingFlow={pricingFlow}
        productPrices={productPrices}
      />
      <PenguinWaterfallChart />
    </div>
  );
}

function ProductTable(props: {
  sectionName: string;
  products: PenguinProductWithVolume[];
  productPrices: PenguinProductPrices;
  recommendedQuote: PenguinRecommendedQuote;
  manualQuote: PenguinManualQuote;
  approvalLevels: PenguinApprovalLevels;
  updateFlow: (
    flow: PenguinPricingFlowWithProductVolumes,
    showLoading?: boolean,
  ) => void;
  penguinOpportunityPricingFlow: PenguinPricingFlowWithProductVolumes;
  showTierEditorForProduct: (product: string) => void;
  showVolumeColumn: boolean;
  subscriptionTerms: number;
  isSupportProduct?: boolean;
}) {
  const { setTooltipText } = useTooltip();
  const {
    sectionName,
    products,
    productPrices,
    recommendedQuote,
    manualQuote,
    updateFlow,
    approvalLevels,
    penguinOpportunityPricingFlow,
    showTierEditorForProduct,
    showVolumeColumn,
    subscriptionTerms,
  } = props;

  const monthlyRevenue = calculateFinalMonthlyCost({
    products,
    recommendedQuote,
    manualQuote,
    productPrices,
  });
  const totalGrossMargin =
    monthlyRevenue -
    sum(
      products.map((product) => {
        const { L1Price, suggestedPrice } = getL1AndSuggestedPriceForProduct({
          productPrices: productPrices,
          product: product,
          pricingFlow: penguinOpportunityPricingFlow,
        });
        return (
          product.volume *
          generateFakeCost(L1Price, product.name, productPrices[product.id])
        );
      }),
    );

  const [selectedQuotePrices, setSelectedQuotePrices] = useState<boolean[]>(
    new Array(products.length).fill(false),
  );
  useEffect(() => {
    // keep the tooltipText up to date with the selectedQuotePrices
    if (_.some(selectedQuotePrices)) {
      const selectQuotes = products
        .filter((p, i) => selectedQuotePrices[i])
        .map((product) => {
          const recommendedPrice = recommendedQuote.products[product.id];
          const manualPrice = manualQuote?.products?.[product.id];
          const quotePrice = manualPrice ?? recommendedPrice ?? 0;
          return fixedPrice(quotePrice);
        });
      const addLines =
        '  ' + selectQuotes.map((p) => `$${p.toFixed(3)}`).join('\n+ ');
      const sumOfSelectedQuotes = _.sum(selectQuotes);
      setTooltipText(`${addLines}\n= $${sumOfSelectedQuotes.toFixed(3)}`);
    }
    return () => {
      setTooltipText(null);
    };
  }, [selectedQuotePrices]);

  useEffect(() => {
    // clear selectedQuotePrices on any other click event
    function clearSelectedQuotePrices() {
      setSelectedQuotePrices(new Array(products.length).fill(false));
    }
    document.addEventListener('click', clearSelectedQuotePrices);
    return () => {
      document.removeEventListener('click', clearSelectedQuotePrices);
    };
  }, []);

  return (
    <div className="my-2 w-full self-stretch">
      <div className="rounded-xl border border-gray-200 bg-white">
        <table className="h-full min-w-full border-separate border-spacing-0">
          <QuoteTableHeader
            sectionName={sectionName}
            showVolumeColumn={showVolumeColumn}
          />
          <tbody>
            {products.length > 0
              ? products.map((product: PenguinProductWithVolume, idx) => {
                  const hasTopBorder =
                    idx === 0 || !selectedQuotePrices[idx - 1];

                  const hasBottomBorder =
                    idx === selectedQuotePrices.length - 1 ||
                    !selectedQuotePrices[idx + 1];
                  return (
                    <CurrentQuoteTableRow
                      isSupportProduct={props.isSupportProduct}
                      key={product.id}
                      product={product}
                      productPrices={productPrices}
                      recommendedQuote={recommendedQuote}
                      manualQuote={manualQuote}
                      approvalLevels={approvalLevels}
                      updateFlow={updateFlow}
                      penguinOpportunityPricingFlow={
                        penguinOpportunityPricingFlow
                      }
                      showTierEditorForProduct={showTierEditorForProduct}
                      showVolumeColumn={showVolumeColumn}
                      subscriptionTerms={subscriptionTerms}
                      onQuotePriceCtrlClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        const focusedElement: HTMLElement | null =
                          document.activeElement as HTMLElement;
                        if (
                          focusedElement &&
                          typeof focusedElement.blur === 'function'
                        ) {
                          focusedElement.blur();
                        }

                        const copy = [...selectedQuotePrices];
                        copy[idx] = !copy[idx];
                        setSelectedQuotePrices(copy);
                      }}
                      quotePriceClassName={
                        selectedQuotePrices[idx]
                          ? classNames(
                              'border-dashed border-x border-x-violet-600',
                              hasTopBorder
                                ? 'border-t border-t-violet-600'
                                : '',
                              hasBottomBorder
                                ? 'border-b border-b-violet-600'
                                : '',
                            )
                          : 'border-x border-x-transparent'
                      }
                    />
                  );
                })
              : null}
          </tbody>
          <QuoteTableFooter
            monthlyRevenue={monthlyRevenue}
            totalGrossMargin={totalGrossMargin}
            totalColumns={showVolumeColumn ? 10 : 9}
            isSupportProduct={props.isSupportProduct}
          />
        </table>
      </div>
    </div>
  );
}

function QuoteTableFooter(props: {
  isSupportProduct?: boolean;
  monthlyRevenue: number;
  totalGrossMargin: number;
  totalColumns: number;
}) {
  const {
    monthlyRevenue: monthlyRevenue,
    totalGrossMargin,
    totalColumns,
  } = props;
  return (
    <tfoot>
      <tr>
        <td
          colSpan={totalColumns - 3}
          className="whitespace-nowrap rounded-bl-xl bg-slate-50 px-6 py-4"
        ></td>
        <th
          scope="col"
          className="bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          {props.isSupportProduct ? '' : 'Totals'}
        </th>
        <th
          scope="col"
          className="rounded-br-xl bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          {props.isSupportProduct
            ? ''
            : formatCurrency({
                amount: monthlyRevenue,
                currency: 'USD',
                rounding: true,
              })}
        </th>
        <th
          scope="col"
          className="rounded-br-xl bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          {props.isSupportProduct
            ? ''
            : formatCurrency({
                amount: totalGrossMargin,
                currency: 'USD',
                rounding: true,
              })}
        </th>
      </tr>
    </tfoot>
  );
}

function QuoteTableHeader(props: {
  sectionName: string;
  showVolumeColumn: boolean;
}) {
  return (
    <thead>
      <tr>
        <th
          scope="col"
          className="sticky top-0 z-10 w-full rounded-tl-xl border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
        >
          {props.sectionName}
        </th>
        {props.showVolumeColumn && (
          <th
            scope="col"
            className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
          >
            Volume
          </th>
        )}
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Rack rate
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          L1 price
        </th>
        <th
          scope="col"
          className="has-tooltip sticky top-0 z-20 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Cost
        </th>
        <th
          scope="col"
          className="has-tooltip sticky top-0 z-20 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Suggested price
          <span className="tooltip z-20 -ml-60 -mt-20 whitespace-nowrap rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white shadow-sm dark:bg-gray-700">
            We take your products and estimated monthly volumes and we come up{' '}
            <br />
            with an overall monthly minimum and price per product that minimizes{' '}
            <br />
            the approval level needed per product.
          </span>
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Quote price
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Approval level
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden rounded-tr-xl  border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Est. monthly revenue
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden rounded-tr-xl  border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Gross Margin
        </th>
      </tr>
    </thead>
  );
}

function MinimumRampUpRow(props: {
  isLastRow: boolean;
  quoteValue: number;
  percentageOfMonthlyRevenue: number;
  monthlyCost: number;
  updateValue: (value: number) => void;
  month: number;
}) {
  const {
    isLastRow,
    quoteValue,
    percentageOfMonthlyRevenue,
    monthlyCost,
    updateValue,
    month,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const numDecimalPlaces = 3;
  const suggestedMinimum = _.round(0.6 * monthlyCost, numDecimalPlaces);

  return (
    <>
      <td className="flex flex-row items-center gap-2 bg-gray-100 px-6 py-4 pr-24 text-sm text-gray-500">
        <ArrowDownRightIcon
          className="h-4 w-4 text-gray-400"
          aria-hidden="true"
        />
        Month {month}
      </td>
      <td className="bg-gray-100 px-6 text-sm text-gray-500">
        {!isLastRow && (
          <button
            onClick={() => {
              updateValue(suggestedMinimum);
            }}
          >
            <Badge color="green">
              {formatCurrency({
                amount: suggestedMinimum,
                currency: 'USD',
                rounding: true,
                minimumFractionDigits: numDecimalPlaces,
              })}
            </Badge>
          </button>
        )}
      </td>
      <td
        className="h-full bg-gray-100 text-sm text-gray-500"
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        {isLastRow ? (
          <div className="px-4 font-semibold">
            {formatCurrency({
              amount: quoteValue,
              currency: 'USD',
              rounding: true,
              minimumFractionDigits: 3,
            })}
          </div>
        ) : (
          <EditableIndicator backgroundColor="gray-100" className="pl-4">
            <FormattedNumberField
              type="text"
              value={quoteValue}
              numberDecimals={3}
              currencyPricing={true}
              className="w-0 grow cursor-pointer border-none bg-transparent px-0 text-sm text-gray-500 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
              updateValue={updateValue}
              prefix="$"
            />
          </EditableIndicator>
        )}
      </td>
      <td className="whitespace-nowrap bg-gray-100 align-middle">
        <span className="px-6 text-sm text-gray-500">
          {formatCurrency({
            amount: monthlyCost,
            currency: 'USD',
            rounding: true,
            minimumFractionDigits: 3,
          })}
        </span>
      </td>
      <td className="bg-gray-100">
        <span className="px-6 text-sm text-gray-500">
          {_.isFinite(percentageOfMonthlyRevenue)
            ? `${percentageOfMonthlyRevenue}%`
            : 'N/A'}
        </span>
      </td>
    </>
  );
}

export function getMonthlyMinimumRampValues({
  pricingFlow,
}: {
  pricingFlow: PenguinPricingFlow;
}): number[] | null {
  // If there is no minimumRampUp return null
  // If there is a ramp returns the quote values for each month with default value set
  const minimumRampUp = pricingFlow.additionalData.minimumRampUp;
  const hasMonthlyMinimumRamp = Array.isArray(minimumRampUp);
  if (hasMonthlyMinimumRamp) {
    const { recommendedQuote, manualQuote } = pricingFlow;
    const monthlyMinimum =
      manualQuote?.monthlyMinimum ?? recommendedQuote.monthlyMinimum;
    return minimumRampUp.map((value) => value ?? monthlyMinimum);
  } else {
    return null;
  }
}

function MonthlyMinimum(props: {
  recommendedQuote: PenguinRecommendedQuote;
  manualQuote: PenguinManualQuote;
  monthlyCost: number;
  updateFlow: (
    flow: PenguinPricingFlowWithProductVolumes,
    showLoading?: boolean,
  ) => void;
  pricingFlow: PenguinPricingFlowWithProductVolumes;
}) {
  const {
    recommendedQuote,
    manualQuote,
    updateFlow,
    pricingFlow,
    monthlyCost,
  } = props;

  const idFixedRadio = useId();
  const idRampedRadio = useId();

  // @ts-ignore
  const monthlyMinimums: any =
    // @ts-ignore
    pricingFlow.pricingSheetData.countryPricingSheets['us'].monthlyMinTierList;

  const monthlyMinimumRampValues = getMonthlyMinimumRampValues({ pricingFlow });
  const hasMonthlyMinimumRamp = monthlyMinimumRampValues !== null;

  const [showRampUp, setShowRampUp] = useState(false);

  if (!monthlyMinimums) {
    return null;
  }
  function setCustomRampUpRow(idx: number, value: number | null) {
    let newMinimumRampUp = pricingFlow.additionalData.minimumRampUp;
    if (newMinimumRampUp == null) {
      throw new Error('tried to update a minimumRampUp that does not exist');
    }
    newMinimumRampUp[idx] = value;

    updateFlow(
      {
        ...pricingFlow,
        additionalData: {
          ...(pricingFlow.additionalData as PenguinAdditionalData),
          minimumRampUp: newMinimumRampUp,
        },
      },
      false,
    );
  }
  const monthlyMinimum =
    manualQuote?.monthlyMinimum ?? recommendedQuote.monthlyMinimum;

  const costForEachMonth = calculateCostForEachMonth({
    products: pricingFlow.products,
    recommendedQuote: pricingFlow.recommendedQuote,
    manualQuote: pricingFlow.manualQuote,
    productPrices: (
      pricingFlow.pricingSheetData.countryPricingSheets as {
        [key: string]: { productInfo: PenguinProductPrices };
      }
    )['us'].productInfo,
    subscriptionTerms: pricingFlow.additionalData.subscriptionTerms,
  });

  const percentageOfEstMonthlyUsage = Math.round(
    ((manualQuote?.monthlyMinimum ?? recommendedQuote.monthlyMinimum) /
      monthlyCost) *
      100,
  );
  return (
    <div>
      <div className="border-gray-20 mb-2 flex w-full items-center justify-between rounded-xl border bg-white px-6 py-3.5">
        Monthly minimum type
        <div>
          <fieldset className="flex items-center space-x-2">
            <label
              htmlFor={idFixedRadio}
              className="flex items-center space-x-2"
            >
              <span className="ml-3 block text-sm leading-6 text-gray-700">
                Fixed
              </span>
              <input
                id={idFixedRadio}
                checked={!hasMonthlyMinimumRamp}
                type="radio"
                className="h-4 w-4 border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900"
                onChange={(e) => {
                  updateFlow(
                    {
                      ...pricingFlow,
                      additionalData: {
                        ...pricingFlow.additionalData,
                        minimumRampUp: null,
                      },
                    },
                    false,
                  );
                }}
              />
            </label>
            <label
              htmlFor={idRampedRadio}
              className="flex items-center space-x-2"
            >
              <span className="ml-3 block text-sm leading-6 text-gray-600">
                Ramped
              </span>
              <input
                id={idRampedRadio}
                checked={hasMonthlyMinimumRamp}
                type="radio"
                className="h-4 w-4 border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900"
                onChange={(e) => {
                  setShowRampUp(true);
                  updateFlow(
                    {
                      ...pricingFlow,
                      additionalData: {
                        ...pricingFlow.additionalData,
                        minimumRampUp: Array(
                          pricingFlow.additionalData.subscriptionTerms,
                        ).fill(null),
                      },
                    },
                    false,
                  );
                }}
              />
            </label>
          </fieldset>
        </div>
      </div>
      <div className="rounded-xl border border-gray-200 bg-white">
        <table className="h-full min-w-full border-separate border-spacing-0">
          <thead>
            <tr>
              <th
                scope="col"
                className="rounded-tl-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
              ></th>
              <Tooltip
                as="th"
                scope="col"
                className="has-tooltip border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-filter"
                location="TOP"
                text="We suggest ~60% of Est. monthly usage revenue"
              >
                <>Suggested minimum</>
              </Tooltip>
              <th
                scope="col"
                className="rounded-tr-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
              >
                Quote minimum
              </th>

              <Tooltip
                as="th"
                scope="col"
                className="rounded-tr-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-filter"
                text={
                  <span>
                    The sum of est. usage revenue from all products
                    <br />
                    for each month, accounting for volume ramp ups
                  </span>
                }
                location="TOP"
              >
                <>Est. monthly usage revenue</>
              </Tooltip>
              <Tooltip
                as="th"
                scope="col"
                className="rounded-tr-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
                text={
                  <span>
                    Quote minimum divided by <br />
                    Est. monthly usage revenue
                  </span>
                }
                location="LEFT"
              >
                % of est monthly usage revenue
              </Tooltip>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="flex h-full flex-row items-center whitespace-nowrap px-4 py-4 pr-24">
                {hasMonthlyMinimumRamp ? (
                  <button
                    className=" mr-2 flex h-5 w-5 items-center justify-center rounded-full border border-fuchsia-950 bg-fuchsia-100"
                    onClick={() => {
                      setShowRampUp(!showRampUp);
                    }}
                  >
                    {showRampUp ? (
                      <ChevronDownIcon className="h-3 w-3" aria-hidden="true" />
                    ) : (
                      <ChevronRightIcon
                        className="h-3 w-3"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                ) : (
                  <div className="mr-2 h-5 w-5"></div>
                )}

                <span className="text-sm font-medium text-gray-900">
                  Monthly minimum
                </span>
              </td>
              <td className="px-6 py-4 align-middle">
                <button
                  onClick={() => {
                    const newPenguinOpportunityPricingFlow = {
                      ...pricingFlow,
                      manualQuote: {
                        ...manualQuote,
                        monthlyMinimum: recommendedQuote.monthlyMinimum,
                      },
                    };
                    updateFlow(newPenguinOpportunityPricingFlow, false);
                  }}
                >
                  <Badge color="green">
                    {formatCurrency({
                      amount: recommendedQuote.monthlyMinimum,
                      currency: 'USD',
                      rounding: false,
                    })}
                  </Badge>
                </button>
              </td>
              <td className="overflow-show h-full w-full p-0 align-top">
                {/* Quote minimum */}
                <EditableIndicator className="pl-4">
                  <FormattedNumberField
                    type="text"
                    value={monthlyMinimum}
                    numberDecimals={3}
                    currencyPricing={true}
                    className="w-0 grow cursor-pointer border-none bg-transparent px-0 text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
                    updateValue={(value: number) => {
                      const newPenguinOpportunityPricingFlow = {
                        ...pricingFlow,
                        manualQuote: {
                          ...manualQuote,
                          monthlyMinimum: value,
                        },
                      };
                      updateFlow(newPenguinOpportunityPricingFlow, false);
                    }}
                    prefix="$"
                  />
                </EditableIndicator>
              </td>
              <td className="whitespace-nowrap px-6 py-4 pr-24 align-middle">
                <span className="text-sm text-gray-500">
                  {formatCurrency({
                    amount: monthlyCost,
                    currency: 'USD',
                    rounding: true,
                    minimumFractionDigits: 3,
                  })}
                </span>
              </td>
              <td className="whitespace-nowrap px-6 py-4 pr-24 align-top align-middle">
                <span className="text-sm text-gray-500">
                  {_.isFinite(percentageOfEstMonthlyUsage)
                    ? `${percentageOfEstMonthlyUsage}%`
                    : 'N/A'}
                </span>
              </td>
            </tr>

            {/* Monthly minimum ramp up rows */}
            {monthlyMinimumRampValues &&
              monthlyMinimumRampValues.map((quoteValue, idx) => {
                const isLastRow = idx === monthlyMinimumRampValues.length - 1;
                const percentageOfMonthlyRevenue = Math.round(
                  (quoteValue / costForEachMonth[idx]) * 100,
                );
                const updateValue = (value: number) => {
                  // this state occurs when a user just clicks on the value and doesn't change it
                  if (value === monthlyMinimum) {
                    setCustomRampUpRow(idx, null);
                  } else {
                    setCustomRampUpRow(idx, value);
                  }
                };
                const month = idx + 1;
                const show = hasMonthlyMinimumRamp && showRampUp;

                return (
                  <Transition
                    key={idx}
                    as="tr"
                    className="overflow-hidden"
                    show={show}
                    enter="transition-all duration-300"
                    enterFrom="max-h-0 opacity-0"
                    enterTo="max-h-[48rem] opacity-100"
                    leave="transition-all duration-300"
                    leaveFrom="max-h-[48rem] opacity-100"
                    leaveTo="max-h-0 opacity-0"
                  >
                    <MinimumRampUpRow
                      isLastRow={isLastRow}
                      quoteValue={quoteValue}
                      percentageOfMonthlyRevenue={percentageOfMonthlyRevenue}
                      monthlyCost={costForEachMonth[idx]}
                      updateValue={updateValue}
                      month={month}
                    />
                  </Transition>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function fixedPrice(productQuotePrice: FixedPrice | TieredPrice): number;
function fixedPrice(productQuotePrice: undefined): undefined;
function fixedPrice(
  productQuotePrice?: FixedPrice | TieredPrice,
): number | undefined;
function fixedPrice(
  productQuotePrice?: FixedPrice | TieredPrice,
): number | undefined {
  if (productQuotePrice == null) {
    return undefined;
  }
  if (productQuotePrice.type !== 'fixed') {
    throw new Error(
      `fixedPrice fxn: only can handle fixed prices, got type=${productQuotePrice.type}`,
    );
  }

  return productQuotePrice.price;
}

export const approvalLevelsHumanReadable = {
  '-1': 'Tiered',
  0: 'No approval needed',
  1: '1. Manager',
  2: '2. Head of Function',
  3: '3. Deal Desk',
  4: '4. Finance',
} as { [key: number]: string };

export const approvalBadgeColors = {
  '-1': 'gray',
  0: 'green',
  1: 'yellow',
  2: 'orange',
  3: 'orange',
  4: 'red',
} as { [key: number]: BadgeColor };

const findClosestVolumeTier = (
  product: PenguinProductWithVolume,
  productInfo: PenguinPricingInformation,
) => {
  let closestVolumeTier = null;
  let delta = Infinity;
  const volume = product.volume;
  for (const volumeTier of productInfo.monthlyVolumeTiers ?? []) {
    if (Math.abs(volumeTier.tier - volume) < delta) {
      closestVolumeTier = volumeTier;
      delta = Math.abs(closestVolumeTier.tier - volume);
    }
  }

  return closestVolumeTier;
};

const findClosestMonthlyMinimumTier = (params: {
  rawMonthlyMinimum: number;
  pricingInfo: PenguinPricingInformation;
  pricingFlow: PenguinPricingFlowWithProductVolumes;
}) => {
  const { rawMonthlyMinimum, pricingInfo, pricingFlow } = params;
  // ##PenguinMonthlyMinProration
  // If subscription term is >= 12 months, use the monthly minimum directly in
  // the computation of monthly minimum tiers. However, if the subscription term
  // is less than a year, prorate the monthly minimum when computing the tier by
  // the length of the subscription.
  // E.g. suppose the monthly min configured on the deal is 1000, but the
  // subscription term is only 6 months. Then, when selecting the tier, you
  // would act as though the monhtly minimum is 1000 * (6/12) = 500.
  // Record of manual CPQ testing here:
  // https://www.notion.so/dealops/george-s-requests-for-plaid-cpq-testing-a2b6c5a694ab4ae3ad10609b5b16da27?pvs=4#f1bad6e8553b4fd1b382b80e92fa97f3
  const subscriptionTermsForApprovalTiers = Math.min(
    pricingFlow.additionalData.subscriptionTerms ?? 12,
    12,
  );
  const monthlyMinimumForApprovalTiers =
    rawMonthlyMinimum * (subscriptionTermsForApprovalTiers / 12);
  let closestMonthlyMinimumTier = null;
  let delta = Infinity;

  for (const minimumTier of pricingInfo.monthlyMinimumTiers ?? []) {
    if (Math.abs(minimumTier.tier - monthlyMinimumForApprovalTiers) < delta) {
      closestMonthlyMinimumTier = minimumTier;
      delta = Math.abs(
        closestMonthlyMinimumTier.tier - monthlyMinimumForApprovalTiers,
      );
    }
  }

  return closestMonthlyMinimumTier;
};

export function getL1AndSuggestedPriceForProduct({
  productPrices,
  product,
  pricingFlow,
}: {
  productPrices: PenguinProductPrices;
  product: PenguinProductWithVolume;
  pricingFlow: PenguinPricingFlowWithProductVolumes;
}): { L1Price: number; suggestedPrice: number | null } {
  const currentMinimum =
    pricingFlow.manualQuote?.monthlyMinimum ??
    pricingFlow.recommendedQuote.monthlyMinimum;

  const pricingData =
    pricingFlow.currentPricingCurves[product.id].pricingInformation;
  if (pricingData.useVolumePricing && pricingData.monthlyVolumeTiers) {
    // Find closest volume tier
    const closestVolumeTier = findClosestVolumeTier(product, pricingData);
    if (closestVolumeTier === null) {
      datadogRum.addError(
        new Error(`no volume tier found for ${product.name}`),
      );
    }
    return {
      L1Price: closestVolumeTier?.level1 ?? pricingData.financeApproval,
      suggestedPrice: closestVolumeTier?.recommendedPricing ?? null,
    };
  } else if (pricingData.nonTieredPricing) {
    // If there is no volume pricing, use the non-tiered pricing
    // If level 1 isn't set, fall back to financeApproval
    return {
      L1Price:
        pricingData.nonTieredPricing.level1 ?? pricingData.financeApproval,
      suggestedPrice: pricingData.nonTieredPricing.recommendedPricing,
    };
  } else {
    // If there is no volume pricing or non-tiered pricing, use the monthly minimum tiers
    // If level 1 isn't set, fall back to financeApproval

    // Find the closest monthly minimum tier
    const closestMonthlyMinimumTier = findClosestMonthlyMinimumTier({
      rawMonthlyMinimum: currentMinimum,
      pricingInfo: pricingData,
      pricingFlow,
    });
    if (closestMonthlyMinimumTier === null) {
      datadogRum.addError(
        new Error(`no monthly min tier found for ${product.name}`),
      );
    }
    return {
      L1Price:
        closestMonthlyMinimumTier?.level1 ?? pricingData['financeApproval'],
      suggestedPrice: closestMonthlyMinimumTier?.recommendedPricing ?? null,
    };
  }
}

function CurrentQuoteTableRow(props: {
  isSupportProduct?: boolean;
  product: PenguinProductWithVolume;
  productPrices: PenguinProductPrices;
  recommendedQuote: PenguinRecommendedQuote;
  manualQuote?: PenguinManualQuote;
  approvalLevels: PenguinApprovalLevels;
  updateFlow: (
    flow: PenguinPricingFlowWithProductVolumes,
    showLoading?: boolean,
  ) => void;
  penguinOpportunityPricingFlow: PenguinPricingFlowWithProductVolumes;
  showTierEditorForProduct: (product: string) => void;
  showVolumeColumn: boolean;
  subscriptionTerms: number;
  onQuotePriceCtrlClick: (e: React.MouseEvent) => void;
  quotePriceClassName: string;
}) {
  const {
    product,
    productPrices,
    recommendedQuote,
    manualQuote,
    updateFlow,
    penguinOpportunityPricingFlow,
    showTierEditorForProduct,
    showVolumeColumn,
    subscriptionTerms,
  } = props;

  const quotePriceRef = useRef<HTMLInputElement>(null);
  const productData = productPrices[product.id];
  const pricingData =
    penguinOpportunityPricingFlow.currentPricingCurves[product.id]
      .pricingInformation;
  const stickerPrice: number = pricingData['selfServe'];

  const recommendedPrice = recommendedQuote.products[product.id];
  const pricingLogs: [string] = recommendedQuote.productPricingLogs
    ? recommendedQuote.productPricingLogs[product.id]
    : [''];

  const manualPrice = manualQuote?.products?.[product.id];

  const approvalLevel =
    penguinOpportunityPricingFlow.approvalLevels?.[product.id];

  const { L1Price, suggestedPrice } = getL1AndSuggestedPriceForProduct({
    productPrices: productPrices,
    product: product,
    pricingFlow: penguinOpportunityPricingFlow,
  });

  let approvalBadgeColor: BadgeColor;
  let approvalHumanReadable: string;

  if (approvalLevel.type === 'tiered') {
    // get max of all levels
    const level = approvalLevel.levels.reduce((acc, level) => {
      return Math.max(acc, level);
    }, 0);
    approvalBadgeColor = approvalBadgeColors[level];
    approvalHumanReadable = approvalLevelsHumanReadable[level];
  } else {
    approvalBadgeColor = approvalBadgeColors[approvalLevel.level];
    approvalHumanReadable =
      approvalLevelsHumanReadable[approvalLevel.level] ?? '';
  }

  const isPercentPricing = productData.priceType === PriceType.PERCENT;
  const numDecimals = isPercentPricing ? 4 : 3;
  const formatPrice = (price?: number) => {
    if (price == null) {
      return 'N/A';
    }
    if (isPercentPricing) {
      return (
        price.toLocaleString('en-US', { minimumFractionDigits: numDecimals }) +
        '%'
      );
    } else {
      return formatCurrency({
        amount: price,
        currency: 'USD',
        rounding: true,
        minimumFractionDigits: numDecimals,
      });
    }
  };

  const quotePrice = manualPrice ?? recommendedPrice ?? 0;

  const [monthlyRevenue, setMonthlyRevenue] = useState<number>(() =>
    monthlyRevenueForQuote(
      quotePrice,
      product.volume,
      product.transactionSize,
      productPrices[product.id],
    ),
  );

  useEffect(() => {
    setMonthlyRevenue(
      monthlyRevenueForQuote(
        quotePrice,
        product.volume,
        product.transactionSize,
        productData,
      ),
    );
  }, [quotePrice, product.volume]);

  function updateManualPrice(manualPrice: number | null | 0) {
    console.log('CALL updateManualPrice');
    let newManualQuote = manualQuote;

    const existingQuoteProductPrice = manualQuote?.products?.[product.id];
    if (
      existingQuoteProductPrice?.type === 'fixed' &&
      existingQuoteProductPrice.price === manualPrice
    ) {
      // No change
      return;
    }

    if (manualPrice === null) {
      //Remove the product from the manual quote
      const { [product.id]: _, ...rest } = manualQuote?.products ?? {};
      newManualQuote = {
        ...manualQuote,
        products: rest,
      };
    } else {
      newManualQuote = {
        ...manualQuote,
        products: {
          ...(manualQuote?.products ?? {}),
          [product.id]: { type: 'fixed', price: manualPrice },
        },
      };
    }

    updateFlow(
      {
        ...penguinOpportunityPricingFlow,
        manualQuote: newManualQuote,
      },
      false,
    );

    // Update all dependent numbers
    setMonthlyRevenue(
      monthlyRevenueForQuote(
        quotePrice,
        product.volume,
        product.transactionSize,
        productPrices[product.id],
      ),
    );
  }

  /***
   * Ramp up toggle
   */
  const [showRampUp, setShowRampUp] = useState(false);

  let volumeSubtext;
  if (product.rampType === 'custom' && product.customRampUp) {
    volumeSubtext = `${product.customRampUp.length} Mo ramp`;
  } else if (product.rampType === 'linear' && product.linearRampUpConfig) {
    volumeSubtext = `${product.linearRampUpConfig.months} Mo ramp`;
  } else if (
    product.rampType === 'linear_quarterly' &&
    product.linearRampUpConfig
  ) {
    volumeSubtext = `${product.linearRampUpConfig.months} Mo ramp`;
  }

  const rampUpRows = getVolumeRampUpRows(product);

  function setCustomRampUpRow(idx: number, value: number) {
    let newCustomRampProduct: PenguinProductWithVolume;
    if (product.rampType !== 'custom') {
      newCustomRampProduct = {
        ...product,
        rampType: 'custom',
        customRampUp: getVolumeRampUpRows(product),
      };
    } else {
      newCustomRampProduct = product;
    }

    // Modify new value at the idx
    newCustomRampProduct.customRampUp[idx] = value;
    if (idx === newCustomRampProduct.customRampUp.length - 1) {
      // If it's the final month we also need to update the volume
      newCustomRampProduct.volume = value;
    }

    updateFlow(
      {
        ...penguinOpportunityPricingFlow,
        products: penguinOpportunityPricingFlow.products.map((p) => {
          if (p.id === product.id) {
            return newCustomRampProduct;
          }
          return p;
        }) satisfies PenguinProductWithVolume[],
      },
      false,
    );
  }

  const hasRampUp = rampUpRows.length > 0;
  const lastRowIdx = rampUpRows.length - 1;

  const isTiered = quotePrice.type === 'tiered';

  const getMonthlyRevenueTooltip = ({
    volume,
  }: {
    volume: number;
  }): ReactNode | null => {
    const formatNum = (n: number): string => {
      return n.toLocaleString('en-US', { maximumFractionDigits: 3 });
    };
    if (quotePrice.type === 'tiered') {
      const tiers = quotePrice.tiers;
      const tiersAndVolumes = _.zip(
        tiers,
        getVolumeForEachTier({
          tiers: tiers,
          volume: volume,
        }),
      ) as [Tier, number][];
      const nonZeroTiersAndVolume = tiersAndVolumes.filter(
        ([tier, volume]) => volume !== 0,
      );

      return (
        <div>
          {nonZeroTiersAndVolume.map(([tier, volume], i) => {
            const isLast = i + 1 === nonZeroTiersAndVolume.length;
            const priceString = tier.price.toLocaleString('en-US', {
              minimumFractionDigits: 3,
            });
            const maybePercent = productData.priceType === 'percent' ? '%' : '';
            return (
              <div>
                <span className="font-extrabold">Tier {i + 1}</span>{' '}
                <span className="font-normal">
                  ({tier.minimum}-{tier.minimum + volume - 1})
                </span>
                <span className="font-semibold">
                  : {priceString}
                  {maybePercent} × {formatNum(volume)} {!isLast && '+'}
                </span>
              </div>
            );
          })}
        </div>
      );
    }
  };
  const fakeCost = props.isSupportProduct
    ? null
    : generateFakeCost(L1Price, product.name, productData);
  const grossMargin = monthlyRevenue - (fakeCost ?? 0) * product.volume;

  return (
    <>
      <tr>
        {/* Product */}
        <td className="flex min-w-[172px] items-center gap-4 border-b border-gray-200 px-6 py-4">
          <div className="h-5 w-5 flex-shrink-0">
            {hasRampUp && (
              <button
                className="absolute flex h-5 w-5 items-center justify-center rounded-full border border-fuchsia-900 bg-fuchsia-50"
                onClick={() => {
                  setShowRampUp(!showRampUp);
                }}
              >
                {showRampUp ? (
                  <ChevronDownIcon className="h-3 w-3" aria-hidden="true" />
                ) : (
                  <ChevronRightIcon className="h-3 w-3" aria-hidden="true" />
                )}
              </button>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-sm font-medium text-gray-900">
              {product.name}
            </span>
            <span className="text-xs text-gray-600">
              {productPrices[product.id]['unitDefinition'] ?? 'N/A'}
            </span>
            {product.transactionSize !== undefined && (
              <span className="text-xs text-gray-600">
                Avg transaction size:{' '}
                {formatCurrency({
                  amount: product.transactionSize,
                  currency: 'USD',
                })}
              </span>
            )}
          </div>
        </td>
        {/* Monthly est. volume */}
        {showVolumeColumn && (
          <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
            <div className="flex flex-col">
              <span className="">
                {productData.volumeType === 'currency' && '$'}
                {product.volume.toLocaleString()}
              </span>
              <span className="">{volumeSubtext}</span>
            </div>
          </td>
        )}
        {/* Rack Rate */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium text-purple-700">
          <button
            className=""
            disabled={typeof stickerPrice !== 'number'}
            title="Set quote price to rack rate"
            onClick={() => {
              if (typeof stickerPrice === 'number') {
                updateManualPrice(stickerPrice);
              }
            }}
          >
            <Badge color="purple">{formatPrice(stickerPrice)}</Badge>
          </button>
        </td>
        {/* L1 Price */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium text-orange-700">
          <button
            className=""
            disabled={typeof L1Price !== 'number'}
            title="Set quote price to L1 price"
            onClick={() => {
              if (typeof L1Price === 'number') {
                updateManualPrice(L1Price);
              }
            }}
          >
            <Badge color="orange">{formatPrice(L1Price)}</Badge>
          </button>
        </td>
        {/* Fake Cost */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium text-orange-700">
          <button
            className=""
            disabled={typeof fakeCost !== 'number'}
            title="Set quote price to cost"
            onClick={() => {
              if (typeof fakeCost === 'number') {
                updateManualPrice(fakeCost);
              }
            }}
          >
            <Badge color="red">
              {!isNil(fakeCost) ? formatPrice(fakeCost) : 'N/A'}
            </Badge>
          </button>
        </td>
        {/* Suggested price */}
        <td className="has-tooltip whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium">
          <button
            className=""
            disabled={typeof suggestedPrice !== 'number'}
            title="Set quote price to suggested price"
            onClick={() => {
              if (typeof suggestedPrice === 'number') {
                updateManualPrice(suggestedPrice);
              }
            }}
          >
            <Badge color="green">
              {formatPrice(suggestedPrice != null ? suggestedPrice : undefined)}
            </Badge>
            {window.isDeveloperModeEnabled && (
              // Only show this tooltip in developer mode
              <span className="tooltip z-20 -ml-60 mt-10 whitespace-nowrap rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white shadow-sm dark:bg-gray-700">
                {pricingLogs.map((pricingLog) => {
                  return (
                    <div key={pricingLog} className="text-xs text-gray-100">
                      {pricingLog}
                    </div>
                  );
                })}
              </span>
            )}
          </button>
        </td>
        {/* Quote price */}
        <td
          className={classNames(
            'whitespace-nowrap border-b border-gray-200 p-0 text-sm font-medium text-gray-500',
            props.quotePriceClassName,
          )}
          onClick={(e: React.MouseEvent<HTMLTableDataCellElement>) => {
            if (e.metaKey) {
              if (!isTiered && !isPercentPricing) {
                props.onQuotePriceCtrlClick(e);
              }
            } else {
              if (isTiered) {
                showTierEditorForProduct(product.id);
              } else {
                if (quotePriceRef.current !== null) {
                  quotePriceRef.current.focus();
                }
              }
            }
          }}
        >
          <EditableIndicator className="py-4 pl-6">
            {isTiered ? (
              'Tiered'
            ) : (
              <FormattedNumberField
                ref={quotePriceRef}
                type="text"
                value={fixedPrice(quotePrice)}
                required={true}
                className=" -ml-3 mr-0 max-w-[120px] cursor-pointer border-none bg-transparent text-sm font-medium text-gray-500 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
                updateValue={(value: number) => {
                  updateManualPrice(value);
                }}
                numberDecimals={numDecimals}
                prefix={isPercentPricing ? undefined : '$'}
                suffix={isPercentPricing ? '%' : undefined}
              />
            )}
          </EditableIndicator>
        </td>
        {/* Approval level */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium text-green-700">
          <Badge color={approvalBadgeColor}>{approvalHumanReadable}</Badge>
        </td>
        {/* Est. Monthly Revenue */}
        <Tooltip
          as="td"
          className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm font-medium text-gray-500"
          location="LEFT"
          disableTooltip={!isTiered}
          text={
            isTiered && getMonthlyRevenueTooltip({ volume: product.volume })
          }
        >
          {props.isSupportProduct
            ? 'See below'
            : formatCurrency({ amount: monthlyRevenue, currency: 'USD' })}
        </Tooltip>
        {/* Gross Margin */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm font-medium text-gray-500">
          {props.isSupportProduct
            ? 'N/A'
            : formatCurrency({ amount: grossMargin, currency: 'USD' })}
        </td>
      </tr>

      {/* Volume ramp up rows */}
      {rampUpRows.map((volume, idx) => {
        const monthlyRevenueTooltip = getMonthlyRevenueTooltip({ volume });

        return (
          <Transition
            as="tr"
            className="overflow-hidden"
            show={hasRampUp && showRampUp}
            enter="transition-all duration-300"
            enterFrom="max-h-0 opacity-0"
            enterTo="max-h-[48rem] opacity-100"
            leave="transition-all duration-300"
            leaveFrom="max-h-[48rem] opacity-100"
            leaveTo="max-h-0 opacity-0"
          >
            <td className="flex h-full items-center gap-2 bg-gray-100 px-6 py-4 text-sm text-gray-500">
              <ArrowDownRightIcon
                className="h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
              Month {idx + 1}
            </td>
            <td className="bg-gray-100 text-sm text-gray-500">
              {idx !== lastRowIdx ? (
                <EditableIndicator className="pl-4" backgroundColor="gray-100">
                  <FormattedNumberField
                    type="text"
                    value={volume}
                    required={true}
                    className="h-full w-0 grow cursor-pointer border-none bg-transparent px-0 text-sm text-gray-500 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
                    updateValue={(value: number) => {
                      setCustomRampUpRow(idx, value);
                    }}
                    numberDecimals={0}
                  />
                </EditableIndicator>
              ) : (
                <span className="pl-4 font-semibold">
                  {volume.toLocaleString()}
                </span>
              )}
            </td>
            <td colSpan={5} className="bg-gray-100">
              {idx === 0 && product.rampType == 'custom' && (
                <button
                  className="pl-8 text-sm text-blue-500 hover:text-blue-700"
                  onClick={() => {
                    const newLinearProduct: PenguinProductWithVolume = {
                      ...product,
                      rampType: 'linear',
                      linearRampUpConfig: {
                        start: 0,
                        months: subscriptionTerms,
                      },
                    };

                    updateFlow(
                      {
                        ...penguinOpportunityPricingFlow,
                        products: penguinOpportunityPricingFlow.products.map(
                          (p) => {
                            if (p.id === product.id) {
                              return newLinearProduct;
                            }
                            return p;
                          },
                        ),
                      },
                      false,
                    );
                  }}
                >
                  Reset to linear ramp up between first and last month
                </button>
              )}
            </td>
            <Tooltip
              as="td"
              className="bg-gray-100 px-6 py-4 text-sm text-gray-500"
              text={monthlyRevenueTooltip}
              location={'LEFT'}
              disableTooltip={monthlyRevenueTooltip == null}
            >
              {formatCurrency({
                amount: monthlyRevenueForQuote(
                  quotePrice,
                  volume,
                  product.transactionSize,
                  productPrices[product.id],
                ),
                currency: 'USD',
              })}
            </Tooltip>
          </Transition>
        );
      })}
    </>
  );
}

function Input(props: {
  value: number;
  onBlur: (e: any) => void;
  currencyPricing?: boolean;
}) {
  const { currencyPricing } = props;
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <>
      {currencyPricing && '$'}
      <input
        type="number"
        value={value}
        onChange={(e) => {
          setValue(parseFloat(e.target.value));
        }}
        required={true}
        className="max-w-[120px] border-none bg-transparent pl-0 text-sm font-medium text-gray-500 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
        onBlur={() => {
          if (props.value !== value) props.onBlur(value);
        }}
      />
    </>
  );
}

function PenguinWaterfallChart() {
  const toCVFlat = (n: number): CurrencyValueFlat => {
    return { type: CurrencyValueType.FLAT, value: n, currency: 'USD' };
  };
  const toCVPercent = (n: number): CurrencyValuePercent => {
    return { type: CurrencyValueType.PERCENT, value: n };
  };
  const { pricingFlow } =
    usePricingFlowContext<PenguinPricingFlowWithProductVolumes>();

  const productPrices =
    pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo;
  const { professionalServicesProducts, mainProducts } =
    pricingFlow.products.reduce(
      (acc, product: PenguinProductWithVolume) => {
        const group = ['Support', 'Implementation Services'].includes(
          productPrices[product.id].skuGroup,
        )
          ? 'professionalServicesProducts'
          : 'mainProducts';
        acc[group].push(product);
        return acc;
      },
      {
        professionalServicesProducts: [] as PenguinProductWithVolume[],
        mainProducts: [] as PenguinProductWithVolume[],
      },
    );
  let pv = 0;
  let data: (Datum | undefined)[] = [];

  // First add monthly subscription fee if it exists
  const monthlyMinimum =
    pricingFlow.manualQuote?.monthlyMinimum ??
    pricingFlow.recommendedQuote?.monthlyMinimum;
  if (monthlyMinimum) {
    pv = monthlyMinimum;
    data.push({
      name: 'Monthly subscription fee',
      value: monthlyMinimum,
      transparentValue: 0,
      tooltipData: {
        'Gross margin': toCVFlat(monthlyMinimum),
        'Take rate': null,
      },
    });
  }

  // Find the recommended price of the Support products and add them together
  const supportBasicPriceRecommended = pricingFlow.recommendedQuote.products[
    'Developer Support (Basic)'
  ]
    ? pricingFlow.recommendedQuote.products['Developer Support (Basic)'].price
    : undefined;
  const supportBasicPriceManual =
    pricingFlow.manualQuote?.products &&
    pricingFlow.manualQuote?.products['Developer Support (Basic)'] &&
    pricingFlow.manualQuote?.products['Developer Support (Basic)'].type ===
      'fixed'
      ? pricingFlow.manualQuote.products['Developer Support (Basic)'].price
      : undefined;
  const supportBasicPrice =
    supportBasicPriceManual ?? supportBasicPriceRecommended ?? undefined;
  const supportPremiumRecommended = pricingFlow.recommendedQuote.products[
    'Developer Support (Premium)'
  ]
    ? pricingFlow.recommendedQuote.products['Developer Support (Premium)'].price
    : undefined;
  const supportPremiumManual =
    pricingFlow.manualQuote?.products &&
    pricingFlow.manualQuote?.products['Developer Support (Premium)'] &&
    pricingFlow.manualQuote?.products['Developer Support (Premium)'].type ===
      'fixed'
      ? pricingFlow.manualQuote.products['Developer Support (Premium)'].price
      : undefined;
  const supportPremiumPrice =
    supportPremiumManual ?? supportPremiumRecommended ?? undefined;
  // Add all the support prices together
  const hasSupport =
    supportBasicPrice !== undefined || supportPremiumPrice !== undefined;
  const supportPricePercentage = hasSupport
    ? (supportBasicPrice ?? 0) + (supportPremiumPrice ?? 0)
    : undefined;

  // Then add all non-support products
  let usageBasedRev = 0;
  data = data.concat(
    pricingFlow.products
      .map((product) => {
        if (
          professionalServicesProducts.some(
            (serviceProduct) => serviceProduct.name === product.name,
          )
        ) {
          return;
        }
        const productPrice = productPrices[product.id];
        const { L1Price, suggestedPrice } = getL1AndSuggestedPriceForProduct({
          productPrices: productPrices,
          product: product,
          pricingFlow: pricingFlow,
        });
        const fakeCost = generateFakeCost(L1Price, product.name, productPrice);
        const quotePrice =
          pricingFlow.manualQuote?.products?.[product.id] ??
          pricingFlow.recommendedQuote.products[product.id];
        const monthlyRevenue = monthlyRevenueForQuote(
          quotePrice,
          product.volume,
          product.transactionSize,
          productPrices[product.id],
        );
        const grossMargin = monthlyRevenue - (fakeCost ?? 0) * product.volume;

        const oldPv = pv;
        pv = grossMargin + oldPv; // set the pv for the next datum
        usageBasedRev += monthlyRevenue;
        return {
          name: product.name,
          value: grossMargin,
          transparentValue: oldPv,
          tooltipData: {
            'Gross margin': toCVFlat(grossMargin),
            'Take rate': toCVPercent((grossMargin / monthlyRevenue) * 100),
          },
        };
      })
      .filter((x) => !isNil(x)),
  );
  // add support products
  const supportMargin = ((supportPricePercentage ?? 0) * usageBasedRev) / 100;
  if (supportPricePercentage) {
    const oldPv = pv;
    pv = supportMargin + oldPv;
    data = data.concat([
      {
        name: 'Support',
        value: supportMargin,
        transparentValue: oldPv,
        tooltipData: {
          'Gross margin': toCVFlat(supportMargin),
          'Take rate': toCVPercent((supportMargin / usageBasedRev) * 100),
        },
      },
    ]);
  }
  const filteredData = data.filter((d) => d !== undefined) as Datum[];

  // Then add total
  filteredData?.push({
    name: 'Total',
    value: pv,
    transparentValue: 0,
    tooltipData: {
      'Gross margin': toCVFlat(pv),
      'Take rate': toCVPercent(
        (pv / (usageBasedRev + (supportMargin ?? 0) + monthlyMinimum)) * 100,
      ),
    },
  });

  return (
    <div className="flex flex-col gap-8 pt-12 px-8">
      <div className="text-lg text-gray-900 font-semibold">
        Gross Margin Analysis
      </div>
      {/* Legend */}
      <div className="w-full flex justify-end pt-4">
        <div className="flex flex-row gap-2">
          <div className="flex flex-row gap-2 items-center">
            <div
              className="h-2 w-2 rounded-md"
              style={{ backgroundColor: '#22C55E' }}
            />
            <span className="text-sm text-slate-700">Positive</span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div
              className="h-2 w-2 rounded-md"
              style={{ backgroundColor: '#DC2626' }}
            />
            <span className="text-sm text-slate-700">Negative</span>
          </div>

          <div className="flex flex-row gap-2 items-center">
            <div
              className="h-2 w-2 rounded-md"
              style={{ backgroundColor: '#3B82F6' }}
            />
            <span className="text-sm text-slate-700">Monthly gross profit</span>
          </div>
        </div>
      </div>
      {/* Chart */}
      <div className="w-full h-[300px]">
        <Waterfall
          data={filteredData}
          currency={'USD'}
          TooltipComponent={GrossProfitAndTakeRateTooltip}
        />
      </div>
    </div>
  );
}
