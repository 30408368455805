import { Link } from 'react-router-dom';

import { datadogRum } from '@datadog/browser-rum';
import axios from 'axios';
import { useState } from 'react';
import { Button } from '../components/Button';
import { TextField } from '../components/Fields';
import { Logo } from '../components/Logo';
import { SlimLayout } from '../components/SlimLayout';

export default function Contact() {
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  async function onClickSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setMessage(null);
    setError(null);
    setLoading(true);

    const formData = new FormData(event.currentTarget);
    const firstName = formData.get('first_name');
    const lastName = formData.get('last_name');
    const company = formData.get('company');
    const email = formData.get('email');

    // Send API request to /api/v1/save_contact_form with form data
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/submit_contact_form`,
        {
          firstName,
          lastName,
          email,
          company,
        },
      );
      setMessage("Thanks! We'll be in touch soon.");
    } catch (error) {
      datadogRum.addError(error);
      console.log(error);
      setError('Something went wrong. Please try again later.');
    }
    setLoading(false);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setMessage(null);
    setError(null);
  }

  return (
    <SlimLayout>
      <div className="flex">
        <Link to="/" aria-label="Home">
          <Logo className="h-10 w-auto" />
        </Link>
      </div>
      <h2 className="mt-20 text-lg font-semibold text-gray-900">
        Contact sales
      </h2>
      <p className="mt-2 text-sm text-gray-700">
        Already registered?{' '}
        <Link to="/login" className="font-medium text-blue-600 hover:underline">
          Sign in
        </Link>{' '}
        to your account.
      </p>
      <form
        className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2"
        onSubmit={onClickSubmit}
      >
        <TextField
          label="First name"
          name="first_name"
          type="text"
          autoComplete="given-name"
          required
          onChange={handleInputChange}
        />
        <TextField
          label="Last name"
          name="last_name"
          type="text"
          autoComplete="family-name"
          onChange={handleInputChange}
        />
        <TextField
          className="col-span-full"
          label="Email address"
          name="email"
          type="email"
          autoComplete="email"
          required
          onChange={handleInputChange}
        />
        <TextField
          className="col-span-full"
          label="Company"
          name="company"
          type="company"
          autoComplete="company"
          required
          onChange={handleInputChange}
        />
        <div className="col-span-full">
          {error && <span className="text-red-500">{error}</span>}
          {message && <span>{message}</span>}
        </div>
        <div className="col-span-full">
          <Button
            type="submit"
            disabled={loading}
            variant="solid"
            color="purple"
            className="w-full"
          >
            <span>
              Submit <span aria-hidden="true">&rarr;</span>
            </span>
          </Button>
        </div>
      </form>
    </SlimLayout>
  );
}
