import {
  ArrowTopRightOnSquareIcon,
  ArrowTrendingUpIcon,
  ClockIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  TagIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { round } from 'lodash';
import { ReactElement } from 'react';
import Badge, { BadgeColor } from 'src/components/Badge';
import { classNames } from 'src/dashboard/App';
import { formatCurrency } from 'src/dashboard/utils';
import { getOpportunitySFDCUrl } from '../../Penguin/Components/OpportunityOverviewBar';
import { usePricingFlowContext } from '../../PricingFlow';
import { AlpacaOpportunityData, AlpacaPricingFlow } from '../alpaca_types';

export function getOpportunityOverviewFields(
  alpacaOpportunityData: AlpacaOpportunityData,
): Array<{
  icon: React.FC<React.HTMLAttributes<SVGSVGElement>>;
  name: string;
  value: React.ReactNode;
}> {
  let stageNameBadgeColor: BadgeColor = 'gray';
  switch (alpacaOpportunityData.Opportunity__StageName) {
    case '07 - Closed Won':
      stageNameBadgeColor = 'green';
      break;
    case '09 - Closed Lost':
      stageNameBadgeColor = 'red';
      break;
  }

  return [
    {
      icon: UserIcon,
      name: 'Owner',
      value: alpacaOpportunityData.Opportunity__Owner_Name__c ?? '–',
    },
    {
      icon: TagIcon,
      name: 'Segment',
      value: alpacaOpportunityData.Account__Customer_Segment__c ?? '–',
    },
    {
      icon: GlobeAltIcon,
      name: 'Country',
      value: alpacaOpportunityData.Account__Country__c ?? '–',
    },

    {
      icon: ClockIcon,
      name: 'Stage',
      value: alpacaOpportunityData.Opportunity__StageName ? (
        <Badge color={stageNameBadgeColor}>
          {alpacaOpportunityData.Opportunity__StageName}
        </Badge>
      ) : (
        '–'
      ),
    },
    {
      icon: ArrowTrendingUpIcon,
      name: 'Probability (%)',
      value: alpacaOpportunityData.Opportunity__Probability + '%' ?? '–',
    },
    {
      icon: CurrencyDollarIcon,
      name: 'Total est. monthly gross profit',
      value: formatCurrency({
        amount: round(
          alpacaOpportunityData.Opportunity__Total_Est_Monthly_Gross_Profit_Margin__c ??
            0,
          2,
        ),
        currency: 'USD', // Hardcoded to USD because that's what the SFDC reporting currency is
      }),
    },
  ];
}

type FieldProps = {
  title: string;
  value: string;
  icon: ReactElement | null;
};
function Field(props: FieldProps) {
  return (
    <div className="m-4 border-b border-gray-200 pb-4">
      <div className="flex items-center mb-2">
        {props.icon}
        <div>
          <h3>{props.title}</h3>
          <p>{props.value}</p>
        </div>
      </div>
    </div>
  );
}

export default function AlpacaOpportunitySidebar() {
  const { pricingFlow } = usePricingFlowContext<AlpacaPricingFlow>();

  const fields = getOpportunityOverviewFields(
    pricingFlow.opportunityData as AlpacaOpportunityData,
  );

  return (
    <div className="relative sticky top-0 z-40 w-64 border-r border-gray-200 bg-white text-xs font-medium h-full">
      <div className="flex-grow overflow-auto pb-28">
        <div className="my-4 line-clamp-5 px-4 text-lg hover:line-clamp-none">
          {pricingFlow.sfdcOpportunityName}
        </div>
        <div className="bg-gray-100 px-6 py-2 text-gray-500">DETAILS</div>
        <div className="flex flex-wrap items-center divide-y">
          {fields.map(
            (field: { icon: any; name: string; value: any }, i: number) => {
              return (
                <div
                  key={i}
                  className="mx-2 flex w-full items-center gap-2 py-3 pr-4"
                >
                  <div className="flex h-9 w-9 justify-center">
                    <field.icon
                      className="m-2 text-gray-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="uppercase text-gray-400">{field.name}</div>
                    <div
                      className={classNames(
                        'opacity-100',
                        'text-md text-gray-600 transition-opacity duration-500 ease-in-out',
                      )}
                    >
                      {field.value}
                    </div>
                  </div>
                </div>
              );
            },
          )}
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 flex h-16 flex-row items-center justify-center border-t bg-white">
        <a
          href={getOpportunitySFDCUrl(pricingFlow)}
          target="_blank"
          rel="noopener noreferrer"
          className="col-span-full inline-flex"
        >
          <button className="flex inline-flex items-center  justify-center gap-2 rounded-lg border border-gray-200 px-5 py-2 font-semibold text-gray-700 shadow-sm hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900">
            Open in SFDC
            <ArrowTopRightOnSquareIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        </a>
      </div>
    </div>
  );
}
